import { IRequirementCategoryModel } from '../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementCategorySearch } from '../../../../../../service/requirement/category/IRequirementCategorySearch';
import { RequirementType } from '../../../../../../service/requirement/entity/RequirementType';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataGridDetailDomain } from '../../../../../design/dataTable/parts/detail/DataGridDetailDomain';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { RequirementCategoryAdminContextMenuDomain } from './context/RequirementCategoryAdminContextMenuDomain';
import { RequirementCategoryAdminDetailUI } from './detail/RequirementCategoryAdminDetailUI';
import { RequirementCategoryAdminModalDomain } from './modal/RequirementCategoryAdminModalDomain';
import { RequirementCategoryAdminUI } from './RequirementCategoryAdminUI';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class RequirementCategoryAdminDomain extends DataTableDomain<
  IRequirementCategoryModel,
  IRequirementCategorySearch
> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.requirement.category);
    this.ui = new RequirementCategoryAdminUI(this, null);
    this.modalDomain = new RequirementCategoryAdminModalDomain(
      rootPrivateServices.requirement.category,
      layoutDomain,
      this,
    );
    this.contextMenuDomain = new RequirementCategoryAdminContextMenuDomain({
      entityService: rootPrivateServices.requirement.category,
      dataTableDomain: this,
      layoutDomain,
    });
    this.detailDomain = new DataGridDetailDomain(new RequirementCategoryAdminDetailUI());
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Шаблоны требований');
    this.ui.tableSubtitle?.setValue('Категории');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-create'],
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-delete'],
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-update'],
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

  async loadDetail() {
    this.detailDomain.ui.isDetail.setValue(true);
    const entitiesDetailSearchResult = await this.rootPrivateServices.requirement.entity.search({
      limit: 100000,
      filter: { type: { equal: RequirementType.system } },
    });
    // @ts-ignore
    this.detailDomain.ui.detailEntities.setList(entitiesDetailSearchResult.data);
    this.detailDomain.ui.detailKey.setValue('categoryId');
  }
}
