import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { ITagEntityService } from '../../../../../../../service/tag/entity/ITagEntityService';
import { IRowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TagEntityAdminContexMenuUI } from './TagEntityAdminContexMenuUI';

export class TagEntityAdminContextMenuDomain extends RowContextMenuDomain<ITagEntityModel> {
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: ITagEntityService;
    dataTableDomain: IDataTableDomain<ITagEntityModel, any>;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<ITagEntityModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new TagEntityAdminContexMenuUI(this);
  }
}
