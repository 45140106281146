export enum RiskManagerQuestionFieldRelationType {
  all = 'all',
  static = 'static',
  answers = 'answers',
  // targets = 'targets',
}

export const RiskManagerQuestionFieldRelationTypeArray = Object.keys(RiskManagerQuestionFieldRelationType) as Array<
  keyof typeof RiskManagerQuestionFieldRelationType
>;
