import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { DataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/DataGridDetailUI';
import { IDataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/IDataGridDetailUI';
import RequirementEntityDetailComponents from './RequirementEntityDetailComponents';

export class RequirementEntityDetailUI
  extends DataGridDetailUI<IRequirementModel>
  implements IDataGridDetailUI<IRequirementModel>
{
  constructor(
    public additionalColumnEntity: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>(
      [],
    ),
  ) {
    super();
    this.getDetailPanelContent.setValue(RequirementEntityDetailComponents);
  }
}
