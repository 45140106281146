import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ApplicationAcceptStatus, IApplicationModel } from '../../../service/application/entity/IApplicationModel';
import {
  GPBIsProjectRoleCanVerifyRequirementsExtension
} from '../projectRole/GPBIsProjectRoleCanFinishAcceptanceTestsExtension';

const verifyApplicationStatus = (application: IApplicationModel) => {
  if (application.acceptStatus === ApplicationAcceptStatus.accepted) {
    return true;
  } else {
    return false;
  }
}

export const GPBIsCanFinishAcceptanceTests = (domain: ApplicationDetailDomain) => {
  const isCanFinishByRoles = GPBIsProjectRoleCanVerifyRequirementsExtension(domain);
  const isCanFinishBySystemStatus = verifyApplicationStatus(domain.ui.application.entity);
  return isCanFinishByRoles && isCanFinishBySystemStatus;
}