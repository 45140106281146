import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { AdminTableUI } from '../../../../../view/admin/page/table/store/AdminTableUI';
import { AdminLogModel } from './AdminLogModel';

export class AdminLogsUI extends AdminTableUI<AdminLogModel> {
  constructor(
    public allEntities = injectEntityList<AdminLogModel>([]),
    public startDate = injectPrimitive<any>(null),
    public endDate = injectPrimitive<any>(null),
    public selectedEvents = injectPrimitive<string[]>([]),
  ) {
    super();
  }
}
