import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { RiskManagerSystemRolePermissions } from '../systemRole/RiskManagerSystemRoleExtensions';
import { RiskManagerSettingsPage } from '../view/RiskManagerSettingsPage';

export const RiskManagerRouter = {
  path: '/settings/risk/manager',
  pageTitle: 'Настройки анкеты рисков',
  component: RiskManagerSettingsPage,
  allowedPermissions: [RiskManagerSystemRolePermissions['extension-risk-manager-settings-admin-read'] as any],
};
