import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import React from 'react';

import { NotificationEvent } from '../../../../../service/notification/INotificationModel';

interface NotificationIconProperty {
  event: NotificationEvent;
}

export const NotificationIcon = ({ event }: NotificationIconProperty) => {
  switch (event) {
    case NotificationEvent.PROJECT:
      return <BusinessCenterIcon />;
    case NotificationEvent.APPLICATION:
      return <AssignmentTurnedInIcon />;
    case NotificationEvent.JIRA:
      return <CloudDoneIcon />;
    case NotificationEvent.REQUIREMENT:
      return <AssignmentIcon />;
    case NotificationEvent.REQUIREMENT_TEMPLATE:
      return <AutoAwesomeMotionIcon />;
    default:
      return <NotificationsActiveIcon />;
  }
};
