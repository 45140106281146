import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetSearch } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerQuestionAnswerPresetContextMenuDomain } from './context/RiskManagerQuestionAnswerPresetContextMenuDomain';
import { RiskManagerQuestionAnswerPresetFormDomain } from './form/RiskManagerQuestionAnswerPresetFormDomain';
import { RiskManagerQuestionAnswerPresetUI } from './RiskManagerQuestionAnswerPresetUI';

export class RiskManagerQuestionAnswerPresetDomain extends DataTableDomain<
  IRiskManagerQuestionAnswerPresetModel,
  IRiskManagerQuestionAnswerPresetSearch
> {
  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.question.answerPreset);
    this.ui = new RiskManagerQuestionAnswerPresetUI(this, null);
    this.modalDomain = new RiskManagerQuestionAnswerPresetFormDomain(
      rootPrivateServices.question.answerPreset,
      layoutDomain,
      this,
    );
    this.contextMenuDomain = new RiskManagerQuestionAnswerPresetContextMenuDomain(
      rootPrivateServices.question.answerPreset,
      this,
      layoutDomain,
    );
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }
}
