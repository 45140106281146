import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';

export class DefaultSearchRequest implements ISearchRequestData {
  offset = 0;
  limit = 1000000;
  sort = {
    rules: [],
  };
  filter = {
    globalText: '',
  };
}
