import { ICode } from './theoryBlocks/ICode';
import { IFile, IVideo } from './theoryBlocks/IFile';
import { IHeading1, IHeading2, IHeading3 } from './theoryBlocks/IHeading';
import { IHidden } from './theoryBlocks/IHidden';
import { IHtml } from './theoryBlocks/IHtml';
import { IList } from './theoryBlocks/IList';
import { IParagraph } from './theoryBlocks/IParagraph';
import { ISpacing48, ISpacing64 } from './theoryBlocks/ISpacing';
import { ISpecial } from './theoryBlocks/ISpecial';
import { ITable } from './theoryBlocks/ITable';
import { ITabs } from './theoryBlocks/ITabs';
import { ITags } from './theoryBlocks/ITags';

export interface IPassingChapter {
  title: string;
  theoryBlocks: IPassingBlock<IPassingBlocks>[];
  sortIndex: number;
  pseudoId: string;
  passed: boolean;
  isUseful: boolean;
}

export type IPassingBlocks =
  | IHeading1
  | IHeading2
  | IHeading3
  | IParagraph
  | IList
  | ITable
  | IFile
  | IVideo
  | ICode
  | ISpacing48
  | ISpacing64
  | IHidden
  | ITabs
  | ITags
  | ISpecial
  | IHtml;

export interface IPassingBlock<TheoryData> {
  block: BlockType;
  data: any;
  sortIndex: number;
  pseudoId: string;
}

interface IPassingTextData {
  text: string;
}

export enum BlockType {
  HEADING_1 = 'Heading 1',
  HEADING_2 = 'Heading 2',
  HEADING_3 = 'Heading 3',
  PARAGRAPH = 'Paragraph',
  LIST = 'List',
  TABLE = 'Table',
  IMAGE = 'Image',
  VIDEO = 'Video',
  CODE_EXAMPLE = 'Code Example',
  SPACING_48 = '48 px',
  SPACING_64 = '64 px',
  HIDDEN_BLOCK = 'Hidden Block',
  TABS = 'Tabs',
  TAGS = 'Tags',
  SPECIAL_BLOCK = 'Special Block',
 
}
