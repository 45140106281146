import { IdGenerator } from '../../generator/IdGenerator';
import { IStore } from '../interface/IStore';

export abstract class Store implements IStore {
  protected storeId: string | null = null;

  getStoreId(): string {
    this.storeId = this.storeId || IdGenerator.generate();
    return this.storeId;
  }
}
