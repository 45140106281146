import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { ExportDataSupportedEntities } from './interface/ExportDataSupportedEntities';
import { IExportDataMainPageDomainData } from './interface/IExportDataMainPageDomainData';

export class ExportDataMainPageDomainData implements IExportDataMainPageDomainData {
  supportEntitiesList: IPrimitiveStore<(ExportDataSupportedEntities | string)[]> = injectPrimitive([]);
  isDataLoading: IPrimitiveStore<boolean> = injectPrimitive(false);
  entitiesData = {};
}
