import { appHistory } from '../../application/ApplicationRouter';
import { IRouterService } from './IRouterService';

export class WebRouterService implements IRouterService {
  goTo(url: string): void {
    appHistory.push(url);
  }

  goBack(): void {
    appHistory.goBack();
  }

  getActiveUrl() {
    return appHistory.location.pathname;
  }
}
