import { Tooltip, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { PropsWithChildren } from 'react';

import { isOverflown } from '../../utils/isOverflown';

type AllHTMLElementProps = React.HTMLProps<HTMLElement>;

interface IOverflownContentProps extends AllHTMLElementProps {
  tooltipText?: string;
  contentClasses?: string;
  contentSx?: Record<string, string>;
  placement?: TooltipProps['placement']
}

export const OverflownContent = ({ contentClasses, children, placement = 'top', contentSx, ...props }: PropsWithChildren<IOverflownContentProps>) => {
  const [elementRef, setElementRef] = useState<HTMLDivElement>();
  const { title } = useStyles();
  const classes = classNames(title, contentClasses);

  return (
    //@ts-ignore
    <Tooltip placement={placement} title={elementRef && isOverflown(elementRef) ? children : ''}>
      {/* @ts-ignore */}
      <div {...props} ref={setElementRef} className={classes} sx={contentSx}>
        {children}
      </div>
    </Tooltip>
  );
};

export const useStyles = makeStyles(() => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
