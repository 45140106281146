import React, { useEffect } from 'react'
import { observer } from 'mobx-react';
import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ApplicationSearchBar } from './ApplicationSearchBar'

export const AllApplicationsPageHeader = observer(({ projectInfoDomain }) => {
  const isLoading = false
  const classes = useStyles();

  useEffect(() => {
    projectInfoDomain.getCountOfProjectsAndApplications();
  }, [])

  const projectsCount = projectInfoDomain.ui?.projectsTotalCount?.value || 0;
  const applicationsCount = projectInfoDomain.ui?.applicationsTotalCount?.value || 0;

  return (
    <Grid height={150} marginBottom={5}>
      {!isLoading && (
        <Grid item={true} xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant={'h4'}>Мои системы</Typography>
            <Divider className={classes.titleDivider} />
            <div className={classes.tableDivider} />
            <Box sx={{ display: 'flex', gap: '16px' }}>
            </Box>
          </Grid>
          <Grid item>
            <div className={classes.projectCounter}>
              <div style={{ color: '#b6b6b6' }}>
                Всего:&nbsp;
              </div>
              <div>
                {`проектов ${projectsCount}, систем ${applicationsCount}`}
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <ApplicationSearchBar domain={projectInfoDomain} />
    </Grid>
  )
})

export const useStyles = makeStyles((theme: Theme) => ({
  titleDivider: {
    width: theme.spacing(12),
    margin: '10px 0',
  },
  tableDivider: {
    margin: '30px 0',
  },
  imageContainer: {
    minWidth: 450,
  },
  projectCounter: {
    width: 250,
    height: 41,
    fontWeight: 200,
    fontSize: 14,
    textAlign: 'end',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-end',
  },
}));
