import { TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { CSSProperties } from 'react';

import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';

export interface ITextFilterProperties {
  currentText: IPrimitiveStore<string>;
  setNewValue: (text: string) => void;
  style: CSSProperties;
}

export const TextFilter = observer(({ currentText, setNewValue, style }: ITextFilterProperties) => {
  return (
    <TextField
      label="Поиск по тексту"
      variant="standard"
      type="text"
      value={currentText.value}
      onChange={(event: any) => {
        setNewValue(event?.target?.value || '');
      }}
      style={{
        ...style,
      }}
    />
  );
});
