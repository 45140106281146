import { IUserProjectRoleActionExtensionItem } from '../../../service/projectRole/entity/actions/UserProjectRoleActionExtension';

export const GPBProjectRoleExtension: IUserProjectRoleActionExtensionItem[] = [
  {
    name: 'Управление возможностью верификации требований при создании АС',
    value: 'changeVerificationStatusOnCreate',
  },
  {
    name: 'Возможность завершить ПСИ',
    value: 'acceptanceTestsFinish',
  },
];
