import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { observer } from 'mobx-react';
import { ProjectInfoDomain } from '../store/ProjectInfoDomain';
import { Colors } from '../../../../../design/color/Colors';
import { LayoutNotificationType } from '../../../../../layout/common/notification/store/ILayoutNotification';
import { LoadingButton } from '@mui/lab';
import { TransferApplicationType } from './TransferTypeEnum';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { ChipListInput } from '../../../../../design/input/chipListInput/ChipListInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const TransferModalWindow = observer(({ domain }: { domain: ProjectInfoDomain }) => {
  useEffect(() => {
    if (domain.ui.isOpenTransferModal.value) {
      domain.loadAllProjectsNamesAndId()
    }
  }, [domain.ui.isOpenTransferModal.value])

  const typeOfTransfer = domain.ui.transferApplicationType.value
  const nameOfTransfer = typeOfTransfer === TransferApplicationType.Transfer ? 'Перенести' : 'Копировать'
  const transferLable =
    typeOfTransfer === TransferApplicationType.Transfer ?
      'Перенос АС в другой проект'
      : typeOfTransfer === TransferApplicationType.Copy ?
        'Копирование АС'
        : ''

  const classes = useStyles();
  const projects = typeOfTransfer === TransferApplicationType.Transfer ? (domain.ui.projectNames.list.filter(
    (project) => project.id !== domain.ui.transferApplication.entity?.projectId
  ) || []) : (domain.ui.projectNames.list || [])

  const isCanTransfer = typeOfTransfer === TransferApplicationType.Transfer ?
    true :
    (domain.ui.copyApplicationData.value.newApplicationName && domain.ui.copyApplicationData.value.projectId)
      ? true : false
  const handleClose = () => {
    domain.ui.isOpenTransferModal.setValue(false)
    setTimeout(() => {
      domain.ui.transferApplicationType.setValue(null)
      domain.ui.transferProjectId.setValue('')
      domain.ui.transferApplication.setEntity(null)
      domain.ui.copyApplicationData.setValue({
        newApplicationName: '',
        applicationId: '',
        projectId: '',
        newLabelsForJira: [],
        needToCopy: {
          isNeedLocalRequirements: false,
          isNeedStatuses: false,
          isNeedAdditionalColumns: false,
          isNeedJiraIntegrations: false,
          isNeedVerifyAllRequirements: false
        }
      })
    }, 200)
  }

  const handleApplyTransfer = async () => {
    if (typeOfTransfer === TransferApplicationType.Transfer) {
      await domain.transferApplicationToOtherProject()
    }
    if (typeOfTransfer === TransferApplicationType.Copy) {
      await domain.copyApplicationToOtherProject()
    }
  }

  const handleChangeProject = (projectId) => {
    if (typeOfTransfer === TransferApplicationType.Transfer) {
      domain.ui.transferProjectId.setValue(projectId)
    }
    if (typeOfTransfer === TransferApplicationType.Copy) {
      const currentData = domain.ui.copyApplicationData.value
      currentData.projectId = projectId
      domain.ui.copyApplicationData.setValue(currentData)
    }
  }

  const transferConfirmation = () => (
    <div className={classes.transferWindow}>
      <div className={classes.transferTitle}>
        {nameOfTransfer} систему?
      </div>
      <div className={classes.transferDataBlock}>
        <div id="alert-dialog-description">
          Вы действительно хотите {nameOfTransfer.toLocaleLowerCase()} систему '{domain.ui.transferApplication.entity?.name}' ?
        </div>
      </div>
      <div className={classes.transferButtonBlock}>
        <Button
          onClick={(e) => { domain.layoutDomain.modalWindow.closeModalWindow() }}
          className={classes.cancelTransferButton}
        >
          {"Отменить"}
        </Button>
        <LoadingButton
          onClick={async (e) => {
            await handleApplyTransfer();
            domain.layoutDomain.modalWindow.closeModalWindow();
          }}
          className={classes.completeTransferButton}
          loading={domain.ui.isTransferLoading.value}
        >
          {nameOfTransfer} систему
        </LoadingButton>
      </div>
    </div>
  );

  const onTryToTransfer = async () => {
    if (typeOfTransfer === TransferApplicationType.Transfer && !domain.ui.transferProjectId.value) {
      await domain.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: `Ошибка валидации, пороверьте заполненность необходимых полей`,
      });
      return
    }

    if (typeOfTransfer === TransferApplicationType.Copy
      && !(domain.ui.copyApplicationData.value.projectId && domain.ui.copyApplicationData.value.newApplicationName)) {
      await domain.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: `Ошибка валидации, пороверьте заполненность необходимых полей`,
      });
      return
    }

    domain.layoutDomain.modalWindow.showModalWindow(transferConfirmation);
  };

  return (
    <Dialog open={domain.ui.isOpenTransferModal.value} onClose={handleClose}>
      <div className={classes.transferWindow}>
        <div className={classes.transferTitle}>{transferLable}</div>
        <TextField
          disabled={true}
          style={{ marginBottom: 20 }}
          label="Копируемая АС"
          variant="standard"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={domain.ui.transferApplication.entity?.name || ''}
        />
        <Autocomplete
          style={{ marginBottom: '15px' }}
          fullWidth
          options={projects}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Выберите проект *"
              variant="standard"
              className=''
              InputLabelProps={{
                shrink: true,
              }} />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          getOptionLabel={(option) => option.name || ''}
          onChange={(e, newValue) => {
            handleChangeProject(newValue?.id)
          }}
        />
        {typeOfTransfer === TransferApplicationType.Copy &&
          <CopyApplicationToOtherProject
            domain={domain}
            projects={projects}
          />
        }
        <div className={classes.transferButtonBlock}>
          <Button
            onClick={(e) => { handleClose() }}
            className={classes.cancelTransferButton}
          >
            Отменить
          </Button>
          <Button
            onClick={(e) => { onTryToTransfer() }}
            className={classes.completeTransferButton}
            disabled={!isCanTransfer}
          >
            {nameOfTransfer} систему
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const CopyApplicationToOtherProject = observer(({ domain, projects }:
  { domain: ProjectInfoDomain, projects: IProjectModel[] }) => {
  const classes = useStyles();

  return (
    <div className={classes.transferDataBlock}>
      <TextField
        disabled={!domain.ui.copyApplicationData.value.projectId}
        style={{ marginBottom: 20 }}
        label="Название новой АС  *"
        variant="standard"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const currentData = domain.ui.copyApplicationData.value
          currentData.newApplicationName = e.target.value
          if (!e.target.value) {
            currentData.newLabelsForJira = []
          }
          domain.ui.copyApplicationData.setValue(currentData)
        }}
      />
      <CopyCheckboxBlock domain={domain} />
      <Grid item marginBottom={3}>
        <ChipListInput
          id={'jira-labels-application-settingsForm'}
          options={domain.ui.copyApplicationData.value.newLabelsForJira}
          defaultValue={domain.ui.copyApplicationData.value.newLabelsForJira}
          disabled={!(
            domain.ui.copyApplicationData.value.needToCopy.isNeedJiraIntegrations
            && domain.ui.copyApplicationData.value.newApplicationName
            && domain.ui.copyApplicationData.value.projectId
          )}
          label={'Метка (Label) Системы в Jira'}
          placeholder={'Введите метку'}
          onChange={(newValues) => {
            const currentData = domain.ui.copyApplicationData.value
            currentData.newLabelsForJira = newValues
            domain.ui.copyApplicationData.setValue(currentData)
          }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>
    </div>
  )
})

const CopyCheckboxBlock = observer(({ domain }: { domain: ProjectInfoDomain }) => {
  const isDisabled = !(domain.ui.copyApplicationData.value.newApplicationName && domain.ui.copyApplicationData.value.projectId)
  return (
    <Grid container flexDirection={'column'} alignItems={'start'} style={{ marginBottom: 20 }} >
      <FormControlLabel
        style={{ marginBottom: 20 }}
        disabled={!(domain.ui.copyApplicationData.value.newApplicationName && domain.ui.copyApplicationData.value.projectId)}
        control={
          <Checkbox title='Требуется верификация сформированных требований' value={domain.ui.copyApplicationData.value.needToCopy.isNeedVerifyAllRequirements} />
        }
        checked={domain.ui.copyApplicationData.value.needToCopy.isNeedVerifyAllRequirements}
        onChange={(event: any) => {
          const currentData = domain.ui.copyApplicationData.value
          currentData.needToCopy.isNeedVerifyAllRequirements = event.target.checked
          if (currentData.needToCopy.isNeedStatuses) {
            currentData.needToCopy.isNeedStatuses = false
          }
          domain.ui.copyApplicationData.setValue(currentData)
        }}
        label={'Требуется верификация сформированных требований'}
      />
      <Grid item style={{
        color: Colors.blackOpacity('0.7'), marginBottom: 10
      }}>
        Выберите что нужно копировать
      </Grid>
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Checkbox title='Локальные требования' value={domain.ui.copyApplicationData.value.needToCopy.isNeedLocalRequirements} />
        }
        onChange={(event: any) => {
          const currentData = domain.ui.copyApplicationData.value
          currentData.needToCopy.isNeedLocalRequirements = event.target.checked
          domain.ui.copyApplicationData.setValue(currentData)
        }}

        checked={domain.ui.copyApplicationData.value.needToCopy.isNeedLocalRequirements}
        label={'Локальные требования'}
      />
      <Tooltip
        title={'Для переноса статусов необходимо убрать чекбокс "Требуется верификация сформированных требований"'}
        disableHoverListener={!domain.ui.copyApplicationData.value.needToCopy.isNeedVerifyAllRequirements}
      >
        <FormControlLabel
          disabled={isDisabled || domain.ui.copyApplicationData.value.needToCopy.isNeedVerifyAllRequirements}
          control={
            <Checkbox title='Статусы требований' value={domain.ui.copyApplicationData.value.needToCopy.isNeedStatuses} />
          }
          onChange={(event: any) => {
            const currentData = domain.ui.copyApplicationData.value
            currentData.needToCopy.isNeedStatuses = event.target.checked
            domain.ui.copyApplicationData.setValue(currentData)
          }}
          checked={domain.ui.copyApplicationData.value.needToCopy.isNeedStatuses}
          label={'Статусы требований'}
        />
      </Tooltip>
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Checkbox title='Дополнительные колонки' value={domain.ui.copyApplicationData.value.needToCopy.isNeedAdditionalColumns} />
        }
        onChange={(event: any) => {
          const currentData = domain.ui.copyApplicationData.value
          currentData.needToCopy.isNeedAdditionalColumns = event.target.checked
          domain.ui.copyApplicationData.setValue(currentData)
        }}
        checked={domain.ui.copyApplicationData.value.needToCopy.isNeedAdditionalColumns}
        label={'Дополнительные колонки'}
      />
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Checkbox title='Поля по интеграции с JIRA' value={domain.ui.copyApplicationData.value.needToCopy.isNeedJiraIntegrations} />
        }
        checked={domain.ui.copyApplicationData.value.needToCopy.isNeedJiraIntegrations}
        onChange={(event: any) => {
          const currentData = domain.ui.copyApplicationData.value
          currentData.needToCopy.isNeedJiraIntegrations = event.target.checked
          if (!event.target.checked) {
            currentData.newLabelsForJira = []
          }
          domain.ui.copyApplicationData.setValue(currentData)
        }}
        label={'Поля по интеграции с JIRA'}
      />
    </Grid>
  )
})

export const useStyles = makeStyles((theme: Theme) => ({
  transferWindow: {
    width: 444,
    minHeight: 220,
    display: 'flex',
    padding: 20,
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  transferTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
  },
  transferDataBlock: {
    width: '100%',
    fontSize: 16,
    marginBottom: 20,
  },
  transferButtonBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  completeTransferButton: {
    margin: 0,
    backgroundColor: `${Colors.greenButtonOpacity('0.7')} `,
    width: '190px',
    height: '40px',
    color: '#f4f4f4',
  },
  cancelTransferButton: {
    margin: 0,
    backgroundColor: `#E0E0E0`,
    width: '120px',
    height: '40px',
    marginRight: '10px',
    color: '#000000',
  },
  dataBlock: {
    marginBottom: 15
  }
}));
