import { injectRootService } from '../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IVendorEntityModel } from '../../../../../service/vendor/entity/IVendorEntityModel';
import { IVendorEntitySearch } from '../../../../../service/vendor/entity/IVendorEntitySearch';
import TableHeader from '../../../../design/dataTable/parts/appBar/TableHeader';
import { DataTableDomain } from '../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { VendorEntityContextMenuDomain } from './context/VendorEntityContextMenuDomain';
import { VendorEntityModalDomain } from './modal/VendorEntityModalDomain';
import { VendorEntityAdminUI } from './VendorEntityAdminUI';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class VendorEntityAdminDomain
  extends DataTableDomain<IVendorEntityModel, IVendorEntitySearch>
  implements IDataTableDomain<IVendorEntityModel, IVendorEntitySearch>
{
  public ui: VendorEntityAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.vendor.entity);
    this.modalDomain = new VendorEntityModalDomain(rootPrivateServices.vendor.entity, layoutDomain, this);

    this.contextMenuDomain = new VendorEntityContextMenuDomain({
      entityService: rootPrivateServices.vendor.entity,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui = new VendorEntityAdminUI(this, null);
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });

    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Требования внешних регуляторов');
    this.ui.tableSubtitle.setValue('Категории');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-create'],
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-delete'],
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-update'],
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }
}
