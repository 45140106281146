import { IServiceModel } from '../common/model/IServiceModel';
import { ITaskManagerSettings } from './IJiraTaskManager';

export interface ITaskManager<ISettingsType = ITaskManagerSettings> extends IServiceModel {
  isActive?: boolean;
  connectionState: TaskManagerConnectionState;
  settings?: ISettingsType;
  type: ITaskManagerType;
  targetId?: string;
  targetType?: ITaskManagerTargetType;
  serverId?: string;
}

export enum ITaskManagerType {
  entity = 'entity',
  jira = 'jira',
}

export enum ITaskManagerTargetType {
  application = 'application',
}

export enum TaskManagerConnectionState {
  notConnected = 'notConnected',
  connected = 'connected',
  errors = 'errors',
}
