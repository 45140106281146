import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryComponent } from '../ITheoryComponent';

export interface IChapter {
  title: string;
}
export const ChapterComponent = observer(({ data }: ITheoryComponent<IChapter>) => {
  return (
    <Typography variant="h3" sx={{ marginBottom: '32px' }}>
      {data.title}
    </Typography>
  );
});
