import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { FormUI } from '../../../../../../view/admin/page/form/store/FormUI';
import {
  IRiskManagerDataModel,
  RiskManagerDataTargetType,
} from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerDataFormDomain } from './RiskManagerDataFormDomain';

const defaultModel: IRiskManagerDataModel = {
  answersData: { data: [] },
  isComplete: false,
  isUpdateConflict: false,
  isNeedUpdate: false,
  questionFieldsCopy: [],
  questionsCopy: [],
  riskLevelsData: { data: [] },
  riskLevelsHistory: { data: [] },
  risksCopy: [],
  staticQuestionsCopy: [],
  targetId: null,
  targetType: RiskManagerDataTargetType.project,
  templateDataCopy: null,
  templateId: null,
  templateVersion: 0,
  questionFieldData: { data: [] },
  staticQuestionData: { data: [] },
  reportData: { downloads: [] },
  requirementThreatLevelsData: { data: [] },
  lastDataChangesDate: null,
};

export class RiskManagerDataFormUI extends FormUI<IRiskManagerDataModel> {
  constructor(
    public domain: RiskManagerDataFormDomain,
    public templates: IEntityListStore<IRiskManagerTemplateModel> = injectEntityList<IRiskManagerTemplateModel>([]),
    public project: IEntityStore<IProjectModel> = injectEntity({}),
  ) {
    super();
    this.setDefaultModel();
  }

  setDefaultModel() {
    this.model.setEntity(defaultModel);
  }
}
