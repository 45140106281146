import { ApplicationRouterExtensions } from '../../application/ApplicationRouterExtensions';
import { IContainer } from '../../common/container/IContainer';
import { RequirementEntityDrawerDomain } from '../../view/admin/page/requirement/newEntity/store/drawer/RequirementEntityDrawerDomain';
import { RequirementFormExtensionsInject } from '../../view/admin/page/requirement/newEntity/store/modal/inject/RequirementFormExtensionsInject';
import { RequirementEntityModalDomain } from '../../view/admin/page/requirement/newEntity/store/modal/RequirementEntityModalDomain';
import { SettingsPageExtension } from '../../view/admin/page/settings/SettingsPageExtension';
import { SystemRolesAdditionalPermissionsInject } from '../../view/admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsInject';
import { JiraPrioritiesAutocomplete } from '../../view/user/page/application/info/parts/application/ApplicationInfoDetailsForm';
import { ProjectFormBottomExtensions } from '../../view/user/page/project/form/parts/ProjectFormBottomExtensions';
import { ProjectFormDomain } from '../../view/user/page/project/form/store/ProjectFormDomain';
import { ProjectInfoButtonInject } from '../../view/user/page/project/info/inject/ProjectInfoButtonInject';
import { ProjectTeamSpaceExtensions } from '../../view/user/page/project/info/inject/ProjectTeamSpaceExtensions';
import { UserProjectTableExtensions } from '../../view/user/page/project/list/parts/extension/UserProjectTableExtensions';
import { IExtension } from '../interface/IExtension';
import { addMVideoTranslations } from './/locale/addMVideoTranslations';
import { RiskManagerDownloadReportButton } from './/report/DownloadReportButton';
import { RiskManagerRouter } from './/router/RiskManagerRouter';
import { RiskManagerSettingsMenu } from './/settings/RiskManagerSettingsMenu';
import { RiskManagerSystemRoleExtensions } from './/systemRole/RiskManagerSystemRoleExtensions';
import { extendProjectFormDomain } from './/view/data/form/project/extendProjectFormDomain';
import { RiskManagerDataForm } from './/view/data/form/RiskManagerDataForm';
import { RiskManagerDataInfoInProjectListColumn } from './/view/data/projectList/RiskManagerDataInfoInProjectListColumn';
import { RiskManagerDataInfoInProjectListHeader } from './/view/data/projectList/RiskManagerDataInfoInProjectListHeader';
import { RiskManagerPriorityByRequirementThreat } from './/view/priority/RiskManagerPriorityByRequirementThreat';
import { RiskManagerProjectRiskInfo } from './/view/projectRiskInfo/RiskManagerProjectRiskInfo';
import { RiskManagerRequirementThreatFormFields } from './/view/requirement/RiskManagerRequirementThreatFormFields';
import {
  extendRequirementEntityDrawerDomain,
  extendRequirementEntityModalDomain,
} from './/view/requirement/store/extendRequirementEntityModalDomain';
import { extendProjectListDomain } from './/view/data/projectList/domain/extendProjectListDomain';
import { ProjectListDomain } from '../../view/user/page/project/list/store/ProjectListDomain';
import { FilterComponent } from '../../view/user/page/project/list/inject/FilterComponent';
import { extendProjectInfoDomain } from './/view/projectRiskInfo/domain/extendProjectInfoDomain';
import { ProjectInfoDomain } from '../../view/user/page/project/info/store/ProjectInfoDomain';
import { JiraTemplatePrioritiesAutocomplete, TemplateJiraRiskContainer } from '../../view/admin/page/taskManager/server/connectinoTemplate/connectionTemplate';
import { RiskManagerDataJiraForm } from './view/jiraTemplate/jiraTemplateForm/RiskManagerDataForm';
import { RiskManagerJiraTemplatePriorityByRequirementThreat } from './view/jiraTemplate/jiraRiskPriority/RiskManagerPriorityByRequirementThreat';

export default class RiskManagerExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container = this.setRouters(container);
    container = await this.setSettingsPage(container);
    container = this.setProjectPage(container);
    container = this.setProjectListPage(container);
    container = this.setProjectInfoPage(container);
    container = this.setReportButton(container);
    container = this.setSystemRoleSettings(container);
    container = this.setPriorityAutocomplete(container);
    container = this.setRequirementFormThreatExtension(container);
    container = this.setJiraTemplateForm(container);
    container = this.setJiraTemplatePriority(container);
  }

  async setSettingsPage(container: IContainer): Promise<IContainer> {
    const settingsPageExtensions = container.get(SettingsPageExtension);
    settingsPageExtensions.push(RiskManagerSettingsMenu);
    container = await addMVideoTranslations(container);
    return container;
  }

  //     const ExtendedProjectListDomainWithManagerFilterRef = extendProjectListDomainWithManagerFilter(ExtendedProjectListDomainRef);
  //   container.set(ProjectListDomain, ExtendedProjectListDomainWithManagerFilterRef);
  //   return container;
  // }

  setProjectListPage(container: IContainer): IContainer {
    const ProjectListDomainRef = container.get(ProjectListDomain);
    const ExtendedProjectListDomainRef = extendProjectListDomain(ProjectListDomainRef);
    container.set(ProjectListDomain, ExtendedProjectListDomainRef);
    return container;
  }

  setProjectInfoPage(container: IContainer): IContainer {
    const ProjectInfoDomainRef = container.get(ProjectInfoDomain);
    const ExtendedProjectInfoDomainRef = extendProjectInfoDomain(ProjectInfoDomainRef);
    container.set(ProjectInfoDomain, ExtendedProjectInfoDomainRef);

    return container;
  }

  setProjectPage(container: IContainer): IContainer {
    const projectFormBottomExtensions = container.get(ProjectFormBottomExtensions);
    projectFormBottomExtensions.push({
      sortIndex: 1,
      Component: RiskManagerDataForm,
    });

    const projectTeamSpaceExtensions = container.get(ProjectTeamSpaceExtensions);
    projectTeamSpaceExtensions.push(RiskManagerProjectRiskInfo);

    const userProjectListPageTableExtensions = container.get(UserProjectTableExtensions);
    userProjectListPageTableExtensions.push({
      HeaderComponent: RiskManagerDataInfoInProjectListHeader,
      RowComponent: RiskManagerDataInfoInProjectListColumn,
    });

    const ProjectFormDomainRef = container.get(ProjectFormDomain);
    const ExtendedProjectFormDomainRef = extendProjectFormDomain(ProjectFormDomainRef);
    container.set(ProjectFormDomain, ExtendedProjectFormDomainRef);
    return container;
  }

  setReportButton(container: IContainer) {
    let projectInfoButtonInjects = container.get(ProjectInfoButtonInject);
    projectInfoButtonInjects.push(RiskManagerDownloadReportButton);

    return container;
  }

  setPriorityAutocomplete(container: IContainer) {
    container.set(JiraPrioritiesAutocomplete, RiskManagerPriorityByRequirementThreat);

    return container;
  }

  setRequirementFormThreatExtension(container: IContainer) {
    let formExtensions = container.get(RequirementFormExtensionsInject);
    formExtensions.push(RiskManagerRequirementThreatFormFields);

    const RequirementAdminModalDomainRef = container.get(RequirementEntityModalDomain);
    const ExtendedProjectFormDomainRef = extendRequirementEntityModalDomain(RequirementAdminModalDomainRef);
    container.set(RequirementEntityModalDomain, ExtendedProjectFormDomainRef);

    const RequirementDrawerDomainRef = container.get(RequirementEntityDrawerDomain);
    const ExtendedRequirementDrawerDomainRef = extendRequirementEntityDrawerDomain(RequirementDrawerDomainRef);
    container.set(RequirementEntityDrawerDomain, ExtendedRequirementDrawerDomainRef as any);
    return container;
  }

  setSystemRoleSettings(container: IContainer) {
    let injects = container.get(SystemRolesAdditionalPermissionsInject);
    injects.push(RiskManagerSystemRoleExtensions);

    return container;
  }

  setRouters(container: IContainer) {
    let routerExtension = container.get(ApplicationRouterExtensions);
    routerExtension.push(RiskManagerRouter);

    container.set(ApplicationRouterExtensions, routerExtension);
    return container;
  }

  setJiraTemplateForm(container: IContainer): IContainer {
    const settingsTemplateExtensions = container.get(TemplateJiraRiskContainer);

    container.set(settingsTemplateExtensions, RiskManagerDataJiraForm);
    return container;
  }

  setJiraTemplatePriority(container: IContainer): IContainer {
    const settingsTemplateExtensions = container.get(JiraTemplatePrioritiesAutocomplete);

    container.set(settingsTemplateExtensions, RiskManagerJiraTemplatePriorityByRequirementThreat);
    return container;
  }
}
