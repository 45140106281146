import { Typography } from '@mui/material';
import { GridCellValue } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react';
import React from 'react';
import Highlighter from 'react-highlight-words';

export interface MultiLineTextProperties {
  value: GridCellValue;
  words?: string | null;
}

export const MultiLineText = observer(({ value, words }: MultiLineTextProperties) => {
  const wordsArray = words ? words.split(' ') : [];
  return (
    <Typography style={{ whiteSpace: 'pre-line' }}>
      <Highlighter searchWords={wordsArray} autoEscape={true} textToHighlight={`${value}`} />
    </Typography>
  );
});
