import { IError } from '../../../common/error/IError';

export class RestErrorHandler {
  handle = (errorResponse: any) => {
    if (`${errorResponse?.status}` === '440') {
      localStorage.removeItem('jwtToken');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    if (errorResponse?.json?.webCode) {
      throw errorResponse.json;
    } else {
      const unknownError: IError = {
        data: {},
        systemCode: 'unknown',
        webCode: '500',
        message: errorResponse.message || 'Ошибка сервера',
      };

      throw unknownError;
    }
  };
}
