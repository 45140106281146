import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerRiskAdminModal } from './RiskManagerRiskAdminModal';

export class RiskManagerRiskAdminModalUI
  extends DataTableFormUI<IRiskManagerRiskModel>
  implements IDataTableFormUI<IRiskManagerRiskModel>
{
  constructor() {
    super();
    this.formComponent.setValue(RiskManagerRiskAdminModal);
  }
}
