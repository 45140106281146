import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { SpecificationEntityAdminDomain } from './store/SpecificationEntityAdminDomain';

export interface ISpecificationEntityAdminTableProperties extends IAdminPageProperties { }

export const SpecificationEntityAdminTable = observer(({ layoutDomain }: ISpecificationEntityAdminTableProperties) => {
  const [domain] = useState<SpecificationEntityAdminDomain>(new SpecificationEntityAdminDomain(layoutDomain));
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])

  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
