import { IPassingStepperDomain } from '../../../../../../../design/passingStepper/store/IPassingStepperDomain';
import { PassingStepperDomain } from '../../../../../../../design/passingStepper/store/PassingStepperDomain';
import { IPassingTheoryDomain } from '../IPassingTheoryDomain';
import { PassingTheoryDomain } from '../PassingTheoryDomain';

export class PassingTheoryStepperDomain extends PassingStepperDomain implements IPassingStepperDomain {
  public rootDomain: IPassingTheoryDomain;
  constructor(rootDomain: IPassingTheoryDomain) {
    super();
    this.rootDomain = rootDomain || new PassingTheoryDomain();
    this.isTheoryAdditionalAtions.setValue(false);
  }
}
