import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { StatusEntityAdminTable } from '../../status/entity/StatusEntityAdminTable';
import { StatusValueAdminTable } from '../../status/value/StatusValueAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const StatusSettingsItem: ISettingsItem = {
  id: 'status',
  settingsItem: {
    key: 'status',
    title: 'Статусы',
    buttons: [
      {
        title: 'Статусы',
        url: `/settings/status/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-statuses-read']],
      },
      {
        title: 'Значения',
        url: `/settings/status/value`,
        permissions: [UserSystemRoleModelPermissionMap['settings-statuses-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-statuses-read']],
    infoText:
      'Содержит перечень статусов, которые могут быть заданы для каждого требования на этапе работы с требованиями для АС. Отображаются в качестве столбцов формы с перечнем требований.',
    orderIndex: 500,
  },
  routers: [
    {
      key: 'status-entity-settings',
      path: '/settings/status/entity',
      pageTitle: 'Статусы',
      component: StatusEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-statuses-read']],
    },
    {
      key: 'status-value-settings',
      path: '/settings/status/value',
      pageTitle: 'Статусы',
      component: StatusValueAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-statuses-read']],
    },
  ],
};
