import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

export interface ScrollerProperties {
  children: any;
  detections: ScrollDetectionItem[];
  removeElement: (element: any) => void;
}

export interface ScrollDetectionItem {
  idName?: string;
  domElement?: any;
  handler: () => void;
  isOnce?: boolean;
  isDone?: boolean;
}

export const Scroller = observer(({ children, detections, removeElement }: ScrollerProperties) => {
  useEffect(() => {
    window.onscroll = () => {
      for (let i = 0; i <= detections.length - 1; i++) {
        const element = detections[i];
        const domElement = element.domElement || element.idName ? document.getElementById(element.idName || '') : null;
        let domElementBox = domElement?.getBoundingClientRect();
        if (domElementBox && domElement) {
          const isOnScreen =
            domElementBox.top > 0 && domElementBox.top + domElement.offsetHeight - window.innerHeight < 0;
          if (isOnScreen) {
            if (element.isOnce && !element.isDone) {
              detections[i].isDone = true;
              element.handler();
              removeElement(element);
            }
          }
        }
      }
    };
  }, [detections, removeElement]);

  return <div>{children}</div>;
});
