import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetService } from '../../../../service/question/answerPreset/IRiskManagerQuestionService';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionService } from '../../../../service/question/IRiskManagerQuestionService';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionService } from '../../../../service/question/static/IRiskManagerStaticQuestionService';
import { RiskManagerStaticQuestionDomain } from '../RiskManagerStaticQuestionDomain';
import { RiskManagerStaticQuestionContextMenuUI } from './RiskManagerStaticQuestionContextMenuUI';

export class RiskManagerStaticQuestionContextMenuDomain extends RowContextMenuDomain<IRiskManagerStaticQuestionModel> {
  public dataTableDomain: RiskManagerStaticQuestionDomain;

  constructor(
    entityService: IRiskManagerStaticQuestionService,
    dataTableDomain: RiskManagerStaticQuestionDomain,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerStaticQuestionModel> | null,
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerStaticQuestionContextMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateStaticQuestions();
  }
}
