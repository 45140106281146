import { DataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../../service/question/IRiskManagerQuestionSearch';
import { RiskManagerQuestionUI } from '../RiskManagerQuestionUI';

export class RiskManagerQuestionRowsUI
  extends DataTableRowsUI<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>
  implements IDataTableRowsUI<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>
{
  constructor(public rootUI: RiskManagerQuestionUI) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'presetId') {
      return {
        dependenciesModels: this.rootUI.answerPresets.list,
        valueField: 'name',
        activeField: null,
        isArray: false,
      };
    }

    return null;
  }
}
