import { makeStyles } from '@mui/styles';

export const useRequirementFilterStyle = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
