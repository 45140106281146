import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { PassingStepper } from '../../../../../design/passingStepper/PassingStepper';
import { IPassingQuizDomain } from './store/IPassingQuizDomain';
import { PassingQuizStepperDomain } from './store/stepper/PassingQuizStepperDomain';

export interface IPassingQuiz {
  quizDomain: IPassingQuizDomain;
}
export const PassingQuiz = observer(({ quizDomain }: IPassingQuiz) => {
  const [stepperDomain] = useState<PassingQuizStepperDomain>(new PassingQuizStepperDomain(quizDomain));

  return <PassingStepper stepperDomain={stepperDomain} />;
});
