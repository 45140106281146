import { RestCRUDService } from '../../../../service/common/rest/RestCRUDService';
import { IRiskManagerQuestionModel } from './IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from './IRiskManagerQuestionSearch';
import { IRiskManagerQuestionService } from './IRiskManagerQuestionService';

export class RestRiskManagerQuestionService
  extends RestCRUDService<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>
  implements IRiskManagerQuestionService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/question/entity`;
  }
}
