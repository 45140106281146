import { computed, observable } from 'mobx';

import { UIStore } from '../../../../../common/store/base/UIStore';
import { ILayoutModalWindowUI } from './ILayoutModalWindowUI';

export class LayoutModalWindowUI extends UIStore implements ILayoutModalWindowUI {
  @observable private componentState: any = null;
  @observable private isOpenState: boolean = false;
  @observable private modalOptions: { isFullWidth?: boolean; fullWidthMaxSize?: string } = {};

  @computed get activeModalWindow() {
    return {
      component: this.componentState,
      isOpen: this.isOpenState,
    };
  }

  @computed get activeModalOptions() {
    return this.modalOptions;
  }

  showModalWindow(
    modalContentComponent: Function,
    options?: { isFullWidth?: boolean; fullWidthMaxSize?: string },
  ): void {
    this.componentState = modalContentComponent;
    this.modalOptions = options || {};
    this.isOpenState = true;
  }

  closeActiveModalWindow() {
    this.isOpenState = false;
  }

  openActiveModalWindow() {
    this.isOpenState = true;
  }
}
