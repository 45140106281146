import { DomainStore } from '../../../../../common/store/base/DomainStore';
import { ILayoutNotification } from './ILayoutNotification';
import { ILayoutNotificationDomain } from './ILayoutNotificationDomain';
import { ILayoutNotificationStore } from './ILayoutNotificationStore';
import { LayoutNotificationStore } from './LayoutNotificationStore';

export class LayoutNotificationDomain extends DomainStore implements ILayoutNotificationDomain {
  constructor(private localNotification: ILayoutNotificationStore = new LayoutNotificationStore()) {
    super();
  }

  getNewNotifications(): ILayoutNotification[] {
    return this.localNotification.newNotifications;
  }

  showNotification(notification: ILayoutNotification): void {
    this.localNotification.showNotification(notification);
  }

  markNotificationAsCompleted(notificationId: string): void {
    this.localNotification.markAsCompleted(notificationId);
  }

  clearNotifications(): void {
    this.localNotification.cleanNotifications();
  }

  get notificationUI(): ILayoutNotificationStore {
    return this.localNotification;
  }
}
