import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IFileOption, ITaskLanguage } from '../../../../service/unit/interface/IUnitPractice';
import { IFile } from '../parts/fileTree/IFileTree';
import { ICodeEditorUI, ICodeFile, IReviewFileList } from './ICodeEditorUI';
import { CodeLanguage } from './ICodeLanguage';
import { CodeMode } from './ICodeMode';

export class CodeEditorUI implements ICodeEditorUI {
  constructor(
    public mode = injectPrimitive<CodeMode>(CodeMode.review),
    public language = injectPrimitive<CodeLanguage>(CodeLanguage.js),
    public lang = injectPrimitive<ITaskLanguage>(null as any),
    public fileTree = injectEntityList<IFile>(null as any),
    public isContextOpen = injectPrimitive<boolean>(false),
    public isInputFileOpen = injectPrimitive<boolean>(false),
    public isInputFolderOpen = injectPrimitive<boolean>(false),
    public isInputRenameOpen = injectPrimitive<boolean>(false),

    public event = injectPrimitive<any>({}),
    public reviewFile = injectPrimitive<IReviewFileList>(null as any),
    public writingFile = injectPrimitive<ICodeFile>(null as any),
    public currentOption = injectPrimitive<IFileOption>(null as any),
    public currentFile = injectPrimitive<IFile>(null as any),
  ) {}
}
