const categories = [
  { name: 'Категория ПДн'.toLocaleLowerCase(), id: 0 },
  { name: 'Категория субъектов ПДн'.toLocaleLowerCase(), id: 1 },
  { name: 'Количество субъектов ПДн'.toLocaleLowerCase(), id: 2 },
  { name: 'Тип актуальных угроз'.toLocaleLowerCase(), id: 3 },
];

const specifications = [
  { name: 'специальные', id: 0, categoryId: 0 },
  { name: 'биометрические', id: 1, categoryId: 0 },
  { name: 'иные', id: 2, categoryId: 0 },
  { name: 'общедоступные', id: 3, categoryId: 0 },

  { name: 'другие лица (не работники банка)', id: 0, categoryId: 1 },
  { name: 'только работники банка', id: 1, categoryId: 1 },

  { name: 'более 100 000', id: 0, categoryId: 2 },
  { name: 'менее 100 000', id: 1, categoryId: 2 },

  { name: 'для информационной системы актуальны угрозы 1-го типа', id: 0, categoryId: 3 },
  { name: 'для информационной системы актуальны угрозы 2-го типа', id: 1, categoryId: 3 },
  { name: 'для информационной системы актуальны угрозы 3-го типа', id: 2, categoryId: 3 },
]

export const protectLevels = [
  'уз 1', 'уз 2', 'уз 3', 'уз 4'
]

export const specificationsNames = specifications.map(specification => specification.name.toLocaleLowerCase());

const combinations = [
  {
    level1: [
      '0000', '0010', '01-0', '1--0', '2000',
      '2110', '2100', '21-0', '11-0', '2010',
      '0001',
      '0100', '0110', '10-0', '1000', '1010', '1100', '1110', '1-00', '1-10' // не явные
    ]
  },
  {
    level2: [
      '3000', '3010', '31-0',
      '0011', '01-1', '1--1', '2001', '3001',
      '0002',
      '3100', '3110', '0101', '0111', '1001', // не явные
      '1011', '10-1', '1101', '1111', '11-1', '1-01', '1-11' // не явные
    ]
  },
  {
    level3: [
      '2011', '21-1', '3011', '31-1',
      '0012', '01-2', '1--2', '2002',
      '2101', '2111', '3101', '3111', '0102', '0112', '1002', '1012', '10-2', // не явные
      '1102', '1112', '11-2', '1-02', '1-12' // не явные
    ]
  },
  {
    level4: [
      '2012', '21-2', '3002', '3012', '31-2',
      '2102', '2112', '3102', '3112' // не явные
    ]
  }
]

function getCombinationFromNames(names) {
  let combination = "";
  for (let category of categories) {
    const spec = specifications.filter(spec => spec.categoryId === category.id && names?.includes(spec.name));
    if (spec.length > 0) {
      combination += spec[0].id;
    } else {
      combination += '-';
    }
  }
  return combination;
}

function isValidSelection(names) {
  for (let category of categories) {
    let count = 0;
    for (let spec of specifications) {
      if (spec.categoryId === category.id && names?.includes(spec.name)) {
        count++;
      }
    }
    if (count > 1) {
      return false;
    }
  }
  return true;
}

export function getProtectionLevel(names) {
  if (!isValidSelection(names)) {
    return 0;
  }

  const userCombination = getCombinationFromNames(names);
  for (let i = 0; i < combinations.length; i++) {
    if (combinations[i][`level${i + 1}`] && combinations[i][`level${i + 1}`]?.includes(userCombination)) {
      return i + 1;
    }
  }
  return 0;
}
