import { DataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../../service/question/IRiskManagerQuestionSearch';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionSearch } from '../../../../service/question/static/IRiskManagerStaticQuestionSearch';
import { RiskManagerStaticQuestionDomain } from '../RiskManagerStaticQuestionDomain';
import { RiskManagerStaticQuestionUI } from '../RiskManagerStaticQuestionUI';

export class RiskManagerStaticQuestionRowsUI
  extends DataTableRowsUI<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>
  implements IDataTableRowsUI<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>
{
  constructor(public rootUI: RiskManagerStaticQuestionUI) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'presetId') {
      return {
        dependenciesModels: this.rootUI.answerPresets.list,
        valueField: 'name',
        activeField: null,
        isArray: false,
      };
    }

    return null;
  }
}
