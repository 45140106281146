import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { ActiveDirectoryAdminDomain } from './store/ActiveDirectoryAdminDomain';
import { DataTable } from '../../../../../design/dataTable/DataTable';

export interface ActiveDirectoryAdminTableProperties extends IAdminPageProperties {}

export const ActiveDirectoryAdminTable = observer(({ layoutDomain }: ActiveDirectoryAdminTableProperties) => {
  const [domain] = useState(new ActiveDirectoryAdminDomain(layoutDomain));

  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])
  
  return (
    <Fragment>
      <DataTable dataTableDomain={domain} tableHeight={window.innerHeight}/>
    </Fragment>
  );
});

