import { inject } from '../../../../../../common/container/inject';
import { IError } from '../../../../../../common/error/IError';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { Store } from '../../../../../../common/store/base/Store';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../service/RootAdminService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { IUserSystemRoleModel } from '../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { MailUI } from './MailUi';

export class MailAdminFormDomain extends Store {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new MailUI(),
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    public systemRoles: IEntityListStore<IUserSystemRoleModel> = injectEntityList([]),
  ) {
    super();
  }

  async loadData(id: string | null) {
    const { data: mailSettings } = await this.rootService.mail.entity
      .search({
        limit: 1,
        sort: [{ name: 'createdAt', isDesc: true }],
      })
      .catch(this.dataLoadErrorCatch);

    if (mailSettings.length) {
      this.ui.isEmptySettings.setValue(false);
      this.ui.mailSettings.setEntity(mailSettings[0]);
    }

    await this.loadDependencies();
  }

  async loadDependencies() {
    const systemRoles = (await this.rootPrivateServices.systemRole.entity.search({ limit: 100000 })).data;
    this.systemRoles.setList(systemRoles);
  }

  async save() {
    if (this.ui.isEmptySettings.value) {
      await this.rootService.mail.entity.createByModel(this.ui.mailSettings.entity).catch(this.dataLoadErrorCatch);
    } else {
      await this.rootService.mail.entity.updateByModel(this.ui.mailSettings.entity).catch(this.dataLoadErrorCatch);
    }
    this.router.goTo('/settings/mail/server');
  }

  cancelEdit = async () => {
    this.router.goTo('/settings/mail/server');
  };

  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };
}
