import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useRequirementFilterStyle } from './style';

interface FilterTitleProperties {
  selectedCount: number;
  title: string;
}

export const FilterTitle = observer(({ selectedCount, title }: FilterTitleProperties) => {
  const classes = useRequirementFilterStyle();
  const selectedCountText = selectedCount > 0 ? `(${selectedCount})` : '';

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <FilterListIcon
          style={{
            fontSize: '20px',
            paddingTop: '7px',
          }}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.heading}>
          {title} {selectedCountText}
        </Typography>
      </Grid>
    </Grid>
  );
});
