import { NotificationType } from '../../../../../../../service/notification/INotificationModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IUserSystemRoleModel } from '../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { IUserSystemRoleService } from '../../../../../../../service/systemRole/entity/IUserSystemRoleService';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SystemRoleAdminModalUI } from './SystemRoleAdminModalUI';

export class SystemRoleAdminModalDomain
  extends DataTableFormDomain<IUserSystemRoleModel, IUserSystemRoleService>
  implements IDataTableFormDomain<IUserSystemRoleModel>
{
  constructor(
    entityService: IUserSystemRoleService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IUserSystemRoleModel, any>,
  ) {
    super(entityService, layoutDomain, dataTableDomain, new SystemRoleAdminModalUI());
  }

  setPermission(permission: UserSystemRoleModelPermissionMap) {
    if (permission === UserSystemRoleModelPermissionMap['global-allow-any']) {
    }
  }

  setModelPermissions(permission: UserSystemRoleModelPermissionMap, isSet: boolean) {
    if (isSet && !this.ui.model.entity.permissions.allowedPermissions.includes(permission)) {
      this.ui.model.entity.permissions.allowedPermissions.push(permission);
    } else {
      this.ui.model.entity.permissions.allowedPermissions = this.ui.model.entity.permissions.allowedPermissions.filter(
        (item) => item !== permission,
      );
    }
  }

  setModelNotificationPermission(notificationType: NotificationType, isSet: boolean) {
    if (isSet && !this.ui.model.entity.notificationPermissions.includes(notificationType)) {
      this.ui.model.entity.notificationPermissions.push(notificationType);
    } else {
      this.ui.model.entity.notificationPermissions = this.ui.model.entity.notificationPermissions.filter(
        (item) => item !== notificationType,
      );
    }
  }
}
