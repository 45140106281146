import React from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import { ProjectInfoDomain } from '../store/ProjectInfoDomain';

interface IProjectSearchBarProps {
  domain: ProjectInfoDomain;
}

export const ApplicationSearchBar = observer(({ domain }: IProjectSearchBarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.searchBlock}>
      <TextField
        className={classes.searchInput}
        size="small"
        variant="outlined"
        maxRows={1}
        autoComplete={'off'}
        value={domain.ui.searchTerm.value}
        onChange={(event) => {
          domain.searchRequest(event.target?.value || "")
          domain.ui.projectListRenderLimit.setValue(10)
        }}
        placeholder='Поиск по системам'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
          style: { fontSize: 16, paddingLeft: 10 }
        }}
        InputLabelProps={{ style: { fontSize: 13 } }}
      />
    </div>
  )
})


export const useStyles = makeStyles((theme: Theme) => ({
  searchBlock: {
    width: '100%',
    marginRight: 'auto',
    margin: 0,
    fontSize: '13px',
    marginTop: 15,
    display: 'flex',
  },
  managerFilter: {
    width: '20%',
    minWidth: '200px',
    fontSize: '13px',
  },
  searchInput: {
    marginRight: 10,
    width: "100%"
  },
}));

