export enum LoggerEventList {
  'serverStart' = 'serverStart',
  'serverShutdown' = 'serverShutdown',
  'userSuccessLogin' = 'userSuccessLogin',
  'userLogout' = 'userLogout',
  'userFailLogin' = 'userFailLogin',
  'userCreateEntity' = 'userCreateEntity',
  'userRemoveEntity' = 'userRemoveEntity',
  'userUpdateEntity' = 'userUpdateEntity',
  'userDeactivateEntity' = 'userDeactivateEntity',
  'userActivateEntity' = 'userActivateEntity',
  'userLocalChangePassword' = 'userLocalChangePassword',
  'userRemoveSystemRole' = 'userRemoveSystemRole',
  'userAddSystemRole' = 'userAddSystemRole',
  'addUserToProject' = 'addUserToProject',
  'removeUserFromProject' = 'removeUserFromProject',
  'createProjectEntity' = 'createProjectEntity',
  'updateProjectEntity' = 'updateProjectEntity',
  'removeProjectEntity' = 'removeProjectEntity',
  'createProjectApplication' = 'createProjectApplication',
  'updateProjectApplication' = 'updateProjectApplication',
  'removeProjectApplication' = 'removeProjectApplication',
  'createSpecificationEntity' = 'createSpecificationEntity',
  'updateSpecificationEntity' = 'updateSpecificationEntity',
  'removeSpecificationEntity' = 'removeSpecificationEntity',
  'createAdditionalColumnEntity' = 'createAdditionalColumnEntity',
  'updateAdditionalColumnEntity' = 'updateAdditionalColumnEntity',
  'removeAdditionalColumnEntity' = 'removeAdditionalColumnEntity',
  'createAdditionalColumnContent' = 'createAdditionalColumnContent',
  'updateAdditionalColumnContent' = 'updateAdditionalColumnContent',
  'removeAdditionalColumnContent' = 'removeAdditionalColumnContent',
  'createRequirement' = 'createRequirement',
  'updateRequirement' = 'updateRequirement',
  'removeRequirement' = 'removeRequirement',
  'createCustomRequirement' = 'createCustomRequirement',
  'updateCustomRequirement' = 'updateCustomRequirement',
  'removeCustomRequirement' = 'removeCustomRequirement',
  'createProjectRole' = 'createProjectRole',
  'updateProjectRole' = 'updateProjectRole',
  'removeProjectRole' = 'removeProjectRole',
  'changePermissionsOnProjectRole' = 'changePermissionsOnProjectRole',
  'systemRoleChangePermissions' = 'systemRoleChangePermissions',
  'statusChangePermissions' = 'statusChangePermissions',
  'cleanLogger' = 'cleanLogger',
  'createActiveDirectoryServer' = 'createActiveDirectoryServer',
  'updateActiveDirectoryServer' = 'updateActiveDirectoryServer',
  'createJiraConnection' = 'createJiraConnection',
  'updateJiraConnection' = 'updateJiraConnection',
  'deleteJiraConnection' = 'deleteJiraConnection',
  'changeStatus' = 'changeStatus',
  'transferApplication' = 'transferApplication'
}

export const LoggerEventListAsArray: {
  key: string;
  value: string;
}[] = Object.entries(LoggerEventList).map(([key, value]) => ({ key, value }));
