import { AnalyticsDomain } from './AnalyticsDomain';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { IApplicationModel } from '../../../../../service/application/entity/IApplicationModel';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { ICurrentApplicationRequirementsData } from './ICurrentApplicationRequirementsData';
import { format } from 'date-fns';
import { IApplicationDataModel } from '../../../../../service/application/data/IApplicationDataModel';

export class AnalyticsRequirementsUi {
  public currentApplication = injectEntity<IApplicationModel>({})
  public applicationList = injectEntityList<IApplicationModel>([])
  public currentApplicationData = injectEntity<IApplicationDataModel>({})
  public analyticsApplicationRequirementDataTimeTab = injectPrimitive('applicationRequirementsDayData')
  public isLoadingApplicationRequirementDataChart = injectPrimitive(false)
  public isLoadingApplicationAnalytics = injectPrimitive(true)
  public applicationRequirementsDayData = injectPrimitive({
    data: [], statusesToView: []
  })
  public applicationRequirementsMonthData = injectPrimitive({
    data: [], statusesToView: []
  })
  public applicationRequirementsYearData = injectPrimitive({
    data: [], statusesToView: []
  })
  public currentApplicationRequirementsData = injectPrimitive<ICurrentApplicationRequirementsData[]>([])
  public currentApplicationRequirementsStatuses = injectPrimitive<any[]>([])
  public vendorTypeList = injectPrimitive<any[]>([])
  public currentVendorType = injectPrimitive<any>(null)
  public currentVendorTypeData = injectPrimitive<any>([])
  public vendorChartData = injectPrimitive<IVendorData[]>([])
  public isLoadingVendorDataChart = injectPrimitive(false)

  constructor(
    public domain: AnalyticsDomain,
  ) { }

  async loadData() {
    this.startLoadAllCharts()
    await this.getApplicationsForUser()
    this.endLoadAllCharts()
  }

  startLoadAllCharts() {
    this.isLoadingApplicationRequirementDataChart.setValue(true)
    this.isLoadingVendorDataChart.setValue(true)
  }

  endLoadAllCharts() {
    this.isLoadingApplicationRequirementDataChart.setValue(false)
    this.isLoadingApplicationAnalytics.setValue(false)
  }

  async getCurrentApplicationData(dataId) {
    const userDataApplication = await this.domain.rootPrivateServices.application.data.getById(dataId)
    this.currentApplicationData.setEntity(userDataApplication)
  }

  async getApplicationsForUser() {
    const userProjectsIds: string[] = (
      await this.domain.rootPrivateServices.project.entity.search({ fields: ['id'] })
    )?.data
      .filter(project => project.id !== undefined)
      .map(project => project.id!);

    const userApplications = await this.domain.rootPrivateServices.application.entity.search({
      filter: {
        projectId: {
          in: userProjectsIds
        }
      },
      fields: [
        'id', 'name', 'dataId'
      ]
    })
    if (!userApplications?.data || userApplications.data.length === 0 || !userApplications.data[0].dataId) {
      return;
    }
    const firstApplicationId = userApplications.data[0]?.id || '';
    this.applicationList.setList(userApplications.data);
    this.currentApplication.setEntity(userApplications.data[0]);
    await this.getCurrentApplicationData(userApplications.data[0].dataId)
    await this.getAllChartsData(firstApplicationId);
    this.currentApplicationRequirementsData.setValue(this.applicationRequirementsDayData.value.data);
  }

  async getAllChartsData(applicationId) {
    await this.getApplicationsRequirementsData(applicationId)
    await this.getVendorChartData(applicationId)
  }

  async setApplicationForGetAnalytics(value) {
    this.startLoadAllCharts()
    this.currentApplication.setEntity(value)
    await this.getCurrentApplicationData(value.dataId)
    await this.getAllChartsData(value.id)
    this.endLoadAllCharts()
  }

  async getApplicationsRequirementsData(applicationId) {
    const applicationRequirementsData = await this.domain.rootPrivateServices.analytics.data.getApplicationRequirementsData(applicationId)
    this.applicationRequirementsDayData.setValue(applicationRequirementsData.day)
    this.applicationRequirementsMonthData.setValue(applicationRequirementsData.month)
    this.applicationRequirementsYearData.setValue(applicationRequirementsData.year)
    this.currentApplicationRequirementsData.setValue(applicationRequirementsData.day.data)
    this.currentApplicationRequirementsStatuses.setValue(applicationRequirementsData.day.statusesToView)
    this.analyticsApplicationRequirementDataTimeTab.setValue('applicationRequirementsDayData')
  }

  async changeAnalyticsApplicationRequirementDataTimeTabs(value) {
    this.isLoadingApplicationRequirementDataChart.setValue(true)
    this.analyticsApplicationRequirementDataTimeTab.setValue(value)
    if (value === 'applicationRequirementsDayData') {
      this.currentApplicationRequirementsData.setValue(this.applicationRequirementsDayData.value.data)
      this.currentApplicationRequirementsStatuses.setValue(this.applicationRequirementsDayData.value.statusesToView)
    } if (value === 'applicationRequirementsMonthData') {
      this.currentApplicationRequirementsData.setValue(this.applicationRequirementsMonthData.value.data)
      this.currentApplicationRequirementsStatuses.setValue(this.applicationRequirementsMonthData.value.statusesToView)
    } if (value === 'applicationRequirementsYearData') {
      this.currentApplicationRequirementsData.setValue(this.applicationRequirementsYearData.value.data)
      this.currentApplicationRequirementsStatuses.setValue(this.applicationRequirementsYearData.value.statusesToView)
    }
    this.isLoadingApplicationRequirementDataChart.setValue(false)
  }

  async getVendorChartData(applicationId) {
    this.isLoadingVendorDataChart.setValue(true)
    const vendorChartData: IVendorData[] = await this.domain.rootPrivateServices.analytics.data.getApplicationVendorData(applicationId)

    if (!vendorChartData[vendorChartData.length - 1]) {
      this.vendorChartData.setValue([])
      await this.getDataForAllVendors()
      this.isLoadingVendorDataChart.setValue(false)
      return
    }

    this.vendorChartData.setValue(vendorChartData)
    const todayData = vendorChartData[vendorChartData.length - 1]

    const vendorTypesList = todayData.analyticsData.map((data) => {
      return {
        vendorId: data.vendorId,
        vendorName: data.vendorName
      }
    }).sort((a, b) => {
      if (a.vendorName < b.vendorName) {
        return -1;
      }
      if (a.vendorName > b.vendorName) {
        return 1;
      }
      return 0;
    })

    if (!vendorTypesList.length) {
      this.vendorChartData.setValue([])
      this.currentVendorTypeData.setValue([])
      this.currentVendorType.setValue(null)
      this.vendorTypeList.setValue([])
      this.isLoadingVendorDataChart.setValue(false)
      return
    }

    this.vendorTypeList.setValue([{
      vendorId: 'allVendorRequirements',
      vendorName: 'Все регуляторы'
    }, ...vendorTypesList])

    this.currentVendorType.setValue({
      vendorId: 'allVendorRequirements',
      vendorName: 'Все регуляторы'
    })
    await this.getDataForAllVendors()
    this.isLoadingVendorDataChart.setValue(false)
  }

  async getDataForVendorChart() {
    const type = this.currentVendorType.value
    const allData = this.vendorChartData.value

    const vendorData = allData.map(entry => {
      const screenDate = entry.screenDate;
      const analyticsDataForVendor = entry.analyticsData.find(data => data.vendorId === type.vendorId);
      return { screenDate, analyticsDataForVendor };
    });
    const result = vendorData.reduce((acc, entry) => {
      const screenDate = entry.screenDate;
      const analyticsDataForVendor = entry.analyticsDataForVendor;

      if (analyticsDataForVendor) {
        //@ts-ignore
        acc.push({
          date: format(new Date(screenDate), 'MM/yy'),
          totalVendorRequirementsCount: analyticsDataForVendor.totalVendorRequirementsCount - analyticsDataForVendor.vendorRequirementsIdsForStatusCount,
          vendorRequirementsIdsForStatusCount: analyticsDataForVendor.vendorRequirementsIdsForStatusCount,
          vendorId: analyticsDataForVendor.vendorId,
          vendorName: analyticsDataForVendor.vendorName
        });
      } else {
        //@ts-ignore
        acc.push({
          date: format(new Date(screenDate), 'MM/yy'),
          totalVendorRequirementsCount: 0,
          vendorRequirementsIdsForStatusCount: 0,
          vendorId: '',
          vendorName: ''
        });
      }

      return acc;
    }, []);

    this.currentVendorTypeData.setValue(result)
  }

  async getDataForAllVendors() {
    const typeList = this.vendorTypeList.value
    const allData = this.vendorChartData.value

    const result = []

    allData.forEach((data) => {
      const curentData = typeList.reduce((acc, currentType) => {
        const currentVendorData = data.analyticsData.find(dataItem => currentType.vendorId === dataItem.vendorId)
        if (!currentVendorData) {
          return acc
        }
        acc.totalVendorRequirementsCount += currentVendorData?.totalVendorRequirementsCount
        acc.vendorRequirementsIdsForStatusCount += currentVendorData?.vendorRequirementsIdsForStatusCount
        acc.dataByVendor.push({
          vendorName: currentVendorData.vendorName,
          totalVendorRequirementsCount: currentVendorData?.totalVendorRequirementsCount,
          vendorRequirementsIdsForStatusCount: currentVendorData?.vendorRequirementsIdsForStatusCount
        })
        return acc
      }, {
        totalVendorRequirementsCount: 0,
        vendorRequirementsIdsForStatusCount: 0,
        dataByVendor: []
      })

      //@ts-ignore
      result.push({
        date: format(new Date(data.screenDate), 'MM/yy'),
        totalVendorRequirementsCount: curentData.totalVendorRequirementsCount - curentData.vendorRequirementsIdsForStatusCount,
        vendorRequirementsIdsForStatusCount: curentData.vendorRequirementsIdsForStatusCount,
        vendorId: 'allVendorRequirements',
        vendorName: 'Все регуляторы',
        dataByVendor: curentData.dataByVendor
      })
    })

    this.currentVendorTypeData.setValue(result)
  }

  async setVendorType(value) {
    await this.isLoadingVendorDataChart.setValue(true)
    await this.currentVendorType.setValue(value)
    if (value.vendorId === 'allVendorRequirements') {
      await this.getDataForAllVendors()
    } else {
      await this.getDataForVendorChart()
    }

    await this.isLoadingVendorDataChart.setValue(false)
  }
}


interface IVendorData {
  screenDate: Date,
  applicationId: string,
  analyticsData: {
    vendorId: string;
    vendorName: string;
    totalVendorRequirementsCount: number;
    statusId: string;
    vendorRequirementsIdsForStatusCount: number;
  }[]
}
