import { IServiceModel } from '../../common/model/IServiceModel';

export interface IAdditionalColumnModel extends IServiceModel {
  name: string;
  type: AdditionalColumnType;
  description: string;
  orderIndex: number;
  isActive: boolean;
}

export enum AdditionalColumnType {
  LINK = 'link',
  ENUM = 'enum',
  DATE = 'date',
  EDITABLE_TEXT = 'editableText',
  DOWNLOAD_FILE = 'downloadFile',
  UPLOAD_FILE = 'uploadFile',
}
