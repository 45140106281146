import {
  UserSystemRoleModelPermissionMap,
} from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { GPBSystemRolesExtensionPermissions } from '../systemRole/GPBSystemRolesExtension';

export const GPBIsProjectRoleCanVerifyRequirementsExtension = (domain: ApplicationDetailDomain) => {
  const isAllowAny = domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
  const isSystemAllow = domain.layoutDomain.userHaveAnyAccess([
    GPBSystemRolesExtensionPermissions['project-not-in-team-permission-finish-acceptance-tests'] as any,
  ]);
  const isProjectAllow = domain.ui.userProjectRoleActions.value.includes('acceptanceTestsFinish');

  return isAllowAny || isProjectAllow || isSystemAllow;
};
