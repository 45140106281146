import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { IVendorEntityModel } from '../../../../../../../service/vendor/entity/IVendorEntityModel';
import { DataTableFormUI } from './../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from './../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { VendorRequirementModal } from './VendorRequirementModal';
export class VendorRequirementModalUI
  extends DataTableFormUI<IVendorRequirementModel>
  implements IDataTableFormUI<IVendorRequirementModel>
{
  constructor(
    public vendorEntity: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([]),
    public requirementEntity: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
  ) {
    super();
    this.formComponent.setValue(VendorRequirementModal);
  }
}
