import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { TaskManagerAdminDomain } from './store/TaskManagerAdminDomain';

export interface ITagCategoryAdminTableProperties extends IAdminPageProperties {}

export const TaskManagerAdminTable = observer(({ layoutDomain }: ITagCategoryAdminTableProperties) => {
  const [domain] = useState<TaskManagerAdminDomain>(new TaskManagerAdminDomain(layoutDomain));
  useEffect(() =>  {
    layoutDomain.ui.pagePadding.setValue(0);
    return(()=> layoutDomain.ui.pagePadding.setValue(24))
  },[])
  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
