import { Theme } from '@emotion/react';
import { makeStyles } from '@mui/styles';

export const useButtonStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '200px',
    height: 'fit-content',
    marginRight: '10px',
    color: '#f4f4f4',
  },
}));
