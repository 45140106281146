import { injectPrimitive } from "../../../../../../../common/store/base/injectPrimitive";
import { PassingListPageUI } from "../../passingListPage/store/PassingListPageUI";

export class LearningUserRatingPageUI extends PassingListPageUI {
    constructor(
        public userDisplayName = injectPrimitive<string>(''),
        public userSystemRole = injectPrimitive<string>('')

    ) {
        super()
    }


    
}