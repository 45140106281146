import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

export const FormIcon = ({ children }: { children: any }) => {
  const classes = useStyles();
  return <Typography className={classes.icon}>{children}</Typography>;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
}));
