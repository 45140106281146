import { EditComponentProps } from '@material-table/core';
import React from 'react';

import { FormMultiAutocomplete } from '../../form/FormMultiAutocomplete';

export const makeMultiAutoCompleteForTable = (label: string, data: any) => (props: EditComponentProps<any>) => {
  return (
    <FormMultiAutocomplete
      selected={props.value}
      label={label}
      onChangeSelect={function (newValue) {
        props.onChange(newValue.map((value) => value.value));
      }}
      placeholder={'Добавить'}
      values={data}
      key={label}
    />
  );
};
