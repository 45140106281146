import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { PassingStepper } from '../../../../../design/passingStepper/PassingStepper';
import { IPassingFinalResultsDomain } from './store/IPassingFinalResultsDomain';
import { PassingFinalResultsStepperDomain } from './store/steppper/PassingFinalResultsStepperDomain';

export interface IPassingFinalResultsProperties {
  finalResultDomain: IPassingFinalResultsDomain;
}
export const PassingFinalResults = observer(({ finalResultDomain }: IPassingFinalResultsProperties) => {
  const [stepperDomain] = useState<PassingFinalResultsStepperDomain>(
    new PassingFinalResultsStepperDomain(finalResultDomain),
  );

  return <PassingStepper stepperDomain={stepperDomain} />;
});
