import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { GPBSystemRolesExtensionPermissions } from '../systemRole/GPBSystemRolesExtension';

export const GPBIsProjectRoleCanVerfifyRequirementsExtension = (domain: ApplicationDetailDomain) => {
  return (
    domain.ui.userProjectRoleActions.value.includes('changeVerificationStatusOnCreate') ||
    domain.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['global-allow-any'],
      GPBSystemRolesExtensionPermissions['project-not-in-team-permission-verify-requirements-on-create'] as any,
    ])
  );
};
