import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';
import { IPageWithLayoutProperties } from '../../../../view/layout/page/IPageWithLayoutProperties';
import { ExportDataMainPageDomain } from './domain/ExportDataMainPageDomain';
import { IExportDataMainPageDomain } from './domain/interface/IExportDataMainPageDomain';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ExportDataMainPageProperties extends IPageWithLayoutProperties { }

export const ExportDataMainPage = observer(({ layoutDomain }: ExportDataMainPageProperties) => {
  const [domain] = useState(new ExportDataMainPageDomain(layoutDomain));
  return (
    <div>
      {domain.data.isDataLoading.value && <LoadingPage />}
      {!domain.data.isDataLoading.value && <LoadedPage domain={domain} />}
    </div>
  );
});

const LoadingPage = () => {
  return <div>Loading...</div>;
};

const LoadedPage = observer(({ domain }: { domain: IExportDataMainPageDomain }) => {
  const dataKeys = Object.keys(domain.data.entitiesData.value);

  return (
    <Grid>
      {dataKeys.map((key, index) => {
        const Component = domain.data.entitiesData.value[key].Component || DataManagerDefaultEntity;
        return <Component domain={domain} entityKey={key} key={`entity_${key}`} />;
      })}
    </Grid>
  );
});

export const DataManagerDefaultEntity = observer(
  ({ domain, entityKey }: { domain: IExportDataMainPageDomain; entityKey: string }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" component="div">
            {domain.i18n.translate(`extension.dataManager.entitiesNames.${entityKey}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataManagerDefaultEntityTable domain={domain} entityKey={entityKey} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);

export const DataManagerDefaultEntityTable = observer(
  ({ domain, entityKey }: { domain: IExportDataMainPageDomain; entityKey: string }) => {
    return <div></div>;
  },
);
