import { action, computed, observable } from 'mobx';

import { IEntityListStore } from '../interface/IEntityListStore';
import { UIStore } from './UIStore';

export class BaseEntityListStore<EntityType> extends UIStore implements IEntityListStore<EntityType> {
  @observable private listState: EntityType[];

  constructor(defaultValue: EntityType[]) {
    super();
    this.listState = defaultValue;
  }

  @action.bound
  setList(newList: EntityType[]): void {
    this.listState = newList;
  }

  @action.bound
  removeByEntity(entity: EntityType): void {
    //@ts-ignore
    this.listState.remove(entity);
  }

  @computed get list() {
    return this.listState;
  }
}
