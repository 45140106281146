import { ILogService } from './log/ILogService';
import { RestLogService } from './log/RestLogService';

export interface IAdminLogRootService {
  log: ILogService;
}

export class AdminLogRootService implements IAdminLogRootService {
  constructor(public log = new RestLogService()) {}
}
