import { blueGrey, deepOrange } from '@mui/material/colors';

export class Colors {
  static selectedTabs = (opacity: string = '1') => `rgb(144,202,249,${opacity})`;
  static negativeActiveOpacity = (opacity: string = '1') => `rgb(244, 67, 54, ${opacity})`;
  static greenButtonOpacity = (opacity: string = '1') => `rgb(76,175,90,${opacity})`;
  static redButtonOpacity = (opacity: string = '1') => `rgb(225,0,0,${opacity})`;
  static blackOpacity = (opacity: string = '1') => `rgba(255, 255, 255, ${opacity})`;
  static newestActiveOpacity = (opacity: string = '1') => `rgb(33, 150, 243, ${opacity})`;
  static baseWhite = '#fff';
  static mainPassive = blueGrey[900];
  static mainActive = blueGrey[50];
  static negativePassive = deepOrange[200];
  static leftMenu = '#29363C';
}
