import { RestService } from '../../common/rest/RestService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IApplicationJiraService, IJiraApplicationTaskManager } from './IApplicationJiraService';
import { IJiraIssueFields } from './IJiraIssueFieldsData';
import { JiraStatusTransition } from './JiraStatusTransition';

export class RestApplicationJiraService extends RestService implements IApplicationJiraService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  async recreateIssueInTaskManager(applicationId: string, requirementId: string): Promise<void> {
    const url = `${this.getEndpoint()}/target/${applicationId}/requirement/${requirementId}/taskManager/recreate`;
    const result = await this.request().url(url).post().json<any>().catch(this.handleError);
    return result;
  }

  async getJiraCredentialsByApplicationId(applicationId: string): Promise<IJiraApplicationTaskManager> {
    const url = `${this.getEndpoint()}/target/${applicationId}`;
    const result = await this.request().url(url).get().json<IJiraApplicationTaskManager>().catch(this.handleError);
    return result;
  }

  async getJiraTransitionsForRequirement(
    applicationId: string,
    requirementId: string,
    taskManager: IJiraApplicationTaskManager,
  ): Promise<JiraStatusTransition[]> {
    const url = `${this.getEndpoint()}/target/${applicationId}/requirement/${requirementId}/transitions`;
    const result = await this.request()
      .url(url)
      .post(taskManager)
      .json<JiraStatusTransition[]>()
      .catch(this.handleError);
    return result;
  }

  async loadProjects(taskManager: IJiraApplicationTaskManager): Promise<any> {
    const url = `${this.getEndpoint()}/project`;
    const result = await this.request().url(url).post(taskManager).json<any>().catch(this.handleError);

    return result;
  }

  async getProjectStatuses(taskManager: IJiraApplicationTaskManager): Promise<any> {
    const url = `${this.getEndpoint()}/project/statuses`;
    const result = await this.request().url(url).post(taskManager).json().catch(this.handleError);

    return result;
  }

  async getProjectPriorities(taskManager: IJiraApplicationTaskManager): Promise<any> {
    const url = `${this.getEndpoint()}/project/priorities`;
    const result = await this.request().url(url).post(taskManager).json().catch(this.handleError);

    return result;
  }

  async getIssuesFields(taskManager: IJiraApplicationTaskManager): Promise<IJiraIssueFields> {
    const url = `${this.getEndpoint()}/issues/fields`;
    const result = await this.request().url(url).post(taskManager).json<IJiraIssueFields>().catch(this.handleError);

    return result;
  }

  async getIssueTypes(taskManager: IJiraApplicationTaskManager): Promise<any> {
    const url = `${this.getEndpoint()}/issue/types`;
    const result = await this.request().url(url).post(taskManager).json().catch(this.handleError);

    return result;
  }

  async saveJiraSettingsToApplication(applicationId: string, taskManager: IJiraApplicationTaskManager): Promise<any> {
    const url = `${this.getEndpoint()}/target/${applicationId}/settings`;
    await this.request().url(url).post(taskManager).json().catch(this.handleError);

    return true;
  }

  async isAdminUser(taskManager: IJiraApplicationTaskManager): Promise<boolean> {
    const url = `${this.getEndpoint()}/project`;
    const result = await this.request().url(url).post(taskManager).json().catch(this.handleError);

    return result.data.isAdmin;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application/jira`;
  }
}
