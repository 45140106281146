import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { RiskManagerTemplateForm } from './RiskManagerTemplateForm';

const defaultModelValue: IRiskManagerTemplateModel = {
  answersValueToRiskData: { valueData: [] },
  description: '',
  name: '',
  isDefault: false,
  questionFieldsIds: [],
  questionsIds: [],
  staticQuestionsIds: [],
  risksIds: [],
  riskLevelsSettings: { levels: [] },
  version: 1,
  requirementThreatsLevelSettings: { levels: [] },
};

export class RiskManagerTemplateFormUI
  extends DataTableFormUI<IRiskManagerTemplateModel>
  implements IDataTableFormUI<IRiskManagerTemplateModel>
{
  constructor() {
    super(defaultModelValue);
    this.formComponent.setValue(RiskManagerTemplateForm);
  }
}
