import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Alert, Autocomplete, Checkbox, FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IFormMultiAutocompleteProperties extends ICommonProperties {
  values: AutocompleteSelectItem[];
  selected?: string[];
  label: string;
  placeholder?: string;
  onChangeSelect: (options: AutocompleteSelectItem[]) => void;
  disabled?: boolean;
  helperText?: string;
  errorMessage?: string | null;
  disableCloseOnSelect?: boolean;
  isSelection?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

export interface AutocompleteSelectItem {
  value?: any;
  name?: string;
  disabled?: boolean;
  types?: AutocompleteSelectItemType[];
}

export enum AutocompleteSelectItemType {
  selection,
  noSelection,
}

export const FormMultiAutocompleteStandard = observer(
  ({
    values,
    label,
    onChangeSelect,
    placeholder = '',
    selected = [],
    disabled = false,
    helperText = undefined,
    errorMessage = null,
    disableCloseOnSelect = true,
    isSelection = true,
    variant = 'standard',
  }: IFormMultiAutocompleteProperties) => {
    const classes = useStyles();
    const onChange: any = (event: any, value: AutocompleteSelectItem[] | null) => {
      value ? onChangeSelect(value) : onChangeSelect([]);
    };
    const selectedValues = values.filter((value) => selected.indexOf(value.value) !== -1);

    return (
      <FormControl fullWidth>
        <div className={classes.root}>
          <Autocomplete
            multiple
            limitTags={3}
            disableCloseOnSelect={disableCloseOnSelect}
            onChange={onChange}
            id={`${label}`}
            options={values}
            getOptionLabel={(option) => option?.name || 'undefined'}
            value={selectedValues}
            getOptionDisabled={(option) => option?.disabled === true}
            renderInput={(params) => (
              <TextField {...params} variant={variant} label={label} placeholder={placeholder} />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                {!option.types?.includes(AutocompleteSelectItemType.noSelection) && (
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                )}
                {option.name || '(Нет названия)'}
              </li>
            )}
            disabled={disabled}
          />
        </div>
        {errorMessage && (
          <Alert icon={false} variant="standard" severity="error">
            {errorMessage}
          </Alert>
        )}
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));
