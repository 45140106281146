import { ProjectInfoDomain } from '../../../../../view/user/page/project/info/store/ProjectInfoDomain';
import { RatingAppRootService } from '../service/RatingAppRootService';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IAppRatingProjectInfoDomain } from './IAppRatingProjectInfoDomain';

export class RatingAppDomain {
  constructor(
    public domain: IAppRatingProjectInfoDomain,
    public ratingValues: IPrimitiveStore<any> = injectPrimitive({ firstValue: 0, secondValue: 0 }),
  ) {
    this.domain = domain;
  }

  async loadRatingApp(applicationId: string): Promise<any> {
    const appRating = this.domain.appRatings.list.find((appRating) => appRating.applicationId === applicationId);

    if (appRating) {
      const { firstValue, secondValue } = appRating;
      this.ratingValues.setValue({ firstValue, secondValue });
    }
  }
}
