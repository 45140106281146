import { RestRatingAppService } from './RestRatingAppService';
import { IRatingAppService } from './IRatingAppService';
import { CRUDServiceType } from '../../../../../service/common/service/CRUDServiceType';

export interface IRatingAppRootService {
  ratingApp: IRatingAppService;
}

export class RatingAppRootService implements IRatingAppRootService {
  constructor(public ratingApp = new RestRatingAppService(CRUDServiceType.admin)) {}
}
