import { Grid, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { riskTimeFormat } from '../../../../../../service/date/since';
import { MultiLineText } from '../../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { DataTableUI } from '../../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerDataModel } from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataSearch } from '../../../../service/manager/data/IRiskManagerDataSearch';
import { RiskColorIcon } from '../../../color/RiskColorIcon';
import { RiskManagerDataTableRowsUI } from './rows/RiskManagerDataTableRowsUI';

const sortByIndex = (current: any, next: any) => (current.sortIndex < next.sortIndex ? -1 : 1);

export class RiskManagerDataTableUI
  extends DataTableUI<IRiskManagerDataModel, IRiskManagerDataSearch>
  implements IDataTableUI<IRiskManagerDataModel, IRiskManagerDataSearch>
{
  public rows: RiskManagerDataTableRowsUI;

  constructor(
    public domain: IDataTableDomain<IRiskManagerDataModel, IRiskManagerDataSearch>,
    rows: RiskManagerDataTableRowsUI | null,
  ) {
    super(domain, rows);
    this.rows = rows || new RiskManagerDataTableRowsUI(this);
    this.tableRootPaperElevation.setValue(0);
    this.isCanCreate.setValue(false);
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'targetId',
        headerName: 'Проект',
        flex: 1,
        type: 'string',
        valueGetter: (params) => {
          return this.domain.ui.rows.getFieldValueAsString('targetId', params.value);
        },
        renderCell: (params) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('targetId', params.row.targetId);
          return (
            <DataTableRowDependency
              onClick={() => {
                window.open(`/project/${params.row.targetId}/info`, '_blank');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        field: 'createDate',
        headerName: 'Дата заполнения',
        flex: 1,
        type: 'Date',
        valueGetter: (params) => {
          return params?.value?.getTime();
        },
        renderCell: (params) => <Typography variant="body1">{riskTimeFormat(params.value || null)}</Typography>,
      },
      {
        field: 'lastDataChangesDate',
        headerName: 'Дата последнего обновления',
        flex: 1,
        valueGetter: (params) => {
          return params?.value?.getTime();
        },
        renderCell: (params) => <Typography variant="body1">{riskTimeFormat(params.value || null)}</Typography>,
      },
      {
        field: 'reportData',
        headerName: 'Дата последнего отчёта',
        flex: 1,
        valueGetter: (params: { row?: IRiskManagerDataModel }) => {
          let lastDownloadTime: Date | null = null;
          if (params.row?.reportData?.downloads[0]) {
            lastDownloadTime = params.row?.reportData?.downloads[0].date;
          }

          return lastDownloadTime?.getTime();
        },
        renderCell: (params) => {
          let lastDownloadTime: number | null = null;
          if (params.row?.reportData?.downloads[0]) {
            lastDownloadTime = params.row?.reportData?.downloads[0].date;
          }

          return <Typography variant="body1">{riskTimeFormat(lastDownloadTime || null)}</Typography>;
        },
      },
      {
        field: 'riskLevelsData',
        headerName: 'Уровни рисков',
        flex: 1,
        type: 'Date',
        renderCell: (params: { row?: IRiskManagerDataModel }) => {
          if (!params.row?.isComplete) {
            return (
              <div>Расчет рисков произойдет после заполнения всех полей анкеты рисков</div>
            )
          }
          const riskData = params.row?.riskLevelsData.data.map((item) => {
            const risk = params.row?.risksCopy?.find((risk) => risk.id === item.riskId);
            const riskLevel = params.row?.templateDataCopy?.riskLevelsSettings?.levels.find(
              (settings) => settings.pseudoId === item.riskLevelSettingsPseudoId,
            );
            return {
              risk,
              riskLevel,
              sortIndex: risk?.sortIndex,
            };
          });
          return (
            <Grid container flexDirection={'row'}>
              {riskData?.sort(sortByIndex).map((item) => {
                return (
                  <Grid item style={{ padding: '3px' }}>
                    <RiskColorIcon
                      title={`${item.risk?.name}: ${item.riskLevel?.name}`}
                      color={item.riskLevel?.color || '#ffffff'}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        },
      },
      {
        field: 'requirementThreatLevelsData',
        headerName: 'Уровни критичности требований',
        flex: 1,
        type: 'Date',
        renderCell: (params: { row?: IRiskManagerDataModel }) => {
          if (!params.row?.isComplete) {
            return (
              <div>Расчет рисков произойдет после заполнения всех полей анкеты рисков</div>
            )
          }
          const riskData = params.row?.requirementThreatLevelsData.data.map((item) => {
            const risk = params.row?.risksCopy?.find((risk) => risk.id === item.riskId);
            const requirementThreatLevelsData =
              params.row?.templateDataCopy?.requirementThreatsLevelSettings?.levels.find(
                (settings) => settings.pseudoId === item.requirementThreatLevelSettingsPseudoId,
              );
            return {
              risk,
              requirementThreatLevelsData,
              sortIndex: risk?.sortIndex,
            };
          });
          return (
            <Grid container flexDirection={'row'}>
              {riskData?.sort(sortByIndex).map((item) => {
                return (
                  <Grid item style={{ padding: '3px' }}>
                    <RiskColorIcon
                      title={`${item.risk?.name}: ${item.requirementThreatLevelsData?.name}`}
                      color={item.requirementThreatLevelsData?.color || '#ffffff'}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        },
      },
      {
        field: 'isNeedUpdate',
        headerName: 'Статус обновления',
        flex: 1,
        type: 'string',
        renderCell: (params: { row?: IRiskManagerDataModel; value?: string }) => {
          let isEditLink = false;
          let text = 'Не требуются обновления';
          if (params.row?.isNeedUpdate) {
            text = 'Требуется обновление';
          }
          if (params.row?.isUpdateConflict) {
            isEditLink = true;
            text = 'Нужно ручное обновление';
          }
          return isEditLink ? (
            <a
              style={{ color: 'white' }}
              href="javascript:;"
              onClick={() => {
                window.open(`/project/${params.row?.targetId}/edit`, '_blank');
              }}
            >
              {text}
            </a>
          ) : (
            <MultiLineText words={this.rows.searchTerm.value} value={text} />
          );
        },
      },
    ];
  }
}
