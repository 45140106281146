import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { DataTable } from '../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../domain/RiskManagerSettingsPageDomain';

export interface RiskManagerTemplateTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerTemplateTable = observer(({ rootDomain }: RiskManagerTemplateTableProperties) => {
  return <DataTable tableHeight={800} dataTableDomain={rootDomain.templateDomain} />;
});
