import { observer } from 'mobx-react';
import * as React from 'react';

import { FormMultiAutocomplete } from '../../../../../../../../design/form/FormMultiAutocomplete';
import { RequirementEntityDrawerDomain } from '../../RequirementEntityDrawerDomain';
import { AdditionalColumnType } from '../../../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';

export const RequirementAdditionalColumnEdit = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  return (
    <>
      <AdditionalColumnInput domain={domain} />
    </>
  );
});

const AdditionalColumnInput = observer(({ domain }: { domain: RequirementEntityDrawerDomain }) => {
  const getColumnContent = (columnId) => {
    return domain.ui.additionalColumnContent.list
      .filter((filterItem) => filterItem.additionalColumnId === columnId)
      .map((mapItem) => ({
        value: mapItem.id,
        name: mapItem.content,
        columnId: mapItem.additionalColumnId,
      }));
  };

  return (
    <>
      {domain.ui.additionalColumns.list.map((column) => (
        (
          column.isActive &&
          column.type !== AdditionalColumnType.UPLOAD_FILE &&
          column.type !== AdditionalColumnType.EDITABLE_TEXT &&
          column.type !== AdditionalColumnType.DATE
        ) &&
        <FormMultiAutocomplete
          errorMessage={domain.getValidationErrorFor('vendor')?.message}
          label={column.name}
          selected={domain.ui.additionalColumnContentToSet.list}
          onChangeSelect={async (newValue: any, event, reason, details) => {
            if (reason === 'selectOption') {
              domain.ui.additionalColumnContentToSet.setList([
                ...domain.ui.additionalColumnContentToSet.list,
                details.option.value,
              ]);
            } else if (reason === 'removeOption') {
              domain.ui.additionalColumnContentToSet.setList(
                domain.ui.additionalColumnContentToSet.list.filter((item) => item !== details.option.value),
              );
            } else if (reason === 'clear') {
              const currentCategoryIds = getColumnContent(column.id).map((column) => column.value);
              domain.ui.additionalColumnContentToSet.setList(
                domain.ui.additionalColumnContentToSet.list.filter((item) => !currentCategoryIds.includes(item)),
              );
            }
          }}
          values={getColumnContent(column.id)}
        />
      ))}
    </>
  );
});
