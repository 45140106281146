import { ListItemText } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react'
import { IApplicationModelWithUser } from '../../../../view/user/page/application/info/parts/applicationInfoHistory/store/ApplicationHistoryUI';

export const VersionListTextWithNumber = observer(({ application }: { application: IApplicationModelWithUser }) => {
    const version = application?.versionNumber || '';
    const listText = version
        ? `Версия ${version}  |  ${application?.auditDate?.toLocaleString()}`
        : application?.auditDate?.toLocaleString();

    return (<ListItemText style={{ whiteSpace: 'pre' }}>{listText}</ListItemText>)
})
