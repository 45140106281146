import { Store } from '../../../../common/store/base/Store';
import { ILayoutDrawerDomain } from '../drawer/store/ILayoutDrawerDomain';
import { LayoutDrawerDomain } from '../drawer/store/LayoutDrawerDomain';
import { ILayoutErrorDomain } from '../error/store/ILayoutErrorDomain';
import { LayoutErrorDomain } from '../error/store/LayoutErrorDomain';
import { ILayoutModalWindowDomain } from '../modalWindow/store/ILayoutModalWindowDomain';
import { LayoutModalWindowDomain } from '../modalWindow/store/LayoutModalWindowDomain';
import { ILayoutNotificationDomain } from '../notification/store/ILayoutNotificationDomain';
import { LayoutNotificationDomain } from '../notification/store/LayoutNotificationDomain';
import { ICommonLayoutDomain } from './ICommonLayoutDomain';

export abstract class CommonLayoutDomainStore extends Store implements ICommonLayoutDomain {
  private errorsDomain: ILayoutErrorDomain;

  constructor(
    errorsDomain: ILayoutErrorDomain | null = null,
    private notificationsDomain: ILayoutNotificationDomain = new LayoutNotificationDomain(),
    private modalWindowDomain: ILayoutModalWindowDomain = new LayoutModalWindowDomain(),
    private drawerDomain: ILayoutDrawerDomain = new LayoutDrawerDomain(),
  ) {
    super();
    this.errorsDomain = errorsDomain || new LayoutErrorDomain(notificationsDomain);
  }

  get notifications() {
    return this.notificationsDomain;
  }

  get modalWindow() {
    return this.modalWindowDomain;
  }

  get drawer() {
    return this.drawerDomain;
  }

  get errors() {
    return this.errorsDomain;
  }
}
