import { Chip, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionFieldModel } from '../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldSearch } from '../../../service/question/field/IRiskManagerQuestionFieldSearch';
import { RiskManagerQuestionFieldInputType } from '../../../service/question/field/RiskManagerQuestionFieldInputType';
import { RiskManagerQuestionFieldRelationType } from '../../../service/question/field/RiskManagerQuestionFieldRelationType';
import { RiskManagerQuestionRowsFieldUI } from './rows/RiskManagerQuestionFieldRowsUI';

export class RiskManagerQuestionFieldUI
  extends DataTableUI<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>
  implements IDataTableUI<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>
{
  constructor(
    public domain: IDataTableDomain<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>,
    rows: IDataTableRowsUI<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch> | null,
    public answerPresets: IEntityListStore<IRiskManagerQuestionAnswerPresetModel> = injectEntityList<IRiskManagerQuestionAnswerPresetModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new RiskManagerQuestionRowsFieldUI(this);
    this.tableRootPaperElevation.setValue(0);
  }

  public translateRelationType(relationType: RiskManagerQuestionFieldRelationType | string) {
    const translationMap = {
      [RiskManagerQuestionFieldRelationType.all]: 'Все',
      [RiskManagerQuestionFieldRelationType.answers]: 'Ответы',
      [RiskManagerQuestionFieldRelationType.static]: 'Статичные ответы',
      // [RiskManagerQuestionFieldRelationType.targets]: 'Определённые вопросы',
    };

    return translationMap[relationType] || 'Unknown';
  }

  public translateInputType(inputType: RiskManagerQuestionFieldInputType | string) {
    const translationMap = {
      // [RiskManagerQuestionFieldInputType.date]: 'Дата',
      // [RiskManagerQuestionFieldInputType.link]: 'Ссылка',
      [RiskManagerQuestionFieldInputType.multiRow]: 'Многострочный текст',
      [RiskManagerQuestionFieldInputType.oneRow]: 'Текст',
      [RiskManagerQuestionFieldInputType.number]: 'Число',
    };

    return translationMap[inputType] || 'Unknown';
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'inputType',
        headerName: 'Тип поля',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return (
            <MultiLineText words={this.rows.searchTerm.value} value={this.translateInputType(params.value || '')} />
          );
        },
      },
      {
        field: 'relationType',
        headerName: 'Тип зависимости',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return (
            <MultiLineText words={this.rows.searchTerm.value} value={this.translateRelationType(params.value || '')} />
          );
        },
      },
      {
        field: 'sortIndex',
        headerName: 'Порядок отображения',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value ?? ''} />;
        },
      },
    ];
  }
}
