import { createContext } from 'react';

import { ILearningUnitPreviewPageDomain } from './LearningUnitPreviewPageDomain';

interface ILearningUnitPreviewPageContext {
  unitPreviewDomain?: ILearningUnitPreviewPageDomain;
  showSidebar?: boolean, 
  setShowSidebar?: (value: boolean) => void;
}

export const LearningUnitPreviewPageContext = createContext<ILearningUnitPreviewPageContext>({
  unitPreviewDomain: undefined,
});
