import React from "react";
import { Autocomplete, CircularProgress, Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from "mobx-react";
import { ApplicationDynamicsChart } from "../charts/ApplicationDynamicsChart";
import { AnalyticsDomain } from "../../../store/AnalyticsDomain";
import { FakeChart } from '../charts/FakeChart'
import { ApplicationVendorChart } from '../charts/ApplicationVendorChart'

export const ApplicationInfoChartsGroup = observer(({ domain }: { domain: AnalyticsDomain }) => {
  const classes = useStyles();

  const defaultValue = domain.ui.requirementsUi.isLoadingApplicationRequirementDataChart.value && domain.ui.requirementsUi.applicationList.list.length === 0
    ? { name: 'Поиск Систем...' } : domain.ui.requirementsUi.currentApplication.entity?.name ?
      domain.ui.requirementsUi.currentApplication.entity : { name: 'Системы не найдены' }
  return (
    <>
      <Grid container className={classes.applicatioAnalyticsBlock}>
        <Grid className={classes.applicationAnalyticsControllerBlock}>
          <Grid className={classes.applicationAnalyticsControllerName}>Выберите АС</Grid>
          <Autocomplete
            size="small"
            disableClearable
            loading={domain.ui.requirementsUi.isLoadingApplicationRequirementDataChart.value}
            loadingText={'Поиск Систем...'}
            disabled={domain.ui.requirementsUi.isLoadingApplicationRequirementDataChart.value}
            options={domain.ui.requirementsUi.applicationList.list}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option.name}
              </li>
            )}
            getOptionLabel={(option) => option?.name || 'undefined'}
            renderInput={(params) =>
              <TextField
                {...params}
                multiline={false}
                variant="outlined"
                placeholder='Система'
                className={classes.applicationAnalyticsControllerAutocomplite}
              />}
            value={defaultValue}
            onChange={(e, value) => {
              domain.ui.requirementsUi.setApplicationForGetAnalytics(value)
            }}
          />
        </Grid>
        <Grid className={classes.applicationAnalyticsChartsBlock}>
          {domain.ui.requirementsUi.applicationList.list.length > 0 ?
            (<>
              <ApplicationDynamicsChart domain={domain} />
              <ApplicationVendorChart domain={domain} />
              {/* <FakeChart showedText={'Данный график на стадии разработки'} img={<PermDataSettingIcon />} /> */}
            </>) : (
              <>
                <FakeChart
                  showedText={domain.ui.requirementsUi.isLoadingApplicationAnalytics.value ? 'Загрузка...' : 'Системы не найдены'}
                  img={domain.ui.requirementsUi.isLoadingApplicationAnalytics.value ? <CircularProgress /> : null}
                />
                <FakeChart
                  showedText={domain.ui.requirementsUi.isLoadingApplicationAnalytics.value ? 'Загрузка...' : 'Системы не найдены'}
                  img={domain.ui.requirementsUi.isLoadingApplicationAnalytics.value ? <CircularProgress /> : null}
                />
              </>
            )}
        </Grid>
      </Grid>
    </>
  )
})



const useStyles = makeStyles((theme: Theme) => ({
  applicatioAnalyticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 440, // после добавления новых графиков увеличить 
    width: '100%',
    backgroundColor: '#2D3A40',
    borderRadius: 8,
    padding: '16px 8px 16px 8px'
  },
  applicationAnalyticsChartsBlock: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(600px, 1fr))',
    gridGap: '2px',
    display: 'grid',
    width: '100%',
  },
  applicationAnalyticsControllerBlock: {
    width: '100%',
    height: 40,
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  applicationAnalyticsControllerName: {
    padding: '8px 16px 8px 8px'
  },
  applicationAnalyticsControllerAutocomplite: {
    width: 240,
  },
  fakeChartBlock: {
    height: 350,
    minWidth: 570,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 16px 24px',
    backgroundColor: '#29363cee',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  fakeChartBlockBlur: {
    position: 'absolute',
    width: '100%',
    height: 340,
    zIndex: 100,
    backgroundColor: '#29363cf7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));