import { DomainStore } from '../../../../../common/store/base/DomainStore';
import { ILayoutDrawerDomain } from './ILayoutDrawerDomain';
import { ILayoutDrawerUI } from './ILayoutDrawerUI';
import { LayoutDrawerUI } from './LayoutDrawerUI';

export class LayoutDrawerDomain extends DomainStore implements ILayoutDrawerDomain {
  constructor(private uiStore: ILayoutDrawerUI = new LayoutDrawerUI()) {
    super();
  }

  showDrawer(component: any): void {
    this.uiStore.showDrawer(component);
    this.uiStore.openActiveDrawer();
  }

  closeDrawer(): void {
    this.uiStore.closeActiveDrawer();
  }

  get ui() {
    return this.uiStore;
  }
}
