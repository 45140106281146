import { IVendorEntityModel } from './../../../../../../service/vendor/entity/IVendorEntityModel';
import { IVendorEntityService } from './../../../../../../service/vendor/entity/IVendorEntityService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { DataTableFormDomain } from './../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from './../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { VendorEntityModalUI } from './VendorEntityModalUI';
export class VendorEntityModalDomain
  extends DataTableFormDomain<IVendorEntityModel, IVendorEntityService>
  implements IDataTableFormDomain<IVendorEntityModel>
{
  public ui: VendorEntityModalUI;
  constructor(
    entityService: IVendorEntityService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IVendorEntityModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new VendorEntityModalUI());
    this.ui = new VendorEntityModalUI();
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependecies();
  }

  async loadDependecies() {
    const systemTypesResult = await this.rootPrivateServices.vendor.entity.getTypes();
    this.ui.systemTypes.setList(systemTypesResult);
  }
}
