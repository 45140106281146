import { CircularProgress, Grid, Toolbar } from '@mui/material';
import React from 'react';

export const PageLoader = () => {
  return (
    <Grid item={true} xs={1}>
      <Toolbar />
      <Toolbar />
      <Toolbar />
      <CircularProgress color="secondary" />
    </Grid>
  );
};
