import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { FormUI } from '../../../../../../view/admin/page/form/store/FormUI';
import {
  IRiskManagerDataModel,
  RiskManagerDataTargetType,
} from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerDataFormDomain } from './RiskManagerDataFormDomain';

const defaultModel: IRiskManagerDataModel = {
  answersData: { data: [] },
  isComplete: false,
  isUpdateConflict: false,
  isNeedUpdate: false,
  questionFieldsCopy: [],
  questionsCopy: [],
  riskLevelsData: { data: [] },
  riskLevelsHistory: { data: [] },
  risksCopy: [],
  staticQuestionsCopy: [],
  targetId: null,
  targetType: RiskManagerDataTargetType.project,
  templateDataCopy: null,
  templateId: null,
  templateVersion: 0,
  questionFieldData: { data: [] },
  staticQuestionData: { data: [] },
  reportData: { downloads: [] },
  requirementThreatLevelsData: { data: [] },
  lastDataChangesDate: null,
};

export class RiskManagerDataFormUI extends FormUI<IRiskManagerDataModel> {
  constructor(
    public domain: RiskManagerDataFormDomain,
    public templates: IEntityListStore<IRiskManagerTemplateModel> = injectEntityList<IRiskManagerTemplateModel>([]),
    public questions: IEntityListStore<IRiskManagerQuestionModel> = injectEntityList<IRiskManagerQuestionModel>([]),
    public risks: IEntityListStore<IRiskManagerRiskModel> = injectEntityList<IRiskManagerRiskModel>([]),
    public staticQuestions: IEntityListStore<IRiskManagerStaticQuestionModel> = injectEntityList<IRiskManagerStaticQuestionModel>(
      [],
    ),
    public questionFields: IEntityListStore<IRiskManagerQuestionFieldModel> = injectEntityList<IRiskManagerQuestionFieldModel>(
      [],
    ),
    public answerQuestionFields: IEntityListStore<IRiskManagerQuestionFieldModel> = injectEntityList<IRiskManagerQuestionFieldModel>(
      [],
    ),
    public staticQuestionFields: IEntityListStore<IRiskManagerQuestionFieldModel> = injectEntityList<IRiskManagerQuestionFieldModel>(
      [],
    ),
    public project: IEntityStore<IProjectModel> = injectEntity({}),
  ) {
    super();
    this.setDefaultModel();
  }

  setDefaultModel() {
    this.model.setEntity(defaultModel);
  }

  getAnswerDataErrors(answerQuestion: IRiskManagerQuestionModel, fieldName: string) {
    const result = Object.values(
      this.validationErrors.list
        .find((item) => item.property === 'answersData')
        ?.children?.find((item) => item.property === 'data')
        ?.children?.find((item) => item.value.questionId === answerQuestion.id || '')
        ?.children?.find((item) => item.property === fieldName)?.constraints || { nonError: null },
    )[0];
    return result;
  }
}
