import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { FormDomain } from '../../../../../../view/admin/page/form/store/FormDomain';
import { LayoutNotificationType } from '../../../../../../view/layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerDataAnswerData, IRiskManagerDataModel } from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataService } from '../../../../service/manager/data/IRiskManagerDataService';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { RiskManagerQuestionFieldRelationType } from '../../../../service/question/field/RiskManagerQuestionFieldRelationType';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerRootService, RiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerDataFormUI } from './RiskManagerDataFormUI';

export class RiskManagerDataFormDomain extends FormDomain<IRiskManagerDataModel, IRiskManagerDataService> {
  public ui: RiskManagerDataFormUI;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    ui: RiskManagerDataFormUI | null = null,
    public riskManagerRootService: IRiskManagerRootService = new RiskManagerRootService('admin'),
    public validationWarnings = injectPrimitive<any[]>([]),
  ) {
    super(layoutDomain, riskManagerRootService.manager.data);
    this.ui = ui || new RiskManagerDataFormUI(this);
  }

  async boot(projectId?: string) {
    await this.loadByProjectId(projectId);
    await this.loadDependencies(projectId);
  }

  async updateToLastVersion() {
    await this.loadLastVersion();
    await this.loadDependencies();
  }

  async loadLastVersion() {
    const riskManagerData = await this.riskManagerRootService.manager.data.getLastVersionData(
      this.ui.model.entity.id || '',
    );
    if (riskManagerData?.id) {
      this.ui.model.setEntity(riskManagerData);
    }
  }

  async loadByProjectId(projectId?: string) {
    if (projectId) {
      try {
        const riskManagerData = await this.riskManagerRootService.manager.data.getByProjectId(projectId);

        if (riskManagerData?.id) {
          this.ui.model.setEntity(riskManagerData);
        }
      } catch (error) {
        if (!this.ui.model.entity.templateId) {
          this.setTemplate(null);
        }
        // this.layoutDomain.notifications.notificationUI.showNotification({
        //   text: 'Ошибка',
        //   type: LayoutNotificationType.error,
        // });
      }
    }
  }

  getWarningsForQuestion(questionId: string = '') {
    if (this.validationWarnings.value.length) {
      return this.validationWarnings.value.find((item) => item.questionId === questionId)?.message;
    }
    return '';
  }

  async loadDependencies(projectId?: string ) {
    try {
      let questions,
        risks,
        staticQuestions,
        questionFields,
        templates = { data: [] } as { data: any[] };
      if (this.ui.model.entity.id) {
        questions = { data: this.ui.model.entity.questionsCopy };
        risks = { data: this.ui.model.entity.risksCopy };
        staticQuestions = { data: this.ui.model.entity.staticQuestionsCopy };
      } else {
        [questions, risks, staticQuestions, questionFields] = await Promise.all([
          this.riskManagerRootService.question.entity.search({ limit: 1000000 }),
          this.riskManagerRootService.risk.search({ limit: 1000000 }),
          this.riskManagerRootService.question.static.search({ limit: 1000000 }),
          this.riskManagerRootService.question.field.search({ limit: 1000000 }),
        ]);
      }

      [questions, staticQuestions, templates, questionFields] = await Promise.all([
        this.riskManagerRootService.question.entity.search({ limit: 1000000 }),
        this.riskManagerRootService.question.static.search({ limit: 1000000 }),
        this.riskManagerRootService.manager.template.search({ limit: 1000000 }),
        this.riskManagerRootService.question.field.search({ limit: 1000000 }),
      ]);

      this.ui.questionFields.setList(questionFields.data);
      this.ui.questions.setList(questions.data);
      this.ui.staticQuestions.setList(staticQuestions.data);
      this.ui.risks.setList(risks.data);
      this.ui.templates.setList(templates.data);

      this.ui.answerQuestionFields.setList(
        this.ui.questionFields.list.filter(
          (item) =>
            item.relationType === RiskManagerQuestionFieldRelationType.all ||
            item.relationType === RiskManagerQuestionFieldRelationType.answers,
        ),
      );

      this.ui.staticQuestionFields.setList(
        this.ui.questionFields.list.filter(
          (item) =>
            item.relationType === RiskManagerQuestionFieldRelationType.all ||
            item.relationType === RiskManagerQuestionFieldRelationType.static,
        ),
      );
      const defaultTemplate = templates.data.find((item) => item?.isDefault === true);
      if (!this.ui.model.entity.templateId && defaultTemplate && !projectId) {
        this.setTemplate(defaultTemplate.id);
      }
      if (!this.ui.model.entity.templateId && projectId) {
        this.setTemplate(null);
      }
    } catch (error) {
      this.layoutDomain.notifications.notificationUI.showNotification({
        text: 'Ошибка',
        type: LayoutNotificationType.error,
      });
    }
  }

  setTemplate(templateId: string | null) {
    const idBefore = this.ui.model.entity.id;
    this.ui.setDefaultModel();
    const template = this.ui.templates.list.find((template) => template.id === templateId);

    template?.staticQuestionsIds.forEach((staticQuestionId) => {
      this.ui.model.entity.staticQuestionData.data.push({
        staticQuestionId: staticQuestionId,
        value: null,
      });

      this.ui.staticQuestionFields.list.forEach((field) => {
        this.ui.model.entity.questionFieldData.data.push({
          fieldId: field.id || '',
          questionId: staticQuestionId,
          type: 'static',
          value: null,
        });
      });
    });

    template?.questionsIds.forEach((questionId) => {
      const question = this.ui.questions.list.find((item) => item.id === questionId);
      if (question && question.id) {
        this.ui.model.entity.answersData.data.push({
          questionId: question.id,
          value: 0,
          answerPseudoId: null,
          answerPseudoIds: [],
          isMultiAnswer: question.isMultiAnswer,
        });
      }

      this.ui.answerQuestionFields.list.forEach((field) => {
        this.ui.model.entity.questionFieldData.data.push({
          fieldId: field.id || '',
          questionId: questionId,
          type: 'answer',
          value: null,
        });
      });
    });

    this.ui.model.entity.id = idBefore;
    this.ui.model.entity.templateId = templateId;
  }

  getOrCreateStaticQuestionData(staticQuestion: IRiskManagerStaticQuestionModel) {
    const staticQuestionIndex = this.ui.model.entity.staticQuestionData.data.findIndex(
      (item) => item.staticQuestionId === staticQuestion.id,
    );
    if (staticQuestionIndex === -1) {
      const newData = {
        staticQuestionId: staticQuestion.id || '',
        value: null,
      };
      this.ui.model.entity.staticQuestionData.data.push(newData);
      return newData;
    } else {
      return this.ui.model.entity.staticQuestionData.data[staticQuestionIndex];
    }
  }

  getOrCreateQuestionFieldData(
    questionField: IRiskManagerQuestionFieldModel,
    questionId: string,
    type: 'static' | 'answer',
    defaultValue = null,
  ) {
    const questionFieldIndex = this.ui.model.entity.questionFieldData.data.findIndex(
      (item) => item.questionId === questionField.id && item.type === type,
    );
    if (questionFieldIndex === -1) {
      const newData = { fieldId: questionField.id || '', questionId, type, value: defaultValue };
      this.ui.model.entity.questionFieldData.data.push(newData);
      return newData;
    } else {
      return this.ui.model.entity.questionFieldData.data[questionFieldIndex];
    }
  }
}
