import React, { Fragment, useState } from 'react';

import { ITagCategoryModel } from '../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../service/tag/entity/ITagEntityModel';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { TagFormSelectionDomain } from './TagFormSelectionDomain';

interface TagFormSelectionProperties {
  selectedTagsIds: string[];
  tagCategories: ITagCategoryModel[];
  tags: ITagEntityModel[];
  onChange: (tagIds: string[]) => void;
  disabled?: boolean;
}

export const TagFormSelection = ({
  selectedTagsIds,
  tagCategories,
  tags,
  onChange,
  disabled = false,
}: TagFormSelectionProperties) => {
  const [domain] = useState<TagFormSelectionDomain>(new TagFormSelectionDomain());
  return (
    <Fragment>
      {tagCategories.map((category) => {
        const categoryValues = domain.tagCategoriesValues(tags, category.id);
        const categorySelectedValues = selectedTagsIds.filter((selectedTagId) =>
          categoryValues.find((categoryValue) => categoryValue.value === selectedTagId),
        );
        const isNeedToRender = categoryValues.length > 0 && (category.isActive || categorySelectedValues.length > 0);
        return isNeedToRender ? (
          <FormMultiAutocomplete
            key={category.id}
            disabled={disabled}
            values={categoryValues}
            onChangeSelect={domain.getComplexMultiSelectFieldHandler({
              selectedIds: selectedTagsIds,
              selectedCategoryId: category.id,
              selectedCategoryValues: categoryValues,
              onChange,
            })}
            selected={selectedTagsIds}
            label={category.isActive ? category.name : `${category.name} (неактивен)`}
            helperText={category.description}
          />
        ) : (
          <div key={category.id} />
        );
      })}
    </Fragment>
  );
};
