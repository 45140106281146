import { IContainer } from '../common/container/IContainer';
import { RestServiceToken } from './common/rest/IRestService';
import { RestService } from './common/rest/RestService';
import { RootPublicService, RootPublicServiceToken } from './public/RootPublicService';
import { RootAdminService, RootAdminServiceToken } from './RootAdminService';
import { RouterServiceToken } from './route/IRouterService';
import { WebRouterService } from './route/WebRouterService';

export function serviceContainer(container: IContainer): IContainer {
  container.setByToken(RouterServiceToken, new WebRouterService());
  container.setByToken(RestServiceToken, new RestService());
  container.setByToken(RootPublicServiceToken, new RootPublicService());
  container.setByToken(RootAdminServiceToken, new RootAdminService());

  return container;
}
