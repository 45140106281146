import { Accordion, AccordionDetails, FormControlLabel, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { SettingsFormDomain } from './store/SettingsFormDomain';
import { FormActions } from '../../../../design/form/FormActions';
import { FormTextField } from '../../../../design/form/FormTextField';
import { IPageWithLayoutProperties } from '../../../../layout/page/IPageWithLayoutProperties';
import { FormModalContainer } from '../../../../design/dataTable/form/view/FormModalContainer';
import { FormMultiAutocomplete } from '../../../../design/form/FormMultiAutocomplete';
import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { SystemRolesAdditionalPermissionsInject } from '../../../../admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsInject';
import { SystemRolesAdditionalPermissionsToExistsGroupsIds } from '../../../../admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsToExistsGroupInject';

export const SettingsForm = observer(({ layoutDomain }: IPageWithLayoutProperties) => {
  const [domain] = useState(new SettingsFormDomain(layoutDomain));
  const systemRolesAdditionalPermissionsExtensions = useContext(ContainerContext).get(
    SystemRolesAdditionalPermissionsInject,
  );

  const settingsUi = domain.ui.notificationSettings.entity;
  useEffect(() => {
    domain.extensionNotificationsList = [
      ...systemRolesAdditionalPermissionsExtensions
        .filter((item) => item.id === SystemRolesAdditionalPermissionsToExistsGroupsIds.notifications)
        .reduce<any[]>((extensionValues, item) => {
          if (item.values) {
            extensionValues.push(...item.values);
          }
          return extensionValues;
        }, [])
    ]
    domain.loadData();
  }, [domain]);
  return (
    <FormModalContainer onClose={domain.cancelEdit}>
      <FormControlLabel
        style={{ width: '100%', padding: '16px', margin: 0 }}
        control={
          <Switch
            color={'success'}
            checked={domain.ui.notificationSettings.entity.isSendWithMail}
            onChange={(event) => {
              domain.ui.notificationSettings.entity.isSendWithMail = event.target.checked;
            }}
            defaultChecked={true}
          />
        }
        label="Отправлять уведомления на почту?"
      />
      <Accordion expanded={settingsUi.isSendWithMail}>
        <></>
        <AccordionDetails>
          <FormTextField
            value={settingsUi.email}
            onChange={(newValue) => {
              settingsUi.email = newValue.trim();
            }}
            label="Укажите почту"
            required={true}
          />
          <FormMultiAutocomplete
            values={[
              ...domain.ui.userNotificationsList.list,
            ]}
            onChangeSelect={(newValue: any) => {
              const unsetItems = [
                ...domain.ui.userNotificationsList.list,
                ...systemRolesAdditionalPermissionsExtensions
                  .filter((item) => item.id === SystemRolesAdditionalPermissionsToExistsGroupsIds.notifications)
                  .reduce<any[]>((extensionValues, item) => {
                    if (item.values) {
                      extensionValues.push(...item.values);
                    }
                    return extensionValues;
                  }, []),
              ]
                .map((item) => item.value)
                .filter((item) => !newValue.includes(item));
              unsetItems.forEach((value) => domain.setModelNotificationPermission(value, false));
              newValue.forEach((value) => domain.setModelNotificationPermission(value.value, true));
            }}
            selected={domain.ui.notificationSettings.entity.notificationToEmail}
            label="Настройки - Уведомления"
          />
        </AccordionDetails>
      </Accordion>
      <FormActions onSave={() => domain.save()} onCancel={() => domain.cancelEdit()} />
    </FormModalContainer>
  );
});
