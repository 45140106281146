import { FileUploadUI } from '../../../../../fileUpload/store/FileUploadUi';

export class VideoUploadUI extends FileUploadUI {
  constructor() {
    super();
    this.maxSize.setValue(5000000);
    this.accept.setList(['.mp4', '.avi', '.webm', '.ogg']);
    this.key.setValue('video/mp4');
  }
}
