import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { FileUploadDomain } from '../../../../../fileUpload/store/FileUploadDomain';
import { IFileUploadUI } from '../../../../../fileUpload/store/IFileUploadUI';
import { ImageUploadUI } from './ImageUploadUI';

export class ImageUploadDomain extends FileUploadDomain {
  public ui: IFileUploadUI;
  constructor(public layoutDomain: IMainLayoutDomainStore) {
    super(layoutDomain);
    this.ui = new ImageUploadUI();
  }
}
