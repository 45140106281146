import { injectEntity } from '../../../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IUnitChapter } from '../../../../../../../service/unit/interface/IUnitTheory';
import { IUnitResultModel } from '../../../../../../../service/unitResult/IUnitResultModel';
import { IPassingAccordionItemProperties } from '../../../components/PassingBody/PassingBodySidebar/PassingSidebarTheory/PassingTheoryAccordionContent/PassingTheoryAccordionContent';
import { IPassingStepContent, IPassingTheoryUI } from './IPassingTheoryUI';

export class PassingTheoryUI implements IPassingTheoryUI {
  constructor(
    public passingTheoryList = injectEntityList<IUnitChapter>([]),
    public stepperList = injectEntityList<IUnitChapter>([] as any),
    public activeStep = injectPrimitive<number | any>(0),
    public startStep = injectPrimitive<number>(0),
    public stepContent = injectEntityList<IPassingStepContent>([] as any),
    public stepsTitles = injectEntityList<string>([] as any),
    public completed = injectPrimitive<any>({}),
    public redirectTo = injectPrimitive<string>(''),
    public additionalActions = injectEntityList<any>([]),
    public navigationList = injectEntityList<IPassingAccordionItemProperties>([]),
    public unitResultModel = injectEntity<IUnitResultModel>({} as any),
    public isExpanded = injectPrimitive<boolean>(false),
    public isEnebled = injectPrimitive<boolean>(false),
    public hash = injectPrimitive<string>(''),
    public activeAccordionTitle = injectPrimitive<string>(''),
  ) {
    this.redirectTo.setValue('Квиз');
  }
}
