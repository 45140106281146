export const StatusValueColor = [
  {
    name: 'Новый',
    rgbaTemplate: 'rgb(144, 149, 155, ?)',
    id: 'new',
  },
  {
    name: 'В разработке',
    rgbaTemplate: 'rgb(78, 85, 96, ?)',
    id: 'inProgress',
  },
  {
    name: 'Требует разъяснений',
    rgbaTemplate: 'rgb(137, 121, 66, ?)',
    id: 'suspend',
  },
  {
    name: 'Выполнено',
    rgbaTemplate: 'rgb(71, 124, 103, ?)',
    id: 'done',
  },
  {
    name: 'Розовый',
    rgbaTemplate: 'rgb(216, 27, 96, ?)',
    id: 'pink',
  },
  {
    name: 'Фиолетовый',
    rgbaTemplate: 'rgb(142, 36, 170, ?)',
    id: 'purple',
  },
  {
    name: 'Темно-фиолетовый',
    rgbaTemplate: 'rgb(94, 53, 177, ?)',
    id: 'deep-purple',
  },

  {
    name: 'Индиго',
    rgbaTemplate: 'rgb(57, 73, 171, ?)',
    id: 'indigo',
  },
  {
    name: 'Синий',
    rgbaTemplate: 'rgb(30, 136, 229, ?)',
    id: 'blue',
  },
  {
    name: 'Светло-синий',
    rgbaTemplate: 'rgb(3, 155, 229, ?)',
    id: 'light-blue',
  },
  {
    name: 'Голубой',
    rgbaTemplate: 'rgb(0, 172, 193, ?)',
    id: 'cyan',
  },
  {
    name: 'Бирюзовый',
    rgbaTemplate: 'rgb(0, 137, 123, ?)',
    id: 'teal',
  },
  {
    name: 'Зеленый',
    rgbaTemplate: 'rgb(67, 160, 71, ?)',
    id: 'green',
  },
  {
    name: 'Светло-зеленый',
    rgbaTemplate: 'rgb(124, 179, 66, ?)',
    id: 'light-green',
  },
  {
    name: 'Лайм',
    rgbaTemplate: 'rgb(192, 202, 51, ?)',
    id: 'liam',
  },

  {
    name: 'Желтый',
    rgbaTemplate: 'rgb(253, 216, 53, ?)',
    id: 'yellow',
  },

  {
    name: 'Янтарный',
    rgbaTemplate: 'rgb(255, 179, 0, ?)',
    id: 'amber',
  },

  {
    name: 'Оранжевый',
    rgbaTemplate: 'rgb(251, 140, 0, ?)',
    id: 'orange',
  },

  {
    name: 'Темно-оранжевый',
    rgbaTemplate: 'rgb(244, 81, 30, ?)',
    id: 'deep-orange',
  },
  {
    name: 'Белый',
    rgbaTemplate: 'rgb(255, 255, 255, ?)',
    id: 'white',
  },
  {
    name: 'Чёрный',
    rgbaTemplate: 'rgb(0, 0, 0, ?)',
    id: 'black',
  },
];
