import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { IUserRatingData, IUserRatingUI } from './IUserRatingUI';

export class UserRatingUI implements IUserRatingUI {
  public usersRatingList = injectEntityList<IUserRatingData>([]);
  public usersRatingFiltredList = injectEntityList<IUserRatingData>([]);
  public isLoading = injectPrimitive<boolean>(true);
  public searchText = injectPrimitive<string>('');
  public users = injectEntityList<IUserEntityModel>([]);
  public score = injectPrimitive<number>(0);
  public filterType = injectPrimitive<string>('champions');
}
