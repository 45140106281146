import { SystemRolesPermissionsExtensionItemData } from '../../../view/admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsInject';
import { SystemRolesAdditionalPermissionsToExistsGroupsIds } from '../../../view/admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsToExistsGroupInject';

export enum GPBSystemRolesExtensionPermissions {
  'project-not-in-team-permission-verify-requirements-on-create' = 'project-not-in-team-permission-verify-requirements-on-create',
  'project-not-in-team-permission-finish-acceptance-tests' = 'project-not-in-team-permission-finish-acceptance-tests',
}

export const GPBSystemRoleExtensions: SystemRolesPermissionsExtensionItemData[] = [
  {
    id: SystemRolesAdditionalPermissionsToExistsGroupsIds.notInTeam,
    values: [
      {
        value: GPBSystemRolesExtensionPermissions['project-not-in-team-permission-verify-requirements-on-create'],
        name: 'Установка флага верификации требований при создании системы',
      },
      {
        value: GPBSystemRolesExtensionPermissions['project-not-in-team-permission-finish-acceptance-tests'],
        name: 'Возможность завершить ПСИ',
      },
    ],
  },
  {
    id: SystemRolesAdditionalPermissionsToExistsGroupsIds.notifications,
    values: [
      {
        value: 'GPB_ACCEPT_APPLICATIONS',
        name: 'Обновление частного набора требований к АС',
      },
    ],
  },
];
