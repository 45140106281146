import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IAdditionalColumnModel } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IAdditionalColumnSearch } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnSearch';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader'

import { AdditionalColumnEntityAdminUI } from './AdditionalColumnEntityAdminUI';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { AdditionalColumnEntityModalDomain } from './modal/AdditionalColumnEntityModalDomain';

export class CustomAppBar {
  Toolbar = TableHeader;
}
export class AdditionalColumnEntityAdminDomain
  extends DataTableDomain<IAdditionalColumnModel, IAdditionalColumnSearch>
  implements IDataTableDomain<IAdditionalColumnModel, IAdditionalColumnSearch>
{
  public ui: AdditionalColumnEntityAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.additionalColumn.entity);
    this.modalDomain = new AdditionalColumnEntityModalDomain(rootPrivateServices.additionalColumn.entity, layoutDomain, this);
    this.ui = new AdditionalColumnEntityAdminUI(this, null);

    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Дополнительные колонки');
    this.ui.tableSubtitle.setValue('Колонки');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-additional-columns-create']
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-additional-columns-delete']
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-additional-columns-update']
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }
}
