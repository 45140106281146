import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IAdditionalColumnContentModel } from '../../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IPerformerTypeModel } from '../../../../../../../service/performer/type/IPerformerTypeModel';
import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { IVendorEntityModel } from '../../../../../../../service/vendor/entity/IVendorEntityModel';
import { DataTableDrawerUI } from '../../../../../../design/dataTable/parts/drawer/DataTableDrawerUI';
import { IDataTableDrawerUI } from '../../../../../../design/dataTable/parts/drawer/IDataTableDrawerUI';
import { RequirementEntityDrawer } from './RequirementEntityDrawer';

export class RequirementEntityDrawerUI
  extends DataTableDrawerUI<IRequirementModel>
  implements IDataTableDrawerUI<IRequirementModel>
{
  constructor(
    public categories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>([]),
    public specifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public specificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    public tagCategory: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([]),
    public tags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public performers: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
    public isCanGenerateShortName: IPrimitiveStore<boolean> = injectPrimitive<boolean>(true),
    public users: IEntityListStore<IUserEntityModel> = injectEntityList<IUserEntityModel>([]),
    public vendors: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([]),
    public vendorsRequirements: IEntityListStore<IVendorRequirementModel> = injectEntityList<IVendorRequirementModel>(
      [],
    ),
    public additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>([]),
    public additionalColumnContent: IEntityListStore<IAdditionalColumnContentModel> = injectEntityList<IAdditionalColumnContentModel>(
      [],
    ),
    public additionalColumnContentToSet: IEntityListStore<string> = injectEntityList<string>([]),
    public requirementVendorsToSet: IEntityListStore<string> = injectEntityList<string>([]),
  ) {
    super();
    this.drawerComponent.setValue(RequirementEntityDrawer);
  }
}
