import { RowContextMenuUI } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI'
import { ActiveDirectoryAdminModel } from './../ActiveDirectoryAdminModel';
import { ActiveDirectoryRowContextMenu } from './ActiveDirectiryCotextMenu';

export class ActiveDirectoryContextMenuUI<RowType> extends RowContextMenuUI<ActiveDirectoryAdminModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: ActiveDirectoryRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
