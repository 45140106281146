import { Link, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryComponent } from '../ITheoryComponent';

export interface IHeading {
  text: string;
}
export const Heading1 = observer(({ data }: ITheoryComponent<IHeading>) => {
  return (
    <Typography variant="h4" sx={{ marginBottom: '32px' }}>
      <Link
        sx={{ textDecoration: 'none', pointerEvents: 'none', cursor: 'none' }}
        href={`#${data.text}`}
        id={data.text}
      >
        {data.text}
      </Link>
    </Typography>
  );
});
