import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectListDomain } from '../../../../../../view/user/page/project/list/store/ProjectListDomain';
import { IRiskManagerProjectListDomain } from './IRiskManagerProjectListDomain';
import { IContainer } from '../../../../../../common/container/IContainer';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IRiskManagerRootService, RiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { IRiskManagerDataModel } from '../../../../service/manager/data/IRiskManagerDataModel';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { UserProjectItemView } from '../../../../../../view/user/page/project/list/store/model/UserProjectItemView';
import { UserProjectItemWithRiskManagerView } from './UserProjectItemWithRiskManagerView';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';

export const extendProjectListDomain = (
  Ref: new (...args: any[]) => ProjectListDomain,
): new (...args: any[]) => IRiskManagerProjectListDomain => {
  class ExtendedProjectFormDomainRef extends Ref {
    public riskManagersData: IEntityListStore<IRiskManagerDataModel> = injectEntityList([]);
    public isLoadRisks: IPrimitiveStore<boolean> = injectPrimitive(false);
    private riskManagerRootService: IRiskManagerRootService = new RiskManagerRootService('admin');
    constructor(layoutDomain: IMainLayoutDomainStore, container: IContainer) {
      super(layoutDomain, container);
    }

    async loadData(): Promise<void> {
      await super.loadData();
      this.isLoadRisks.setValue(true);
      const riskManagerData = (
        await this.riskManagerRootService.manager.data
          .search({
            limit: 100000,
            fields: ['id', 'targetId', 'riskLevelsReportData', 'isComplete'],
          })
          .catch((error) => {
            console.log(error);
            return { data: [] as IRiskManagerDataModel[] };
          })
      ).data;

      this.riskManagersData.setList(riskManagerData);
      this.isLoadRisks.setValue(false);
    }

    async mapProjectInfoFromServiceToView(userProjectsInfo: IProjectModel[]): Promise<UserProjectItemView[]> {
      const projectInfo: UserProjectItemWithRiskManagerView[] = await super.mapProjectInfoFromServiceToView(
        userProjectsInfo,
      );

      const extendedProjectInfo: UserProjectItemWithRiskManagerView[] = projectInfo.map((item) => {
        item.riskManagerData = this.riskManagersData.list.find((riskManager) => riskManager.targetId === item.id);
        return item;
      });

      return extendedProjectInfo;
    }
  }

  return ExtendedProjectFormDomainRef;
};
