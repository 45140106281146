export enum RiskManagerStaticQuestionType {
  multiRow = 'multiRow',
  oneRow = 'oneRow',
  number = 'number',
  // date = 'date',
  // link = 'link',
}

export const RiskManagerStaticQuestionTypeArray = Object.keys(RiskManagerStaticQuestionType) as Array<
  keyof typeof RiskManagerStaticQuestionType
>;
