import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import EmptyLayout from './EmptyLayout';

const EmptyLayoutRoute: React.StatelessComponent<RouteProps> = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

export default EmptyLayoutRoute;
