import { RowContextMenuUI } from '../../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../../view/design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';
import { IRiskManagerDataModel } from '../../../../../service/manager/data/IRiskManagerDataModel';
import { RiskManagerDataTableRowContextMenu } from './RiskManagerDataTableRowContextMenu';

export class RiskManagerDataTableContextMenuUI<RowType> extends RowContextMenuUI<IRiskManagerDataModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: RiskManagerDataTableRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
