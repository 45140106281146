import { IRowContextMenuUI } from '../../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../../view/design/dataTable/store/IDataTableDomain';
import { LayoutNotificationType } from '../../../../../../../view/layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { RiskManagerReportDomain } from '../../../../../report/store/RiskManagerReportDomain';
import { IRiskManagerDataModel } from '../../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataService } from '../../../../../service/manager/data/IRiskManagerDataService';
import { RiskManagerDataTableContextMenuUI } from './RiskManagerDataTableContextMenuUI';

export class RiskManagerDataTableContextMenuDomain extends RowContextMenuDomain<IRiskManagerDataModel> {
  public reportDomain: RiskManagerReportDomain;
  constructor(
    private dataService: IRiskManagerDataService,
    dataTableDomain: IDataTableDomain<IRiskManagerDataModel, any>,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerDataModel> | null,
    reportDomain?: RiskManagerReportDomain | null,
  ) {
    super(dataService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerDataTableContextMenuUI(this);
    this.reportDomain = reportDomain || new RiskManagerReportDomain(layoutDomain);
  }

  async onUpdateToLastVersionByRow(row: IRiskManagerDataModel) {
    const result = await this.dataService.autoMigrateById(row.id || '');
    if (result.state === 'cancel') {
      this.layoutDomain.notifications.showNotification({
        text: 'Конфликт автоматического обновления, нужно обновить в форме проекта',
        type: LayoutNotificationType.warning,
      });
    }
    await this.dataTableDomain.loadData();
  }

  async downloadReportAsJSON(row: IRiskManagerDataModel) {
    const projectId = row.targetId;
    await this.reportDomain?.downloadRiskManagerJson(projectId || '');
    await this.dataTableDomain.loadData();
  }

  async onUpdateAllToLastVersion(row: IRiskManagerDataModel) {}
}
