
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SyncAlt } from '@mui/icons-material';

import { FormAutocomplete } from '../../../../../view/design/form/FormAutocomplete';
import { FormIcon } from '../../../../../view/design/form/FormIcon';
import { FormTextLabel } from '../../../../../view/design/form/FormTextLabel';
import { FormTitleText } from '../../../../../view/design/form/FormTitleText';
import { RiskManagerPriorityByRequirementThreatDomain } from './store/RiskManagerPriorityByRequirementThreatDomain';
import { IAdminPageProperties } from '../../../../../view/admin/page/IAdminPageProperties';
import { TaskManagerServerListAdminDomain } from '../../../../../view/admin/page/taskManager/server/connectinoTemplate/store/TaskManagerTemplateDomain';
import { JiraTemplatePrioritiesAutocomplete } from '../../../../../view/admin/page/taskManager/server/connectinoTemplate/connectionTemplate';


export interface ITaskManagerServerListDomain extends IAdminPageProperties {
  domain: TaskManagerServerListAdminDomain;
}

export const RiskManagerJiraTemplatePriorityByRequirementThreat = observer(({ domain }: ITaskManagerServerListDomain) => {
  const [extensionsDomain] = useState<RiskManagerPriorityByRequirementThreatDomain>(
    new RiskManagerPriorityByRequirementThreatDomain(domain),
  );

  const jiraProjectPriorities = domain.jiraProjectPriorities.value;
  const jiraPrioritiesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraProjectPriorities].map((item) => ({
    name: item.name || '',
    value: item.id || '',
  }));

  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const issueTypeId = domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;

  useEffect(() => {
    extensionsDomain.requirementThreatData.setValue([]);
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraPriorities();
        await extensionsDomain.loadRequirementThreatData();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain, domain.riskTemplateId.value]);

  return extensionsDomain.requirementThreatData.value.length > 0 ? (
    <>
      <Grid container direction={'column'}>
        <FormTitleText text={'Приоритет уровню уязвимостей требований'} />
        {extensionsDomain.requirementThreatData.value.map((levelData) => {
          const isLoading = domain.isLoadingJiraPriorities.value;
          const selectedPriorityId =
            domain.taskManager?.entity?.connectionTemplate.settings?.value?.prioritiesMap?.find(
              (link) => link.tagId === levelData.levelPseudoId,
            )?.externalPriorityId || null;
          return (
            <Grid item>
              <Grid xs={8} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                <Grid item>
                  <FormTextLabel text={levelData.name} />
                </Grid>
                <Grid item>
                  <FormIcon>
                    <SyncAlt color={!!selectedPriorityId ? 'secondary' : 'disabled'} />
                  </FormIcon>
                </Grid>
                <Grid item xs={4}>
                  <FormAutocomplete
                    values={isLoading ? [] : jiraPrioritiesAutocompleteValues}
                    onChangeSelect={(newValue) => {
                      if (domain.taskManager.entity?.connectionTemplate.settings?.value) {
                        if (!domain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap) {
                          domain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap = [];
                        }
                        const selectedJiraPriorityIndex =
                          domain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap.findIndex(
                            (item) => item.tagId === levelData.levelPseudoId,
                          );
                        if (!newValue?.value) {
                          domain.taskManager.entity?.connectionTemplate.settings.value.prioritiesMap.splice(
                            selectedJiraPriorityIndex,
                            1,
                          );
                        } else {
                          if (selectedJiraPriorityIndex !== -1) {
                            domain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap[
                              selectedJiraPriorityIndex
                            ].externalPriorityId = newValue.value;
                            domain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap[
                              selectedJiraPriorityIndex
                            ].name = newValue.name;
                          } else {
                            domain.taskManager.entity?.connectionTemplate.settings.value.prioritiesMap.push({
                              isDefault: false,
                              orderIndex: 0,
                              externalPriorityId: newValue.value,
                              tagId: levelData.levelPseudoId || '',
                              name: newValue.name,
                            });
                          }
                        }
                      }
                    }}
                    key={`jira-priority-map-${levelData.levelPseudoId}`}
                    selected={selectedPriorityId}
                    label="Приоритет в Jira"
                    onOpen={() => {
                      domain.removeJiraValidationErrors();
                    }}
                    // onClose={() => changeIsloading(false)}
                    isLoading={isLoading}
                    loadingText={'Загрузка приоритетов'}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <JiraTemplatePrioritiesAutocomplete domain={domain} />
    </>
  ) : (
    <JiraTemplatePrioritiesAutocomplete domain={domain} />
  );
});
