import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldService } from '../../../../service/question/field/IRiskManagerQuestionFieldService';
import { IRiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerQuestionFieldDomain } from '../RiskManagerQuestionFieldDomain';
import { RiskManagerQuestionFieldFormUI } from './RiskManagerQuestionFieldFormUI';

export class RiskManagerQuestionFieldFormDomain
  extends DataTableFormDomain<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldService>
  implements IDataTableFormDomain<IRiskManagerQuestionFieldModel>
{
  public ui: RiskManagerQuestionFieldFormUI;
  public dataTableDomain: RiskManagerQuestionFieldDomain;

  constructor(
    public rootService: IRiskManagerRootService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: RiskManagerQuestionFieldDomain,
    ui = new RiskManagerQuestionFieldFormUI(),
  ) {
    super(rootService.question.field, layoutDomain, dataTableDomain, ui);
    this.ui = ui;
    this.dataTableDomain = dataTableDomain;
  }

  async loadData(id: string | null) {
    await super.loadData(id);
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateQuestionFields();
    }
  }
}
