import qs from 'qs';
import wretch from 'wretch';

import { CoreRestErrorHandler } from './CoreRestErrorHandler';
import { ICoreRestService } from './ICoreRestService';

export class CoreRestService implements ICoreRestService {
  constructor(protected errorHandler = new CoreRestErrorHandler()) {}

  async post<Request = any, Response = boolean>(url: string, data: Request): Promise<Response> {
    return await this.request().url(url).post(data).json<Response>().catch(this.handleError);
  }

  async put<Request = any, Response = boolean>(url: string, data: Request): Promise<Response> {
    return await this.request().url(url).put(data).json<Response>().catch(this.handleError);
  }

  async delete<Response = boolean>(url: string): Promise<Response> {
    return await this.request().url(url).delete().fetchError(this.handleError).json<Response>().catch(this.handleError);
  }

  async patch<Request = any, Response = boolean>(url: string, data: Request): Promise<Response> {
    return await this.request().url(url).patch(data).json<Response>().catch(this.handleError);
  }

  async get<QueryData = any, QueryResult = any>(url: string, queryData?: QueryData): Promise<QueryResult> {
    const paramsString = qs.stringify(queryData || {});
    const result = await this.request()
      .url(`${url}?${paramsString}`)
      .get()
      .fetchError(this.handleError)
      .json<QueryResult>()
      .catch(this.handleError);

    return result;
  }

  handleError = (errorResponse: any) => {
    return this.errorHandler.handle(errorResponse);
  };

  protected request() {
    const jwt = localStorage.getItem('jwtToken');
    return wretch()
      .options({
        headers: {
          ...(jwt && ({ Authorization: `Bearer ${jwt}` } as any)),
        },
      })
      .errorType('json');
  }
}
