import Editor from '@monaco-editor/react';
import { FileCopy } from '@mui/icons-material';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';

import { ITheoryComponent } from '../ITheoryComponent';

export interface ICodeExample {
  language: string;
  content: string;
  name: string;
  maxHeight: number;
}

export const CodeExample = observer(({ data }: ITheoryComponent<ICodeExample>) => {
  const editorRef = useRef(null);
  const [editorHeight, setEditorHeight] = useState<number>(0);
  const handleEditorMount = (editor) => {
    setTimeout(() => {
      data.maxHeight = 272;
      editorRef.current = editor;
      setEditorHeight(editor.getScrollHeight());
      editor.layout();
    }, 0);
  };
  const useStyles = makeStyles((theme: Theme) => ({
    editor: {
      maxHeight: '272px',
      height: editorHeight > data.maxHeight ? '100%' : editorHeight,
      '& .monaco-editor .overflow-guard': {
        height: editorHeight,
        borderRadius: '0px 0px 16px 16px',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box sx={{ marginBottom: '32px', width: '100%' }}>
      <Box
        sx={{
          borderRadius: '16px 16px 0px 0px',
          background: '#1E1E1E',
          padding: '4px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignSelf: 'center',
        }}
      >
        <Typography variant="body1">{data.name}</Typography>
        <IconButton
          sx={{ cursor: 'pointer', fontSize: '20px' }}
          onClick={() => {
            navigator.clipboard.writeText(data.content);
          }}
        >
          <FileCopy />
        </IconButton>
      </Box>
      <Editor
        className={classes.editor}
        theme="vs-dark"
        height={editorHeight > data.maxHeight ? data.maxHeight : editorHeight}
        language={data.language?.toLowerCase()}
        value={data.content}
        onMount={handleEditorMount}
        options={{
          wordWrap: 'on',
          readOnly: true,
          scrollbar: {
            vertical: editorHeight > data.maxHeight ? 'auto' : 'hidden',
            horizontal: 'hidden',
            handleMouseWheel: editorHeight > data.maxHeight,
          },
          minimap: { enabled: false },
        }}
      />
      <Box
        sx={{
          height: '14px',
          borderRadius: '0px 0px 16px 16px',
          background: '#1E1E1E',
        }}
      ></Box>
    </Box>
  );
});
