import { RestCRUDService } from '../../../../../../service/common/rest/RestCRUDService';
import { ILogModel } from './ILogModel';
import { ILogsSearch } from './ILogSearch';
import { ILogService } from './ILogService';

export class RestLogService extends RestCRUDService<ILogModel, ILogsSearch> implements ILogService {
  getEndpoint(): string {
    const url = this.config.url.api.admin;
    return `${url}/extension/log`;
  }
}
