export interface ILayoutNotification {
  id?: string;
  state?: ILayoutNotificationState;
  durationInMs?: number;
  text: string;
  type: LayoutNotificationType;
}

export enum ILayoutNotificationState {
  new = 'new',
  inProgress = 'inProgress',
  complete = 'complete',
}

export enum LayoutNotificationType {
  text = 'text',
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
}
