import { IUserProjectRoleModel } from './../../../../../../service/projectRole/entity/IUserProjectRoleModel';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IUserEntityModel } from '../../../../../../service/user/IUserEntityModel';
import { FormUI } from '../../../../../admin/page/form/store/FormUI';
import { ProjectFormModel } from './ProjectFormModel';

export class ProjectFormUI extends FormUI<ProjectFormModel> {
  constructor(
    public users = injectEntityList<IUserEntityModel>([]),
    public projectRoles = injectEntityList<IUserProjectRoleModel>([]),
    public isCanUseUnitedForm = injectPrimitive<boolean>(false),
  ) {
    super();
  }

  projectRoleAutocompleteValues(roleId: string) {
    const result = this.users.list
      .filter(
        (user) =>
          !this.model.entity.rolesMap?.data?.find(
            (roleData) => roleData.userId === user.id && roleData.roleId !== roleId,
          ),
      )
      .map((entity) => ({ value: entity.id, name: entity.displayName + ` (${entity.login || ''})`}));
    return result;
  }
}
