import React from 'react';

import { inject } from '../../../../../../common/container/inject';
import { IError } from '../../../../../../common/error/IError';
import { Store } from '../../../../../../common/store/base/Store';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../service/RootAdminService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { MailAdminUI } from './MailAdminUi';

export class MailAdminDomain extends Store {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new MailAdminUI(),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
  ) {
    super();
  }

  setPermissions() {
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-update']]),
    );
  }

  async loadData() {
    this.setPermissions();
    const { data: mailSettings } = await this.rootService.mail.entity
      .search({
        limit: 1,
        sort: [{ name: 'createdAt', isDesc: true }],
      })
      .catch(this.dataLoadErrorCatch);

    if (mailSettings.length) {
      this.ui.isEmptySettings.setValue(false);
      this.ui.mailSettings.setEntity(mailSettings[0]);
    }
  }

  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };
}
