import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { inject } from '../../../../common/container/inject';
import { ISignInPageDomain, SignInPageDomainToken } from './store/ISignInPageDomain';

export const LogoutPage = observer(() => {
  const [signInPageDomain] = useState<ISignInPageDomain>(inject(SignInPageDomainToken));
  useEffect(() => {
    signInPageDomain.logout();
  }, [signInPageDomain]);
  return <div />;
});
