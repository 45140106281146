import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { VendorRequirementAdminDomain } from './store/VendorRequirementAdminDomain';
import { useParams } from 'react-router';

export interface IVendorRequirementAdminTable extends IAdminPageProperties { }

export const VendorRequirementAdminTable = observer(({ layoutDomain }: IVendorRequirementAdminTable) => {
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])

  const [domain] = useState<VendorRequirementAdminDomain>(new VendorRequirementAdminDomain(layoutDomain));
  const { vendorId = null, vendorType = null } = useParams<any>();
  domain.activeVendorId.setValue(vendorId);
  domain.activeVendorType.setValue(vendorType);

  return (
    <Fragment>
      <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />
    </Fragment>
  );
});
