import { Box } from '@mui/material';
import React from 'react';

import { EDUIcon } from '../../../../../view/design/icon/EDUIcon';
import { REQIcon } from '../../../../../view/design/icon/REQIcon';
import { StartIcon } from '../../../../../view/design/icon/StartIcon';

export const LearningBottomMenuLogo = () => {
  return (
    <Box sx={{ padding: '18px 21px 18px 16px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Box></Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StartIcon />
        <Box sx={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
          <EDUIcon />
          <REQIcon />
        </Box>
      </Box>
    </Box>
  );
};
