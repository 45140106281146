import React from 'react';

import { inject } from '../../../../../../../../../common/container/inject';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { IUnitChapter } from '../../../../../../../service/unit/interface/IUnitTheory';
import { Chapter } from '../Chapter';
import { IPassingTheoryDomain } from './IPassingTheoryDomain';
import { PassingTheoryUI } from './PassingTheoryUI';

export class PassingTheoryDomain implements IPassingTheoryDomain {
  constructor(
    public ui = new PassingTheoryUI(),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.ui.additionalActions.setList([
      {
        Useful: (index: number) => {},
      },
      {
        Uclear: (index: number) => {},
      },
      { 'Contact Us': (index: number) => {} },
    ]);
  }
  redirect = (page: string) => {};

  redirectInNavigation = (page: string) => {};

  totalSteps = () => {
    return this.ui.stepsTitles.list.length;
  };

  completedSteps = () => {
    return Object.keys(this.ui.completed.value).length;
  };

  isLastStep = () => {
    return this.ui.activeStep.value === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleBack = () => {
    this.ui.activeStep.setValue(this.ui.activeStep.value - 1);
  };
  boot = () => {
    this.ui.isExpanded.setValue(true);
    this.ui.stepperList.setList(this.ui.passingTheoryList.list);
    const titles = this.ui.passingTheoryList.list.map((chapter) => chapter.title);
    this.ui.stepsTitles.setList(titles);
    const contest = this.ui.passingTheoryList.list.map((chapter) => {
      return {
        component: <Chapter chapter={chapter} />,
        isEneble: true,
      };
    });
    this.ui.stepContent.setList(contest);
    if (this.ui.navigationList.list.length === 0) {
      const navigationList = this.ui.passingTheoryList.list.map((chapter: IUnitChapter, index) => {
        return {
          title: chapter.title,
          passed: false,
          isActive: index === 0 ? true : false,
          subHeader: chapter.theoryBlocks
            .filter(
              (block) => block.block === 'Heading 1' || block.block === 'Heading 2' || block.block === 'Heading 3',
            )
            .map((heading) => {
              const padding =
                heading.block === 'Heading 1'
                  ? '40px'
                  : heading.block === 'Heading 2'
                  ? '64px'
                  : heading.block === 'Heading 3'
                  ? '88px'
                  : '0';
              const subHeader = {
                type: padding,
                // @ts-ignore

                title: heading.data.text,
              };
              return subHeader;
            }),
          navigationRedirect: this.navigationRedirect,
        };
      });
      this.ui.navigationList.setList(navigationList);
    }
  };
  handleNext = () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ? this.ui.stepsTitles.list.findIndex((step, i) => !(i in this.ui.completed.value))
        : this.ui.activeStep.value + 1;
    this.ui.activeStep.setValue(newActiveStep);
    this.ui.navigationList.list.forEach((chapter, index) => {
      if (index === newActiveStep) {
        chapter.isActive = true;
        this.ui.navigationList.list[index - 1].passed = true;
      }
    });
  };
  onRedirectTo = async () => {
    this.ui.navigationList.list[this.ui.navigationList.list.length - 1].passed = true;
    await this.learningRootService.unitResult.passTheory({
      ...this.ui.unitResultModel.entity,
      theoryResult: { totalTheoryResult: 10 },
    });
    this.ui.activeStep.setValue(null);
    this.redirect('quiz');
    this.ui.isExpanded.setValue(false);
  };
  navigationRedirect = (indexTitle: string) => {
    this.ui.hash.setValue('');
    this.redirectInNavigation('theory');
    this.ui.stepsTitles.list.forEach((title, index) => {
      if (indexTitle === title) {
        this.ui.activeStep.setValue(index);
      }
    });
  };
  expandHandler = () => {
    this.ui.isExpanded.setValue(!this.ui.isExpanded.value);
  };
  scrollToAnchor = (indexTitle: string, hash: string) => {
    this.redirectInNavigation('theory');
    this.ui.stepsTitles.list.forEach((title, index) => {
      if (indexTitle === title) {
        this.ui.activeStep.setValue(index);
      }
    });
    this.ui.hash.setValue(hash);
    const element = document.getElementById(hash);
    element?.scrollIntoView({ behavior: 'smooth' });
  };
  onExpandIconClick = (title: string) => {
    if (this.ui.activeAccordionTitle.value === title) {
      this.ui.activeAccordionTitle.setValue('');
    } else {
      this.ui.activeAccordionTitle.setValue(title);
    }
  };
}
