import { IRequirementModel } from '../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementService } from '../../../../../service/requirement/entity/IRequirementService';
import { injectRootService } from '../../../../../service/RootServiceFactory';
import { RequirementEntityDrawerDomain } from '../../../../../view/admin/page/requirement/newEntity/store/drawer/RequirementEntityDrawerDomain';
import { RequirementEntityModalDomain } from '../../../../../view/admin/page/requirement/newEntity/store/modal/RequirementEntityModalDomain';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import {
  RiskManagerRequirementThreatDrawerFieldsDomain,
  RiskManagerRequirementThreatFormFieldsDomain,
} from './RiskManagerRequirementThreatFormFieldsDomain';

export interface IRiskManagerRequirementEntityModalDomain extends RequirementEntityModalDomain {
  riskManagerRequirementThreatFormFieldsDomain: RiskManagerRequirementThreatFormFieldsDomain;
}

export const extendRequirementEntityModalDomain = (
  Ref: new (...args: any[]) => RequirementEntityModalDomain,
): new (...args: any[]) => RequirementEntityModalDomain => {
  class ExtendedRequirementModalDomainRef extends Ref {
    public riskManagerRequirementThreatFormFieldsDomain: RiskManagerRequirementThreatFormFieldsDomain;

    constructor(
      entityService: IRequirementService,
      layoutDomain: IMainLayoutDomainStore,
      dataTableDomain: IDataTableDomain<IRequirementModel, any>,
      public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    ) {
      super(entityService, layoutDomain, dataTableDomain, rootPrivateServices);
      this.riskManagerRequirementThreatFormFieldsDomain = new RiskManagerRequirementThreatFormFieldsDomain(this);
    }

    async save() {
      try {
        await super.save();
        if (this.ui.validationErrors.list.length === 0) {
          await this.riskManagerRequirementThreatFormFieldsDomain.save();
        }
      } catch (error) {}
    }

    async updateTableAfterMutate(entityId: string, isUpdate: boolean) {
      await super.updateTableAfterMutate(entityId, isUpdate);
      if (this.ui.validationErrors.list.length === 0) {
        this.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.setEntity({
          requirementId: '',
          risksMap: { risksIds: [] },
        });
      }
    }

    onCloseModal() {
      super.onCloseModal();
      this.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.setEntity({
        requirementId: '',
        risksMap: { risksIds: [] },
      });
    }
  }

  return ExtendedRequirementModalDomainRef;
};

export const extendRequirementEntityDrawerDomain = (
  Ref: new (...args: any[]) => RequirementEntityDrawerDomain,
): new (...args: any[]) => RequirementEntityDrawerDomain => {
  class ExtendedRequirementDrawerDomainRef extends Ref {
    public riskManagerRequirementThreatFormFieldsDomain: RiskManagerRequirementThreatDrawerFieldsDomain;

    constructor(
      entityService: IRequirementService,
      layoutDomain: IMainLayoutDomainStore,
      dataTableDomain: IDataTableDomain<IRequirementModel, any>,
      public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    ) {
      super(entityService, layoutDomain, dataTableDomain, rootPrivateServices);
      this.riskManagerRequirementThreatFormFieldsDomain = new RiskManagerRequirementThreatDrawerFieldsDomain(this);
    }

    async save() {
      try {
        await super.save();
        if (this.ui.validationErrors.list.length === 0) {
          await this.riskManagerRequirementThreatFormFieldsDomain.save();
        }
      } catch (error) {}
    }

    async updateTableAfterMutate(entityId: string, isUpdate: boolean) {
      await super.updateTableAfterMutate(entityId, isUpdate);
      if (this.ui.validationErrors.list.length === 0) {
        this.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.setEntity({
          requirementId: '',
          risksMap: { risksIds: [] },
        });
      }
    }

    onCloseDrawer() {
      super.onCloseDrawer();
      this.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.setEntity({
        requirementId: '',
        risksMap: { risksIds: [] },
      });
    }
  }

  return ExtendedRequirementDrawerDomainRef;
};
