import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { FormModalContainer } from '../../../../../../design/dataTable/form/view/FormModalContainer';
import { FormActions } from '../../../../../../design/form/FormActions';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { TaskManagerAdminModalDomain } from './TaskManagerAdminModalDomain';

export interface ITagCategoryAdminModalProperties {
  domain: TaskManagerAdminModalDomain;
}

export const TaskManagerAdminModal = observer(({ domain }: ITagCategoryAdminModalProperties) => {
  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
  }, [domain]);

  return (
    <FormModalContainer onClose={() => domain.onCancelUpdates()}>
      <TagNameCustomFieldText domain={domain} />
      <TaskManagerHostCustomFieldArea domain={domain} />
      <TaskManagerUserCustomFieldArea domain={domain} />
      <TaskManagerApitokenCustomFieldArea domain={domain} />
      <FormActions
        onSave={() => {
          domain.ui.model.entity.createDate = undefined;
          domain.ui.model.entity.lastUpdateDate = undefined;
          domain.onSaveUpdates();
        }}
        onCancel={() => domain.onCancelUpdates()}
      />
    </FormModalContainer>
  );
});

const TagNameCustomFieldText = observer(({ domain }: { domain: TaskManagerAdminModalDomain }) => {
  return (
    <FormTextField
      required={true}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const TaskManagerHostCustomFieldArea = observer(({ domain }: { domain: TaskManagerAdminModalDomain }) => {
  const onChange = (newvalue: string) => {
    if(domain.ui.model.entity.credentials?.value) {
       domain.ui.model.entity.credentials.value.host = newvalue;
    }
  }
  return (
    <FormTextField
      required={true}
      label={'Хост'}
      value={domain.ui.model.entity.credentials?.value.host || ''}
      onChange={(value) => onChange(value)}
      errorMessage={domain.getValidationErrorFor('host')?.message}
    />
  );
});

const TaskManagerUserCustomFieldArea = observer(({ domain }: { domain: TaskManagerAdminModalDomain }) => {
  const onChange = (newvalue: string) => {
    if(domain.ui.model.entity.credentials?.value) {
       domain.ui.model.entity.credentials.value.username = newvalue;
    }
  }
  return (
    <FormTextField
      required={true}
      label={'Пользователь'}
      value={domain.ui.model.entity.credentials?.value.username}
      onChange={(value) => onChange(value)}
      errorMessage={domain.getValidationErrorFor('username')?.message}
    />
  );
});

const TaskManagerApitokenCustomFieldArea = observer(({ domain }: { domain: TaskManagerAdminModalDomain }) => {
  const onChange = (newvalue: string) => {
    if(domain.ui.model.entity.credentials?.value) {
       domain.ui.model.entity.credentials.value.apiToken = newvalue;
    }
  }
  return (
    <FormTextField
      required={true}
      type='password'
      label={'API токен'}
      value={domain.ui.model.entity.credentials?.value.apiToken}
      onChange={(value) => onChange(value)}
      errorMessage={domain.getValidationErrorFor('apiToken')?.message}
    />
  );
});
