import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ProjectLinkProperties {
  url: string;
  children: any;
  onClick?: () => void;
}

export const ProjectLink = ({ url, children, onClick }: ProjectLinkProperties) => {
  return (
    <Link component={RouterLink} to={url} onClick={onClick}>
      {children}
    </Link>
  );
};