import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { IUserPageProperties } from '../../IUserPageProperties';
import { ApplicationInfoContent } from './parts/ApplicationInfoContent';
import { ApplicationDetailDomain } from './store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from './store/ApplicationDetailsMode';
import { ApplicationDetailsType } from './store/ApplicationDetailsType';

export const ApplicationTemplateEdit = observer(({ layoutDomain }: IUserPageProperties) => {
  const container = useContext(ContainerContext);
  const [domain] = useState(new (container.get(ApplicationDetailDomain))(layoutDomain));
  const { applicationId = undefined, projectId = undefined } = useParams<any>();

  useEffect(() => {
    domain.startPage(container, ApplicationDetailsMode.edit, applicationId, projectId, ApplicationDetailsType.template);
  }, [domain, applicationId, projectId]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={12}>
        <ApplicationInfoContent domain={domain} />
      </Grid>
    </Grid>
  );
});
