import { ISpecificationEntitySearch } from '../../../../../../../service/specification/entity/ISpecificationeEntitySearch';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { DataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { SpecificationEntityAdminUI } from '../SpecificationEntityAdminUI';

export class SpecificationEntityRowsUI
  extends DataTableRowsUI<ISpecificationEntityModel, ISpecificationEntitySearch>
  implements IDataTableRowsUI<ISpecificationEntityModel, ISpecificationEntitySearch>
{
  constructor(public rootUI: SpecificationEntityAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'categoryId') {
      return {
        dependenciesModels: this.rootUI.categories.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }

    return null;
  }
}
