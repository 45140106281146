import { action, computed, observable } from 'mobx';

import { IdGenerator } from '../../../../../common/generator/IdGenerator';
import { UIStore } from '../../../../../common/store/base/UIStore';
import { ILayoutError } from './ILayoutError';
import { ILayoutErrorUI } from './ILayoutErrorUI';

export class LayoutErrorUI extends UIStore implements ILayoutErrorUI {
  @observable private errorsList: ILayoutError[] = [];

  @computed get newErrors(): ILayoutError[] {
    return this.errorsList.filter((error) => error.isShowed === false);
  }

  @action.bound
  addError(newError: ILayoutError): void {
    newError.id = newError.id || IdGenerator.generate();
    newError.isShowed = false;
    this.errorsList.push(newError);
  }

  @action.bound
  markAsShowed(errorId: string): void {
    const errorIndex = this.errorsList.findIndex((error) => error.id === errorId);
    this.errorsList[errorIndex].isShowed = true;
  }
}
