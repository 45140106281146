import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { ITagCategoryModel } from './ITagCategoryModel';
import { ITagCategorySearch } from './ITagCategorySearch';
import { ITagCategoryService } from './ITagCategoryService';

export class RestTagCategoryService
  extends RestCRUDService<ITagCategoryModel, ITagCategorySearch>
  implements ITagCategoryService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/tag/category`;
  }
}
