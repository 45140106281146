import { DataTableRowsUI } from "../../../../../../../design/dataTable/parts/rows/DataTableRowsUI";
import { IDataTableRowsUI } from "../../../../../../../design/dataTable/parts/rows/IDataTableRowsUI";
import { ActiveDirectoryAdminModel } from "../ActiveDirectoryAdminModel";
import { ActiveDirectoryAdminUI } from "../ActiveDirectoryAdminUI";

export class ActiveDirectoryRowsUI
  extends DataTableRowsUI<ActiveDirectoryAdminModel, any>
  implements IDataTableRowsUI<ActiveDirectoryAdminModel, any>
{
  constructor(public rootUI: ActiveDirectoryAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'activeDirectoryId') {
      return {
        dependenciesModels: this.rootUI.activeDirectory.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }

    return null;
  }
}
