import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionService } from '../../../../service/question/IRiskManagerQuestionService';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionService } from '../../../../service/question/static/IRiskManagerStaticQuestionService';
import { IRiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerStaticQuestionDomain } from '../RiskManagerStaticQuestionDomain';
import { RiskManagerStaticQuestionFormUI } from './RiskManagerStaticQuestionFormUI';

export class RiskManagerStaticQuestionFormDomain
  extends DataTableFormDomain<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionService>
  implements IDataTableFormDomain<IRiskManagerStaticQuestionModel>
{
  public ui: RiskManagerStaticQuestionFormUI;
  public dataTableDomain: RiskManagerStaticQuestionDomain;

  constructor(
    public rootService: IRiskManagerRootService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: RiskManagerStaticQuestionDomain,
    ui = new RiskManagerStaticQuestionFormUI(),
  ) {
    super(rootService.question.static, layoutDomain, dataTableDomain, ui);
    this.ui = ui;
    this.dataTableDomain = dataTableDomain;
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateStaticQuestions();
    }
  }
}
