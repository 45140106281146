import { BlockType } from '../../../../../service/unit/interface/IUnitTheory';
import { CodeExample } from './media/CodeExample';
import { ImageComponent } from './media/Image';
import { VideoComponent } from './media/Video';
import { HiddenBlock } from './nesting/HiddenBlock';
import { SpecialBlock } from './nesting/SpecialBlock';
import { TabsBlock } from './nesting/TabsBlock';
import { TagsBlock } from './nesting/TagsBlock';
import { Spacing48 } from './spacing/Spacing48';
import { Spacing64 } from './spacing/Spacing64';
import { Heading1Component } from './text/Heading1';
import { Heading2Component } from './text/Heading2';
import { Heading3Component } from './text/Heading3';
import { ListComponent } from './text/List';
import { ParagraphComponent } from './text/Paragraph';
import { TableComponent } from './text/Table';

export const ElementFactory = {
  [BlockType.HEADING_1]: Heading1Component,
  [BlockType.HEADING_2]: Heading2Component,
  [BlockType.HEADING_3]: Heading3Component,
  [BlockType.PARAGRAPH]: ParagraphComponent,
  [BlockType.LIST]: ListComponent,
  [BlockType.TABLE]: TableComponent,
  [BlockType.IMAGE]: ImageComponent,
  [BlockType.VIDEO]: VideoComponent,
  [BlockType.CODE_EXAMPLE]: CodeExample,
  [BlockType.SPACING_48]: Spacing48,
  [BlockType.SPACING_64]: Spacing64,
  [BlockType.HIDDEN_BLOCK]: HiddenBlock,
  [BlockType.TABS]: TabsBlock,
  [BlockType.TAGS]: TagsBlock,
  [BlockType.SPECIAL_BLOCK]: SpecialBlock,
  // [BlockType.HTML, <div>HTML</div>],
};
