import { Action } from '@material-table/core';

import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';
import { DefaultSearchRequest } from './DefaultSearchRequest';
import { IAdminTableColumn, IAdminTableUI, MethodAction } from './IAdminTableUI';

export class AdminTableUI<
  ServiceModelType extends IServiceModel = IServiceModel,
  SearchRequestType extends ISearchRequestData = ISearchRequestData,
> implements IAdminTableUI<ServiceModelType, SearchRequestType>
{
  constructor(
    public searchRequest = injectEntity<SearchRequestType>(
      //@ts-ignore
      new DefaultSearchRequest(),
    ),
    public entities = injectEntityList<ServiceModelType>([]),
    public columns = injectEntityList<IAdminTableColumn<ServiceModelType>>([]),
    public actions = injectEntityList<Action<IServiceModel> | MethodAction<IServiceModel>>([]),
    public validationErrors = injectEntityList<IValidationErrorData>([]),
    public totalEntitiesCount = injectPrimitive<number>(0),
    public isLoading = injectPrimitive<boolean>(false),
    public isError = injectPrimitive<boolean>(false),
    public isInlineEdit = injectPrimitive<boolean>(true),
    public isCanEdit = injectPrimitive<boolean>(true),
    public isCanDelete = injectPrimitive<boolean>(true),
    public isCanCreate = injectPrimitive<boolean>(true),
    public searchTerm = injectPrimitive<string>(''),
    public tableOptions = {},
    public detailPanel: any = [],
    public isEditable: (rowData: ServiceModelType) => boolean = (data) => {
      return true;
    },
    public isDeletable: (rowData: ServiceModelType) => boolean = (data) => {
      return true;
    },
    public isShowDelete = injectPrimitive<boolean>(true),
  ) {}
}
