import { ApplicationDetailDomain } from '../store/ApplicationDetailDomain';

export const isAcceptRequirementAvailableExtension: IIsAcceptRequirementAvailableExtension[] = [
  (domain: ApplicationDetailDomain) => {
    return true;
  },
];

export interface IIsAcceptRequirementAvailableExtension {
  (domain: ApplicationDetailDomain): boolean;
}
