
import { IError } from '../../../../common/error/IError';
import { CRUDServiceType } from '../../../../service/common/service/CRUDServiceType';
import { IMainLayoutDomainStore } from '../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationDetailDomain } from '../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { RestRatingService } from '../service/GPBAdditionalService';

export class GPBApplicationDomain extends ApplicationDetailDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    private ratingService = new RestRatingService(CRUDServiceType.admin),
  ) {
    super(layoutDomain);
  }

  async setRatingSecondValue(applicationDataId: string) {
    try {
      await this.ratingService.setSecondRating(applicationDataId)
    } catch (error) {
      this.applicationFormErrorsHandler(error as IError)
    }
  }

}
