import { IServiceModel } from '../../common/model/IServiceModel';

export interface IActiveDirectoryServerEntityModel extends IActiveDirectoryServerCredentials, IServiceModel {
  name: string;
  isMainLoginMethod: boolean;
  isBackgroundUserSync: boolean;
  isConnected: ActiveDirectoryServerConnectionState;

  systemRoleToAdGroup: IActiveDirectorySystemRoleToGroupNameMap;

  lastSyncDate: Date;
}

export enum ActiveDirectoryServerConnectionState {
  connected = 'connected',
  notConnected = 'notConnected',
  error = 'error',
}

export interface IActiveDirectoryServerCredentials {
  url: string;
  baseDN: string;
  username: string;
  password: string;
  parsedDC?: string[];
}

export interface IActiveDirectorySystemRoleToGroupNameMap {
  mapping: IActiveDirectorySystemRoleToGroupNameMapItem[];
}

export interface IActiveDirectorySystemRoleToGroupNameMapItem {
  systemRoleId: string | null;
  groupName: string;
}
