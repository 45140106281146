import { IError } from '../../../../common/error/IError';
import { IValidationErrorData } from '../../../../common/error/IValidationErrorData';

export class WrongCredentialError implements IError<IValidationErrorData[]> {
  message = 'Неверный пароль или логин';
  webCode = '401';
  systemCode = 'none-system-code';

  constructor(public data: IValidationErrorData[] = []) {}
}
