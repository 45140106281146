import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerRiskModel } from '../../../service/risk/IRiskManagerRiskModel';
import { IRiskManagerRiskSearch } from '../../../service/risk/IRiskManagerRIskSearch';

export class RiskManagerRiskAdminUI
  extends DataTableUI<IRiskManagerRiskModel, IRiskManagerRiskSearch>
  implements IDataTableUI<IRiskManagerRiskModel, IRiskManagerRiskSearch>
{
  constructor(
    domain: IDataTableDomain<IRiskManagerRiskModel, IRiskManagerRiskSearch>,
    rows: IDataTableRowsUI<IRiskManagerRiskModel, IRiskManagerRiskSearch> | null,
  ) {
    super(domain, rows);
    this.tableRootPaperElevation.setValue(0);
  }
  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'sortIndex',
        headerName: 'Порядок отображения',
        flex: 0.5,
        type: 'number',
        headerAlign: 'left',
        align: 'center',
        renderCell: (params: { value?: string | number | boolean | object | Date | null | undefined }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
      },
    ];
  }
}
