import { IError } from '../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { ICRUDService } from '../../../../../service/common/service/ICRUDService';
import { LayoutNotificationType } from '../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormUI } from './FormUI';

export abstract class FormDomain<ModelType extends { id?: string }, ServiceType extends ICRUDService<any, any>> {
  constructor(
    protected layoutDomain: IMainLayoutDomainStore,
    protected entityService: ServiceType,
    public ui = new FormUI<ModelType>(),
  ) {}

  async loadData(id: string | null) {
    this.ui.isLoading.setValue(true);
    if (id) {
      await this.loadItem(id);
    } else {
      this.ui.model.setEntity({} as any);
    }
    this.ui.isLoading.setValue(false);
  }

  transformServerToView(item: ModelType): any {
    return item;
  }

  transformViewToServer(item: any): ModelType {
    return item;
  }

  async delete() {
    let entity = this.ui.model.entity;
    entity = this.transformViewToServer(entity);
    if (entity.id) {
      await this.callService(this.entityService.deleteById(entity.id));
    }
  }

  async save(entityToSave?: any) {
    let entity = entityToSave || this.ui.model.entity;
    entity = this.transformViewToServer(entity);
    if (entity.id && entity.id) {
      await this.callService(this.entityService.updateByModel(entity));
    } else {
      const newId = await this.callService<string>(this.entityService.createByModel(entity));
      if (newId) {
        this.ui.model.entity['id'] = `${newId}`;
      }
      return newId;
    }
  }

  getUpdateFieldHandler(fieldName: string) {
    return (newValue: any) => {
      // @ts-ignore
      this.ui.model.entity[fieldName] = newValue;
    };
  }

  getUpdateIntegerFieldHandler(fieldName: string) {
    return (newValue: any) => {
      const INTEGER_REGEX = /^-?\d*$/;

      if (INTEGER_REGEX.test(newValue) || newValue === '') {
        this.ui.model.entity[fieldName] = newValue;
        this.removeValidationErrors();
      } else {
        this.addValidationErrors([
          {
            target: fieldName,
            message: 'Введите целое число',
          },
        ]);
      }
    };
  }

  getMultiSelectFieldHandler(fieldName: string) {
    return (newValueList: { value?: string }[]) => {
      // @ts-ignore
      this.ui.model.entity[fieldName] = newValueList.map((value) => value.value);
    };
  }

  getUpdateSelectFieldHandler(fieldName: string) {
    return (newValue: any) => {
      // @ts-ignore
      this.ui.model.entity[fieldName] = newValue?.value;
    };
  }

  async loadItem(id: string) {
    try {
      this.ui.isLoading.setValue(true);

      const entity = await this.entityService.getById(id);

      this.ui.isLoading.setValue(false);
      const resultItem = this.transformServerToView(entity);
      this.ui.model.setEntity(resultItem);

      return true;
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  addValidationErrors(errors: IValidationErrorData[]) {
    this.ui.validationErrors.setList(errors);
  }

  getValidationErrorFor(fieldName: string, id?: string) {
    const error = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
    return error || null;
  }

  async removeValidationErrors() {
    this.ui.validationErrors.setList([]);
  }

  async removeValidationErrorFor(fieldName: string, id?: string) {
    const item = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
    if (item) {
      this.ui.validationErrors.removeByEntity(item);
    }
  }

  errorsHandler = async (error: IError): Promise<boolean> => {
    if (error.webCode === '400') {
      this.addValidationErrors(error.data);
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }

    return false;
  };

  protected async callService<ReturnType = any>(serviceHandler: Promise<any>): Promise<boolean | ReturnType> {
    try {
      this.ui.isLoading.setValue(true);
      const result = await serviceHandler;

      this.ui.isLoading.setValue(false);
      return result || true;
    } catch (error) {
      this.ui.isLoading.setValue(false);
      return this.errorsHandler(error);
    }
  }
}
