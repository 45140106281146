
import { inject } from '../../../../../../../common/container/inject';
import { injectEntityList } from "../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../common/store/base/injectPrimitive";
import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { IEntityListStore } from "../../../../../../../common/store/interface/IEntityListStore";
import { IError } from '../../../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../../../common/error/IValidationErrorData';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { ITaskManagerType, TaskManagerConnectionState } from '../../../../../../../service/taskManager/ITaskManager';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ITaskManagerServer } from '../../../../../../../service/taskManager/server/ITaskManagerServer';
import { ITaskManagerServerService } from '../../../../../../../service/taskManager/server/ITaskManagerServerService';
import { FormDomain } from '../../../../form/store/FormDomain';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../service/RootAdminService';
import { IJiraIssueFields } from '../../../../../../../service/application/jira/IJiraIssueFieldsData';
import { IStatusEntityModel } from '../../../../../../../service/status/entity/IStatusEntityModel';
import { IStatusValueModel } from '../../../../../../../service/status/value/IStatusValueModel';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { IJiraApplicationSettingsType } from '../../../../../../../service/taskManager/IJiraTaskManager';
import { TaskManagerViewModel } from './TaskManagerViewModel';
import { IPerformerTypeModel } from '../../../../../../../service/performer/type/IPerformerTypeModel';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { LayoutNotificationType } from '../../../../../../layout/common/notification/store/ILayoutNotification';

export class TaskManagerServerListAdminDomain extends FormDomain<ITaskManagerServer, ITaskManagerServerService> {

  private isLoadedApplicationDependencies: boolean = false;


  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    protected router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    public statuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
    public statusesValues: IEntityListStore<IStatusValueModel> = injectEntityList<IStatusValueModel>([]),
    public editPerformerTypes: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
    public editTagsCategories: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([]),
    public editTags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public jiraValidationErrors = injectEntityList<IValidationErrorData>([]),
    public statusesColumns = injectPrimitive<any[]>([]),
    public extraStatusesColumns = injectPrimitive<any[]>([]),
    public riskTemplateId = injectPrimitive<string | null>(''),
    public editJiraTaskManager = injectEntity<TaskManagerViewModel>({
      settings: {
        value: {
          customLabels: [],
          statusesMap: [],
          allowedInternalStatusesIds: [],
          projectId: null,
          projectKey: null,
          prioritiesMap: [],
          riskTemplateId: '',
          deletedStatus: {
            externalPriorityId: '',
            name: '',
          },
          issue: {
            namePrefix: null,
            type: { id: null, name: null },
          },
        },
        type: IJiraApplicationSettingsType.applicationCommon,
      },
      type: ITaskManagerType.jira,
      connectionState: TaskManagerConnectionState.notConnected,
    }),
    public taskManager = injectEntity<ITaskManagerServer | null>(null),
    public jiraProjects = injectPrimitive<any[]>([]),
    public jiraProjectStatuses = injectPrimitive<any[]>([]),
    public jiraProjectPriorities = injectPrimitive<any[]>([]),
    public jiraIssuesFields = injectPrimitive<IJiraIssueFields>({ fields: [] }),
    public jiraProjectTagsForPriority = injectPrimitive<
      { name: string; orderIndex: number; isDefault: boolean; tag: ITagEntityModel }[]
    >([]),
    public jiraIssueTypes = injectPrimitive<any[]>([]),
    public isLoadingJiraProjects = injectPrimitive<boolean>(false),
    public isLoadingJiraStatuses = injectPrimitive<boolean>(false),
    public isLoadingJiraPriorities = injectPrimitive<boolean>(false),
    public isLoadingJiraIssuesFields = injectPrimitive<boolean>(false),
    public isLoadingJiraIssueTypes = injectPrimitive<boolean>(false),
    public isFirstBootLoaded = injectPrimitive<boolean>(false),
    public taskManagerServers: IEntityListStore<ITaskManagerServer> = injectEntityList<ITaskManagerServer>([]),
    public editStatuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
  ) {
    super(layoutDomain, rootPrivateServices.taskManager.server);
  }

  public checkJiraConnectionByStates() {
    const isConnectedJira =
      this.jiraValidationErrors.list.length === 0 &&
      !!this.taskManager?.entity?.connectionTemplate.settings?.value?.projectId &&
      !!this.taskManager?.entity?.connectionTemplate.settings?.value.issue?.type?.id;
    if (this.taskManager.entity) {
      this.taskManager.entity.connectionState = isConnectedJira
        ? TaskManagerConnectionState.connected
        : TaskManagerConnectionState.notConnected;
    }
  }

  public async loadApplicationJiraSettings() {
    if (
      this.taskManager.entity?.connectionTemplate.settings?.value?.issue &&
      this.taskManager.entity?.connectionTemplate.settings.value.issue.namePrefix === null
    ) {
      this.taskManager.entity.connectionTemplate.settings.value.issue.namePrefix = '';
    }
  }

  public async loadJiraProjects() {
    this.isLoadingJiraProjects.setValue(true);

    try {
      if (this.taskManager.entity) {
        const taskManagerForProject = {
          type: ITaskManagerType.jira,
          serverId: this.taskManager.entity.id,
          connectionState: TaskManagerConnectionState.notConnected,
          settings: this.taskManager.entity.connectionTemplate.settings || undefined
        }
        const jiraProjects = await this.rootService.application.jira.loadProjects(taskManagerForProject);
        this.jiraProjects.setValue(jiraProjects);
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }

    this.isLoadingJiraProjects.setValue(false);
  }

  async boot(coonectionId: string | null) {
    try {
      await this.loadApplicationDependencies();
      if (coonectionId) {
        const taskManagerServer = await this.rootPrivateServices.taskManager.server
          .getById(coonectionId)
        if (!taskManagerServer.connectionTemplate.settings) {
          taskManagerServer.connectionTemplate.settings = {
            value: {
              customLabels: [],
              statusesMap: [],
              allowedInternalStatusesIds: [],
              projectId: null,
              projectKey: null,
              prioritiesMap: [],
              riskTemplateId: '',
              deletedStatus: {
                externalPriorityId: '',
                name: '',
              },
              issue: {
                namePrefix: null,
                type: { id: null, name: null },
              },
            },
            type: IJiraApplicationSettingsType.applicationCommon,
          };
        }
        if (taskManagerServer.connectionTemplate?.settings?.value?.riskTemplateId !== undefined) {
          this.riskTemplateId.setValue(taskManagerServer.connectionTemplate.settings.value.riskTemplateId);
        }

        if (taskManagerServer.connectionTemplate.settings?.value) {
          if(!taskManagerServer.connectionTemplate.settings.value.deletedStatus) {
            taskManagerServer.connectionTemplate.settings.value.deletedStatus = {
              externalPriorityId: '',
              name: '',
            };
          }
        }

        this.taskManager.setEntity(taskManagerServer);
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }
  }

  startPage = async () => {
    if (!this.isFirstBootLoaded.value) {
      this.setTableSettings();
      this.isFirstBootLoaded.setValue(true);
    }
  };

  setNewProject() {
    if (this.taskManager.entity?.connectionTemplate.settings?.value) {
      this.taskManager.entity.connectionTemplate.settings.value = {
        customLabels: [],
        statusesMap: [],
        allowedInternalStatusesIds: [],
        projectId: null,
        projectKey: null,
        prioritiesMap: [],
        deletedStatus: {
          externalPriorityId: '',
          name: '',
        },
        issue: {
          namePrefix: null,
          type: { id: null, name: null },
        },
      }
    }
  }

  public async loadJiraStatuses() {
    this.isLoadingJiraStatuses.setValue(true);
    this.jiraProjectStatuses.setValue([]);

    try {
      if (this.taskManager.entity) {
        this.taskManager.entity.type = ITaskManagerType.jira;
        const taskManagerForProject = {
          type: ITaskManagerType.jira,
          serverId: this.taskManager.entity.id,
          connectionState: TaskManagerConnectionState.notConnected,
          settings: this.taskManager.entity.connectionTemplate.settings || undefined
        }
        const jiraProjectStatuses = await this.rootService.application.jira.getProjectStatuses(
          taskManagerForProject
        );
        this.jiraProjectStatuses.setValue(jiraProjectStatuses);
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }
    this.isLoadingJiraStatuses.setValue(false);
  }

  public async loadJiraPriorities() {
    this.isLoadingJiraPriorities.setValue(true);
    this.jiraProjectPriorities.setValue([]);

    try {
      if (this.taskManager.entity) {
        this.taskManager.entity.type = ITaskManagerType.jira;
        const taskManagerForProject = {
          type: ITaskManagerType.jira,
          serverId: this.taskManager.entity.id,
          connectionState: TaskManagerConnectionState.notConnected,
          settings: this.taskManager.entity.connectionTemplate.settings || undefined
        }

        const jiraProjectPriorities = await this.rootService.application.jira.getProjectPriorities(
          taskManagerForProject
        );
        this.jiraProjectPriorities.setValue(jiraProjectPriorities);
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }
    this.isLoadingJiraPriorities.setValue(false);
  }

  public async loadJiraIssuesFields() {
    this.isLoadingJiraIssuesFields.setValue(true);

    try {
      if (this.taskManager.entity) {
        this.taskManager.entity.type = ITaskManagerType.jira;
        const taskManagerForProject = {
          type: ITaskManagerType.jira,
          serverId: this.taskManager.entity.id,
          connectionState: TaskManagerConnectionState.notConnected,
          settings: this.taskManager.entity.connectionTemplate.settings || undefined
        }
        const jiraIssuesFields = await this.rootService.application.jira.getIssuesFields(
          taskManagerForProject
        );
        this.jiraIssuesFields.setValue(jiraIssuesFields);
      }

      if (
        this.taskManager.entity?.connectionTemplate.settings?.value &&
        !this.taskManager.entity?.connectionTemplate.settings?.value?.fields
      ) {
        this.taskManager.entity.connectionTemplate.settings.value.fields = [];
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }
    this.isLoadingJiraIssuesFields.setValue(false);
  }

  public async loadJiraIssueTypes() {
    this.isLoadingJiraIssueTypes.setValue(true);
    this.jiraIssueTypes.setValue([]);

    try {
      if (this.taskManager.entity) {
        const taskManagerForProject = {
          type: ITaskManagerType.jira,
          serverId: this.taskManager.entity.id,
          connectionState: this.taskManager.entity.connectionState,
          settings: this.taskManager.entity.connectionTemplate.settings || undefined
        }
        const jiraIssueTypes = await this.rootService.application.jira.getIssueTypes(taskManagerForProject);
        this.jiraIssueTypes.setValue(jiraIssueTypes);
      }
    } catch (error) {
      this.jiraFormErrorsHandler(error as unknown as IError);
    }
    this.isLoadingJiraIssueTypes.setValue(false);
  }

  private async loadApplicationDependencies() {

    if (!this.isLoadedApplicationDependencies) {
      const [
        editStatuses,
        editPerformerTypes,
        taskManagerServers,
        tagCategories,
        tags,
        statusValues,
        performerTypes
      ] = await Promise.all([
        this.rootService.status.entity.search({ limit: 100000 }),
        this.rootService.performer.type.search({ limit: 100000 }),
        this.rootService.taskManager.server.search({ limit: 10000 }),
        this.rootService.tag.category.search({ limit: 10000 }),
        this.rootService.tag.entity.search({ limit: 10000 }),
        this.rootService.status.value.search({ limit: 100000 }),
        this.rootService.performer.type.search({ limit: 100000 }),
      ]);
      this.isLoadedApplicationDependencies = true;
      this.editStatuses.setList(editStatuses.data);
      this.taskManagerServers.setList(taskManagerServers.data);
      this.editPerformerTypes.setList(editPerformerTypes.data);
      this.editTagsCategories.setList(tagCategories.data);
      this.editTags.setList((tags?.data as any) || []);
      this.statusesValues.setList(statusValues.data);
      this.editPerformerTypes.setList(performerTypes.data);
      this.startPage();
    }

  }

  addJiraValidationErrors(
    errors: IValidationErrorData[],
    isProjectId: boolean = false,
    isIssueType: boolean = true,
    isStatusesMap: boolean = true,
    isPriorityMap: boolean = true,
  ) {
    this.clearJiraLoadedDataValues(isProjectId, isIssueType, isStatusesMap, isPriorityMap);
    this.jiraValidationErrors.setList(errors);
  }

  clearJiraLoadedDataValues(
    isProjectId: boolean,
    isIssueType: boolean,
    isStatusesMap: boolean,
    isPriorityMap: boolean,
    isFiledsData?: boolean,
  ) {
    if (isProjectId && this.editJiraTaskManager.entity.settings?.value) {
      this.editJiraTaskManager.entity.settings.value.projectId = null;
    }
    if (isStatusesMap) {
      this.clearJiraStatusesData();
    }
    if (isIssueType) {
      this.clearJiraIssueTypeData();
    }

    if (isPriorityMap) {
      this.clearJiraPriorityData();
    }

    if (isFiledsData) {
      this.clearJiraFieldsData();
    }
  }

  jiraFormErrorsHandler = async (
    error: IError,
    isProjectId: boolean = false,
    isIssueType: boolean = true,
    isStatusesMap: boolean = true,
    isPriorityMap: boolean = true,
  ) => {
    return this.jiraTemlateErrorsHandler(error, () => this.addJiraValidationErrors(error.data, isProjectId, isIssueType, isStatusesMap, isPriorityMap));
  };

  jiraTemlateErrorsHandler = async (error: IError, errorHandler?: (data: any) => void): Promise<void> => {
    if (error.webCode === '400') {
      errorHandler ? errorHandler(error.data) : this.addJiraValidationErrors(error.data);
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }
  };

  clearJiraStatusesData() {
    if (this.editJiraTaskManager.entity.settings?.value?.statusesMap) {
      this.editJiraTaskManager.entity.settings.value.statusesMap = [];
      this.editJiraTaskManager.entity.settings.value.allowedInternalStatusesIds = [];
    }
  }

  clearJiraFieldsData() {
    if (this.editJiraTaskManager.entity.settings?.value?.fields) {
      this.editJiraTaskManager.entity.settings.value.fields = [];
    }
  }

  clearJiraPriorityData() {
    if (this.editJiraTaskManager.entity.settings?.value?.prioritiesMap) {
      this.editJiraTaskManager.entity.settings.value.prioritiesMap = [];
    }
  }

  async removeJiraValidationErrors() {
    this.jiraValidationErrors.setList([]);
  }

  getJiraValidationErrorFor(fieldName: string, id?: string) {
    const error = this.jiraValidationErrors.list?.find((validationError) => validationError.target === fieldName);
    return error || null;
  }

  clearJiraIssueTypeData() {
    if (this.editJiraTaskManager.entity.settings?.value?.issue?.type?.id) {
      this.editJiraTaskManager.entity.settings.value.issue.type.id = undefined;
    }
  }

  getStatusesValues(statusId: string, activeStatusValueId: string | null): { value: string; name: string }[] {
    const sortByIndex = (current: any, next: any) => (current.orderIndex < next.orderIndex ? -1 : 1);
    const statusValues = this.statusesValues.list
      .filter((statusValue) => statusValue.statusId === statusId)
      .filter((statusValue) => {
        return statusValue.isActive === true || statusValue.id === activeStatusValueId;
      })
      .sort(sortByIndex);
    return statusValues.map((statusValue) => ({
      value: statusValue.id || '',
      name: statusValue.name || '',
    }));
  }

  getStatusValue(statusValueId?: string): IStatusValueModel | undefined {
    return this.statusesValues.list.find((statusValue) => statusValue.id === statusValueId);
  }


  taskManagerServersValues(): any {
    return [{
      value: this.taskManager.entity?.id,
      name: this.taskManager.entity?.name,
    }]
  }

  public async setPrioritiesSettings() {
    this.jiraProjectTagsForPriority.setValue([]);
    const priorityTagCategoryName = 'критичность требования';
    // const highPriorityTagName = 'высокая';
    // const midPriorityTagName = 'средняя';
    // const lowPriorityTagName = 'низкая';

    const priorityTagCategory = this.editTagsCategories.list.find(
      (tagCategory) => tagCategory.name?.toLocaleLowerCase() === priorityTagCategoryName,
    );

    this.editTags.list.forEach((tag) => {
      if(tag.categoryId === priorityTagCategory?.id) {
        this.jiraProjectTagsForPriority.value.push({
          name: `${priorityTagCategory.name}: ${tag.name}`,
          orderIndex: 0,
          isDefault: false,
          tag: tag,
        });
      }
    })
  }

  setTableSettings = () => {
    this.setPrioritiesSettings();
  };


  getStatusValueByName(name: string): IStatusValueModel | null {
    return this.statusesValues.list?.find((statusValue) => statusValue.name === name) || null;
  }

  async saveTemplate() {
    if (!this.jiraValidationErrors?.list?.length) {
      try {
        if (this.taskManager.entity?.connectionTemplate) {
          if (this.taskManager.entity.connectionTemplate?.settings?.value) {
            this.taskManager.entity.connectionTemplate.settings.value.riskTemplateId = this.riskTemplateId.value ? this.riskTemplateId.value : null;
          }
          await this.rootPrivateServices.taskManager.server.createConntectionTemplate(this.taskManager.entity?.connectionTemplate, this.taskManager.entity?.id || '')
        }
        this.layoutDomain.notifications.showNotification({
          type: LayoutNotificationType.success,
          text: 'Шаблон сохранен',
        });
        this.router.goTo('/settings/taskManager/server');
      } catch (error) {
        this.jiraFormErrorsHandler(error as unknown as IError);
      }
    }
  }

  async cancel() {
    this.router.goTo('/settings/taskManager/server');
  }
}
