import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TaskManagerAdminModalUI } from './TaskManagerAdminModalUI';
import { ITaskManagerServer } from '../../../../../../../service/taskManager/server/ITaskManagerServer';
import { ITaskManagerServerService } from '../../../../../../../service/taskManager/server/ITaskManagerServerService';

export class TaskManagerAdminModalDomain
  extends DataTableFormDomain<ITaskManagerServer, ITaskManagerServerService>
  implements IDataTableFormDomain<ITaskManagerServer>
{
  constructor(
    entityService: ITaskManagerServerService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ITaskManagerServer, any>,
  ) {
    super(entityService, layoutDomain, dataTableDomain, new TaskManagerAdminModalUI());
  }
}
