import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { DataGridDetailDomain } from '../../../../../../design/dataTable/parts/detail/DataGridDetailDomain';
import { IDataGridDetailDomain } from '../../../../../../design/dataTable/parts/detail/IDataGridDetailDomain';
import { RequirementEntityDetailUI } from './RequirementEntityDetailUI';

export class RequirementEntityDetailDomain
  extends DataGridDetailDomain<IRequirementModel>
  implements IDataGridDetailDomain<IRequirementModel>
{
  public ui: RequirementEntityDetailUI;
  constructor() {
    super(new RequirementEntityDetailUI());
    this.ui = new RequirementEntityDetailUI();
  }
}
