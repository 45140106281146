import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { IRiskManagerQuestionFieldModel } from '../../question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionModel } from '../../question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerRiskModel } from '../../risk/IRiskManagerRiskModel';
import { IRiskManagerRiskLevelSettings, IRiskManagerTemplateModel } from '../template/IRiskManagerTemplateModel';

export interface IRiskManagerDataModel extends IServiceModel {
  templateId: string | null;
  templateVersion: number;
  isUpdateConflict: boolean;
  isNeedUpdate: boolean;
  isComplete: boolean;
  answersData: IRiskManagerDataAnswerData;
  staticQuestionData: IRiskManagerDataStaticQuestionData;
  questionFieldData: IRiskManagerDataQuestionFieldData;
  riskLevelsData: IRiskManagerDataRiskLevelData;
  riskLevelsReportData: IRiskManagerDataRiskLevelReportData;
  requirementThreatLevelsData: IRiskManagerDataRequirementThreatLevelData;
  riskLevelsHistory: IRiskManagerRiskLevelDataHistory;

  risksCopy: IRiskManagerRiskModel[];
  questionsCopy: IRiskManagerQuestionModel[];
  staticQuestionsCopy: IRiskManagerStaticQuestionModel[];
  questionFieldsCopy: IRiskManagerQuestionFieldModel[];
  templateDataCopy: IRiskManagerTemplateModel | null;
  targetType: RiskManagerDataTargetType;
  targetId: string | null;

  reportData: IReportData;
  lastDataChangesDate: Date | null;
}

export enum RiskManagerDataTargetType {
  project = 'project',
  application = 'application',
}

export interface IRiskManagerDataQuestionFieldData {
  data: IRiskManagerDataQuestionFieldDataItem[];
}

export interface IRiskManagerDataStaticQuestionData {
  data: IRiskManagerDataStaticQuestionDataItem[];
}

export interface IRiskManagerDataAnswerData {
  data: IRiskManagerDataAnswersDataItem[];
}

export interface IRiskManagerDataRiskLevelData {
  data: IRiskManagerRiskLevelDataItem[];
}

export interface IRiskManagerDataRequirementThreatLevelData {
  data: IRiskManagerRequirementThreatLevelDataItem[];
}

export interface IRiskManagerDataRiskLevelReportData {
  data: IRiskManagerDataRiskLevelReportDataItem[];
}

export interface IRiskManagerDataRiskLevelReportDataItem {
  riskName: string;
  riskLevelName: string;
  riskColor: string | null;
  sortIndex: number;
}

export interface IRiskManagerDataQuestionFieldDataItem {
  type: 'static' | 'answer';
  questionId: string;
  fieldId: string;
  value: any;
}

export interface IRiskManagerDataStaticQuestionDataItem {
  staticQuestionId: string;
  value: any;
}

export interface IRiskManagerDataAnswersDataItem {
  questionId: string;
  answerPseudoId: string | null;
  answerPseudoIds: string[];
  isMultiAnswer: boolean;
  value: number | null;
}

export interface IRiskManagerRiskLevelDataItem {
  riskLevelSettingsPseudoId: string;
  riskId: string;
  value: number;
}

export interface IRiskManagerRequirementThreatLevelDataItem {
  requirementThreatLevelSettingsPseudoId: string;
  riskId: string;
  value: number;
}

export interface IRiskManagerRiskLevelDataHistory {
  data: IRiskManagerRiskLevelDataHistoryItem[];
}

export interface IRiskManagerRiskLevelDataHistoryItem {
  changeDate: Date;
  lastState: IRiskManagerRiskLevelDataItem;
  riskCopy: IRiskManagerRiskModel;
  riskLevelsSettingsCopy: IRiskManagerRiskLevelSettings;
}

export interface IReportData {
  downloads: IReportDownloadData[];
}

export interface IReportDownloadData {
  date: Date;
  type: ReportTypes;
  templateId: string;
  templateVersion: number;
}

export enum ReportTypes {
  json = 'json',
}
