import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionModel } from '../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../service/question/IRiskManagerQuestionSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerQuestionContextMenuDomain } from './context/RiskManagerQuestionContextMenuDomain';
import { RiskManagerQuestionFormDomain } from './form/RiskManagerQuestionFormDomain';
import { RiskManagerQuestionUI } from './RiskManagerQuestionUI';

export class RiskManagerQuestionDomain extends DataTableDomain<IRiskManagerQuestionModel, IRiskManagerQuestionSearch> {
  public ui: RiskManagerQuestionUI;

  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.question.entity);
    this.ui = new RiskManagerQuestionUI(this, null);
    this.modalDomain = new RiskManagerQuestionFormDomain(rootPrivateServices, layoutDomain, this);
    this.contextMenuDomain = new RiskManagerQuestionContextMenuDomain(
      rootPrivateServices.question.entity,
      this,
      layoutDomain,
    );
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
  }

  async loadDependencies() {
    const answerPreset = await this.rootPrivateServices.question.answerPreset.search({ limit: 100000 });
    this.ui.answerPresets.setList(answerPreset.data);
  }
}
