import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerRiskModel } from '../../../service/risk/IRiskManagerRiskModel';
import { IRiskManagerRiskSearch } from '../../../service/risk/IRiskManagerRIskSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerRiskContextMenuDomain } from './context/RiskManagerRiskContextMenuDomain';
import { RiskManagerRiskAdminModalDomain } from './modal/RiskManagerRiskAdminModalDomain';
import { RiskManagerRiskAdminUI } from './RiskManagerRiskAdminUI';

export class RiskManagerRiskAdminDomain extends DataTableDomain<IRiskManagerRiskModel, IRiskManagerRiskSearch> {
  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.risk);
    this.ui = new RiskManagerRiskAdminUI(this, null);
    this.modalDomain = new RiskManagerRiskAdminModalDomain(rootPrivateServices.risk, layoutDomain, this);
    this.contextMenuDomain = new RiskManagerRiskContextMenuDomain(rootPrivateServices.risk, this, layoutDomain);
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }
}
