import { Autocomplete } from '@mui/lab';
import { Alert, Chip, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

export interface ChipListInputProperties {
  id: string;
  options: string[];
  defaultValue: string[];
  label: string;
  placeholder: string;
  onChange: (newValues: string[]) => void;
  helperText?: string | null;
  errorMessage?: string | null;
  disabled?: boolean,
  inputLabelProps?: any,
}

export const ChipListInput = observer(
  ({
    defaultValue,
    options,
    id,
    label,
    placeholder,
    onChange,
    helperText = null,
    errorMessage = null,
    disabled = false,
    inputLabelProps = {}
  }: ChipListInputProperties) => {
    const classes = useStyles();
    return (
      <div className={classes.container}>
        <Autocomplete
          disabled={disabled}
          color={'secondary'}
          multiple
          id={id}
          options={options}
          defaultValue={defaultValue}
          onChange={(event, newValues) => {
            onChange(newValues);
          }}
          renderOption={(props, option) => (
            <li {...props}>
              {'Добавить метку ' + option}
            </li>
          )}
          filterOptions={(options, params) => {
            const newOptions = options.filter(option => option.includes(params.inputValue))
            if (!newOptions.length && params.inputValue) {
              newOptions.push(params.inputValue)
            }
            return newOptions;
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip color={'secondary'} label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField color={'secondary'} {...params} InputLabelProps={{ ...inputLabelProps }} variant={'standard'} label={label} placeholder={placeholder} />
          )}
        />

        {!errorMessage && helperText && <FormHelperText>{helperText}</FormHelperText>}
        {errorMessage && (
          <Alert icon={false} variant="outlined" severity="error">
            {errorMessage}
          </Alert>
        )}
      </div>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%'
  },
}));
