import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { ITaskManagerServer } from './ITaskManagerServer';
import { ITaskManagerServerSearch } from './ITaskManagerServerSearch';
import { ITaskManagerServerService } from './ITaskManagerServerService';

export class RestTaskManagerServerService
  extends RestCRUDService<ITaskManagerServer, ITaskManagerServerSearch>
  implements ITaskManagerServerService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  public async createConntectionTemplate(connectinoTemplate: any, connectionId: string) {
    const result = await this.request()
      .url(`${this.getEndpoint()}/template/${connectionId}`)
      .post(connectinoTemplate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
    return result.id;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/task/manager/server`;
  }
}
