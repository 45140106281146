import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Colors } from '../../../design/color/Colors';

export const widthInPercents = (itemCount: number, totalStatusesCount: number, minPercent: number = 15) => {
  const percents = Math.round((itemCount / totalStatusesCount) * 100);
  let widthPercents = percents < minPercent ? minPercent : percents;
  // widthPercents = widthPercents > 55 ? 55 : widthPercents;
  if (totalStatusesCount < 10 && itemCount < 2) {
    widthPercents = minPercent;
  }
  return widthPercents;
};

export const useApplicationRequirementStyle = makeStyles((theme: Theme) => ({
  notAcceptedRequirement: {
    backgroundColor: Colors.newestActiveOpacity('0.7'),
  },
  excludedRequirement: {
    backgroundColor: Colors.negativeActiveOpacity('0.7'),
  },
  statusItem: {
    height: '50px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '15px',
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  selectedStatus: {
    border: '3px solid white',
    borderRight: 'none',
  },
}));
