import shortid from 'shortid';

export interface IIdGenerator {
  generate(): string;
}

export class IdGenerator implements IIdGenerator {
  generate(): string {
    return IdGenerator.generate();
  }

  static generate(): string {
    return shortid.generate();
  }
}
