import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetService } from '../../../../service/question/answerPreset/IRiskManagerQuestionService';
import { RiskManagerQuestionAnswerPresetDomain } from '../RiskManagerQuestionAnswerPresetDomain';
import { RiskManagerQuestionAnswerPresetFormUI } from './RiskManagerQuestionAnswerPresetFormUI';

export class RiskManagerQuestionAnswerPresetFormDomain
  extends DataTableFormDomain<IRiskManagerQuestionAnswerPresetModel, IRiskManagerQuestionAnswerPresetService>
  implements IDataTableFormDomain<IRiskManagerQuestionAnswerPresetModel>
{
  public ui: RiskManagerQuestionAnswerPresetFormUI;

  constructor(
    entityService: IRiskManagerQuestionAnswerPresetService,
    layoutDomain: IMainLayoutDomainStore,
    public dataTableDomain: RiskManagerQuestionAnswerPresetDomain,
    ui = new RiskManagerQuestionAnswerPresetFormUI(),
  ) {
    super(entityService, layoutDomain, dataTableDomain, ui);
    this.ui = ui;
  }

  getUpdateIntegerFieldHandlerForSortIndex(answer) {
    return (newValue: any) => {
      const INTEGER_REGEX = /^-?\d*$/;

      if (INTEGER_REGEX.test(newValue) || newValue === '') {
        answer.sortIndex = newValue;
        this.removeValidationErrors();
      } else {
        this.addValidationErrors([
          {
            target: 'sortIndex',
            message: 'Введите целое число',
          },
        ]);
      }
    };
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateAnswerPresets();
    }
  }
}
