import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';

import { PassingFinalResults } from '../../../parts/finalResults/PassingFinalResults';
import { PassingPractice } from '../../../parts/practice/PassingPractice';
import { PassingQuiz } from '../../../parts/quiz/PassingQuiz';
import { PassingTheory } from '../../../parts/theory/PassingTheory';
import { PassingContext } from '../../../PassingContext';

export const PassingBodyMain = observer(() => {
  const { passingDomain, theoryDomain, quizDomain, practiceDomain, finalResultsDomain } = useContext(PassingContext);
  const isFullScreen = practiceDomain.ui.isFullScreen.value;
  const [styles, setStyles] = useState({
    paddingTop: isFullScreen ? '0px' : '24px',
    width: isFullScreen ? '100%' : '75%'
  });

  useEffect(() => {
    setStyles({
      paddingTop: isFullScreen ? '0px' : '24px',
      width: isFullScreen ? '100%' : '75%'
    });
  }, [isFullScreen]);
  return (
    <Box sx={styles}>
      {passingDomain.ui.passingPage.value === 'theory' && theoryDomain.ui.isEnebled.value && (
        <PassingTheory theoryDomain={passingDomain.theoryDomain} />
      )}
      {passingDomain.ui.passingPage.value === 'quiz' && quizDomain.ui.isEnebled.value && (
        <PassingQuiz quizDomain={passingDomain.quizDomain} />
      )}
      {passingDomain.ui.passingPage.value === 'practice' && practiceDomain.ui.isEnebled.value && (
        <PassingPractice practiceDomain={passingDomain.practiceDomain} />
      )}
      {passingDomain.ui.passingPage.value === 'finalResults' && finalResultsDomain.ui.isEnebled.value && (
        <PassingFinalResults finalResultDomain={passingDomain.finalResultsDomain} />
      )}
    </Box>
  );
});
