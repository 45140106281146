import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { SidebarStepsComponents } from '../SidebarStepsComponents';
import { LearningUnitPreviewPageContext } from '../../store/LearningUnitPreviewPageContext';

export const LearningUnitPreviewSidebar = observer(() => {
  const classes = useStyles();
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const unit = unitPreviewDomain?.ui?.unit?.entity;

  return (
    <div className={classes.sidebarContainer}>
      <SidebarStepsComponents unit={unit} />
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));
