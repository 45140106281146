import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { MouseEvent, PropsWithChildren, ReactNode, useState } from 'react';

interface IPreviewAccordionProps {
  title: ReactNode;
  additionalInfo?: ReactNode;
  onTitleClick?: () => void;
  titleClasses?: string;
  disabled?: boolean;
  isExpandable?: boolean;
}

export const PreviewAccordion = ({
  title,
  additionalInfo,
  children,
  disabled,
  isExpandable = true,
  ...props
}: PropsWithChildren<IPreviewAccordionProps>) => {
  const [expand, setExpand] = useState(false);
  const toggleAccordion = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (isExpandable) {
      setExpand((prev) => !prev);
    }
  };

  const classes = useStyles();

  const onTitleClick = (event: MouseEvent<HTMLElement>) => {
    if (props.onTitleClick) {
      event.stopPropagation();
      props.onTitleClick();
    }
  };

  const titleClasses = classNames(classes.accordionItemTitleText, props.titleClasses);

  return (
    <Accordion expanded={expand} disabled={disabled} className={classes.accordionWrapper}>
      <AccordionSummary
        onClick={toggleAccordion}
        className={classes.accordionItem}
        expandIcon={isExpandable && <ExpandMore />}
      >
        <Typography className={classes.accordionItemTitle}>
          <div onClick={onTitleClick} className={titleClasses}>
            {title}
          </div>
          {additionalInfo}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  accordionWrapper: {
    padding: 0,
    boxShadow: 'none',
    background: '#444F55',
    borderRadius: '5px',
    '&::before': {
      display: 'none',
    },
  },
  accordionItem: {
    minHeight: '20px !important',
    margin: 0,
    '& .MuiAccordionSummary-content:first-child': {
      margin: '12px 0',
    },
  },
  accordionItemTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accordionItemTitleText: {
    '&:hover': {
      color: '#777777',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '100ms',
    },
  },
  accordionDetails: {
    padding: 0,
  },
}));
