import { ILayoutUser } from './ILayoutUser';

export const defaultUser: ILayoutUser = {
  id: '',
  avatarUrl: '',
  displayName: '',
  systemRoleId: '',
  systemRoleName: '',
  allowedPermissions: [],
  newMessagesCount: 0,
};
