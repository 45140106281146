import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { PassingBodyMain } from './PassingBodyMain';
import { PassingBodySidebar } from './PassingBodySidebar';

interface IPassingBody {
  isFullScreen: boolean;
}

export const PassingBody = observer(({ isFullScreen }: IPassingBody) => {
  const classes = useStyles();
  return (
    <Box className={classes.passingBodyContainer}>
      <PassingBodyMain />
      {!isFullScreen && <Divider orientation="vertical" sx={{ margin: '0px 16px 0px 0px' }} />}
      {!isFullScreen && <PassingBodySidebar />}
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  passingBodyContainer: {
    display: 'flex',
    height: 'calc(100vh - 105px)',
    position: 'relative',
    overflow: 'hidden',
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
  },
}));
