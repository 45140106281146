import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { DataTable } from '../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../domain/RiskManagerSettingsPageDomain';

export interface RiskManagerQuestionFieldTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerQuestionFieldTable = observer(({ rootDomain }: RiskManagerQuestionFieldTableProperties) => {
  return <DataTable tableHeight={800} dataTableDomain={rootDomain.questionFieldDomain} />;
});
