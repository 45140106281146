import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdminTable } from '../../table/AdminTable';
import { PerformerTypeAdminDomain } from './store/PerformerTypeAdminDomain';

export interface IPerformerTypeAdminTableProperties extends IAdminPageProperties {}

export const PerformerTypeAdminTable = observer(({ layoutDomain }: IPerformerTypeAdminTableProperties) => {
  const container = useContext(ContainerContext);
  const DomainClassConstructor = container.get(PerformerTypeAdminDomain);

  const [domain] = useState(new DomainClassConstructor(layoutDomain));

  return <AdminTable tableDomain={domain} />;
});
