import { observer } from 'mobx-react';
import { Fragment, useContext, useEffect, useState } from 'react';
import React from 'react';

import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { RequirementEntityAdminDomain } from './store/RequirementEntityAdminDomain';

export interface IRequirementEntityAdminTable extends IAdminPageProperties { }

export const RequirementEntityAdminTable = observer(({ layoutDomain }: IRequirementEntityAdminTable) => {
  const container = useContext(ContainerContext);
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])
  const [domain] = useState<RequirementEntityAdminDomain>(new RequirementEntityAdminDomain(layoutDomain, container));

  return (
    <Fragment>
      <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />
    </Fragment>
  );
});
