import { IUserSystemRoleModel } from '../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { RowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';

export class SystemRoleAdminContextMenuUI<RowType> extends RowContextMenuUI<IUserSystemRoleModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
