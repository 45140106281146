import { IRatingAppModel } from './IRatingAppModel';
import { IRatingAppService } from './IRatingAppService';
import { CoreRestCRUDService } from '../../../../../service/coreCommon/common/rest/CoreRestCRUDService';
import { CRUDServiceType } from '../../../../../service/common/service/CRUDServiceType';

export class RestRatingAppService extends CoreRestCRUDService<IRatingAppModel> implements IRatingAppService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/rating_app`;
  }

  async updateAppRating(applicationDataId) {
    return await this.request()
      .url(this.getEndpoint())
      .put(applicationDataId)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }
}
