import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { GenerateUUID } from '../../../../service/pseudoId/GenerateUUID';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { RiskManagerQuestionForm } from './RiskManagerQuestionForm';

const defaultModelValue: IRiskManagerQuestionModel = {
  text: '',
  sortIndex: 1,
  presetId: null,
  answersMap: { answers: [] },
  isMultiAnswer: false,
  isUpdatedByExternalService: false,
};

export class RiskManagerQuestionFormUI
  extends DataTableFormUI<IRiskManagerQuestionModel>
  implements IDataTableFormUI<IRiskManagerQuestionModel>
{
  constructor(
    public answerPresets: IEntityListStore<IRiskManagerQuestionAnswerPresetModel> = injectEntityList([]),
    public defaultAnswerPreset: IEntityStore<IRiskManagerQuestionAnswerPresetModel | null> = injectEntity(null),
  ) {
    super(defaultModelValue);
    this.formComponent.setValue(RiskManagerQuestionForm);
  }

  addAnswer() {
    this.model.entity.answersMap.answers.push({
      text: '',
      questionId: null,
      defaultValue: 0,
      sortIndex: 1,
      pseudoId: GenerateUUID(),
    });

    this.model.entity.presetId = null;
  }

  removeAnswer(pseudoId: string) {
    this.model.entity.answersMap.answers = [
      ...this.model.entity.answersMap.answers.filter((item) => item.pseudoId !== pseudoId),
    ];

    this.dropPreset();
  }

  dropPreset() {
    this.model.entity.presetId = null;
  }

  getAnswerError(index: number, property: string) {
    const result = Object.values(
      this.validationErrors.list
        ?.find((validationError) => validationError.property === 'answersMap')
        ?.children?.find((validationError) => validationError.property === 'answers')
        ?.children?.find((answer) => Number(answer.property) === index)
        ?.children?.find((item) => item.property?.toLocaleLowerCase() === property.toLocaleLowerCase())
        ?.constraints || { nonError: null },
    )[0];

    return result;
  }

  getQuestionError(property: string) {
    const result = Object.values(
      this.validationErrors?.list?.find((item) => item.property?.toLocaleLowerCase() === property.toLocaleLowerCase())
        ?.constraints || { nonError: null },
    )[0];

    return result;
  }
}
