import { ConfigManager } from '../../../../../application/config/ConfigManager';
import { IConfig } from '../../../../../application/config/IConfig';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { inject } from '../../../../../common/container/inject';
import { IError } from '../../../../../common/error/IError';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { Store } from '../../../../../common/store/base/Store';
import { INotificationModel } from '../../../../../service/notification/INotificationModel';
import { ServiceType, injectRootService } from '../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { INotificationDomain } from './INotificationDomain';
import { NotificationUI } from './NotificationUI';

export class NotificationDomain extends Store implements INotificationDomain {
  static lookInterval: any;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public i18n = inject<ILocalization>(ILocalizationToken),
    public ui = new NotificationUI(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(ServiceType.admin),
    public config: IConfig = ConfigManager.getConfig(),
    private timer: any = 0,
    public isLoading = injectPrimitive<boolean>(true),
  ) {
    super();
  }

  async loadData(): Promise<void> {
    const mail = await this.rootService.mail.entity.search({ limit: 1 });
    if (mail.data.length) {
      this.ui.isSetConfig.setValue(true);
    }

    const notification = await this.rootService.notification
      .search({
        limit: 1000,
        sort: [{ name: 'createdAt', isDesc: true }],
      })
      .catch(this.dataLoadErrorCatch);

    if (notification.data.length <= 6) {
      await notification.data.forEach((notification) => {
        notification.isRead = true;
        this.readNotification(notification);
      });
    }
    this.ui.notifications.setList(notification.data);
    this.isLoading.setValue(false)
  }

  async lookForUpdates() {
    await this.loadUnreadNotificationsCount();
    // if (!NotificationDomain.lookInterval) {
    //   const interval = setInterval(async () => {
    //     await this.loadUnreadNotificationsCount();
    //   }, 1000);
    //   NotificationDomain.lookInterval = interval;
    // }
  }

  async loadUnreadNotificationsCount(): Promise<void> {
    const unreadNotificationsCount = await this.rootService.notification.getUnreadCount();
    this.ui.notificationUnreadCount.setValue(unreadNotificationsCount.unreadCount);
  }

  debounce(func, wait = 500) {
    return () => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(func, wait);
    };
  }

  readNotification(notification: INotificationModel) {
    this.ui.unreadNotifications.setList([...this.ui.unreadNotifications.list, { ...notification, isRead: true }]);

    const req = this.debounce(async () => {
      await this.rootService.notification
        .readNotifications(this.ui.unreadNotifications.list)
        .catch(this.dataLoadErrorCatch);
    });
    req();
  }

  stared = async (notification: INotificationModel): Promise<void> => {
    await this.rootService.notification.updateByModel({ ...notification, isImportant: !notification.isImportant });
  };

  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };
}
