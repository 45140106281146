import { Box, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { MailAdminDomain } from './store/MailAdminDomain';

interface IConnectionViewBoxProperties {
  mailDomain: MailAdminDomain;
}

export const ConnectionViewBox = observer(({ mailDomain }: IConnectionViewBoxProperties) => {
  return (
    <Paper elevation={4}>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Тип аутентификации</Typography>
          <Typography>{mailDomain.ui.mailSettings.entity.authType}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Название</Typography>
          <Typography>{mailDomain.ui.mailSettings.entity.name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Хост</Typography>
          <Typography>{mailDomain.ui.mailSettings.entity.host}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Порт</Typography>
          <Typography>{mailDomain.ui.mailSettings.entity.port}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Логин</Typography>
          <Typography>{mailDomain.ui.mailSettings.entity.user}</Typography>
        </Box>
        {mailDomain.ui.mailSettings.entity.domain && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>Domain</Typography>
            <Typography>{mailDomain.ui.mailSettings.entity.domain}</Typography>
          </Box>
        )}
        {mailDomain.ui.mailSettings.entity.workstation && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>Workstation</Typography>
            <Typography>{mailDomain.ui.mailSettings.entity.workstation}</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
});
