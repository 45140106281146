import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IStatusEntityModel } from './IStatusEntityModel';
import { IStatusEntitySearch } from './IStatusEntitySearch';
import { IStatusEntityService } from './IStatusEntityService';
import { statusTypeOfValues } from './StatusTypeOfValues';

export class RestStatusEntityService
  extends RestCRUDService<IStatusEntityModel, IStatusEntitySearch>
  implements IStatusEntityService
{
  constructor(private type: CRUDServiceType) {
    super();
  }
  async getValueTypes() {
    return statusTypeOfValues;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/status`;
  }
}
