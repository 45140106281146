import { ICode } from './theoryBlocks/ICode';
import { IFile } from './theoryBlocks/IFile';
import { IHeading } from './theoryBlocks/IHeading';
import { IHidden } from './theoryBlocks/IHidden';
import { IHtml } from './theoryBlocks/IHtml';
import { IList } from './theoryBlocks/IList';
import { IParagraph } from './theoryBlocks/IParagraph';
import { ISpacing } from './theoryBlocks/ISpacing';
import { ISpecial } from './theoryBlocks/ISpecial';
import { ITable } from './theoryBlocks/ITable';
import { ITabs } from './theoryBlocks/ITabs';
import { ITags } from './theoryBlocks/ITags';

export interface IUnitTheory {
  chapters: IUnitChapter[];
  isEnabled: boolean;
}
export interface IUnitChapter {
  title: string;
  theoryBlocks: ITheoryBlock<ITheoryBlocks>[];
  sortIndex: number;
  pseudoId: string;
}

export type ITheoryBlocks =
  | IHeading
  | IParagraph
  | IList
  | ITable
  | IFile
  | ICode
  | ISpacing
  | IHidden
  | ITabs
  | ITags
  | ISpecial
  | IHtml;

export interface ITheoryBlock<TheoryData> {
  block: BlockType;
  data: TheoryData;
  sortIndex: number;
  pseudoId: string;
  parentId: string;
  Element?: any;
  name:string;

}

export enum BlockType {
  HEADING_1 = 'Heading 1',
  HEADING_2 = 'Heading 2',
  HEADING_3 = 'Heading 3',
  PARAGRAPH = 'Paragraph',
  LIST = 'List',
  TABLE = 'Table',
  IMAGE = 'Image',
  VIDEO = 'Video',
  CODE_EXAMPLE = 'Code Example',
  SPACING_48 = '48 px',
  SPACING_64 = '64 px',
  HIDDEN_BLOCK = 'Hidden Block',
  TABS = 'Tabs',
  TAGS = 'Tags',
  SPECIAL_BLOCK = 'Special Block',
  HTML = 'HTML',
}
