import { RestCRUDService } from '../../../../../service/common/rest/RestCRUDService';
import { IRiskManagerDataModel } from './IRiskManagerDataModel';
import { IRiskManagerDataSearch } from './IRiskManagerDataSearch';
import { IRiskManagerDataService } from './IRiskManagerDataService';

export class RestRiskManagerDataService
  extends RestCRUDService<IRiskManagerDataModel, IRiskManagerDataSearch>
  implements IRiskManagerDataService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  async downloadReport(id: string): Promise<void> {
    const url = `${this.getEndpoint()}/report/download/${id}`;
    this.request().url(url).post().json<void>().catch(this.handleError);
  }

  getLastVersionData(dataId: string): Promise<IRiskManagerDataModel> {
    const url = `${this.getEndpoint()}/last/version/${dataId}`;
    return this.request().url(url).get().json<IRiskManagerDataModel>().catch(this.handleError);
  }

  autoMigrateAll(): Promise<{ state: 'done' | 'cancel' }> {
    const url = `${this.getEndpoint()}/auto/migrate/all`;
    return this.request().url(url).post().json<{ state: 'done' | 'cancel' }>().catch(this.handleError);
  }

  autoMigrateById(dataId: string): Promise<{ state: 'done' | 'cancel' }> {
    const url = `${this.getEndpoint()}/auto/migrate/${dataId}`;
    return this.request().url(url).post().json<{ state: 'done' | 'cancel' }>().catch(this.handleError);
  }

  async getByProjectId(projectId: string): Promise<IRiskManagerDataModel | null> {
    if (projectId) {
      const url = `${this.getEndpoint()}/project/${projectId}`;
      return this.request().url(url).get().json<IRiskManagerDataModel>().catch(this.handleError);
    }
    return null;
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/data`;
  }
}
