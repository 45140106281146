import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IPassingPracticeDomain } from '../../../admin/page/passing/parts/practice/store/IPassingPracticeDomain';
import { IPassingQuizDomain } from '../../../admin/page/passing/parts/quiz/store/IPassingQuizDomain';
import { IPassingTheoryDomain } from '../../../admin/page/passing/parts/theory/store/IPassingTheoryDomain';
import { IAdditionalActionsProps, IPassingStepperDomain } from './IPassingStepperDomain';

export abstract class PassingStepperDomain implements IPassingStepperDomain {
  public rootDomain: any;
  public quizDomain?: IPassingQuizDomain;
  public theoryDomain?: IPassingTheoryDomain;
  public practiceDomain?: IPassingPracticeDomain;
  constructor(
    rootDomain: any | null = null,
    quizDomain?: IPassingQuizDomain,
    theoryDomain?: IPassingTheoryDomain,
    practiceDomain?: IPassingPracticeDomain,
    public isQuizAdditionalAtions = injectPrimitive<boolean>(false),
    public quizAdditionalActions = injectEntityList<IAdditionalActionsProps>([]),
    public isTheoryAdditionalAtions = injectPrimitive<boolean>(false),
    public theoryAdditionalActions = injectEntityList<IAdditionalActionsProps>([]),
    public isPracticeAdditionalAtions = injectPrimitive<boolean>(false),
    public practiceAdditionalActions = injectEntityList<IAdditionalActionsProps>([]),
  ) {
    this.rootDomain = rootDomain;
    this.quizDomain = quizDomain;
    this.theoryDomain = theoryDomain;
    this.practiceDomain = practiceDomain;
  }
}
