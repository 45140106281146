import { RestCRUDService } from "../../../../service/common/rest/RestCRUDService";


export class ManagerFilterRootService {
  constructor(
    public filter = new ManagerFilterService(),
  ) { }
}

export class ManagerFilterService
  extends RestCRUDService<any, any>
{
  getEndpoint(): string {
    const url = this.config.url.api.admin
    return `${url}/managerFilter`;
  }

  getManagers() {
    const url = `${this.getEndpoint()}`;
    return this.request().url(url).get().json<any>().catch(this.handleError);
  }
}
