import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { SystemRoleAdminDomain } from './store/SystemRoleAdminDomain';

export interface ISystemRoleAdminTableProperties extends IAdminPageProperties { }

export const SystemRoleAdminTable = observer(({ layoutDomain }: ISystemRoleAdminTableProperties) => {
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])
  const [domain] = useState<SystemRoleAdminDomain>(new SystemRoleAdminDomain(layoutDomain));

  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
