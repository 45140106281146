import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { DataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerTemplateSearch } from '../../../../service/manager/template/IRiskManagerTemplateSearch';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerTemplateUI } from '../RiskManagerTemplateUI';

export class RiskManagerTemplateRowsUI
  extends DataTableRowsUI<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>
  implements IDataTableRowsUI<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>
{
  constructor(
    public rootUI: RiskManagerTemplateUI,
    public risks: IEntityListStore<IRiskManagerRiskModel> = injectEntityList([]),
    public questions: IEntityListStore<IRiskManagerQuestionModel> = injectEntityList([]),
    public staticQuestions: IEntityListStore<IRiskManagerStaticQuestionModel> = injectEntityList([]),
    public questionFields: IEntityListStore<IRiskManagerQuestionFieldModel> = injectEntityList([]),
  ) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    return null;
  }
}
