import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IErrorUpload, IFileUploadUI } from './IFileUploadUI';

export class FileUploadUI implements IFileUploadUI {
  constructor(
    public files = injectEntityList<File>([]),
    public isLoading = injectPrimitive<boolean>(false),
    public accept = injectEntityList<string>([]),
    public maxSize = injectPrimitive<number>(3000000),
    public error = injectPrimitive<IErrorUpload>({ code: '', message: '' }),
    public key = injectPrimitive<string>(''),
    public maxFiles = injectPrimitive<number>(1),
  ) {}
}
