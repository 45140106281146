import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementCategoryService } from '../../../../../../../service/requirement/category/IRequirementCategoryService';
import { IRowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { RequirementCategoryAdminContextMenuUI } from './RequirementCategoryAdminContextMenuUI';

export class RequirementCategoryAdminContextMenuDomain extends RowContextMenuDomain<IRequirementCategoryModel> {
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: IRequirementCategoryService;
    dataTableDomain: IDataTableDomain<IRequirementCategoryModel, any>;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<IRequirementCategoryModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RequirementCategoryAdminContextMenuUI(this);
  }
}
