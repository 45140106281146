import { SystemRolesPermissionsExtensionItemData } from '../../../view/admin/page/systemRole/entity/store/injects/SystemRolesAdditionalPermissionsInject';

export enum RiskManagerSystemRolePermissions {
  'extension-risk-manager-settings-project-fill' = 'extension-risk-manager-settings-project-fill',
  'extension-risk-manager-settings-report' = 'extension-risk-manager-settings-report',
  'extension-risk-manager-settings-admin-read' = 'extension-risk-manager-settings-admin-read',
  'extension-risk-manager-settings-admin-update' = 'extension-risk-manager-settings-admin-update',
  'extension-risk-manager-settings-admin-create' = 'extension-risk-manager-settings-admin-create',
  'extension-risk-manager-settings-admin-delete' = 'extension-risk-manager-settings-admin-delete',
}

export const RiskManagerSystemRoleExtensions: SystemRolesPermissionsExtensionItemData = {
  label: 'Анкета рисков',
  values: [
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-project-fill'],
      name: 'Заполнение в проектах',
    },
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-report'],
      name: 'Доступ к отчётам',
    },
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-admin-read'],
      name: 'Администрирование - чтение',
    },
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-admin-update'],
      name: 'Администрирование - редактирование',
    },
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-admin-create'],
      name: 'Администрирование - создание',
    },
    {
      value: RiskManagerSystemRolePermissions['extension-risk-manager-settings-admin-delete'],
      name: 'Администрирование - удаление',
    },
  ],
};
