import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { Colors } from '../../../design/color/Colors';
import { IMainLayoutDomainStore } from '../../main/store/domain/IMainLayoutDomainStore';
import { ILayoutNotification, LayoutNotificationType } from './store/ILayoutNotification';

export interface NotificationWrapperProperties {
  children: any;
}

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    backgroundColor: Colors.negativeActiveOpacity('0.5') + ' !important',
    fontSize: '16px !important',
    maxWidth: '40%',
    maxHeight: '250px',
    height: '100%',
  },
  success: {
    backgroundColor: Colors.greenButtonOpacity('0.5') + ' !important',
    fontSize: '16px !important',
    // color: Colors.negativePassiveText + '#2b0000 !important',
  },
}));

export const NotificationWrapper = ({ children }: NotificationWrapperProperties) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
      }}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
};

export interface NotificationHandlerProperties {
  layoutDomain: IMainLayoutDomainStore;
}

export const NotificationHandler = observer(({ layoutDomain }: NotificationHandlerProperties) => {
  const { enqueueSnackbar } = useSnackbar();
  const newNotifications = layoutDomain.notifications.getNewNotifications();

  useEffect(() => {
    const notificationTypeToSnackbarTypeMap: any = {
      [LayoutNotificationType.text]: 'default',
      [LayoutNotificationType.info]: 'info',
      [LayoutNotificationType.warning]: 'warning',
      [LayoutNotificationType.error]: 'error',
      [LayoutNotificationType.success]: 'success',
    };
    newNotifications.forEach((notification: ILayoutNotification) => {
      const snackbarType = notificationTypeToSnackbarTypeMap[notification.type] || 'default';
      enqueueSnackbar(notification.text, {
        variant: snackbarType,
      });
      layoutDomain.notifications.markNotificationAsCompleted(notification.id || '');
    });
  }, [layoutDomain, newNotifications, enqueueSnackbar]);

  return <div />;
});
