import { useMemo } from "react"
import { IUnitModel } from "../../../../../../../../service/unit/interface/IUnitModel"

export const useFinalResultsValues = (unit?: IUnitModel) => {
    return useMemo(() => ({
        success: {
          quizResult: Number(unit?.quiz?.questions?.length),
          practiceResult: Number(unit?.practice?.tasks.length),
          totalUnitResult: unit?.score || 100,
        },
        neutral: {
          quizResult: Math.round(Number(unit?.quiz?.questions?.length) * 0.7),
          practiceResult: Math.round(Number(unit?.practice?.tasks.length) * 0.7),
          totalUnitResult: Math.round(unit?.score || 100) * 0.7,
        },
        failure: {
          quizResult: Math.floor(Number(unit?.quiz?.questions?.length) * 0.5),
          practiceResult: Math.floor(Number(unit?.practice?.tasks.length) * 0.5),
          totalUnitResult: Math.floor((unit?.score || 100) * 0.5),
        },
      }), [unit?.practice?.tasks.length, unit?.quiz?.questions?.length, unit?.score])
}