import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { LearningRichTextDisplay } from '../../../../../design/richTextEditor/LearningRichTextEditor';
import { ITheoryComponent } from '../ITheoryComponent';

export interface IHeading {
  text: string;
}
export const Paraghraph = observer(({ data }: ITheoryComponent<IHeading>) => {
  return (
    <Box sx={{ marginBottom: '16px', width: '100%', fontSize: '20px', lineHeight: 1.6 }}>
      <LearningRichTextDisplay data={data.text} />
    </Box>
  );
});
