import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { DataTable } from '../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../domain/RiskManagerSettingsPageDomain';

export interface RiskManagerQuestionTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerStaticQuestionTable = observer(({ rootDomain }: RiskManagerQuestionTableProperties) => {
  return <DataTable tableHeight={800} dataTableDomain={rootDomain.staticQuestionDomain} />;
});
