import { ExportDataMainPage } from '../view/export/ExportDataMainPage';
import { ImportDataMainPage } from '../view/import/ImportDataMainPage';

export const ImportDataRouter = {
  path: '/settings/import/data',
  pageTitle: 'Импорт данных',
  component: ImportDataMainPage,
  allowedPermissions: [],
};

export const ExportDataRouter = {
  path: '/settings/export/data',
  pageTitle: 'Экспорт данных',
  component: ExportDataMainPage,
  allowedPermissions: [],
};
