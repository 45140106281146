import { injectPrimitive } from '../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../common/store/interface/IPrimitiveStore';
import { injectRootService } from '../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { RiskManagerSystemRolePermissions } from '../../systemRole/RiskManagerSystemRoleExtensions';
import { RiskManagerDataTableDomain } from '../data/adminTable/domain/RiskManagerDataTableDomain';
import { RiskManagerQuestionFieldDomain } from '../field/domain/RiskManagerQuestionFieldDomain';
import { RiskManagerQuestionDomain } from '../question/domain/RiskManagerQuestionDomain';
import { RiskManagerQuestionAnswerPresetDomain } from '../questionAnswerPreset/domain/RiskManagerQuestionAnswerPresetDomain';
import { RiskManagerRiskAdminDomain } from '../risk/domain/RiskManagerRiskAdminDomain';
import { RiskManagerStaticQuestionDomain } from '../staticQuestion/domain/RiskManagerStaticQuestionDomain';
import { RiskManagerTemplateDomain } from '../template/domain/RiskManagerTemplateDomain';

export class RiskManagerSettingsPageDomain {
  public riskDomain: RiskManagerRiskAdminDomain;
  public answerPresetDomain: RiskManagerQuestionAnswerPresetDomain;
  public questionDomain: RiskManagerQuestionDomain;
  public questionFieldDomain: RiskManagerQuestionFieldDomain;
  public staticQuestionDomain: RiskManagerStaticQuestionDomain;
  public templateDomain: RiskManagerTemplateDomain;
  public dataTableDomain: RiskManagerDataTableDomain;
  public isCanRead: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false);
  public isCanCreate: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false);
  public isCanEdit: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false);
  public isCanDelete: IPrimitiveStore<boolean> = injectPrimitive<boolean>(false);

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public coreRootService = injectRootService(layoutDomain.serviceType.value),
    riskDomain: RiskManagerRiskAdminDomain | null = null,
    answerPresetDomain: RiskManagerQuestionAnswerPresetDomain | null = null,
    questionDomain: RiskManagerQuestionDomain | null = null,
    questionFieldDomain: RiskManagerQuestionFieldDomain | null = null,
    staticQuestionDomain: RiskManagerStaticQuestionDomain | null = null,
    templateDomain: RiskManagerTemplateDomain | null = null,
    dataTableDomain: RiskManagerDataTableDomain | null = null,
  ) {
    this.riskDomain = riskDomain || new RiskManagerRiskAdminDomain(this, layoutDomain);
    this.answerPresetDomain = answerPresetDomain || new RiskManagerQuestionAnswerPresetDomain(this, layoutDomain);
    this.questionDomain = questionDomain || new RiskManagerQuestionDomain(this, layoutDomain);
    this.questionFieldDomain = questionFieldDomain || new RiskManagerQuestionFieldDomain(this, layoutDomain);
    this.staticQuestionDomain = staticQuestionDomain || new RiskManagerStaticQuestionDomain(this, layoutDomain);
    this.templateDomain = templateDomain || new RiskManagerTemplateDomain(this, layoutDomain);
    this.dataTableDomain = dataTableDomain || new RiskManagerDataTableDomain(this, layoutDomain);
  }

  async onUpdateAnswerPresets() {
    await this.questionDomain.loadDependencies();
  }

  async onUpdateQuestions() {
    await this.templateDomain.loadDependencies();
    await this.templateDomain.modalDomain.resyncDependenciesValues();
  }

  async onUpdateRisks() {
    await this.templateDomain.loadDependencies();
    await this.templateDomain.modalDomain.resyncDependenciesValues();
  }

  async onUpdateStaticQuestions() {
    await this.templateDomain.loadDependencies();
    await this.templateDomain.modalDomain.resyncDependenciesValues();
  }

  async onUpdateQuestionFields() {
    await this.templateDomain.loadDependencies();
    await this.templateDomain.modalDomain.resyncDependenciesValues();
  }

  async onUpdateTemplate() {
    await this.dataTableDomain.loadData();
  }

  async setPermissions() {
    const isCanRead = this.riskDomain.layoutDomain.userHaveAnyAccess([
      RiskManagerSystemRolePermissions[
        'extension-risk-manager-settings-admin-read'
      ] as unknown as UserSystemRoleModelPermissionMap,
    ]);

    const isCanEdit = this.riskDomain.layoutDomain.userHaveAnyAccess([
      RiskManagerSystemRolePermissions[
        'extension-risk-manager-settings-admin-update'
      ] as unknown as UserSystemRoleModelPermissionMap,
    ]);

    const isCanDelete = this.riskDomain.layoutDomain.userHaveAnyAccess([
      RiskManagerSystemRolePermissions[
        'extension-risk-manager-settings-admin-delete'
      ] as unknown as UserSystemRoleModelPermissionMap,
    ]);

    const isCanCreate = this.riskDomain.layoutDomain.userHaveAnyAccess([
      RiskManagerSystemRolePermissions[
        'extension-risk-manager-settings-admin-create'
      ] as unknown as UserSystemRoleModelPermissionMap,
    ]);

    this.isCanRead.setValue(isCanRead);
    this.isCanEdit.setValue(isCanEdit);
    this.isCanCreate.setValue(isCanCreate);
    this.isCanDelete.setValue(isCanDelete);

    this.answerPresetDomain.ui.isCanCreate.setValue(isCanCreate);
    this.answerPresetDomain.ui.isCanDelete.setValue(isCanDelete);
    this.answerPresetDomain.ui.isCanEdit.setValue(isCanEdit);

    this.questionDomain.ui.isCanCreate.setValue(isCanCreate);
    this.questionDomain.ui.isCanDelete.setValue(isCanDelete);
    this.questionDomain.ui.isCanEdit.setValue(isCanEdit);

    this.questionFieldDomain.ui.isCanCreate.setValue(isCanCreate);
    this.questionFieldDomain.ui.isCanDelete.setValue(isCanDelete);
    this.questionFieldDomain.ui.isCanEdit.setValue(isCanEdit);

    this.riskDomain.ui.isCanCreate.setValue(isCanCreate);
    this.riskDomain.ui.isCanDelete.setValue(isCanDelete);
    this.riskDomain.ui.isCanEdit.setValue(isCanEdit);

    this.staticQuestionDomain.ui.isCanCreate.setValue(isCanCreate);
    this.staticQuestionDomain.ui.isCanDelete.setValue(isCanDelete);
    this.staticQuestionDomain.ui.isCanEdit.setValue(isCanEdit);

    this.templateDomain.ui.isCanCreate.setValue(isCanCreate);
    this.templateDomain.ui.isCanDelete.setValue(isCanDelete);
    this.templateDomain.ui.isCanEdit.setValue(isCanEdit);

    this.dataTableDomain.ui.isCanCreate.setValue(false);
    this.dataTableDomain.ui.isCanDelete.setValue(false);
    this.dataTableDomain.ui.isCanEdit.setValue(false);
  }
}
