import React from 'react';

export const MvideoMainLayoutLeftNavigationTopLogo = ({ isShow }: { isShow: boolean }) => {
  return isShow ? (
    <img
      alt={'/public/extension/mvideo/logo/main.png'}
      style={{ marginLeft: '-12px' }}
      width="108"
      height="108"
      src="/public/extension/mvideo/logo/main.png"
    />
  ) : (
    <></>
  );
};
