import AssignmentIcon from '@mui/icons-material/Assignment';
import { Button, Grid, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IMainLayoutDomainStore } from '../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { RiskManagerReportDomain } from './store/RiskManagerReportDomain';
import { IRiskManagerProjectInfoDomain } from '../view/projectRiskInfo/domain/IRiskManagerProjectInfoDomain';

export const RiskManagerDownloadReportButton = observer(
  ({
    projectId,
    layoutDomain,
    domain: rootDomain,
  }: {
    projectId: string;
    layoutDomain: IMainLayoutDomainStore;
    domain: IRiskManagerProjectInfoDomain;
  }) => {
    const classes = useStyles();
    const [domain] = useState(new RiskManagerReportDomain(rootDomain, layoutDomain));
    useEffect(() => {
      domain.setAccessToProjectReport(projectId);
    }, [domain, projectId, domain.rootDomain.riskManagersData.list]);

    return (
      <React.Fragment>
        {domain.isHaveAccessAndReport.value && (
          <PopupState variant="popover" popupId="projectReportsDownloadButton">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="outlined"
                  startIcon={<AssignmentIcon />}
                  color="secondary"
                  {...bindTrigger(popupState)}
                >
                  Отчёты
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      domain.downloadRiskManagerJson(projectId);
                      popupState.close();
                    }}
                  >
                    Анкета Рисков - JSON
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        )}
      </React.Fragment>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
  mainContainerPaper: {
    padding: theme.spacing(2),
  },
  tableAccordion: {
    height: 820,
  },
  root: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));
