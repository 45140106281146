import { inject } from '../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { Store } from '../../../../../../common/store/base/Store';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { INotificationExtensionMap, NotificationType } from '../../../../../../service/notification/INotificationModel';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../service/RootAdminService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { IUserSystemRoleModel } from '../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { notificationMapValues } from '../../../../../admin/page/systemRole/entity/store/modal/modalConstValues';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SettingsUi } from './SettingsUi';

export class SettingsFormDomain extends Store {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public ui = new SettingsUi(),
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    public systemRoles: IEntityListStore<IUserSystemRoleModel> = injectEntityList([]),
    private userNotificationsList: any = [],
    public extensionNotificationsList: any = []
  ) {
    super();
  }

  async loadData() {
    const user = await this.rootService.user.entity.getById(this.layoutDomain.ui.activeUser.entity.id);
    this.ui.currentUser.setEntity(user);
    
    if (user.systemRoleId) {
      const fullNotificationMap = [...notificationMapValues, ...this.extensionNotificationsList];
      const userSystemRole = await this.rootService.systemRole.entity.getById(user.systemRoleId);
      await this.filterUserPermissionNotificationRecursion(
        userSystemRole.notificationPermissions,
        0,
        fullNotificationMap,
      );
      this.ui.userNotificationsList.setList(this.userNotificationsList.filter((item) => !!item));
    }
    if (JSON.stringify(user.notificationsSettings) !== '{}') {
      this.ui.notificationSettings.setEntity(user.notificationsSettings);
    }
  }

  // @todo rewrite with backend validation
  async save() {
    if (
      !this.ui.notificationSettings.entity.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,5}$/g) &&
      this.ui.notificationSettings.entity.isSendWithMail
    ) {
      // @todo wtf is that ?
      return alert('Вы некорректно ввели email');
    }

    if (
      !this.ui.notificationSettings.entity.notificationToEmail.length &&
      this.ui.notificationSettings.entity.isSendWithMail
    ) {
      // @todo wtf is that ?
      return alert('Выберите какие email вам отправлять на почту');
    }
    this.ui.currentUser.entity.notificationsSettings = this.ui.notificationSettings.entity;
    await this.rootService.user.entity.updateByModel(this.ui.currentUser.entity);
    this.layoutDomain.modalWindow.closeModalWindow();
  }

  cancelEdit = async () => {
    this.layoutDomain.modalWindow.closeModalWindow();
  };

  filterUserPermissionNotificationRecursion = async (
    userCurrentPermission: NotificationType[],
    increment = 0,
    fullNotificationMap: INotificationExtensionMap[]): Promise<void> => {
    if (userCurrentPermission.length > increment) {
      const userNotification = fullNotificationMap.find((notification) => {
        return notification.value === userCurrentPermission[increment];
      });
      this.userNotificationsList.push(userNotification);
      return this.filterUserPermissionNotificationRecursion(userCurrentPermission, increment + 1, fullNotificationMap);
    } else {
      return;
    }
  };

  setModelNotificationPermission(notificationType: NotificationType, isSet: boolean) {
    if (isSet && !this.ui.notificationSettings.entity.notificationToEmail.includes(notificationType)) {
      this.ui.notificationSettings.entity.notificationToEmail.push(notificationType);
    } else {
      this.ui.notificationSettings.entity.notificationToEmail =
        this.ui.notificationSettings.entity.notificationToEmail.filter((item) => item !== notificationType);
    }
  }
}
