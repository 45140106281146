import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { LayoutNotificationType } from '../../../../../../view/layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectFormDomain } from '../../../../../../view/user/page/project/form/store/ProjectFormDomain';
import { RiskManagerDataTargetType } from '../../../../service/manager/data/IRiskManagerDataModel';
import { RiskManagerDataFormDomain } from '../domain/RiskManagerDataFormDomain';

export interface IRiskManagerProjectFormDomain extends ProjectFormDomain {
  riskManagerDataFormDomain: RiskManagerDataFormDomain;
}

export const extendProjectFormDomain = (
  Ref: new (...args: any[]) => ProjectFormDomain,
): new (...args: any[]) => IRiskManagerProjectFormDomain => {
  class ExtendedProjectFormDomainRef extends Ref {
    public riskManagerDataFormDomain: RiskManagerDataFormDomain;

    constructor(
      layoutDomain: IMainLayoutDomainStore,
      public isModalOpen = injectPrimitive<boolean>(false),
      public isFinalySave = injectPrimitive<boolean>(true),
    ) {
      super(layoutDomain);
      this.riskManagerDataFormDomain = new RiskManagerDataFormDomain(layoutDomain);
    }

    async save(redirect: boolean = true) {
      try {
        const emptyQuestionsAnswersDataArray = this.riskManagerDataFormDomain.ui.model.entity.answersData.data.filter(
          (item) => item.answerPseudoId === null && item.answerPseudoIds.length === 0,
        );
        if (emptyQuestionsAnswersDataArray.length === 0 || !this?.isFinalySave.value) {
          await this.finalySave(redirect);
          this.isFinalySave.setValue(true);
        } else {
          await this.isModalOpen.setValue(true);
          this.isFinalySave.setValue(false);
          const warningsList = emptyQuestionsAnswersDataArray.map((item) => ({
            questionId: item.questionId,
            message: 'Необходимо заполнить ответ на вопрос',
          }));
          this.riskManagerDataFormDomain.validationWarnings.setValue(warningsList);
        }
      } catch (error) {}
    }

    async finalySave(redirect: boolean = true) {
      try {
        await super.save(false);
        if (this.ui.validationErrors.list.length === 0) {
          if (this.riskManagerDataFormDomain.ui.model.entity.templateId) {
            this.riskManagerDataFormDomain.ui.model.entity.targetId = this.ui.model.entity.id || '';
            this.riskManagerDataFormDomain.ui.model.entity.targetType = RiskManagerDataTargetType.project;
            this.riskManagerDataFormDomain.ui.validationErrors.setList([]);
            await this.saveRiskManagerData();

            if (this.riskManagerDataFormDomain.ui.validationErrors.list.length === 0) {
              if (redirect) {
                this.layoutDomain.notifications.showNotification({
                  type: LayoutNotificationType.success,
                  text: `Анкета рисков сохранена для проекта ${this.ui.model.entity.name}`,
                });
                this.redirectAfterSave();
              }
            }
          } else {
            await this.riskManagerDataFormDomain.delete();
            if (redirect) {
              this.redirectAfterSave();
            }
          }
        }
      } catch (error) {}
    }

    async saveRiskManagerData() {
      await this.riskManagerDataFormDomain.save();
    }
  }

  return ExtendedProjectFormDomainRef;
};
