import { inject } from '../../../../../../../common/container/inject';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../../service/unitResult/IUnitResultModel';
import { UserDataLevel } from '../../../../../service/userData/IUserDataModel';
import { IPassingListPageDomain } from './IPassingListPageDomain';
import { IPassingListPageUI } from './IPassingListPageUI';
import { PassingListPageUI } from './PassingListPageUI';

export class PassingListPageDomain implements IPassingListPageDomain {
  public ui: IPassingListPageUI;
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    protected router = inject<IRouterService>(RouterServiceToken),
    protected learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    protected rootService = injectRootService(layoutDomain.serviceType.value),
  ) {
    this.ui = new PassingListPageUI();
  }
  loadData = async (userId?: string) => {
    this.ui.isLoading.setValue(true);
    const id = userId ? userId : this.layoutDomain.ui.activeUser.entity.id;
    const unitResults = await this.learningRootService.unitResult
      .search({
        limit: 100000,
        filter: [{ fieldName: 'userId', type: 'equal', value: id }],
      })
      .catch((err) => {
        if (err.webCode === '500') {
          return this.router.goTo('/serviceNotAvailable');
        }
      });

    const projects = (
      await this.rootService.project.entity.search({
        filter: {
          json: [
            {
              fieldPath: 'rolesMap.data[].userId',
              value: id,
              type: 'equal',
            },
          ],
        },
      })
    ).data;

    const userProjectIds = projects.map((project) => project.id);
    const userProjectRoles = projects.flatMap((project) => (project.rolesMap?.data ?? []).map((role) => role.roleId));

    const allUsersApplications = (
      await this.rootService.application.entity.search({
        limit: 100000,
        filter: { projectId: { in: userProjectIds as string[] } },
      })
    ).data;

    const publishedUnits = await this.learningRootService.unit.search({
      limit: 100000,
      filter: [
        {
          fieldName: 'isPublished',
          type: 'equal',
          value: true,
        },
      ],
    });

    const importantUserUnits = this.determineMandatoryUnits(
      publishedUnits.data,
      allUsersApplications,
      projects,
      userProjectRoles,
      id,
    );

    const unimportantUnitList = publishedUnits.data.filter((unit) => !importantUserUnits.includes(unit));
    this.ui.importantUnitList.setList(importantUserUnits);
    this.ui.unimportantUnitList.setList(unimportantUnitList);
    const combinedUnits = [...importantUserUnits, ...unimportantUnitList];
    this.ui.passingList.setList(combinedUnits);
    const { data: specificationCategoryData } = await this.rootService.specification.category.search({
      fields: ['name', 'id'],
      limit: 100000,
    });
    this.ui.specificationCategory.setList(specificationCategoryData);
    const { data: characteristicData } = await this.rootService.specification.entity.search({
      fields: ['name', 'categoryId'],
      limit: 10000,
    });
    this.ui.characteristic.setList(characteristicData);

    const missingUnitResults = this.ui.passingList.list.filter((unit) => {
      return !unitResults.data.some((result) => result.unitId === unit.id);
    });
    await Promise.all(
      missingUnitResults.map(async (unit) => {
        await this.learningRootService.unitResult.createByModel({
          quizResult: { questions: [], totalQuizResult: 0 },
          theoryResult: { totalTheoryResult: 0 },
          practiceResult: { tasks: [], totalPracticeResult: 0 },
          totalUnitResult: 0,
          unitId: unit.id,
          userId: id,
          isPassed: null,
        });
      }),
    );

    const userUnitResults = (
      await this.learningRootService.unitResult.search({
        limit: 100000,
        filter: [{ fieldName: 'userId', type: 'equal', value: id }],
      })
    ).data.filter((unitResult) => {
      return combinedUnits.some((unit) => unit.id === unitResult.unitId);
    });

    this.ui.passingReslutList.setList(userUnitResults);
    this.getAllPossibleScoreOfUnits();

    userUnitResults.length !== 0 && this.createdGroupedList();
    this.checkingPassedAmount();
    if (userUnitResults) {
      setTimeout(() => {
        this.ui.isLoading.setValue(false);
      }, 300);
    }
    this.ui.filtredGroupedUnits.setList(this.ui.groupedUnits.list);

    const applicationsWithUnits = allUsersApplications
      .map((application) => {
        const applicationCopy: any = {
          id: application.id,
          name: application.name,
          specificationsIds: application.specificationsIds,
        };
        const specificationsIds = application.specificationsIds || [];

        applicationCopy.units = importantUserUnits.filter(
          (unit) =>
            unit.includedIn.includes(application.id) &&
            unit.settings.characteristicsIds.some((characteristicId) => specificationsIds.includes(characteristicId)),
        );

        applicationCopy.unitResults = applicationCopy.units.map((unit) => {
          return this.ui.passingReslutList.list.find((result) => result.unitId === unit.id) || null;
        });

        return applicationCopy;
      })
      .filter((application) => {
        return application.units.length;
      });
    this.ui.applicationsWithUnits.setList(applicationsWithUnits);
    this.getUserData(id, combinedUnits, unitResults.data);
  };

  getUserData = async (id: string, combinedUnits: IUnitModel[], userUnitResults: IUnitResultModel[]) => {
    const userDataSearch = await this.learningRootService.userData.search({
      limit: 100000,
      fields: { include: ['toNextLevelScore', 'level', 'totalScore', 'maxScore'] },
      filter: [
        {
          fieldName: 'userId',
          type: 'equal',
          value: id,
        },
      ],
    });
    if (userDataSearch.data.length > 0) {
      this.ui.userData.setValue(userDataSearch.data[0]);
    } else {
      let newMaxScore = 0;
      let newUserTotalScore = 0;
      combinedUnits.forEach((unit) => (newMaxScore += unit.score));
      userUnitResults.forEach((reuslt) => (newUserTotalScore += reuslt.totalUnitResult));
      const level =
        newUserTotalScore <= Math.round((newMaxScore * 35) / 100)
          ? UserDataLevel.JUNIOR
          : Math.round((newMaxScore * 35) / 100) <= newUserTotalScore &&
            newUserTotalScore <= Math.round((newMaxScore * 69) / 100)
          ? UserDataLevel.MIDDLE
          : UserDataLevel.SENIOR;

      const toNextLevelScore =
        level === UserDataLevel.SENIOR
          ? Math.round(newMaxScore - newUserTotalScore)
          : level === UserDataLevel.MIDDLE
          ? Math.round((newMaxScore * 69) / 100 - newUserTotalScore + 1)
          : Math.round((newMaxScore * 35) / 100 - newUserTotalScore + 1);
      const newUserDataId = await this.learningRootService.userData.createByModel({
        userId: id,
        totalScore: newUserTotalScore,
        level,
        maxScore: newMaxScore,
        toNextLevelScore,
      });
      const newUserDataSearchResult = await this.learningRootService.userData.getById(newUserDataId);
      this.ui.userData.setValue(newUserDataSearchResult);
    }
  };
  getAllPossibleScoreOfUnits = () => {
    let score = 0;
    this.ui.passingList.list.forEach((unit) => {
      score += unit.score;
    });
    this.ui.allUnitsScore.setValue({ allUnitsScore: score, possibleScoreOnePart: Math.round(score / 3) });
  };

  checkingPassedAmount = () => {
    this.ui.groupedUnits.list.forEach((group) => {
      group.importantUnitList.forEach((unit) => {
        for (let i = 0; i < this.ui.passingReslutList.list.length; i++) {
          if (unit.id === this.ui.passingReslutList.list[i].unitId && this.ui.passingReslutList.list[i].isPassed) {
            group.passedAmount += 1;
          }
        }
      });
    });
    this.ui.groupedUnits.list.forEach((group) => {
      group.unimportantUnitList.forEach((unit) => {
        for (let i = 0; i < this.ui.passingReslutList.list.length; i++) {
          if (unit.id === this.ui.passingReslutList.list[i].unitId && this.ui.passingReslutList.list[i].isPassed) {
            group.passedAmount += 1;
          }
        }
      });
    });
  };

  createdGroupedList = () => {
    const characteristicToSpecName = {};
    this.ui.characteristic.list.forEach((char) => {
      const spec = this.ui.specificationCategory.list.find((spec) => spec.id === char.categoryId);
      if (spec) {
        characteristicToSpecName[char.id] = spec.name;
      }
    });

    const groupedUnits = {};
    const allUnits = [...this.ui.importantUnitList.list, ...this.ui.unimportantUnitList.list];
    allUnits.forEach((unit) => {
      const charIds = unit.settings.characteristicsIds;
      if (charIds && charIds.length > 0) {
        const charId = charIds[0];
        const groupName = characteristicToSpecName[charId] || charId;
        groupedUnits[groupName] = groupedUnits[groupName] || [];
        groupedUnits[groupName].push(unit);
      } else {
        const groupName = 'Без Контекста';
        groupedUnits[groupName] = groupedUnits[groupName] || [];
        groupedUnits[groupName].push(unit);
      }
    });

    const sortedGroupNames = Object.keys(groupedUnits).sort();

    this.ui.groupedUnits.setList(
      sortedGroupNames.map((groupName) => ({
        groupName,
        importantUnitList: groupedUnits[groupName].filter((unit) => this.ui.importantUnitList.list.includes(unit)),
        unimportantUnitList: groupedUnits[groupName].filter((unit) => !this.ui.importantUnitList.list.includes(unit)),
        passedAmount: 0,
        passingPercent: 0,
      })),
    );
    this.calculatePassingPercentages();
  };

  calculatePassingPercentages = () => {
    this.ui.groupedUnits.list.forEach((group) => {
      const totalUnits = group.importantUnitList.length + group.unimportantUnitList.length;
      const passedUnits = this.calculatePassedUnits(group);
      group.passingPercent = Math.round((passedUnits / totalUnits) * 100);
    });
  };

  calculatePassedUnits = (group) => {
    let passedAmount = 0;
    [...group.importantUnitList, ...group.unimportantUnitList].forEach((unit) => {
      if (this.ui.passingReslutList.list.some((result) => result.unitId === unit.id && result.isPassed)) {
        passedAmount++;
      }
    });
    return passedAmount;
  };
  redirect = async (id: string) => {
    !this.ui.isAdminMode.value && this.router.goTo(`/learning/${id}`);
  };

  handleSearchByGroupsAndName = (searchText: string) => {
    const trimmedSearchText = searchText.trim();
    const filteredGroups = this.ui.groupedUnits.list
      .map((group) => {
        const isGroupNameMatch = group.groupName.toLowerCase().includes(trimmedSearchText.toLowerCase());

        const filterUnitsBySearchText = (unitList: any[]) => {
          return unitList.filter((unit) =>
            unit.settings.titleHeading.toLowerCase().includes(trimmedSearchText.toLowerCase()),
          );
        };

        const filteredImportantUnits = filterUnitsBySearchText(group.importantUnitList);
        const filteredUnimportantUnits = filterUnitsBySearchText(group.unimportantUnitList);

        return {
          ...group,
          importantUnitList: isGroupNameMatch ? group.importantUnitList : filteredImportantUnits,
          unimportantUnitList: isGroupNameMatch ? group.unimportantUnitList : filteredUnimportantUnits,
        };
      })
      .filter((group) => group.importantUnitList.length > 0 || group.unimportantUnitList.length > 0);

    this.ui.filtredGroupedUnits.setList(filteredGroups);
    this.calculatePassingPercentages();
  };

  determineMandatoryUnits = (units, applications, projects, userProjectRoles, userId) => {
    return units.filter((unit) => {
      return applications.some((application) => {
        // Проверяем, связан ли проект пользователя с приложением
        const userProject = projects.find((project) => project.id === application.projectId);

        // Проверяем, имеет ли пользователь в проекте роль, совпадающую с ролью в настройках юнита
        const isUserInProjectRole =
          userProject &&
          userProject.rolesMap.data.some((role) => role.userId === userId && userProjectRoles.includes(role.roleId));

        // Проверяем, соответствует ли роль пользователя в проекте какой-либо из ролей, необходимых для юнита
        const hasMatchingRole = unit.settings.teamRolesIds.some((unitRole) =>
          userProject.rolesMap.data.some(
            (projectRole) => projectRole.userId === userId && projectRole.roleId === unitRole,
          ),
        );

        // Проверяем совпадение характеристик юнита и приложения
        const hasMatchingCharacteristic = unit.settings.characteristicsIds.some((characteristic) =>
          application.specificationsIds.includes(characteristic),
        );

        return isUserInProjectRole && hasMatchingRole && hasMatchingCharacteristic;
      });
    });
  };
}
