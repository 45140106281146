import React from "react";
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from "mobx-react";

export const LegendChartLabel = observer(({ color, labelName }: { color: string, labelName: string }) => {
  const iconBackgroundColor = color || '#FFFFFF'
  const classes = useStyles();

  return (
    <Grid item className={classes.legengLableBlock}>
      <div style={{ backgroundColor: iconBackgroundColor }} className={classes.legendIcon} />
      <div style={{ whiteSpace: 'nowrap' }}>{labelName}</div>
    </Grid>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  legendIcon: {
    minWidth: 14,
    height: 8,
    borderRadius: '27px',
    marginRight: '8px'
  },
  legengLableBlock: {
    padding: '5px 5px 5px 5px',
    fontSize: '12px',
    color: '#FFFFFF80',
    display: 'flex',
    alignItems: 'center'
  }
}))
