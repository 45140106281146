import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { DataTable } from '../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../IAdminPageProperties';
import { VendorEntityAdminDomain } from './store/VendorEntityAdminDomain';

export interface IVendorEntityAdminTable extends IAdminPageProperties { }

export const VendorEntityAdminTable = observer(({ layoutDomain }: IVendorEntityAdminTable) => {
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])

  const [domain] = useState<VendorEntityAdminDomain>(new VendorEntityAdminDomain(layoutDomain));

  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
