import { IError } from '../../../../../common/error/IError';
import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';
import { ICRUDService } from '../../../../../service/common/service/ICRUDService';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { LayoutNotificationType } from '../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableUI } from './AdminTableUI';
import { IAdminTableDomain } from './IAdminTableDomain';

export abstract class AdminTableDomain<
  ServiceModelType extends IServiceModel,
  SearchRequestType extends ISearchRequestData,
> implements IAdminTableDomain<ServiceModelType, SearchRequestType>
{
  constructor(
    protected layoutDomain: IMainLayoutDomainStore,
    protected entityService: ICRUDService<ServiceModelType, SearchRequestType>,
    public ui = new AdminTableUI<ServiceModelType, SearchRequestType>(),
  ) {}

  async loadData() {
    this.ui.isLoading.setValue(true);
    this.setPermissions();
    await this.loadList();
    this.ui.isLoading.setValue(false);
  }

  setPermissions() {
    const isAdmin = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    this.ui.isCanEdit.setValue(isAdmin);
    this.ui.isCanCreate.setValue(isAdmin);
    this.ui.isCanDelete.setValue(isAdmin);
  }

  async createNew(entity: ServiceModelType) {
    const serverEntity = this.transformViewToServer(entity);
    return await this.callService(this.entityService.createByModel(serverEntity));
  }

  async removeById(id: string) {
    return await this.callService(this.entityService.deleteById(id));
  }

  async updateEntity(entity: ServiceModelType) {
    const serverEntity = this.transformViewToServer(entity);
    return await this.callService(this.entityService.updateByModel(serverEntity));
  }

  async loadList(request?: SearchRequestType) {
    if (request) {
      this.ui.searchRequest.setEntity(request);
    }

    try {
      this.ui.isLoading.setValue(true);

      const searchResult = await this.entityService.search(this.ui.searchRequest.entity);

      this.ui.isLoading.setValue(false);
      this.ui.totalEntitiesCount.setValue(searchResult.totalCount);
      const data = searchResult.data.map((item) => this.transformServerToView(item));
      this.ui.entities.setList(data);
      this.ui.searchRequest.setEntity(searchResult.request);

      return true;
    } catch (error) {
      this.ui.isLoading.setValue(false);
      return this.errorsHandler(error);
    }
  }

  transformServerToView(item: ServiceModelType): any {
    return item;
  }

  transformViewToServer(item: any): ServiceModelType {
    return item;
  }

  onSearchChange(term: string) {
    this.ui.searchTerm.setValue(term);
  }

  addValidationErrors(errors: IValidationErrorData[]) {
    this.ui.validationErrors.setList(errors);
  }

  getValidationErrorFor(fieldName: string, id?: string) {
    const error = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
    return error || null;
  }

  async removeValidationErrors() {
    this.ui.validationErrors.setList([]);
  }

  async removeValidationErrorFor(fieldName: string, id?: string) {
    const item = this.ui.validationErrors.list.find((validationError) => validationError.target === fieldName);
    if (item) {
      this.ui.validationErrors.removeByEntity(item);
    }
  }

  errorsHandler = async (error: IError): Promise<boolean> => {
    if (error.webCode === '400') {
      this.addValidationErrors(error.data);
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }

    return false;
  };

  protected async callService(serviceHandler: Promise<any>, isUpdateList: boolean = true): Promise<boolean> {
    try {
      this.ui.isLoading.setValue(true);
      await serviceHandler;
      if (isUpdateList) {
        await this.loadList();
      }

      this.ui.isLoading.setValue(false);
      return true;
    } catch (error) {
      this.ui.isLoading.setValue(false);
      return this.errorsHandler(error);
    }
  }
}
