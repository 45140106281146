import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IVendorEntityModel } from './IVendorEntityModel';
import { IVendorEntitySearch } from './IVendorEntitySearch';
import { IVendorEntityService } from './IVendorEntityService';

export class RestVendorEntityService
  extends RestCRUDService<IVendorEntityModel, IVendorEntitySearch>
  implements IVendorEntityService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  async getSystemVendors(): Promise<IVendorEntityModel[]> {
    const result = await this.request()
      .url(`${this.getEndpoint()}/system`)
      .get()
      .json<IVendorEntityModel[]>()
      .catch(this.handleError);

    return result;
  }

  async getTypes(): Promise<string[]> {
    const result = await this.request().url(`${this.getEndpoint()}/types`).get().json<Object>().catch(this.handleError);

    return Object.values(result);
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/vendor`;
  }
}
