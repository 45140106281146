import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { ITagEntitySearch } from '../../../../../../../service/tag/entity/ITagEntitySearch';
import { DataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { TagEntityAdminUI } from '../TagEntityAdminUI';

export class TagEntityRowsUI
  extends DataTableRowsUI<ITagEntityModel, ITagEntitySearch>
  implements IDataTableRowsUI<ITagEntityModel, ITagEntitySearch>
{
  constructor(public rootUI: TagEntityAdminUI) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'categoryId') {
      return {
        dependenciesModels: this.rootUI.categories.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }

    return null;
  }
}
