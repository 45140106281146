import { CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';

export const TableLoader = (props: any) => {
  return (
    <div
      style={{
        display: 'table',
        width: '100%',
        height: '100%',
        backgroundColor: alpha(props.theme.palette.background.paper, 0.7),
      }}
    >
      <div
        style={{
          display: 'table-cell',
          width: '100%',
          height: '100%',
          verticalAlign: 'middle',
          textAlign: 'center',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    </div>
  );
};
