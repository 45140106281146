import { Button } from '@mui/material';
import React from 'react';

export interface ProjectButtonLinkProperties {
  action: any;
  children: any;
  icon?: any;
  className?: string;
}

export const ProjectButton = ({ icon, action, children, className }: ProjectButtonLinkProperties) => {
  return (
    <Button className={className} variant="outlined" startIcon={icon} color="secondary" onClick={action}>
      {children}
    </Button>
  );
};
