import wretch from 'wretch';

import { IUserDataModel } from './IUserDataModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';

wretch().errorType('json');

export class RestUserDataService extends LearningCoreRestCRUDService<IUserDataModel> {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/userData`;
  }


}
