import wretch from 'wretch';

import { ITaskModel } from './ITaskModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';

wretch().errorType('json');

export class RestTaskService extends LearningCoreRestCRUDService<ITaskModel> {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/task`;
  }
}
