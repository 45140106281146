import Add from '@mui/icons-material/Add';
import { Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

export interface IEmptyPage {
  image: any;
  title: string;
  buttonTitle: string;
  buttonAction: any;
}
export const EmptyPage = observer(
  ({ image = null, title = '', buttonTitle = '', buttonAction = () => {} }: IEmptyPage) => {
    const classes = useStyles();
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', padding: '24px 0px' }}>
        <img src={image} alt={'empty-page'} className={classes.image} />
        <Typography>{title}</Typography>
        <Button variant="contained" startIcon={<Add />} onClick={buttonAction}>
          {buttonTitle}
        </Button>
      </Box>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: '400px',
    height: 'autos',
  },
}));
