import { Grid } from '@mui/material';
import React from 'react';

import { ProjectListDomain } from '../../../view/user/page/project/list/store/ProjectListDomain';

export const MvideoAllRequirementVendorLink = ({ domain }: { domain: ProjectListDomain }) => {
  return (
    <Grid item={true} xs={3}>
      <a target="_blank" rel="noreferrer" href="https://wiki.mvideo.ru/x/sh4-E">
        <div style={{ width: 178, height: 178 }}>
          <img style={{ width: 178, height: 178 }} src="/public/extension/mvideo/logo/vendor.png" alt="" />
        </div>
      </a>
    </Grid>
  );
};
