import { IContainer } from '../../common/container/IContainer';
import { requirementFilterExtension } from '../../view/common/requirement/filter/extensions/RequirementFilterExtension';
import { MainLayoutLeftNavigationTopLogo } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutLeftNavigationTopLogo';
import { AllRequirementVendorLink } from '../../view/user/page/project/list/inject/AllRequirementVendorLink';
import { IExtension } from '../interface/IExtension';
import { MvideoAllRequirementVendorLink } from './logo/AllRequirementVendorLink';
import { MvideoMainLayoutLeftNavigationTopLogo } from './logo/MainLayoutLeftNavigationTopLogo';
import { RiskFilter } from './filter/riskFilter/RiskFilter';
import { ProjectListDomain } from '../../view/user/page/project/list/store/ProjectListDomain';
import { extendProjectListDomainWithManagerFilter } from './managerFilter/ManagerFilterDomain';
import { ManagerFilterAutocomplite } from './managerFilter/ManagerFilterAutocomplite';
import { FilterComponent } from '../../view/user/page/project/list/inject/FilterComponent';

export default class MvideoExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container.set(AllRequirementVendorLink, MvideoAllRequirementVendorLink);
    container.set(MainLayoutLeftNavigationTopLogo, MvideoMainLayoutLeftNavigationTopLogo);
    container = this.setProjectListPage(container);
    container = this.setRiskFilter(container);
    container = this.setManagerFilter(container);
  }

  setProjectListPage(container: IContainer): IContainer {
    const ProjectListDomainRef = container.get(ProjectListDomain);
    const ExtendedProjectListDomainWithManagerFilterRef =
      extendProjectListDomainWithManagerFilter(ProjectListDomainRef);
    container.set(ProjectListDomain, ExtendedProjectListDomainWithManagerFilterRef);
    return container;
  }



  setManagerFilter(container: IContainer) {
    const filters = container.get(FilterComponent);
    filters.push({
      Component: ManagerFilterAutocomplite,
    });
    return container;
  }

  setRiskFilter(container: IContainer) {
    const filters = container.get(requirementFilterExtension);
    filters.push({
      id: 'risk-filter',
      Component: RiskFilter,
    });
    return container;
  }


}
