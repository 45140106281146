import { IServiceModel } from '../../common/model/IServiceModel';
import { IJiraApplicationSettings } from '../IJiraTaskManager';
import { ITaskManagerType, TaskManagerConnectionState } from '../ITaskManager';
import { ITaskManagerServerCredentials } from './ITaskManagerServerCredentials';

export interface ITaskManagerServer<ICredentialsType = ITaskManagerServerCredentials> extends IServiceModel {
  isActive?: boolean;
  credentials: ICredentialsType;
  connectionState: TaskManagerConnectionState;
  connectionTemplate: IConnectionTemplate;
  type: ITaskManagerType;
  name: string;
  webhookData?: ITaskManagerWebhookData;
}

export interface IConnectionTemplate {
  settings: IJiraApplicationSettings | null;
}

export type ITaskManagerWebhookData = IJiraWebhookData | null;

export interface IJiraWebhookData {
  value: IJiraAllWebhookDataType;
  type: IJiraWebhookDataType;
}

type IJiraAllWebhookDataType = IJiraBasicWebhookData;

export interface IJiraBasicWebhookData {
  id: string;
  events: string[];
  callbackUrl: string;
  serverId: string;
  creator: string;
  filter: string;
  isHavePermissions: boolean;
}

export enum IJiraWebhookDataType {
  basic = 'basic',
}
