import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationAffectAdminTable } from '../../application/affect/ApplicationAffectAdminTable';
import { RequirementForm } from '../../requirement/entity/form/RequirementForm';
import { RequirementCategoryAdminTable } from '../../requirement/newCategory/RequirementCategoryAdminTable';
import { RequirementEntityAdminTable } from '../../requirement/newEntity/RequirementEntityAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const RequirementSettingsItem: ISettingsItem = {
  id: 'requirement',
  routers: [
    {
      key: 'requirements-category-settings',
      path: '/settings/requirement/category',
      pageTitle: 'Категории требований',
      component: RequirementCategoryAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirements-read']],
    },
    {
      key: 'requirements-entity-settings',
      path: '/settings/requirement/entity',
      pageTitle: 'Требования',
      component: RequirementEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirements-read']],
    },
    {
      key: 'requirements-entity-edit-settings',
      path: '/settings/requirement/entity/:id/edit',
      pageTitle: 'Редактировать требование',
      component: RequirementForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirements-update']],
    },
    {
      key: 'requirements-category-settings',
      path: '/settings/requirement/entity/new',
      pageTitle: 'Добавить требование',
      component: RequirementForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirements-create']],
    },
    {
      key: 'application-affect-settings',
      path: '/settings/affect/entity',
      pageTitle: 'Доработки',
      component: ApplicationAffectAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-application-affect-read']],
    },
  ],
  settingsItem: {
    key: 'requirements',
    title: 'Шаблоны требований',
    buttons: [
      {
        title: 'Категории',
        url: `/settings/requirement/category`,
        permissions: [UserSystemRoleModelPermissionMap['settings-requirements-read']],
      },
      {
        title: 'Значения',
        url: `/settings/requirement/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-requirements-read']],
      },
      // {
      //   title: 'Доработки',
      //   url: `/settings/affect/entity`,
      //   permissions: [UserSystemRoleModelPermissionMap['settings-application-affect-read']],
      // },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-requirements-read']],
    infoText: 'Содержит перечень всех требований по информационной безопасности организации',
    orderIndex: 200,
  },
};
