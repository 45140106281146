import { ArrowBack, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { GroupCardComponent } from '../passingListPage/parts/GroupCardComponent/GroupCardComponent';
import { SystemsList } from '../passingListPage/parts/SystemsList';
import { UserInfoCard } from '../passingListPage/parts/UserInfoCard';
import { LearningUserRatingPageDomain } from './store/LearningUserRatingPageDomain';

export interface ILearningUserRatingPage {
  layoutDomain: IMainLayoutDomainStore;
}
export const LearningUserRatingPage = observer(({ layoutDomain }: ILearningUserRatingPage) => {
  const [learningUserRatingPageDomain] = useState<LearningUserRatingPageDomain>(
    new LearningUserRatingPageDomain(layoutDomain),
  );
  const { userId = null } = useParams<any>();

  useEffect(() => {
    learningUserRatingPageDomain.loadData(userId as string);
    learningUserRatingPageDomain.getUserDataBio(userId as string);
  }, [learningUserRatingPageDomain, userId]);
  return (
    <>
      {learningUserRatingPageDomain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Button
            startIcon={<ArrowBack />}
            size="small"
            variant="contained"
            onClick={() => learningUserRatingPageDomain.goBackToRaitngTable()}
          >
            Рейтинг участников
          </Button>
          <UserInfoCard
            fio={learningUserRatingPageDomain.ui.userDisplayName.value}
            role={learningUserRatingPageDomain.ui.userSystemRole.value}
            userData={learningUserRatingPageDomain.ui.userData.value}
          />
          <SystemsList systems={learningUserRatingPageDomain.ui.applicationsWithUnits.list} />
          <Typography variant="h4" sx={{ marginTop: '64px', marginBottom: '64px' }}>
            Карта компетенций
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', marginTop: '48px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', alignItems: 'flex-end' }}>
              <TextField
                variant="standard"
                sx={{ flex: '1' }}
                label="Название контекста или юнита"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <Search />
                    </IconButton>
                  ),
                }}
              />
              <FormControl variant="standard" sx={{ flex: '1' }}>
                <InputLabel>Показывать</InputLabel>
                <Select></Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3,1fr)',
                columnGap: '24px',
                rowGap: '32px',
                paddingBottom: '64px',
              }}
            >
              {learningUserRatingPageDomain.ui.filtredGroupedUnits.list.map((group) => {
                if (group.importantUnitList.length === 0 && group.unimportantUnitList.length === 0) {
                  return null;
                }
                return (
                  <GroupCardComponent
                    groupName={group.groupName}
                    passingPercent={Math.round(
                      (group.passedAmount / (group.importantUnitList.length + group.unimportantUnitList.length)) * 100,
                    )}
                    importantUnitList={group.importantUnitList}
                    unimportantUnitList={group.unimportantUnitList}
                    domain={learningUserRatingPageDomain}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
});
