import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitListAdminCard } from '../../../../../../../../../extension/learning/view/admin/page/unitListPage/store/IUnitListAdminCard';

export interface IUnitListAdminCardProperties {
  card: IUnitListAdminCard;
  handleEdit: any;
  handlePublish: any;
  handleUnpublish: any;
  handleDelete: any;
  handleCheck: any;
  isChecked?: boolean;
}
export const RecommendedUnitListItem = observer(
  ({ card, handleEdit, handleCheck, isChecked }: IUnitListAdminCardProperties) => {
    const onItemChecked = (e) => {
      e.stopPropagation();
      handleCheck(card.id);
    };

    return (
      <Box
        sx={{
          cursor: 'pointer',
          padding: '8px 10px 8px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          background: 'rgba(255, 255, 255, 0.12)',
          alignItems: 'center',
          width: '100%',
          borderRadius: '8px',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.18)',
          },
        }}
        onClick={() => handleEdit(card.id)}
    >
        <Typography sx={{ cursor: 'pointer' }}>{card.name}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button onClick={onItemChecked} sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center', gap: '4px' }}>
            {!isChecked && <span>Добавить</span>}
            {isChecked ? <StarIcon /> : <StarBorderIcon />}
          </Button>
        </Box>
      </Box>
    );
  },
);
