import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { CoreRestCRUDService } from '../coreCommon/common/rest/CoreRestCRUDService';
import { IMailEntityModel } from './IMailEntityModel';
import { IMailEntityService } from './IMailEntityService';

export class RestMailEntityService extends CoreRestCRUDService<IMailEntityModel> implements IMailEntityService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/mail`;
  }
}
