import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import { FinalResultsVerbose } from '../../../../../../shared/components/FinalResultsVerbose/FinalResultsVerbose';
import { LearningUnitPreviewPageContext } from '../../../store/LearningUnitPreviewPageContext';
import { PreviewFinalResultsTabs } from './components/PreviewFinalResultsTabs';
import { useFinalResultsValues } from './hooks/useFinalResultsValues';

export const PreviewFinalResults = observer(() => {
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const unit = unitPreviewDomain?.ui?.unit?.entity;
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('success');
  const finalResultsValues = useFinalResultsValues(unit);
  const finalResultsVerbose = unit?.finalResult?.results;

  return (
    <div className={classes.theorySectionContent}>
      <PreviewFinalResultsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <FinalResultsVerbose
        finalResultsVerbose={finalResultsVerbose?.[currentTab]}
        maximumScore={unit?.score}
        correctQuizMaximumScore={unit?.quiz?.questions?.length}
        correctQuizAnswersCount={finalResultsValues[currentTab].quizResult}
        correctPracticeMaximumScore={unit?.practice?.tasks.length}
        correctPracticeAnswersCount={finalResultsValues[currentTab].practiceResult}
        totalUnitResult={finalResultsValues[currentTab].totalUnitResult}
      />
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  theorySectionContent: {
    maxWidth: '760px',
    width: '100%',
    paddingBottom: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  chapterWrapper: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));
