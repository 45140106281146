import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerTemplateService } from '../../../../service/manager/template/IRiskManagerTemplateService';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetService } from '../../../../service/question/answerPreset/IRiskManagerQuestionService';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionService } from '../../../../service/question/IRiskManagerQuestionService';
import { RiskManagerTemplateDomain } from '../RiskManagerTemplateDomain';
import { RiskManagerTemplateContextMenuUI } from './RiskManagerTemplateContextMenuUI';

export class RiskManagerTemplateContextMenuDomain extends RowContextMenuDomain<IRiskManagerTemplateModel> {
  public dataTableDomain: RiskManagerTemplateDomain;
  constructor(
    entityService: IRiskManagerTemplateService,
    dataTableDomain: RiskManagerTemplateDomain,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerTemplateModel> | null,
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerTemplateContextMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateTemplate();
  }
}
