import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { CoreRestCRUDService } from '../../coreCommon/common/rest/CoreRestCRUDService';
import { IApplicationAffectModel } from './IApplicationAffectModel';
import { IApplicationAffectService } from './IApplicationAffectService';

export class RestApplicationAffectService
  extends CoreRestCRUDService<IApplicationAffectModel>
  implements IApplicationAffectService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/application/affect`;
  }
}
