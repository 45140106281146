import { Chip, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../service/question/IRiskManagerQuestionSearch';
import { IRiskManagerStaticQuestionModel } from '../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionSearch } from '../../../service/question/static/IRiskManagerStaticQuestionSearch';
import { RiskManagerStaticQuestionType } from '../../../service/question/static/RiskManagerStaticQuestionType';
import { RiskManagerStaticQuestionRowsUI } from './rows/RiskManagerStaticQuestionRowsUI';

export class RiskManagerStaticQuestionUI
  extends DataTableUI<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>
  implements IDataTableUI<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>
{
  constructor(
    public domain: IDataTableDomain<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>,
    rows: IDataTableRowsUI<IRiskManagerStaticQuestionModel, IRiskManagerQuestionSearch> | null,
    public answerPresets: IEntityListStore<IRiskManagerQuestionAnswerPresetModel> = injectEntityList<IRiskManagerQuestionAnswerPresetModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new RiskManagerStaticQuestionRowsUI(this);
    this.tableRootPaperElevation.setValue(0);
  }

  public translateType(inputType: RiskManagerStaticQuestionType | string) {
    const translationMap = {
      // [RiskManagerStaticQuestionType.date]: 'Дата',
      // [RiskManagerStaticQuestionType.link]: 'Ссылка',
      [RiskManagerStaticQuestionType.multiRow]: 'Многострочный текст',
      [RiskManagerStaticQuestionType.oneRow]: 'Текст',
      [RiskManagerStaticQuestionType.number]: 'Число',
    };

    return translationMap[inputType] || 'Unknown';
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'description',
        headerName: 'Описание',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },

      {
        field: 'type',
        headerName: 'Тип поля',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={this.translateType(params.value || '')} />;
        },
      },
      {
        field: 'sortIndex',
        headerName: 'Порядок отображения',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value ?? ''} />;
        },
      },
    ];
  }
}
