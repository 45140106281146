import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AdditionalColumnRequirementRelationType } from '../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';
import { AdditionalColumnType } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { DownloadFileColumn } from '../../../../../design/fileUpload/DownloadFileColumn';
import { FileUploadDomain } from '../../../../../design/fileUpload/store/FileUploadDomain';
import { IFileUploadDomain } from '../../../../../design/fileUpload/store/IFileUploadDomain';
import { UploadFileColumn } from '../../../../../design/fileUpload/UploadFileColumn';
import { FormActions } from '../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { AdditionalColumnContentFormDomain } from './store/AdditionalColumnContentFormDomain';

export interface IAdditionalColumnContentAdminFormProperties extends IAdminPageProperties {}

export const AdditionalColumnContentAdminForm = observer(
  ({ layoutDomain }: IAdditionalColumnContentAdminFormProperties) => {
    const [domain] = useState(new AdditionalColumnContentFormDomain(layoutDomain));
    const [uploadDomain] = useState<IFileUploadDomain>(new FileUploadDomain(layoutDomain));
    const { entityId = null } = useParams<any>();
    const uiEntity = domain.ui.model.entity;
    useEffect(() => {
      domain.loadData(entityId).then(() => {
        if (domain.ui.model.entity.uploadedFileUrl) {
          uploadDomain.ui.currentFile.entity.fileUrl = domain.ui.model.entity.uploadedFileUrl;
          uploadDomain.ui.currentFile.entity.fileName = domain.ui.model.entity['content'];
        }
      });
    }, [domain, entityId]);

    return (
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={8}>
          <Paper elevation={3}>
            {!(
              domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.DOWNLOAD_FILE ||
              domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.UPLOAD_FILE
            ) && (
              <FormTextField
                errorMessage={domain.getValidationErrorFor('content')?.message}
                value={uiEntity.content}
                onChange={domain.getUpdateFieldHandler('content')}
                label="Контент"
                multiline
                rows={10}
                required={true}
                inputProps={{
                  'data-cy': 'extra-column-content'
                }}
              />
            )}

            {domain.activeAdditionalColumn.entity?.type === AdditionalColumnType.DOWNLOAD_FILE && (
              <>
                {!uploadDomain.ui.currentFile.entity.fileName ? (
                  <Box sx={{ padding: '10px 0' }}>
                    <UploadFileColumn
                      uploadDomain={uploadDomain}
                      onChange={(event) => {
                        domain.ui.model.entity['content'] = event.fileName;
                        domain.ui.model.entity['uploadedFileUrl'] = event.url;
                      }}
                      sx={{ width: '80%', margin: 'auto' }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <DownloadFileColumn
                      sx={{ width: '100%' }}
                      originalFileName={uploadDomain.ui.currentFile.entity.fileName}
                      uploadDomain={uploadDomain}
                      defaultValue={uploadDomain.ui.currentFile.entity.fileUrl}
                      isCanCancel={true}
                      cancelFile={() => {
                        uploadDomain.ui.currentFile.entity.fileName = '';
                        domain.cancelFile(domain.ui.model.entity);
                        domain.ui.model.entity.uploadedFileUrl = '';
                      }}
                    />
                  </Box>
                )}
              </>
            )}

            <FormAutocomplete
              values={domain.additionalColumnsValues.list}
              onChangeSelect={(newValue: any) => {
                domain.setAdditionalColumn(newValue.value);
                const handler = domain.getUpdateFieldHandler('additionalColumnId');
                handler(newValue.value);
              }}
              key={'requirementsRelationType' + Date.now()}
              errorMessage={domain.getValidationErrorFor('additionalColumnId')?.message}
              selected={uiEntity.additionalColumnId}
              label="Дополнительная колонка"
              dataCy='extra-column-title'
            />

            <FormAutocomplete
              values={domain.requirementsRelationTypes}
              onChangeSelect={(newValue: any) => {
                domain.ui.model.entity.requirementsRelationType = newValue.value;
              }}
              key="requirementsRelationType"
              errorMessage={domain.getValidationErrorFor('requirementsRelationType')?.message}
              selected={uiEntity.requirementsRelationType || AdditionalColumnRequirementRelationType.all}
              label="Тип применения"
              dataCy='extra-column-usage-type'
            />

            <FormMultiAutocomplete
              values={domain.requirementsValues.list}
              onChangeSelect={(newValue: any) => {
                domain.ui.model.entity.requirementsIds = newValue.map((value: any) => value.value);
              }}
              key="requirementsIds"
              selected={uiEntity.requirementsIds}
              label="Требования"
              dataCy='extra-column-requirements'
              disabled={uiEntity.requirementsRelationType === AdditionalColumnRequirementRelationType.all}
            />
          </Paper>

          <FormActions onSave={domain.save} onCancel={domain.cancelEdit} />
        </Grid>
      </Grid>
    );
  },
);
