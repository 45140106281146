import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IUserSystemRoleModel } from '../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { SystemRoleAdminModal } from './SystemRoleAdminModal';

export class SystemRoleAdminModalUI
  extends DataTableFormUI<IUserSystemRoleModel>
  implements IDataTableFormUI<IUserSystemRoleModel>
{
  constructor(public isAllowEveryThing: IPrimitiveStore<boolean> = injectPrimitive(false)) {
    super();
    this.formComponent.setValue(SystemRoleAdminModal);
  }

  getDefaultModel() {
    return {
      name: '',
      description: '',
      permissions: { allowedPermissions: [] },
      isActive: true,
      notificationPermissions: [],
    };
  }
}
