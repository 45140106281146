import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IVendorEntityModel } from '../../../../../../service/vendor/entity/IVendorEntityModel';
import { DataTableFormUI } from '../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { VendorEntityModal } from './VendorEntityModal';

export class VendorEntityModalUI
  extends DataTableFormUI<IVendorEntityModel>
  implements IDataTableFormUI<IVendorEntityModel>
{
  constructor(public systemTypes = injectEntityList<any>([])) {
    super();

    this.formComponent.setValue(VendorEntityModal);
  }
}
