import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryComponent } from '../ITheoryComponent';

export interface IMedia {
  name: string;
  originalName: string;
  data: string;
  indexName?: string;
  type: 'image' | 'video';
}
export const Media = observer(({ data }: ITheoryComponent<IMedia>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        '& img': {
          borderRadius: '8px',
        },
        '& video': {
          borderRadius: '8px',
        },
      }}
    >
      {data.type === 'image' && (
        <img src={data.data} alt={data.indexName} height="auto" width="100%" />
      )}
      {data.type === 'video' && (
        <video
          height="auto"
          width="100%"
          controls
          controlsList="nodownload"
          src={data.data}
        >
          <source src={data.data} type="video/*" />
        </video>
      )}
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        {data.indexName}
      </Typography>
    </Box>
  );
});
