import { injectEntityList } from '../../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../../common/store/interface/IEntityListStore';
import { IUserSystemRoleModel } from '../../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { DataTableFormUI } from '../../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { UserAdminModel } from '../UserAdminModel';
import { UserModal } from './UserModal';

export class UserModalUI extends DataTableFormUI<UserAdminModel> implements IDataTableFormUI<UserAdminModel> {
  constructor(
    public systemRoleId: IEntityListStore<IUserSystemRoleModel> = injectEntityList<IUserSystemRoleModel>([]),
  ) {
    super();
    this.formComponent.setValue(UserModal);
  }
}
