import { FileUploadUI } from '../../../../../fileUpload/store/FileUploadUi';

export class ImageUploadUI extends FileUploadUI {
  constructor() {
    super();
    this.accept.setList(['.png', '.jpg', '.jpeg']);
    this.key.setValue('image/png');
    this.maxSize.setValue(5000000);
  }
}
