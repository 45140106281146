import { IContainer } from '../../common/container/IContainer';
import { setClassInjection } from '../../common/container/setClassInjection';
import { MainLayoutDomainStoreToken } from './main/store/domain/IMainLayoutDomainStore';
import { MainLayoutDomainStore } from './main/store/domain/MainLayoutDomainStore';

export function layoutContainer(container: IContainer): IContainer {
  setClassInjection(container, MainLayoutDomainStoreToken, MainLayoutDomainStore);

  return container;
}
