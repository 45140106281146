import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';

import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IPerformerTypeSearch } from '../../../../../../../service/performer/type/IPerformerTypeSearch';
import { IProjectModel } from '../../../../../../../service/project/IProjectModel';
import { IUserSystemRoleModel } from '../../../../../../../service/systemRole/entity/IUserSystemRoleModel';
import { MultiLineText } from '../../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../../design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../../design/dataTable/store/IDataTableUI';
import { UserAdminRowsUI } from './rows/UserAdminRowsUI';
import { UserAdminModel } from './UserAdminModel';
import { UserAdminDomain } from './UserAdminDomain';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class UserAdminUI
  extends DataTableUI<UserAdminModel, IPerformerTypeSearch>
  implements IDataTableUI<UserAdminModel, IPerformerTypeSearch>
{
  constructor(
    public domain: UserAdminDomain,
    rows: IDataTableRowsUI<UserAdminModel, IPerformerTypeSearch> | null,
    public systemRoleId: IEntityListStore<IUserSystemRoleModel> = injectEntityList<IUserSystemRoleModel>([]),
    public projects: IEntityListStore<IProjectModel> = injectEntityList<IProjectModel>([]),
  ) {
    super(domain, rows);
    this.rows = rows || new UserAdminRowsUI(this);
  }
  setDomainId(): string {
    return 'settings/user/entity';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Логин',
        field: 'login',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          if (this.domain.contextMenuDomain.ui.activeRow.entity?.login === 'admin') {
            this.domain.setNoDeletePermission();
          } else {
            this.domain.setPermissions();
          }
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Почта',
        field: 'email',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'ФИО',
        field: 'displayName',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Роль',
        field: 'systemRoleId',
        flex: 1,
        valueGetter: (params) => {
          const systemRoleId = this.domain.ui.rows.getFieldValueAsString('systemRoleId', params.value);

          return systemRoleId;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('systemRoleId', params.row.systemRoleId);
          return (
            <DataTableRowDependency
              onClick={() => {
                // this.domain.onLinkAction(params.row.categoryId, '/settings/tag/category');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },

      {
        headerName: 'Тип создания',
        field: 'sourceType',
        flex: 1,
        valueGetter: (params) => {
          if (params.value === 'internal') {
            return 'Локальный';
          } else if (params.value === 'activeDirectory') {
            return 'Active Directory';
          }
        },
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 1,
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <>
            {row?.row?.sourceType !== 'activeDirectory' && < div style={{
              display: 'flex',
              justifyContent: 'space-between',
              minWidth: 40,
              maxWidth: 60,
            }
            }>
              <Tooltip title="Редактировать">
                <IconButton
                  disabled={!this.isCanEdit.value}
                  // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                  onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                >
                  <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
              {
                row?.row?.login !== 'admin' &&
                <Tooltip title="Удалить">
                  <IconButton
                    disabled={!this.isCanDelete.value}
                    // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                    onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
                  >
                    <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
                  </IconButton>
                </Tooltip>
              }
            </div >}
          </>

        );
      },

    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
