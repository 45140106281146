import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IVendorRequirementModel } from './IVendorRequirementModel';
import { IVendorRequirementSearch } from './IVendorRequirementSearch';
import { IVendorRequirementService } from './IVendorRequirementService';

export class RestVendorRequirementService
  extends RestCRUDService<IVendorRequirementModel, IVendorRequirementSearch>
  implements IVendorRequirementService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/vendor/requirement`;
  }
}
