import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';

import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IRequirementCategoryModel } from '../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { ApplicationAffectAdminModel } from './ApplicationAffectAdminModel';
import { ApplicationAffectRowsUI } from './rows/ApplicationAffectRowsUI';

export class ApplicationAffectAdminUI
  extends DataTableUI<ApplicationAffectAdminModel, any>
  implements IDataTableUI<ApplicationAffectAdminModel, any>
{
  constructor(
    public domain: IDataTableDomain<ApplicationAffectAdminModel, any>,
    rows: IDataTableRowsUI<ApplicationAffectAdminModel, any> | null,
    public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
    public requirementsCategories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new ApplicationAffectRowsUI(this);
  }
  setDomainId(): string {
    return 'settings/application/affect/entity';
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Требования',
        field: 'requirementsIds',
        flex: 1,
        valueGetter: (params) => {
          const requirements = this.domain.ui.rows.getFieldValueAsString('requirementsIds', params.value);
          return requirements;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies(
            'requirementsIds',
            params.row.requirementsIds,
          );
          return (
            <DataTableRowDependency
              getTextValue={(item: any) => item.shortName}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'string',
        field: 'orderIndex',
        flex: 0.5,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
      },
    ];
  }
}
