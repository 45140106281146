import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { RowContextMenus } from '../RowContextMenus';
import { DefaultRowContextMenu } from '../view/DefaultRowContextMenu';
import {
  CommonRowContextMenuComponentProperties,
  DefaultRowContextMenuComponentProperties,
  IRowContextMenuUI,
  IRowContextMenuUIComponent,
  IRowDefaultContextMenuUIComponent,
} from './IRowContextMenuUI';

export class RowContextMenuUI<RowType> implements IRowContextMenuUI<RowType> {
  constructor(
    public contextDomain,
    public additionalContextMenus = injectEntityList([]),
    public defaultContextMenu = injectPrimitive<
      IRowDefaultContextMenuUIComponent<RowType, DefaultRowContextMenuComponentProperties<RowType>>
    >({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    }),
    public openedContextMenu = injectPrimitive(null),
    public selectedRows = injectEntityList<RowType>([]),
    public isHaveSelectedRows = injectPrimitive<boolean>(false),
    public component = RowContextMenus,
    public activeRow: IEntityStore<RowType | null> = injectEntity(null),
  ) {}

  getContextMenuByRow(
    row: RowType,
  ): IRowContextMenuUIComponent<RowType, CommonRowContextMenuComponentProperties<RowType>> {
    return this.defaultContextMenu.value;
  }

  showContextMenu(row: RowType, event: any): void {
    this.activeRow.setEntity(row);
    const contextMenu = this.getContextMenuByRow(row);
    contextMenu.properties.row = row;
    contextMenu.properties.event = event;
    contextMenu.properties.isOpened = true;
  }

  closeContextMenu(row: RowType): void {
    this.activeRow.setEntity(null);
    const contextMenu = this.getContextMenuByRow(row);
    contextMenu.properties.row = null;
    contextMenu.properties.event = null;
    contextMenu.properties.isOpened = false;
  }

  selectRows(rows: RowType[]): void {
    this.selectedRows.setList(rows);
    const isHaveSelectedRows = rows.length > 0;
    this.isHaveSelectedRows.setValue(isHaveSelectedRows);
  }
}
