import { IStore } from '../../../../../common/store/interface/IStore';
import { ISignInPageUIStore } from './ISignInPageUIStore';

export interface ISignInPageDomain extends IStore {
  loadAdServers(): void;
  boot(): Promise<void>;
  changeLoginMethod(value: any): void;
  ui: ISignInPageUIStore;
  login(): Promise<boolean>;
  logout(): Promise<boolean>;
}

export const SignInPageDomainToken = Symbol.for('SignInPageDomainToken');
