import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { IStatusEntityModel } from '../../../../../service/status/entity/IStatusEntityModel';
import { StatusValueSystemType } from '../../../../../service/status/system/StatusValueSystemType';
import { IStatusValueModel } from '../../../../../service/status/value/IStatusValueModel';
import { StatusSearchIds, StatusSearchItemIds } from '../store/CommonFilterUI';
import { FilterTitle } from './FilterTitle';
import { useRequirementFilterStyle } from './style';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';

interface StatusesFilterProperties {
  statuses: IEntityListStore<IStatusEntityModel>;
  statusesValues: IEntityListStore<IStatusValueModel>;
  searchStatusesIds: IPrimitiveStore<StatusSearchIds>;
  setNewValue: (statusId: string, statusValueId: string | null, checked: boolean) => void;
  rootDomain: any
}

export const StatusesFilter = observer(
  ({ statuses, statusesValues, searchStatusesIds, setNewValue, rootDomain }: StatusesFilterProperties) => {
    const totalCount = searchStatusesIds.value.statuses.reduce((count, searchStatus) => {
      return searchStatus.values.length + count;
    }, 0);
    return (
      <Accordion TransitionProps={{ unmountOnExit: true }} style={{ boxShadow: "none", position: 'inherit' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <FilterTitle selectedCount={totalCount} title={'Статусы'} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={!(rootDomain instanceof RequirementEntityAdminDomain)}>
            {statuses.list.map((status) => {
              const searchIds = searchStatusesIds.value.statuses.find((searchStatus) => searchStatus.id === status.id);
              const values = statusesValues.list.filter((statusValue) => statusValue.statusId === status.id);
              return (
                <StatusCategory
                  key={status.id}
                  status={status}
                  statusesValues={values}
                  searchStatusesIds={searchIds}
                  setNewValue={setNewValue}
                />
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);

interface StatusCategoryProperties {
  status: IStatusEntityModel;
  statusesValues: IStatusValueModel[];
  searchStatusesIds: StatusSearchItemIds | undefined;
  setNewValue: (statusId: string, statusValueId: string | null, checked: boolean) => void;
}

const StatusCategory = observer(
  ({ status, statusesValues, searchStatusesIds, setNewValue }: StatusCategoryProperties) => {
    const classes = useRequirementFilterStyle();

    return (
      <Grid item xs={3}>
        <FormControl component="fieldset" margin='none'>
          <FormLabel color="secondary" component="legend">
            {status.name}
          </FormLabel>
          <FormGroup
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <StatusValue
              status={status}
              key={`${status.id}-none`}
              isChecked={searchStatusesIds?.isIncludeEmpty || false}
              statusValue={{
                statusId: status.id || '',
                description: '',
                name: 'Пустое значение',
                isActive: true,
                orderIndex: 1,
                systemType: StatusValueSystemType.none,
                isDefault: false,
                isShowInStats: false,
                colorId: '',
              }}
              setNewValue={setNewValue}
            />
            {statusesValues.map((statusValue) => {
              const isChecked = searchStatusesIds?.values.includes(statusValue.id || '') || false;
              return (
                <StatusValue
                  status={status}
                  key={statusValue.id}
                  isChecked={isChecked}
                  statusValue={statusValue}
                  setNewValue={setNewValue}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
    );
  },
);

interface StatusValueProperties {
  isChecked: boolean;
  setNewValue: (statusId: string, statusValueId: string | null, checked: boolean) => void;
  statusValue: IStatusValueModel;
  status: IStatusEntityModel;
}

const StatusValue = observer(({ statusValue, status, isChecked, setNewValue }: StatusValueProperties) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          name={statusValue.name}
          sx={{
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
      }
      onChange={(event: any) => {
        setNewValue(status.id || '', statusValue.id || null, event.target.checked);
      }}
      label={statusValue.name}
    />
  );
});
