import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitChapter } from '../../../../../../service/unit/interface/IUnitTheory';
import { ChapterComponent } from '../../theoryComponents/text/ChapterComponent';
import { TheoryComponentList } from '../../theoryComponents/TheoryComponentList';

export interface ICahpterProperties {
  chapter: IUnitChapter;
}

export const Chapter = observer(({ chapter }: ICahpterProperties) => {
  const classes = useStyles();

  return (
    <Box sx={{ height: '100%', overflowY: 'auto', paddingBottom: '64px', paddingRight: '24px' }}>
      <ChapterComponent data={{ title: chapter.title }} />
      {chapter.theoryBlocks.map((block) => {
        const Component = TheoryComponentList[block.block];
        if (!Component) return null;

        return (
          <div className={classes.componentWrapper} key={block.pseudoId}>
            <Component data={block.data} />
          </div>
        );
      })}
    </Box>
  );
});

const useStyles = makeStyles({
  componentWrapper: {
    width: '100%',
    overflowWrap: 'break-word',

    '& *': {
      maxWidth: '100%',
      boxSizing: 'border-box',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },

    '& table td': {
      width: 'auto',
      boxSizing: 'border-box',
      overflowWrap: 'anywhere',
    },
  },
});
