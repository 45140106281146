import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Colors } from '../../../../design/color/Colors';

export const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: Colors.leftMenu,
      overflow: 'hidden',
    },
    toolbar: {
      paddingTop: theme.spacing(1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    titleContainer: {
      paddingLeft: theme.spacing(5),
    },
    titleText: {
      // lineHeight: '48px',
      color: '#B6B6B6',
    },
    navContainer: {
      paddingLeft: theme.spacing(3),
    },
    iconContainer: {
      paddingLeft: theme.spacing(5),
      paddingTop: 100,
    },
  };
});
