import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { PassingSidebarFinalResults } from './PassingSidebarFinalResults';
import { PassingSidebarPractice } from './PassingSidebarPractice';
import { PassingSidebarQuiz } from './PassingSidebarQuiz';
import { PassingSidebarTheory } from './PassingSidebarTheory';

export const PassingBodySidebar = observer(() => {
  const classes = useStyles();

  return (
    <Box className={classes.passingBodySidebar}>
      <PassingSidebarTheory />
      <PassingSidebarQuiz />
      <PassingSidebarPractice />
      <PassingSidebarFinalResults />
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  passingBodySidebar: {
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
    width: '25%',
  },
}));
