import React, { useEffect, useState } from 'react';
import { TableCell } from '@mui/material';
import { observer } from 'mobx-react';
import { ProjectInfoDomain } from '../../../../view/user/page/project/info/store/ProjectInfoDomain';
import { IProjectApplicationView } from '../../../../view/user/page/project/info/store/model/IProjectApplicationView';
import { RatingAppDomain } from './store/RatingAppDomain';
import { IAppRatingProjectInfoDomain } from './store/IAppRatingProjectInfoDomain';

export const GPBRatingAppTitleExtension = observer(() => {
  return (
    <TableCell style={{ fontWeight: 'bold' }} align="right">
      Рейтинги АС, %
    </TableCell>
  );
});

interface RatingValuesProperties {
  projectApplication: IProjectApplicationView;
  domain: IAppRatingProjectInfoDomain;
}

export const GPBRatingAppValuesExtension = observer(({ domain, projectApplication }: RatingValuesProperties) => {
  const [extendedRatingAppDomain] = useState(new RatingAppDomain(domain));
  useEffect(() => {
    extendedRatingAppDomain.loadRatingApp(projectApplication.id);
  }, [domain, domain.appRatings, domain.appRatings.list]);

  return (
    <TableCell
      style={{
        maxWidth: '50px',
        minWidth: '50px',
        paddingTop: '35px',
        textAlign: 'end',
        borderBottom: 'none',
        verticalAlign: 'top',
      }}
      component="th"
      scope="row"
    >
      {extendedRatingAppDomain.ratingValues.value.firstValue}, {extendedRatingAppDomain.ratingValues.value.secondValue}
    </TableCell>
  );
});
