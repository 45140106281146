import { AnalyticsDomain } from './AnalyticsDomain';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { AnalyticsRequirementsUi } from './AnalyticsRequirementsUi';

export class AnalyticsUi {
  public requirementsUi = new AnalyticsRequirementsUi(this.domain);

  constructor(
    public domain: AnalyticsDomain,
    public analyticsPageTabs = injectPrimitive('requirementsAnalytics'),
  ) { }
}
