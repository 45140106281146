import { Box, Chip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../../../../../extension/learning/service/unit/interface/IUnitModel';
import { IUnitListAdminPageDomain } from '../../../../../../../../../extension/learning/view/admin/page/unitListPage/store/IUnitListAdminPageDomain';
import { RecommendedUnitListItem } from '../RecommendedUnitListItem';

interface Props {
  title: string;
  units: IUnitModel[];
  domain: IUnitListAdminPageDomain;
  checkedUnits?: string[];
  currentRoleId?: string | null;
  setCheckedUnits?: (value: any) => any;
}

export const RecommendedUnitListCard = observer(
  ({ title, units, domain, currentRoleId, checkedUnits, setCheckedUnits }: Props) => {
    const requiredUnits = units.filter((item) =>
    currentRoleId ? item.settings.teamRolesIds.includes(currentRoleId) && item.isPublished : false,
    );
    const optionalUnits = units.filter((item) =>
      currentRoleId ? (!item.settings.teamRolesIds.includes(currentRoleId) && item.isPublished) : item.isPublished,
    );

    const handleCheck = (id) => {
      setCheckedUnits?.((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
    };

    if (!requiredUnits.length && !optionalUnits.length) {
      return <></>
    }

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'repeat(auto-fill,64px)',
          rowGap: '8px',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {requiredUnits?.length > 0 && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.5 }}>
                <div>Обязательные</div>
                <Chip label={requiredUnits?.length} variant="outlined" />
              </div>
              {requiredUnits.map((item) => (
                <RecommendedUnitListItem
                  handleEdit={domain.onEditUnit}
                  handleDelete={domain.onDeleteUnit}
                  handlePublish={domain.publishUnit}
                  handleUnpublish={domain.unpublishUnit}
                  card={{
                    name: item.settings.titleHeading,
                    isPublished: item.isPublished,
                    id: item.id,
                  }}
                  isChecked={checkedUnits?.includes(item.id)}
                  handleCheck={() => handleCheck(item.id)}
                />
              ))}
            </>
          )}

          {optionalUnits?.length > 0 && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.5 }}>
                <div>Необязательные</div>
                <Chip label={optionalUnits?.length} variant="outlined" />
              </div>
              {optionalUnits.map((item) => (
                <RecommendedUnitListItem
                  handleEdit={domain.onEditUnit}
                  handleDelete={domain.onDeleteUnit}
                  handlePublish={domain.publishUnit}
                  handleUnpublish={domain.unpublishUnit}
                  card={{
                    name: item.settings.titleHeading,
                    isPublished: item.isPublished,
                    id: item.id,
                  }}
                  isChecked={checkedUnits?.includes(item.id)}
                  handleCheck={() => handleCheck(item.id)}
                />
              ))}
            </>
          )}
        </Box>
      </Box>
    );
  },
);
