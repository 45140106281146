import wretch from 'wretch';

import { IContextModel } from './IContextModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';

wretch().errorType('json');

export class RestContextService extends LearningCoreRestCRUDService<IContextModel> {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/context`;
  }
}
