import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IAdditionalColumnContentModel } from '../../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import {AdditionalColumnContentModal} from './AdditionalColumnContentModal'

export class AdditionalColumnContentModalUI
  extends DataTableFormUI<IAdditionalColumnContentModel>
  implements IDataTableFormUI<IAdditionalColumnContentModel>
{
  constructor(
    public isLoading = injectPrimitive(true)
  ) {
    super();
    this.formComponent.setValue(AdditionalColumnContentModal);
  }
}
