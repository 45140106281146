import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { FormDomain } from '../../../../../../view/admin/page/form/store/FormDomain';
import { LayoutNotificationType } from '../../../../../../view/layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerDataModel } from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataService } from '../../../../service/manager/data/IRiskManagerDataService';
import { IRiskManagerRootService, RiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerDataFormUI } from './RiskManagerDataFormUI';

export class RiskManagerDataFormDomain extends FormDomain<IRiskManagerDataModel, IRiskManagerDataService> {
  public ui: RiskManagerDataFormUI;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    ui: RiskManagerDataFormUI | null = null,
    public riskManagerRootService: IRiskManagerRootService = new RiskManagerRootService('admin'),
    public validationWarnings = injectPrimitive<any[]>([]),
  ) {
    super(layoutDomain, riskManagerRootService.manager.data);
    this.ui = ui || new RiskManagerDataFormUI(this);
  }

  async boot(riskTemplateId: string | null) {
    try {
      let templates = { data: [] } as { data: any[] };

      templates = await this.riskManagerRootService.manager.template.search({ limit: 1000000 });
      this.ui.templates.setList(templates.data);

      const defaultTemplate = templates.data.find((item) => item?.isDefault === true);
      if (!this.ui.model.entity.templateId && defaultTemplate && riskTemplateId !== null) {
        this.setTemplate(defaultTemplate.id);
      }
    } catch (error) {
      this.layoutDomain.notifications.notificationUI.showNotification({
        text: 'Ошибка',
        type: LayoutNotificationType.error,
      });
    }
  }

  setTemplate(templateId: string | null) {
    const idBefore = this.ui.model.entity.id;
    this.ui.setDefaultModel();
    this.ui.model.entity.id = idBefore;
    this.ui.model.entity.templateId = templateId;
  }
}
