import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { RowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';

export class VendorRequirementContextMenuUI<RowType> extends RowContextMenuUI<IVendorRequirementModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
