import React from 'react';

import { IProjectView } from '../../../../../../view/user/page/project/info/store/model/IProjectView';
import { ProjectInfoDomain } from '../../../../../../view/user/page/project/info/store/ProjectInfoDomain';

export const extendProjectInfoDomainConstructor = (
  Ref: new (...args: any[]) => ProjectInfoDomain,
): new (...args: any[]) => ProjectInfoDomain => {
  class ExtendedDomainRef extends Ref {
    constructor(...args) {
      super(...args);
      // this.ui.teamTableRowsHeaders.list.push({ key: 'learningProgress', title: 'Уровень' });
    }

    async loadData(projectId?: string): Promise<void> {
      await super.loadData(projectId);
      await this.loadLearningData(this.ui.projects.list);
    }

    //@Levan load real data  and add to content
    //Dont use style property, wtf error
    async loadLearningData(projects: IProjectView[]) {
      // projects.forEach((projectView) => {
      //   const content = this.ui.teamTableRowsContent.list.find((item) => item.projectId === projectView.id);
      //   const levelName = 'Новичок';
      //   if (content) {
      //     content.data = content.data.map((contentItem) => {
      //       contentItem.data.push({
      //         content: (
      //           <Grid container width={'100%'}>
      //             <Grid item width={'50%'}>
      //               <BorderMultiLinearProgress maxNumber={3} currentValue={1} width={'100%'} />
      //             </Grid>
      //             <Grid item>{levelName}</Grid>
      //           </Grid>
      //         ),
      //       });
      //       return contentItem;
      //     });
      //   }
      // });
    }
  }

  return ExtendedDomainRef;
};
