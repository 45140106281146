import { ILocalization, ILocalizationToken } from '../../../application/language/ILocalization';
import { IContainer } from '../../../common/container/IContainer';
import { mergeDeep } from '../../../common/utils/mergeDeep';
import en from './en/GPBTranslation.json';
import ru from './ru/GPBTranslation.json';

const getGPBTranslations = () => {
  return {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  };
};

export const addGPBTranslations = async (container: IContainer) => {
  const lang = container.getByToken<ILocalization, any>(ILocalizationToken);
  const riskManagerLangs = getGPBTranslations();
  lang.i18n.store.data = mergeDeep(lang.i18n.store.data, riskManagerLangs);

  return container;
};
