import { IContainer } from '../../../../common/container/IContainer';
import { setClassInjection } from '../../../../common/container/setClassInjection';
import { SignInPageDomainToken } from './store/ISignInPageDomain';
import { SignInPageDomain } from './store/SignInPageDomain';

export function signInPageContainer(container: IContainer): IContainer {
  setClassInjection(container, SignInPageDomainToken, SignInPageDomain);

  return container;
}
