import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { FormAutocomplete } from '../../../../../view/design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../view/design/form/FormMultiAutocomplete';
import { FormTextField } from '../../../../../view/design/form/FormTextField';
import { IPageWithLayoutProperties } from '../../../../../view/layout/page/IPageWithLayoutProperties';
import { IRiskManagerTemplateModel } from '../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerQuestionFieldModel } from '../../../service/question/field/IRiskManagerQuestionFieldModel';
import { RiskManagerQuestionFieldInputType } from '../../../service/question/field/RiskManagerQuestionFieldInputType';
import { IRiskManagerQuestionModel } from '../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../../service/question/static/IRiskManagerStaticQuestionModel';
import { RiskManagerStaticQuestionType } from '../../../service/question/static/RiskManagerStaticQuestionType';
import { RiskManagerDataFormDomain } from './domain/RiskManagerDataFormDomain';
import { IRiskManagerProjectFormDomain } from './project/extendProjectFormDomain';
import SaveAnswerDialog from './parts/SaveAnswerDialog';

export interface RiskManagerDataFormProperties extends IPageWithLayoutProperties {
  projectFormDomain: IRiskManagerProjectFormDomain;
  projectId: string;
}

const sortByIndex = (current: any, next: any) => (current.sortIndex < next.sortIndex ? -1 : 1);

export const RiskManagerDataForm = observer(({ projectFormDomain, projectId }: RiskManagerDataFormProperties) => {

  const isCanEditFields = projectFormDomain.layoutDomain.userHaveAnyAccessToEditProject(projectId).isCanEditFields

  const riskManagerDomain = projectFormDomain.riskManagerDataFormDomain;
  useEffect(() => {
    riskManagerDomain.boot(projectId);
  }, [riskManagerDomain, projectId]);

  const template =
    riskManagerDomain.ui.model.entity?.templateDataCopy ||
    riskManagerDomain.ui.templates.list.find(
      (template) => template.id === riskManagerDomain.ui.model.entity.templateId,
    );
  return (
    <Fragment>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Анкета рисков</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper elevation={3}>
            <TemplateField domain={riskManagerDomain} isCanEdit={isCanEditFields} />
            {riskManagerDomain.ui.model.entity.templateId && template && (
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <StaticQuestions domain={riskManagerDomain} template={template} isCanEdit={isCanEditFields} />
                  <AnswerQuestions domain={riskManagerDomain} template={template} isCanEdit={isCanEditFields} />
                </Grid>
              </Grid>
            )}
          </Paper>
        </AccordionDetails>
      </Accordion>
      <Toolbar />
      <SaveAnswerDialog domain={projectFormDomain} />
    </Fragment>
  );
});

const TemplateField = observer(({ domain, isCanEdit }: { domain: RiskManagerDataFormDomain, isCanEdit: boolean }) => {
  const values = [
    {
      name: 'Без анкеты рисков',
      value: null
    },
    ...domain.ui.templates.list.map((template) => ({
      value: template.id,
      name: template.name,
    }))
  ];

  return (
    <Fragment>
      <FormAutocomplete
        values={values}
        onChangeSelect={(newValue: any) => {
          domain.setTemplate(newValue.value);
        }}
        key="templateInputId"
        errorMessage={undefined}
        selected={domain.ui.model.entity.templateId}
        selectedDefault={null}
        label="Шаблон"
        disabled={!isCanEdit}
      />
      {domain.ui.model.entity.isNeedUpdate && (
        <Button
          style={{ marginLeft: '15px', marginBottom: '15px' }}
          variant="outlined"
          color="secondary"
          // className={classes.actionButton}
          onClick={() => {
            domain.updateToLastVersion();
          }}
          disabled={!isCanEdit}
        // disabled={disabled}
        >
          Обновить до последней версии
        </Button>
      )}
      <Divider />
    </Fragment>
  );
});

const StaticQuestions = observer(
  ({ domain, template, isCanEdit }: { domain: RiskManagerDataFormDomain; template: IRiskManagerTemplateModel, isCanEdit: boolean }) => {
    const staticQuestions = domain.ui.staticQuestions.list.sort(sortByIndex).filter((staticQuestion) => {
      return template.staticQuestionsIds?.includes(staticQuestion.id || '');
    });
    return (
      <Grid container flexDirection={'column'}>
        {staticQuestions.map((staticQuestion) => {
          return (
            <Grid item key={staticQuestion.id}>
              <StaticQuestion domain={domain} template={template} staticQuestion={staticQuestion} isCanEdit={isCanEdit} />
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

const AnswerQuestions = observer(
  ({ domain, template, isCanEdit }: { domain: RiskManagerDataFormDomain; template: IRiskManagerTemplateModel, isCanEdit: boolean }) => {
    const answerQuestions = domain.ui.questions.list
      .sort(sortByIndex)
      .filter((question) => template.questionsIds?.includes(question.id || ''));

    return (
      <Grid container flexDirection={'column'}>
        {answerQuestions.map((question, index) => {
          return (
            <Grid item key={question.id}>
              <AnswerQuestion index={index} domain={domain} template={template} answerQuestion={question} isCanEdit={isCanEdit} />
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

const AnswerQuestion = observer(
  ({
    domain,
    template,
    answerQuestion,
    index,
    isCanEdit
  }: {
    domain: RiskManagerDataFormDomain;
    template: IRiskManagerTemplateModel;
    answerQuestion: IRiskManagerQuestionModel;
    index: number;
    isCanEdit: boolean
  }) => {
    const answersValues = answerQuestion.answersMap.answers.sort(sortByIndex).map((answer) => ({
      value: answer.pseudoId,
      name: answer.text,
    }));
    const answerQuestionData = domain.ui.model.entity.answersData.data.find(
      (item) => item.questionId === answerQuestion.id,
    ) || { value: null, answerPseudoId: null, answerPseudoIds: [], isMultiAnswer: answerQuestion.isMultiAnswer };

    const [value, setValue] = useState(answerQuestionData.answerPseudoId);
    const [multiValue, setMultiValue] = useState(answerQuestionData.answerPseudoIds);
    if (value !== answerQuestionData.answerPseudoId) {
      setValue(answerQuestionData.answerPseudoId);
    }
    if (multiValue !== answerQuestionData.answerPseudoIds) {
      setMultiValue(answerQuestionData.answerPseudoIds);
    }
    return (
      <Grid container flexDirection={'row'}>
        <Grid item style={{ width: '50%' }}>
          <Grid container>
            {answerQuestion.isMultiAnswer === true && (
              <Grid item style={{ width: '100%', paddingBottom: '16px', paddingTop: '16px' }}>
                <div style={{ paddingLeft: '16px' }}>
                  <MultiLineText value={answerQuestion.text} />
                </div>
                <FormMultiAutocomplete
                  values={answersValues}
                  onChangeSelect={(newValue: any) => {
                    answerQuestionData.answerPseudoIds = newValue.map((value: any) => value.value);
                    setMultiValue(newValue.map((value: any) => value.value));
                  }}
                  key={answerQuestion.id}
                  selected={multiValue}
                  label=""
                  disabled={!isCanEdit}
                  helperText={answerQuestion.text}
                  warningMessage={domain.getWarningsForQuestion(answerQuestion.id)}
                  errorMessage={domain.ui.getAnswerDataErrors(answerQuestion, 'answerPseudoIds') || undefined}
                />
              </Grid>
            )}
            {answerQuestion.isMultiAnswer === false && (
              <Grid item style={{ width: '100%', paddingBottom: '16px', paddingTop: '16px' }}>
                <div style={{ paddingLeft: '16px' }}>
                  <MultiLineText value={answerQuestion.text} />
                </div>
                <FormAutocomplete
                  values={answersValues}
                  onChangeSelect={(newValue: any) => {
                    answerQuestionData.answerPseudoId = newValue.value;
                    setValue(newValue.value);
                  }}
                  key={answerQuestion.id}
                  errorMessage={domain.ui.getAnswerDataErrors(answerQuestion, 'answerPseudoId') || undefined}
                  selected={value as string}
                  label=""
                  warningMessage={domain.getWarningsForQuestion(answerQuestion.id) || undefined}
                  disabled={!isCanEdit}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item style={{ width: '49%' }}>
          <Grid container>
            {domain.ui.answerQuestionFields.list
              .filter((item) => template.questionFieldsIds.includes(item?.id || ''))
              .sort(sortByIndex)
              .map((field) => {
                return (
                  <Grid item style={{ width: '50%' }}>
                    <QuestionField domain={domain} type={'answer'} question={answerQuestion} field={field} isCanEdit={isCanEdit} />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

const StaticQuestion = observer(
  ({
    domain,
    template,
    staticQuestion,
    isCanEdit
  }: {
    domain: RiskManagerDataFormDomain;
    template: IRiskManagerTemplateModel;
    staticQuestion: IRiskManagerStaticQuestionModel;
    isCanEdit: boolean;
  }) => {
    const staticQuestionData = domain.ui.model.entity.staticQuestionData.data.find(
      (item) => item.staticQuestionId === staticQuestion.id,
    ) || { value: null };
    return (
      <Grid container flexDirection={'row'}>
        <Grid item style={{ width: '50%' }}>
          <Grid container>
            {staticQuestion.type === RiskManagerStaticQuestionType.multiRow && (
              <Grid item style={{ width: '100%' }}>
                <FormTextField
                  required={false}
                  multiline={true}
                  rows={4}
                  label={staticQuestion.name}
                  value={staticQuestionData.value || ''}
                  onChange={(newValue: any) => {
                    staticQuestionData.value = newValue;
                  }}
                  helperText={staticQuestion.description || ''}
                  disabled={!isCanEdit}
                />
              </Grid>
            )}
            {staticQuestion.type === RiskManagerStaticQuestionType.oneRow && (
              <Grid item style={{ width: '100%' }}>
                <FormTextField
                  required={false}
                  multiline={false}
                  label={staticQuestion.name}
                  value={staticQuestionData.value || ''}
                  onChange={(newValue: any) => {
                    staticQuestionData.value = newValue;
                  }}
                  helperText={staticQuestion.description || ''}
                  disabled={!isCanEdit}
                />
              </Grid>
            )}
            {staticQuestion.type === RiskManagerStaticQuestionType.number && (
              <Grid item style={{ width: '100%' }}>
                <FormTextField
                  type={'number'}
                  required={false}
                  multiline={false}
                  label={staticQuestion.name}
                  value={staticQuestionData.value || 0}
                  onChange={(newValue: any) => {
                    staticQuestionData.value = Number(newValue);
                  }}
                  helperText={staticQuestion.description || ''}
                  disabled={!isCanEdit}
                />
              </Grid>
            )}
            {/* {staticQuestion.type === RiskManagerStaticQuestionType.date && (
          <Grid item flex="0.7">
            <FormBox>
              <DatePicker
                showTodayButton={true}
                label={staticQuestion.name}
                value={staticQuestionData.value || new Date().getTime()}
                onChange={(date: any) => {
                  staticQuestionData.value = date?.getTime();
                }}
                InputProps={{
                  color: 'secondary',
                }}
                disabled={false}
                //@ts-ignore
                renderInput={(props) => <TextField {...props} />}
              />
            </FormBox>
          </Grid>
        )} */}
          </Grid>
        </Grid>
        <Grid item style={{ width: '49%' }}>
          <Grid container>
            {domain.ui.staticQuestionFields.list
              .filter((item) => template.questionFieldsIds.includes(item?.id || ''))
              .sort(sortByIndex)
              .map((field) => {
                return (
                  <Grid item style={{ width: '50%' }}>
                    <QuestionField domain={domain} type={'static'} question={staticQuestion} field={field} isCanEdit={isCanEdit} />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

const QuestionField = observer(
  ({
    domain,
    question,
    field,
    type,
    isCanEdit,
  }: {
    domain: RiskManagerDataFormDomain;
    question: IRiskManagerStaticQuestionModel | IRiskManagerQuestionModel;
    field: IRiskManagerQuestionFieldModel;
    type: 'static' | 'answer';
    isCanEdit: boolean;
  }) => {
    const fieldData = domain.ui.model.entity.questionFieldData.data.find(
      (item) => item.fieldId === field.id && item.questionId === question.id && item.type === type,
    ) || { value: null };
    return (
      <Fragment>
        {field.inputType === RiskManagerQuestionFieldInputType.multiRow && (
          <FormTextField
            required={false}
            multiline={true}
            rows={4}
            label={field.name}
            value={fieldData.value || ''}
            onChange={(newValue: any) => {
              fieldData.value = newValue;
            }}
            helperText={field.description || ''}
            disabled={!isCanEdit}
          />
        )}
        {field.inputType === RiskManagerQuestionFieldInputType.oneRow && (
          <FormTextField
            required={false}
            multiline={false}
            label={field.name}
            value={fieldData.value || ''}
            onChange={(newValue: any) => {
              fieldData.value = newValue;
            }}
            helperText={field.description || ''}
            disabled={!isCanEdit}
          />
        )}
        {field.inputType === RiskManagerQuestionFieldInputType.number && (
          <FormTextField
            type={'number'}
            required={false}
            multiline={false}
            label={field.name}
            value={fieldData.value || 0}
            onChange={(newValue: any) => {
              fieldData.value = Number(newValue);
            }}
            helperText={field.description || ''}
            disabled={!isCanEdit}
          />
        )}
      </Fragment>
    );
  },
);
