import { action, computed, observable } from 'mobx';

import { IEntityStore } from '../interface/IEntityStore';
import { UIStore } from './UIStore';

export class BaseEntityStore<EntityType> extends UIStore implements IEntityStore<EntityType> {
  @observable private entityState: EntityType;

  constructor(defaultValue: EntityType) {
    super();
    this.entityState = defaultValue;
  }

  @action.bound
  setEntity(entity: EntityType): void {
    this.entityState = entity;
  }

  @computed get entity() {
    return this.entityState;
  }
}
