import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagCategoryService } from '../../../../../../../service/tag/category/ITagCategoryService';
import { IDataTableDomain } from './../../../../../../design/dataTable/store/IDataTableDomain';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TagCategoryAdminModalUI } from './TagCategoryAdminModalUI';

export class TagCategoryAdminModalDomain
  extends DataTableFormDomain<ITagCategoryModel, ITagCategoryService>
  implements IDataTableFormDomain<ITagCategoryModel>
{
  constructor(
    entityService: ITagCategoryService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ITagCategoryModel, any>,
  ) {
    super(entityService, layoutDomain, dataTableDomain, new TagCategoryAdminModalUI());
  }
}
