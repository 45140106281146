import { ITagEntityModel } from '../../../../../../service/tag/entity/ITagEntityModel';
import { ComplexMultiselectDomain } from '../../../form/store/ComplexMultiselectDomain';

export class TagFormSelectionDomain extends ComplexMultiselectDomain {
  tagCategoriesValues = (tags: ITagEntityModel[], categoryId?: string) => {
    return tags
      .filter((entity) => entity.categoryId === categoryId)
      .map((entity) => ({
        value: entity.id || '',
        name: entity.isActive ? entity.name : `${entity.name} (неактивен)`,
      }));
  };
}
