import { observer } from 'mobx-react';
import React from 'react';

import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { IRowContextMenuDomain } from './domain/IRowContextMenuDomain';

export interface IRowContextMenus<RowType extends IServiceModel = IServiceModel> {
  domain: IRowContextMenuDomain<RowType>;
}

export const RowContextMenus = observer(({ domain }: IRowContextMenus) => {
  const DefaultContextMenu = domain.ui.defaultContextMenu.value.Component;
  return (
    <div>
      <DefaultContextMenu
        key={domain.ui.defaultContextMenu.value.id}
        domain={domain}
        {...domain.ui.defaultContextMenu.value.properties}
      />
      {domain.ui.additionalContextMenus.list.map((Menu) => (
        <Menu.Component key={Menu.id} domain={domain} {...Menu.properties} />
      ))}
    </div>
  );
});
