export const RiskColorsMap = [
  {
    name: 'Red',
    color: '#e53935',
  },
  {
    name: 'Pink',
    color: '#d81b60',
  },
  {
    name: 'Purple',
    color: '#8e24aa',
  },
  {
    name: 'Deep Purple',
    color: '#5e35b1',
  },

  {
    name: 'Indigo',
    color: '#3949ab',
  },
  {
    name: 'Blue',
    color: '#1e88e5',
  },
  {
    name: 'Light Blue',
    color: '#039be5',
  },
  {
    name: 'Cyan',
    color: '#00acc1',
  },
  {
    name: 'Teal',
    color: '#00897b',
  },
  {
    name: 'Green',
    color: '#43a047',
  },
  {
    name: 'Light Green',
    color: '#7cb342',
  },
  {
    name: 'Lime',
    color: '#c0ca33',
  },

  {
    name: 'Yellow',
    color: '#fdd835',
  },

  {
    name: 'Amber',
    color: '#ffb300',
  },

  {
    name: 'Orange',
    color: '#fb8c00',
  },

  {
    name: 'Deep Orange',
    color: '#f4511e',
  },
  {
    name: 'White',
    color: '#ffffff',
  },
  {
    name: 'Black',
    color: '#000000',
  },
];
