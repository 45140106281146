import { Dialog } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonLayoutDomain } from '../store/ICommonLayoutDomain';

export interface LayoutModalWindowProperties {
  layoutDomain: ICommonLayoutDomain;
}

export const LayoutModalWindow = observer(({ layoutDomain }: LayoutModalWindowProperties) => {
  const onClose = () => {
    layoutDomain.modalWindow.closeModalWindow();
  };
  const activeModal = layoutDomain.modalWindow.ui.activeModalWindow;
  const options = layoutDomain.modalWindow.ui.activeModalOptions;
  return (
    <div>
      {activeModal.component && (
        <Dialog
          fullWidth={options.isFullWidth}
          maxWidth={options.fullWidthMaxSize as any}
          open={activeModal.isOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {activeModal.component()}
        </Dialog>
      )}
    </div>
  );
});
