import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IUserProjectRoleModel } from './IUserProjectRoleModel';
import { IUserProjectRoleSearch } from './IUserProjectRoleSearch';
import { IUserProjectRoleService } from './IUserProjectRoleService';

export class RestUserProjectRoleService
  extends RestCRUDService<IUserProjectRoleModel, IUserProjectRoleSearch>
  implements IUserProjectRoleService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/user/project/role`;
  }
}
