import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import { TaskManagerServerListAdminDomain } from '../../../../../../view/admin/page/taskManager/server/connectinoTemplate/store/TaskManagerTemplateDomain';
import { ApplicationDetailDomain } from '../../../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { RiskManagerRootService } from '../../../../service/RiskManagerRootService';

export class RiskManagerPriorityByRequirementThreatDomain {
  constructor(
    public baseDomain: TaskManagerServerListAdminDomain,
    private rootPrivateServices: RiskManagerRootService = new RiskManagerRootService('admin'),
    public requirementThreatData: IPrimitiveStore<{ name: string; levelPseudoId: string }[]> = injectPrimitive([]),
  ) {}

  async loadRequirementThreatData() {
    const sortByName = function compare(
      a: { name: string; levelPseudoId: string },
      b: { name: string; levelPseudoId: string },
    ) {
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1;
      }
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      }
      return 0;
    };

    const riskManagerData = await this.rootPrivateServices.manager.template
      .getById(this.baseDomain.riskTemplateId.value || '')
      .catch((error) => console.log(error));

    riskManagerData?.requirementThreatsLevelSettings.levels.forEach(async (level) => {
      const risk = await this.rootPrivateServices.risk.getById(level.riskId || '')
      if (risk) {
        this.requirementThreatData.value.push({
          name: `${risk?.name} - ${level.name}`,
          levelPseudoId: level.pseudoId,
        });
        this.requirementThreatData.value.sort(sortByName);
      }
    });
  }
}
