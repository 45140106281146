import { Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../../../../common/properties/ICommonProperties';
import { IProjectView } from '../store/model/IProjectView';

export interface IProjectInfoHeaderProperties extends ICommonProperties {
  project: IProjectView;
}

export const ProjectInfoHeader = observer(({ project }: IProjectInfoHeaderProperties) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography fontSize={26}>{project.name}</Typography>
      <Typography color={'textSecondary'} variant="h6">
        {project.description}
      </Typography>
      <Divider className={classes.headerDivider} />
    </div >
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '800px',
  },
  headerDivider: {
    width: theme.spacing(24),
    marginTop: theme.spacing(1),
  },
}));
