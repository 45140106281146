export interface IJiraServerCredentials {
  value: IJiraServerAllCredentialsType;
  type: JiraServerCredentialsType;
}

type IJiraServerAllCredentialsType = IJiraTokenCredentials;

export interface IJiraTokenCredentials {
  apiToken: string;
  username: string;
  host: string;
}

export enum JiraServerCredentialsType {
  basic = 'basic',
}
