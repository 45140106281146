import { IError } from '../../../../common/error/IError';

export class CoreRestErrorHandler {
  handle = (errorResponse: any) => {
    if (errorResponse?.json?.webCode) {
      throw errorResponse.json;
    } else {
      const unknownError: IError = {
        data: {},
        systemCode: 'unknown',
        webCode: '500',
        message: 'Ошибка сервера',
      };

      throw unknownError;
    }
  };
}
