import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IRequirementSearch } from '../../../../../../service/requirement/entity/IRequirementSearch';
import { IVendorRequirementModel } from '../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { VendorRequirementContextMenuDomain } from './context/VendorRequirementContextMenuDomain';
import { VendorRequirementModalDomain } from './modal/VendorRequirementModalDomain';
import { VendorRequirementAdminUI } from './VendorRequirementAdminUI';


export class CustomAppBar {
  Toolbar = TableHeader;
}
export class VendorRequirementAdminDomain
  extends DataTableDomain<IVendorRequirementModel, IRequirementSearch>
  implements IDataTableDomain<IVendorRequirementModel, IRequirementSearch>
{
  public ui: VendorRequirementAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    public activeVendorId = injectPrimitive<string | null>(null),
    public activeVendorType = injectPrimitive<string | null>(null),
  ) {
    super(layoutDomain, rootPrivateServices.requirement.vendor);
    this.ui = new VendorRequirementAdminUI(this, null);
    this.modalDomain = new VendorRequirementModalDomain(rootPrivateServices.requirement.vendor, layoutDomain, this);
    this.contextMenuDomain = new VendorRequirementContextMenuDomain({
      entityService: rootPrivateServices.requirement.vendor,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Требования внешних регуляторов');
    this.ui.tableSubtitle.setValue('Значения');
  }

  async boot(): Promise<void> {
    await this.getCurrentVendor()
    await super.boot()
  }

  async getCurrentVendor() {
    if (this.activeVendorType.value) {
      const currentVendor = (await this.rootPrivateServices.vendor.entity.search({
        limit: 1000, filter: {
          //@ts-ignore
          systemType: {
            equal: this.activeVendorType.value
          }
        }
      })).data
      if (currentVendor.length > 0) {
        this.ui.rows.searchRequest.setEntity({
          limit: 10000,
          filter: {
            //@ts-ignore
            vendorId: {
              in: currentVendor.map(item => item.id)
            }
          }
        })
      }
    }
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-create'],
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-delete'],
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-update'],
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

  async loadData() {
    await this.loadDepenicies();
    await super.loadData();
  }
  async loadDepenicies() {
    const [vendorEntitySearchResult, requirementEntitySearchResult] = await Promise.all([
      this.rootPrivateServices.vendor.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.requirement.entity.search({ limit: 100000 }),
    ]);
    this.ui.vendorEntity.setList(vendorEntitySearchResult.data);
    this.ui.requirementEntity.setList(requirementEntitySearchResult.data);
  }
}
