import { ITagEntityService } from './../../../../../../../service/tag/entity/ITagEntityService';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { IDataTableDomain } from './../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from './../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { TagEntityAdminModalUI } from './TagEntityAdminModalUI';

export class TagEntityAdminModalDomain
  extends DataTableFormDomain<ITagEntityModel, ITagEntityService>
  implements IDataTableFormDomain<ITagEntityModel>
{
  public ui: TagEntityAdminModalUI;
  constructor(
    entityService: ITagEntityService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ITagEntityModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new TagEntityAdminModalUI());
    this.ui = new TagEntityAdminModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  async loadDependency() {
    const categoriesSearchResult = await this.rootPrivateServices.tag.category.search({ limit: 10000000 });
    this.ui.categories.setList(categoriesSearchResult.data);
  }
}
