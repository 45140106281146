import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import { ICommonProperties } from '../../common/properties/ICommonProperties';
import { Colors } from '../../view/design/color/Colors';

export interface IApplicationThemeProperties extends ICommonProperties {
  children: ReactNode;
  isFlip?: boolean;
}

export default function ApplicationTheme({ isFlip = false, children }: IApplicationThemeProperties) {
  const theme = createProjectTheme(isFlip);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

const createProjectTheme = (isFlip: boolean) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: { paper: Colors.mainPassive, default: Colors.mainPassive },
      primary: {
        main: isFlip ? Colors.mainActive : Colors.mainActive,
      },
      secondary: {
        main: isFlip ? Colors.mainActive : Colors.mainActive,
      },
      error: {
        main: Colors.negativePassive,
      },
    },
    typography: {
      fontFamily: ['Late', 'Roboto', 'Arial', 'sans-serif'].join(','),
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: 'fit-content !important',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingRight: '20px',
            },
          },
          paddingNone: {
            '&:last-child': {
              paddingRight: '20px',
            },
          },
        },
      },
    },
  });
  return theme;
};
