import { FormControlLabel, Grid, Paper, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormActions } from '../../../../design/form/FormActions';
import { FormTextField } from '../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { MailAdminFormDomain } from './store/MailAdminFormDomain';
import { FormAutocomplete } from '../../../../design/form/FormAutocomplete';
import { authEnum } from '../../../../../service/mail/IMailEntityModel';

export interface ISmtpAdminFormProperties extends IAdminPageProperties { }

export const MailAdminForm = observer(({ layoutDomain }: ISmtpAdminFormProperties) => {
  const [domain] = useState(new MailAdminFormDomain(layoutDomain));
  const { serverId = null } = useParams<any>();
  const authTypes = Object.values(authEnum).map((auth, iterator) => {
    return {
      value: auth,
      name: auth,
    }
  });
  useEffect(() => {
    domain.loadData(serverId);
  }, [domain, serverId]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={8}>
        <Paper elevation={3}>
          <FormAutocomplete
            values={authTypes}
            onChangeSelect={(newValue: any) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, authType: newValue.name });
            }}
            selectedDefault={{ value: 'Plain', name: 'Plain' }}
            selected={domain.ui.mailSettings.entity.authType}
            label="Тип аутентификации"
          />
          <FormTextField
            value={domain.ui.mailSettings.entity.name}
            onChange={(newValue) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, name: newValue });
            }}
            label="Название"
            required={true}
          />

          <FormTextField
            value={domain.ui.mailSettings.entity.host}
            onChange={(newValue) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, host: newValue.trim() });
            }}
            label="Хост"
            required={true}
          />
          {domain.ui.mailSettings.entity.authType === authEnum.NTLM && (
            <>
              <FormTextField
                value={domain.ui.mailSettings.entity.domain}
                onChange={(newValue) => {
                  domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, domain: newValue.trim() });
                }}
                label="Domain"
                required={false} /><FormTextField
                value={domain.ui.mailSettings.entity.workstation}
                onChange={(newValue) => {
                  domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, workstation: newValue.trim() });
                }}
                label="Workstation"
                required={false} />
            </>
          )}

          <FormTextField
            type={'number'}
            value={domain.ui.mailSettings.entity.port + ''}
            onChange={(newValue) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, port: +newValue });
            }}
            label="Порт"
            required={true}
          />

          <FormTextField
            value={domain.ui.mailSettings.entity.user}
            onChange={(newValue) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, user: newValue.trim() });
            }}
            label="Логин"
            required={true}
          />

          <FormTextField
            value={domain.ui.mailSettings.entity.password}
            type={'password'}
            onChange={(newValue) => {
              domain.ui.mailSettings.setEntity({ ...domain.ui.mailSettings.entity, password: newValue });
            }}
            label="Пароль"
            required={true}
          />

          <FormControlLabel
            style={{ width: '100%', padding: '16px', margin: 0 }}
            control={
              <Switch color={'success'}
                checked={domain.ui.mailSettings.entity.ignoreTLS}
                onChange={(event) => { domain.ui.mailSettings.entity.ignoreTLS = event.target.checked }}
                defaultChecked={true} />
            }
            label="IgnoreTLS"
          />
        </Paper>

        <FormActions onSave={() => domain.save()} onCancel={() => domain.cancelEdit()} />
      </Grid>
    </Grid>
  );
});
