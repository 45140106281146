import { IContainer } from '../../../common/container/IContainer';
import { injectEntityList } from '../../../common/store/base/injectEntityList';
import { IMainLayoutDomainStore } from '../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectListDomain } from '../../../view/user/page/project/list/store/ProjectListDomain';
import { ManagerFilterRootService } from './service/ManagerFilterService';
import { IManagersUsers } from './IManagerFilter';
import { IEntityListStore } from '../../../common/store/interface/IEntityListStore';
import { injectPrimitive } from '../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../common/store/interface/IPrimitiveStore';

export interface IManagerFilterDomain extends ProjectListDomain {
  managersList: IEntityListStore<IManagersUsers>;
  filteredManagersList: IEntityListStore<IManagersUsers>;
  isManagersLoading: IPrimitiveStore<boolean>;
  setFilteredManagersList: (list: IManagersUsers[]) => void;
}

export const extendProjectListDomainWithManagerFilter = (
  Ref: new (...args: any[]) => ProjectListDomain,
): new (...args: any[]) => IManagerFilterDomain => {
  class ExtendedProjectListDomainRef extends Ref {
    private managerFilter = new ManagerFilterRootService();

    public managersList = injectEntityList<IManagersUsers>([]);
    public managerId = injectPrimitive('');
    public filteredManagersList = injectEntityList<IManagersUsers>([]);
    public isManagersLoading = injectPrimitive<boolean>(false);

    constructor(layoutDomain: IMainLayoutDomainStore, container: IContainer) {
      super(layoutDomain, container);
    }

    async loadData(): Promise<void> {
      await super.loadData();
      const result = await this.managerFilter.filter.getManagers();
      this.managersList.setList(result.managerUsersData);
      this.managerId.setValue(result.managerRoleId);
    }

    setFilteredManagersList(list: IManagersUsers[]) {
      this.filteredManagersList.setList(list);
      const usersManagersIds = list.map((user) => user.id);
      let filterObject = this.projectFilters.value;
      if (!usersManagersIds.length) {
        filterObject = {};
      } else {
        filterObject.json = [
          {
            fieldPath: 'rolesMap.data[].roleId',
            value: this.managerId.value,
            type: 'equal',
          },
          {
            fieldPath: 'rolesMap.data[].userId',
            value: [...usersManagersIds],
            type: 'in',
          },
        ];
      }

      this.projectFilters.setValue(filterObject);

      super.triggerSearch(0);
    }
  }

  return ExtendedProjectListDomainRef;
};
