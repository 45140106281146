import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IPerformerTypeModel } from './IPerformerTypeModel';
import { IPerformerTypeSearch } from './IPerformerTypeSearch';
import { IPerformerTypeService } from './IPerformerTypeService';

export class RestPerformerTypeService
  extends RestCRUDService<IPerformerTypeModel, IPerformerTypeSearch>
  implements IPerformerTypeService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/performer/type`;
  }
}
