import { Container } from './Container';

/**
 * @deprecated The method should not be used
 */
export const inject = <ReturnType>(token: any): ReturnType => {
  const container = new Container();
  return container.getByToken(token);
};

export type ConstructorType<T> = new (...args: any[]) => T;

export const injectValue = <ReturnType>(token: any): ReturnType => {
  const container = new Container();
  return container.get(token);
};
