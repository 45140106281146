import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IStatusValueModel } from './IStatusValueModel';
import { IStatusValueSearch } from './IStatusValueSearch';
import { IStatusValueService } from './IStatusValueService';

export class RestStatusValueService
  extends RestCRUDService<IStatusValueModel, IStatusValueSearch>
  implements IStatusValueService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/status/value`;
  }
}
