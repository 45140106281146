import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { INotificationModel } from '../../../../../service/notification/INotificationModel';

export class NotificationUI {
  constructor(
    public notifications: IEntityListStore<INotificationModel> = injectEntityList([]),
    public notificationUnreadCount: IPrimitiveStore<number> = injectPrimitive(0),
    public unreadNotifications: IEntityListStore<INotificationModel> = injectEntityList([]),
    public isSetConfig: IPrimitiveStore<boolean> = injectPrimitive(false),
  ) {}
}
