import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { GenerateUUID } from '../../../../service/pseudoId/GenerateUUID';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';
import { RiskManagerStaticQuestionType } from '../../../../service/question/static/RiskManagerStaticQuestionType';
import { RiskManagerQuestionForm } from './RiskManagerStaticQuestionForm';

const defaultModelValue: IRiskManagerStaticQuestionModel = {
  description: '',
  name: '',
  sortIndex: 1,
  type: RiskManagerStaticQuestionType.multiRow,
};

export class RiskManagerStaticQuestionFormUI
  extends DataTableFormUI<IRiskManagerStaticQuestionModel>
  implements IDataTableFormUI<IRiskManagerStaticQuestionModel>
{
  constructor() {
    super(defaultModelValue);
    this.formComponent.setValue(RiskManagerQuestionForm);
  }
}
