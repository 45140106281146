import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldService } from '../../../../service/question/field/IRiskManagerQuestionFieldService';
import { RiskManagerQuestionFieldDomain } from '../RiskManagerQuestionFieldDomain';
import { RiskManagerQuestionFieldContextMenuUI } from './RiskManagerQuestionFieldContextMenuUI';

export class RiskManagerQuestionFieldContextMenuDomain extends RowContextMenuDomain<IRiskManagerQuestionFieldModel> {
  public dataTableDomain: RiskManagerQuestionFieldDomain;
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: IRiskManagerQuestionFieldService;
    dataTableDomain: RiskManagerQuestionFieldDomain;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<IRiskManagerQuestionFieldModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerQuestionFieldContextMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateQuestionFields();
  }
}
