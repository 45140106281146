import { IUIStore } from './IUIStore';

export interface IPrimitiveStore<PrimitiveType> extends IUIStore {
  value: PrimitiveType;
  setValue(value: PrimitiveType): void;
}

export interface IPrimitiveStoreFactory<Type> {
  (defaultValue: Type): IPrimitiveStore<Type>;
}

export const PrimitiveStoreToken = Symbol.for('PrimitiveStoreToken');
