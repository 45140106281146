import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { UIStore } from '../../../../../common/store/base/UIStore';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IProjectModel } from '../../../../../service/project/IProjectModel';
import { IUserProjectRoleModel } from '../../../../../service/projectRole/entity/IUserProjectRoleModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ScrollDetectionItem } from '../../parts/Scroller/Scroller';
import { defaultUser } from './defaultUser';
import { ILayoutUser } from './ILayoutUser';
import { IMainLayoutUIStore } from './IMainLayoutUIStore';

export class MainLayoutUIStore extends UIStore implements IMainLayoutUIStore {
  constructor(
    public activeUser = injectEntity<ILayoutUser>(defaultUser),
    public projectRoles: IEntityListStore<IUserProjectRoleModel> = injectEntityList([]),
    public userProjects: IEntityListStore<IProjectModel> = injectEntityList([]),
    public isBootLoading = injectPrimitive<boolean>(true),
    public isBackgroundLoading = injectPrimitive<boolean>(false),
    public isAllowedUser = injectPrimitive<boolean>(true),
    public pageTitle = injectPrimitive<string>('...'),
    public pagePadding = injectPrimitive<number>(24),
    public scrollDetection = injectPrimitive<ScrollDetectionItem[]>([]),
  ) {
    super();
  }

  removeDetections(elements: ScrollDetectionItem[]): void {
    for (const index in elements) {
      //@ts-ignore
      this.scrollDetection.value.remove(elements[index]);
    }
  }

  addDetections(elements: ScrollDetectionItem[]): void {
    this.scrollDetection.setValue([...this.scrollDetection.value, ...elements]);
  }
}
