import { Chip, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

export interface IDataTableRowDependency {
  values: any[];
  onClick?: (item: any) => void;
  searchTerm?: string;
  getTextValue: (item: any) => string;
  backupValue?: string | null;
}

export const DataTableRowDependency = observer(
  ({ values, onClick, searchTerm, getTextValue, backupValue = null }: IDataTableRowDependency) => {
    const classes = useStyles();

    return (
      <Stack spacing={1} alignItems="flex-start">
        <Stack direction="row" style={{ flexWrap: 'wrap' }} spacing={0}>
          {values.map((item) => {
            const onChipClick = onClick
              ? (event) => {
                  event.stopPropagation();
                  onClick(item);
                }
              : undefined;

            return (
              <Chip
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                sx={{
                  marginLeft: '5px',
                  marginBottom: '5px',
                  padding: '10px 10px',
                  borderRadius: '4px',
                }}
                onClick={onChipClick}
                label={getTextValue(item)}
                title={getTextValue(item)}
              />
            );
          })}
          {values.length === 0 && backupValue && backupValue.length > 1 && (
            <Chip
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              sx={{
                marginLeft: '5px',
                marginBottom: '5px',
                padding: '10px 10px',
                borderRadius: '4px',
              }}
              label={backupValue}
              title={backupValue}
            />
          )}
        </Stack>
      </Stack>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  chipRoot: { margin: theme.spacing(1), height: '100%', display: 'flex', flexDirection: 'column' },
  chipLabel: { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' },
}));
