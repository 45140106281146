import { ILocalization, ILocalizationToken } from '../../../application/language/ILocalization';
import { IContainer } from '../../../common/container/IContainer';
import { mergeDeep } from '../../../common/utils/mergeDeep';
import en from './en/RiskManagerTranslation.json';
import ru from './ru/RiskManagerTranslation.json';

const getRiskManagerTranslations = () => {
  return {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  };
};

export const addMVideoTranslations = async (container: IContainer) => {
  const lang = container.getByToken<ILocalization, any>(ILocalizationToken);
  const riskManagerLangs = getRiskManagerTranslations();
  lang.i18n.store.data = mergeDeep(lang.i18n.store.data, riskManagerLangs);

  return container;
};
