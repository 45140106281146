import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { ProjectListDomain } from '../../../../../../view/user/page/project/list/store/ProjectListDomain';

import { ILearningProjectListDomain } from './ILearningProjectListDomain';

export const extendProjectListDomainConstructor = (
  Ref: new (...args: any[]) => ProjectListDomain,
): new (...args: any[]) => ILearningProjectListDomain => {
  class ExtendedDomainRef extends Ref implements ILearningProjectListDomain {
    constructor(...args) {
      super(...args);
    }

    async loadData(): Promise<void> {
      await super.loadData();
      await this.loadUsersProgress();
    }

    //@Levan load real data and split by project teams from all applications
    async loadUsersProgress() {
      function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

      const learningProgressData = this.ui.userProjects.list.map((projectData) => ({
        projectId: projectData.id,
        teamProgressValue: randomIntFromInterval(0, 100),
      }));

      this.learningTeamProgressByProjects.setList(learningProgressData);
    }

    public learningTeamProgressByProjects: IEntityListStore<{ projectId: string; teamProgressValue: number }> =
      injectEntityList([]);
  }

  return ExtendedDomainRef;
};
