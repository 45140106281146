import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { FileUploadDomain } from '../../../../../fileUpload/store/FileUploadDomain';
import { IFileUploadUI } from '../../../../../fileUpload/store/IFileUploadUI';
import { VideoUploadUI } from './VideoUploadUI';

export class VideoUploadDomain extends FileUploadDomain {
  public ui: IFileUploadUI;
  constructor(public layoutDomain: IMainLayoutDomainStore) {
    super(layoutDomain);
    this.ui = new VideoUploadUI();
  }
}
