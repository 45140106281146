import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { TagCategoryAdminTable } from '../../tag/category/TagCategoryAdminTable';
import { TagEntityAdminTable } from '../../tag/entity/TagEntityAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const TagSettingsItem: ISettingsItem = {
  id: 'tag',
  settingsItem: {
    key: 'tag',
    title: 'Тэги для требований',
    buttons: [
      {
        title: 'Категории',
        url: `/settings/tag/category`,
        permissions: [UserSystemRoleModelPermissionMap['settings-tags-read']],
      },
      {
        title: 'Значения',
        url: `/settings/tag/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-tags-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-tags-read']],
    infoText:
      'Содержит перечень тэгов, которые могут быть назначены для каждого требования. Используется для группировки и фильтрации требований для АС',
    orderIndex: 400,
  },
  routers: [
    {
      key: 'tags-category-settings',
      path: '/settings/tag/category',
      pageTitle: 'Категории тэгов',
      component: TagCategoryAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-tags-read']],
    },
    {
      key: 'tags-entity-settings',
      path: '/settings/tag/entity',
      pageTitle: 'Тэги',
      component: TagEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-tags-read']],
    },
  ],
};
