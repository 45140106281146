import { Alert } from '@mui/lab';
import { FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';

export interface IFormAutocompleteProperties extends ICommonProperties {
  values: AutocompleteSelectItem[];
  shouldSort?: boolean;
  selected?: string | null;
  label: string | undefined;
  onChangeSelect: (option: { value: string; name?: string } | null) => void;
  errorMessage?: string;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  controlPadding?: string;
  required?: boolean;
  helperText?: string;
  size?: string;
  style?: any;
  variant?: 'standard' | 'outlined' | 'filled';
}

export interface AutocompleteSelectItem {
  value: any;
  name: string;
  icons?: { fragment: any; position: FormAutocompleteOptionIconPosition }[];
}

export enum FormAutocompleteOptionIconPosition {
  left = 'left',
  right = 'right',
}
export const FormAutocompleteStandard = observer(
  ({
    values,
    shouldSort = false,
    label,
    onChangeSelect,
    selected = null,
    errorMessage,
    disabled = false,
    onOpen,
    onClose,
    isLoading,
    controlPadding = undefined,
    loadingText = '',
    required = false,
    helperText = undefined,
    size = 'small',
    style = {},
    variant = 'standard',
  }: IFormAutocompleteProperties) => {
    const classes = useStyles({ controlPadding });
    const sortedValues = shouldSort ? [...values].sort((a, b) => a.name.localeCompare(b.name)) : values;

    const onChange: any = (event: any, value: { value: string } | null) => {
      value ? onChangeSelect(value) : onChangeSelect(null);
      event.stopPropagation();
    };

    const selectedValue = sortedValues.find((value) => selected === value.value) || undefined;
    const [isOpen, changeIsOpen] = useState(false);
    const changeAutocompleteState = (newOpenState: boolean) => {
      if (!isOpen && newOpenState !== isOpen) {
        onOpen && onOpen();
      }
      if (isOpen && newOpenState !== isOpen) {
        onClose && onClose();
      }
      changeIsOpen(newOpenState);
    };

    return (
      <FormControl style={style} fullWidth className={classes.formControl}>
        <div className={classes.root}>
          {((selected && sortedValues.length > 0) || !selected) && (
            <Autocomplete
              classes={{ option: classes.autocompleteOption }}
              //@ts-ignore
              size={size}
              disabled={disabled}
              //@ts-ignore
              value={selectedValue || null}
              color="secondary"
              options={sortedValues}
              getOptionLabel={(option) => option.name}
              onChange={onChange}
              loading={isLoading}
              loadingText={loadingText}
              onOpen={(event) => {
                // event.stopPropagation();
              }}
              disableClearable={true}
              // open={isOpen}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === (value?.value || value);
              }}
              onClickCapture={function (event: any) {
                //@ts-ignore
                changeAutocompleteState(disabled ? false : !isOpen);
              }}
              onClose={() => {
                changeAutocompleteState(false);
              }}
              renderOption={(props: any, option: any) => {
                const leftIcons =
                  option.icons?.filter((icon: any) => icon.position === FormAutocompleteOptionIconPosition.left) || [];
                const rightIcons =
                  option.icons?.filter((icon: any) => icon.position === FormAutocompleteOptionIconPosition.right) || [];

                return (
                  <li {...props} key={option.value}>
                    {leftIcons.map((icon: any) => icon.fragment)}
                    <div
                      style={{
                        paddingLeft: leftIcons.length > 0 ? '10px' : '0px',
                        paddingRight: rightIcons.length > 0 ? '10px' : '0px',
                      }}
                    >
                      {option.name}
                    </div>
                    {rightIcons.map((icon: any) => icon.fragment)}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  variant={variant}
                  required={required}
                  color="secondary"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  label={label}
                />
              )}
            />
          )}
          {errorMessage && (
            <Alert icon={false} variant="outlined" severity="error">
              {errorMessage}
            </Alert>
          )}
        </div>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {},
  autocompleteOption: {
    minHeight: '40px',
  },
  disabled: {
    border: '1px solid white',
  },
}));
