import { Box, Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IQuestion } from '../../../../../../../../service/unit/interface/IUnitQuiz';
import { IQuestionsQuizResult } from '../../../../../../../../service/unitResult/IUnitResultModel';
import { QuizAccordionItem } from '../components/QuizAccordionItem';

interface IPassingQuizAccordionProps {
  question: IQuestion;
  answer: IQuestionsQuizResult;
  index: number;
  isEbaleToCheck: boolean;
  isEbaleToRePass: boolean;
  navigationRedirect: (title: string) => void;
}

export const PassingQuizAccordionContent = observer(
  ({ question, answer, isEbaleToCheck, isEbaleToRePass, index, navigationRedirect }: IPassingQuizAccordionProps) => {
    const defaultAnswer = {
      answers: [],
      result: 0,
    };

    const effectiveAnswer = answer || defaultAnswer;
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '8px 16px',
            cursor: 'pointer',
          }}
          onClick={() => navigationRedirect(question.pseudoId)}
        >
          <QuizAccordionItem
            result={effectiveAnswer?.result}
            index={index}
            showResult={!isEbaleToCheck || !isEbaleToRePass}
          />
          <Typography
            variant="caption"
            sx={{
              color: 'rgba(255,255,255,0.7)',
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {question.name}
          </Typography>
        </Box>
        <Divider />
      </Box>
    );
  },
);
