export interface ILayoutError {
  id?: string;
  isShowed?: boolean;
  errorData?: any;
  message: string;
  type: ILayoutErrorType;
}

export enum ILayoutErrorType {
  server = 'server',
  internal = 'internal',
}
