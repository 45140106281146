import { Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IServiceModel } from '../../../../../../../service/common/model/IServiceModel';
import { CommonRowContextMenuComponentProperties } from '../../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { IRiskManagerDataModel } from '../../../../../service/manager/data/IRiskManagerDataModel';
import { RiskManagerDataTableContextMenuDomain } from './RiskManagerDataTableContextMenuDomain';

export interface IRiskManagerDataTableRowContextMenuProperties
  extends CommonRowContextMenuComponentProperties<IRiskManagerDataModel> {
  domain: RiskManagerDataTableContextMenuDomain;
}

export const RiskManagerDataTableRowContextMenu = observer(
  <RowType extends IServiceModel = IServiceModel>({
    domain,
    isOpened,
    event,
    row,
  }: IRiskManagerDataTableRowContextMenuProperties) => {
    useEffect(() => {
      const rowStatus = row?.isComplete;
      domain.reportDomain.setAccessToProjectReport(row?.targetId || '', domain.layoutDomain, rowStatus);
    }, [
      domain, domain.layoutDomain,
      row?.targetId,
      row?.isNeedUpdate,
      row?.isComplete,
      row?.isUpdateConflict
    ]);
    return (
      <Menu
        open={isOpened}
        onClose={() => {
          row && domain.ui.closeContextMenu(row);
        }}
        anchorReference="anchorPosition"
        //@ts-ignore

        anchorPosition={isOpened === true ? { top: event?.clientY, left: event?.clientX } : undefined}
        componentsProps={{
          root: {
            //@ts-ignore
            onContextMenu: (e) => {
              e.preventDefault();
              row && domain.ui.closeContextMenu(row);
            },
          },
        }}
      >
        {row?.isNeedUpdate && (
          <MenuItem
            onClick={() => {
              row && domain.ui.closeContextMenu(row);
              row && domain.onUpdateToLastVersionByRow(row);
            }}
          >
            Обновить
          </MenuItem>
        )}
        {domain.reportDomain.isHaveAccessAndReport.value && (
          <MenuItem
            onClick={() => {
              row && domain.ui.closeContextMenu(row);
              row && domain.downloadReportAsJSON(row);
            }}
          >
            Скачать отчёт в JSON
          </MenuItem>
        )}
      </Menu>
    );
  },
);
