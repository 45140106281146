import * as React from 'react';
import { FunctionComponent, ReactElement } from 'react';

export interface IStep {
  label: string;
  content: FunctionComponent | ReactElement;
}

export enum stepKey {
  info = 'info',
  learning = 'learning',
  specifications = 'specifications',
  tags = 'tags',
  vendor = 'vendor',
  additionalColumns = 'additionalColumns',
}

export const steps = {
  info: {
    label: 'Общее',
    content: () => <></>,
  } as IStep,
  specifications: {
    label: 'Характеристики',
    content: () => <></>,
  } as IStep,
  tags: {
    label: 'Теги',
    content: () => <></>,
  } as IStep,
  vendor: {
    label: 'Источник требования',
    content: () => <></>,
  } as IStep,
  additionalColumns: {
    label: 'Дополнительные колонки',
    content: () => <></>,
  } as IStep,
};
