import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IEntityStore } from '../../../../../common/store/interface/IEntityStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { DataTableEmptyForm } from './default/DataTableEmptyForm';
import { IDataTableFormUI } from './IDataTableFormUI';
import { DataTableFormMode } from './mode/DataTableFormMode';

export class DataTableFormUI<ModelType> implements IDataTableFormUI<ModelType> {
  public validationErrors = injectEntityList<IValidationErrorData>([]);
  public model: IEntityStore<ModelType>;
  public formComponent: IPrimitiveStore<any> = injectPrimitive(DataTableEmptyForm);
  public mode = injectPrimitive<DataTableFormMode>(DataTableFormMode.modal);
  modalContainer = injectPrimitive<any>(null);
  pageContainer = injectPrimitive<any>(null);

  constructor(private defaultValue: ModelType = {} as ModelType) {
    this.model = injectEntity<ModelType>(defaultValue);
    this.getDefaultModel();
  }

  getDefaultModel() {
    return this.defaultValue;
  }
}
