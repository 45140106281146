import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IDataGridDetailUI } from './IDataGridDetailUI';

export class DataGridDetailUI<RowType> implements IDataGridDetailUI<RowType> {
  public detailEntities = injectEntityList<RowType>([]);
  public secondDetailEntities = injectEntityList<RowType>([]);
  public isDetail = injectPrimitive<boolean>(false);
  public detailKey = injectPrimitive<string>('');
  public secondDetailKey = injectPrimitive<string>('');
  public getDetailPanelContent = injectPrimitive<any>(null);
  public isArray = injectPrimitive<boolean>(false);
  public isSecondArray = injectPrimitive<boolean>(false);
  public isMultipleDetail = injectPrimitive<boolean>(false);
}
