import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdditionalColumnEntityAdminDomain } from './store/AdditionalColumnEntityAdminDomain';

export interface IAdditionalColumnEntityAdminTable extends IAdminPageProperties { }

export const AdditionalColumnEntityAdminTable = observer(({ layoutDomain }: IAdditionalColumnEntityAdminTable) => {
  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])

  const [domain] = useState<AdditionalColumnEntityAdminDomain>(new AdditionalColumnEntityAdminDomain(layoutDomain));

  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
