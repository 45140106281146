import { inject } from '../../../../../../../../../common/container/inject';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { generateUUID } from '../../../../../../../utils/generateUUID';
import { IUnitDomain } from '../../../store/IUnitDomain';
import { IQuizDomain } from './IQuizDomain';
import { IQuizUI } from './IQuizUI';
import { QuizUI } from './QuizUI';

export class QuizDomain implements IQuizDomain {
  public ui: IQuizUI;
  public unitDomain: IUnitDomain;
  constructor(
    unitDomain: IUnitDomain,
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.ui = new QuizUI();
    this.unitDomain = unitDomain;
  }

  addQuestion = async () => {
    this.unitDomain.ui.unit.entity.quiz.questions.push({
      name: '',
      explanation: '',
      type: 'single',
      randomize: true,
      answers: [],
      sortIndex: this.unitDomain.ui.unit.entity.quiz.questions.length + 1,
      pseudoId: generateUUID(),
      isCodeExample: false,
      codeExample: [],
    });
  };

  boot = () => {
    this.ui.activeQuestion.setValue(this.unitDomain.ui.unit.entity.quiz.questions[0]);
  };

  onDelete = (index: number) => {
    if (this.unitDomain.ui.unit.entity.quiz.questions.length !== 1) {
      this.unitDomain.ui.unit.entity.quiz.questions.splice(index, 1);
      this.reindexQuestions();
      this.ui.activeQuestion.setValue(this.unitDomain.ui.unit.entity.quiz.questions[0]);
    }
  };

  reindexQuestions = () => {
    let index = 1;
    const newList = this.unitDomain.ui.unit.entity.quiz.questions.map((question) => {
      question.sortIndex = index;
      index++;
      return question;
    });
    this.unitDomain.ui.unit.entity.quiz.questions = newList;
  };
}
