import { ChevronRight, ErrorOutline, ExpandMore } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IPassingFile } from './IPassingFileTree';

export interface IPassingFileTree {
  fileTree: IPassingFile[];

  currentFile: IPassingFile;
  pickingFile: (file: IPassingFile) => void;
}

export const PassingFileTree = observer(({ fileTree, currentFile, pickingFile }: IPassingFileTree) => {
  const [expands, setExpands] = useState<string[]>([]);

  function renderTree(file: IPassingFile) {
    if (file.children?.length !== 0) {
      return (
        <TreeItem
          sx={{
            '& > div': {
              flexDirection: file.isQuestion === true ? 'row-reverse' : 'row',
            },
            '& .MuiTreeItem-label': {
              paddingLeft: file.isQuestion === true ? '23px' : '',
            },
            '& .MuiTreeItem-iconContainer': {
              marginRight: file.isQuestion === true ? '0px' : '4px',
              marginLeft: '8px',
            },
            '& .MuiTreeItem-group': {},
          }}
          endIcon={file.isQuestion && <ErrorOutline />}
          id={file.id}
          nodeId={file.id}
          label={file.name}
          onClick={() => pickingFile(file)}
        >
          {file.children?.map((file) => {
            return renderTree(file);
          })}
        </TreeItem>
      );
    } else {
      return (
        <TreeItem
          id={file.id}
          nodeId={file.id}
          label={file.name}
          onClick={() => {
            pickingFile(file);
          }}
        />
      );
    }
  }
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      defaultExpanded={expands}
      multiSelect
      sx={{
        flexGrow: 1,
        maxWidth: 400,
      }}
    >
      {fileTree !== null &&
        fileTree.map((file) => {
          expands.push(file.id);
          return renderTree(file);
        })}
      {currentFile !== null && !currentFile.hasOwnProperty('children') && (
        <Box
          onClick={(event) => {
            event.preventDefault();
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            gap: '9.5px',
            fontSize: '16px',
            color: 'rgba(25, 118, 210, 1)',
            position: 'absolute',
            bottom: 10,
            left: 15,
            zIndex: 100,
          }}
        ></Box>
      )}
    </TreeView>
  );
});
