import { action, computed, observable } from 'mobx';

import { IPrimitiveStore } from '../interface/IPrimitiveStore';
import { UIStore } from './UIStore';

export class BasePrimitiveStore<Type> extends UIStore implements IPrimitiveStore<Type> {
  @observable private valueState: Type;

  constructor(defaultValue: Type) {
    super();
    this.valueState = defaultValue;
  }

  @action.bound
  setValue(value: Type): void {
    this.valueState = value;
  }

  @computed get value() {
    return this.valueState;
  }
}
