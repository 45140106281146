import { IApplicationAffectModel } from '../../../../../../../service/application/affect/IApplicationAffectModel';
import { DataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { ApplicationAffectAdminUI } from '../ApplicationAffectAdminUI';

export class ApplicationAffectRowsUI
  extends DataTableRowsUI<IApplicationAffectModel, any>
  implements IDataTableRowsUI<IApplicationAffectModel, any>
{
  constructor(public rootUI: ApplicationAffectAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'requirementsIds') {
      return {
        dependenciesModels: this.rootUI.requirements.list,
        valueField: 'shortName',
        activeField: 'isActive',
        isArray: true,
      };
    }

    return null;
  }
}
