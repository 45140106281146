import { ProjectInfoDomain } from '../../../../../view/user/page/project/info/store/ProjectInfoDomain';
import { IAppRatingProjectInfoDomain } from './IAppRatingProjectInfoDomain';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IRatingAppModel } from '../service/IRatingAppModel';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IContainer } from '../../../../../common/container/IContainer';
import { IProjectView } from '../../../../../view/user/page/project/info/store/model/IProjectView';
import application from '../../../../../application/Application';
import { RatingAppRootService } from '../service/RatingAppRootService';

export const extendProjectInfoDomain = (
  Ref: new (...args: any[]) => ProjectInfoDomain,
): new (...args: any[]) => IAppRatingProjectInfoDomain => {
  class ExtendedProjectFormDomainRef extends Ref {
    appRatings: IEntityListStore<IRatingAppModel> = injectEntityList<IRatingAppModel>([]);

    private rootPrivateService: RatingAppRootService = new RatingAppRootService();
    constructor(layoutDomain: IMainLayoutDomainStore, container: IContainer) {
      super(layoutDomain, container);
    }

    async loadData(projectId?: string): Promise<void> {
      await super.loadData(projectId);
      const applicationIds = this.ui.projects.list.reduce((applicationIds: string[], project: IProjectView) => {
        applicationIds.push(...project.applications.map((application) => application.id));
        return applicationIds;
      }, []);
      const { data } = await this.rootPrivateService.ratingApp.search({
        filter: [
          {
            fieldName: 'applicationId',
            type: 'in',
            value: applicationIds,
          },
        ],
      });

      this.appRatings.setList(data);
    }
  }

  return ExtendedProjectFormDomainRef;
};
