import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerStaticQuestionModel } from '../../../service/question/static/IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionSearch } from '../../../service/question/static/IRiskManagerStaticQuestionSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerStaticQuestionContextMenuDomain } from './context/RiskManagerStaticQuestionContextMenuDomain';
import { RiskManagerStaticQuestionFormDomain } from './form/RiskManagerStaticQuestionFormDomain';
import { RiskManagerStaticQuestionUI } from './RiskManagerStaticQuestionUI';

export class RiskManagerStaticQuestionDomain extends DataTableDomain<
  IRiskManagerStaticQuestionModel,
  IRiskManagerStaticQuestionSearch
> {
  public ui: RiskManagerStaticQuestionUI;

  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.question.static);
    this.ui = new RiskManagerStaticQuestionUI(this, null);
    this.modalDomain = new RiskManagerStaticQuestionFormDomain(rootPrivateServices, layoutDomain, this);
    this.contextMenuDomain = new RiskManagerStaticQuestionContextMenuDomain(
      rootPrivateServices.question.static,
      this,
      layoutDomain,
    );
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
  }

  async loadDependencies() {
    // const answerPreset = await this.rootPrivateServices.question.answerPreset.search({ limit: 100000 });
    // this.ui.answerPresets.setList(answerPreset.data);
  }
}
