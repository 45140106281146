import { Grid, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormActions } from '../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../design/form/FormAutocomplete';
import { FormCheckboxField } from '../../../../../design/form/FormCheckboxField';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { UserAdminFormDomain } from './store/UserAdminFormDomain';

export interface IUserAdminFormProperties extends IAdminPageProperties {}

export const UserAdminForm = observer(({ layoutDomain }: IUserAdminFormProperties) => {
  const [domain] = useState(new UserAdminFormDomain(layoutDomain));
  const { userId = null } = useParams<any>();
  const uiEntity = domain.ui.model.entity;
  useEffect(() => {
    domain.loadData(userId);
  }, [domain, userId]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
      <Grid item xs={8}>
        <Paper elevation={3}>
          <FormTextField
            errorMessage={domain.getValidationErrorFor('displayName')?.message}
            value={uiEntity.displayName}
            onChange={domain.getUpdateFieldHandler('displayName')}
            label="ФИО"
            required={true}
          />

          <FormTextField
            errorMessage={domain.getValidationErrorFor('email')?.message}
            value={uiEntity.email}
            onChange={domain.getUpdateFieldHandler('email')}
            label="Почта"
            required={true}
          />

          <FormTextField
            errorMessage={domain.getValidationErrorFor('login')?.message}
            value={uiEntity.login}
            onChange={domain.getUpdateFieldHandler('login')}
            label="Логин"
            required={true}
          />

          <FormTextField
            type="password"
            errorMessage={domain.getValidationErrorFor('password')?.message}
            value={uiEntity.password}
            onChange={domain.getUpdateFieldHandler('password')}
            label="Пароль"
            required={true}
          />

          <FormTextField
            type="password"
            errorMessage={domain.getValidationErrorFor('confirmPassword')?.message}
            value={uiEntity.confirmPassword}
            onChange={domain.getUpdateFieldHandler('confirmPassword')}
            label="Подтверждение пароля"
            required={true}
          />

          <FormAutocomplete
            values={domain.roles.list}
            onChangeSelect={(newValue: any) => {
              const handler = domain.getUpdateFieldHandler('systemRoleId');
              handler(newValue.value);
            }}
            key="role"
            errorMessage={domain.getValidationErrorFor('systemRoleId')?.message}
            selected={uiEntity.systemRoleId}
            label="Роль"
            required={true}
          />
          <FormCheckboxField
            value={uiEntity.isActive}
            defaultValue={true}
            onChange={domain.getUpdateFieldHandler('isActive')}
            label="Активен"
          />
        </Paper>

        <FormActions onSave={domain.save} onCancel={domain.cancelEdit} />
      </Grid>
    </Grid>
  );
});
