import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IEntityStore } from '../../../../../common/store/interface/IEntityStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { DataTableEmptyDrawer } from './default/DataTableEmptyDrawer';
import { IDataTableDrawerUI } from './IDataTableDrawerUI';

export class DataTableDrawerUI<ModelType> implements IDataTableDrawerUI<ModelType> {
  public validationErrors = injectEntityList<IValidationErrorData>([]);
  public model: IEntityStore<ModelType>;
  public drawerComponent: IPrimitiveStore<any> = injectPrimitive(DataTableEmptyDrawer);
  drawerContainer = injectPrimitive<any>(null);
  pageContainer = injectPrimitive<any>(null);
  public isEditMode = injectPrimitive(false)

  constructor(private defaultValue: ModelType = {} as ModelType) {
    this.model = injectEntity<ModelType>(defaultValue);
    this.getDefaultModel();
  }

  getDefaultModel() {
    return this.defaultValue;
  }
}
