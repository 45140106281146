import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IProjectModel } from '../../../../../../../service/project/IProjectModel';
import { DataTableRowsUI } from '../../../../../../../view/design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { IRiskManagerDataModel } from '../../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataSearch } from '../../../../../service/manager/data/IRiskManagerDataSearch';
import { IRiskManagerRiskModel } from '../../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerDataTableUI } from '../RiskManagerDataTableUI';

export class RiskManagerDataTableRowsUI
  extends DataTableRowsUI<IRiskManagerDataModel, IRiskManagerDataSearch>
  implements IDataTableRowsUI<IRiskManagerDataModel, IRiskManagerDataSearch>
{
  constructor(
    public rootUI: RiskManagerDataTableUI,
    public risks: IEntityListStore<IRiskManagerRiskModel> = injectEntityList([]),
    public projects: IEntityListStore<IProjectModel> = injectEntityList([]),
  ) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'targetId') {
      return {
        dependenciesModels: this.projects.list,
        valueField: 'name',
        activeField: null,
        isArray: false,
      };
    }
    return null;
  }
}
