import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FilterTitle } from '../../../../view/common/requirement/filter/parts/FilterTitle';
import { useRequirementFilterStyle } from '../../../../view/common/requirement/filter/parts/style';
import { CommonFilterDomain } from '../../../../view/common/requirement/filter/store/CommonFilterDomain';
import { RiskRequirementFilterDomain } from './RiskRequirementFilterDomain';

interface RiskFilterProperties {
  rootDomain: CommonFilterDomain;
}
export const RiskFilter = observer(({ rootDomain }: RiskFilterProperties) => {
  const { applicationId = '' } = useParams<any>();
  const [domain] = useState(new RiskRequirementFilterDomain(rootDomain));
  useEffect(() => {
    if (!applicationId) {
      domain.isShow.setValue(false);
    }
    domain.loadData(applicationId);
  }, []);
  const classes = useRequirementFilterStyle();
  return domain.isShow.value ? (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
        <FilterTitle selectedCount={domain.searchRiskIds.value.length} title={'Уровни критичности требований'} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {domain.risks.list.map((risk) => {
                  const isChecked = domain.searchRiskIds.value.includes(risk.id || '');
                  // console.log(isChecked, risk.id);
                  return (
                    <Risk
                      key={risk.id}
                      risk={risk}
                      setNewValue={(id: string, isChecked: boolean) => {
                        isChecked
                          ? domain.searchRiskIds.value.push(id)
                          : //@ts-ignore
                            domain.searchRiskIds.value.remove(id);
                        domain.rootDomain.triggerSearch();
                      }}
                      isChecked={isChecked}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : (
    <Fragment />
  );
});

interface RiskProperties {
  setNewValue: (id: string, checked: boolean) => void;
  risk: any;
  isChecked: boolean;
}

const Risk = observer(({ risk, isChecked, setNewValue }: RiskProperties) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          name={risk.name}
          sx={{
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
      }
      onChange={(event: any) => {
        setNewValue(risk.id || '', event.target.checked);
      }}
      label={risk.name}
    />
  );
});
