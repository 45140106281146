import { IError } from '../../../../../common/error/IError';
import { DomainStore } from '../../../../../common/store/base/DomainStore';
import { LayoutNotificationType } from '../../notification/store/ILayoutNotification';
import { ILayoutNotificationDomain } from '../../notification/store/ILayoutNotificationDomain';
import { ILayoutError, ILayoutErrorType } from './ILayoutError';
import { ILayoutErrorDomain } from './ILayoutErrorDomain';
import { ILayoutErrorUI } from './ILayoutErrorUI';
import { LayoutErrorUI } from './LayoutErrorUI';

export class LayoutErrorDomain extends DomainStore implements ILayoutErrorDomain {
  constructor(private notifications: ILayoutNotificationDomain, private uiStore = new LayoutErrorUI()) {
    super();
  }

  handleError(error: IError | any): void {
    if (error) {
      this.handleErrorWithValue(error);
    } else {
      this.handleEmptyWithValue();
    }
  }

  private handleErrorWithValue(error: IError | any) {
    if (error.systemCode || error.webCode) {
      this.handleFormatedError(error as IError);
    } else {
      this.handleEmptyWithValue();
    }
  }

  private handleFormatedError(error: IError) {
    this.addUIError({
      message: 'Произошла ошибка на сервере',
      type: ILayoutErrorType.internal,
    });
    this.notifications.showNotification({
      text: 'Произошла ошибка на сервере',
      type: LayoutNotificationType.error,
    });
  }

  private handleEmptyWithValue() {
    this.uiStore.addError({
      message: 'Неизвестная ошибка',
      type: ILayoutErrorType.internal,
      errorData: {},
    });
    this.notifications.showNotification({
      text: 'Неизвестная ошибка',
      type: LayoutNotificationType.error,
    });
  }

  addUIError(newError: ILayoutError): void {
    this.uiStore.addError(newError);
  }

  get ui(): ILayoutErrorUI {
    return this.uiStore;
  }
}
