import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { Colors } from '../../../../../../view/design/color/Colors';
import { FormModalContainer } from '../../../../../../view/design/dataTable/form/view/FormModalContainer';
import { MultiLineText } from '../../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { FormActions } from '../../../../../../view/design/form/FormActions';
import {
  FormAutocomplete,
  FormAutocompleteOptionIconPosition,
} from '../../../../../../view/design/form/FormAutocomplete';
import {
  AutocompleteSelectItemType,
  FormMultiAutocomplete,
} from '../../../../../../view/design/form/FormMultiAutocomplete';
import { FormTextField } from '../../../../../../view/design/form/FormTextField';
import { FormTextLabel } from '../../../../../../view/design/form/FormTextLabel';
import { IRiskManagerRiskLevelSettingsItem } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { RiskColorIcon } from '../../../color/RiskColorIcon';
import { RiskColorsMap } from '../../../color/RiskColorsMap';
import { RiskManagerTemplateFormDomain } from './RiskManagerTemplateFormDomain';

export interface IRiskManagerTemplateFormProperties {
  domain: RiskManagerTemplateFormDomain;
}

const sortByIndex = (current: any, next: any) => (current.sortIndex < next.sortIndex ? -1 : 1);

export const RiskManagerTemplateForm = observer(({ domain }: IRiskManagerTemplateFormProperties) => {
  const classes = useStyles();
  const selectedQuestions =
    domain.dataTableDomain.ui.rows.questions.list
      .sort(sortByIndex)
      .filter((question) => domain.ui.model.entity.questionsIds?.includes(question.id || '')) || [];
  const selectedRisks =
    domain.dataTableDomain.ui.rows.risks.list
      .sort(sortByIndex)
      .filter((risk) => domain.ui.model.entity.risksIds?.includes(risk.id || '')) || [];
  return (
    <FormModalContainer onClose={domain.onCancelUpdates}>
      <Grid style={{ width: '98%' }} spacing={2} container direction="column">
        <Grid item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Настройки шаблона</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3}>
                <NameField domain={domain} />
                <DescriptionField domain={domain} />
                <IsDefaultField domain={domain} />
                <RisksField domain={domain} />
                <QuestionsField domain={domain} />
                <StaticQuestionsField domain={domain} />
                <QuestionsFieldsField domain={domain} />
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ width: '100%' }} item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Настройки уровней риска</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3}>
                <FormTextLabel variant={'h5'} text={'Уровни рисков: '} />
                <Divider light={true} variant={'fullWidth'} />
                {domain.ui.model.entity.risksIds?.length === 0 && (
                  <Fragment>
                    <FormTextLabel variant={'body1'} text={'Нужно выбрать риски'} />
                  </Fragment>
                )}
                {selectedRisks.length > 0 &&
                  selectedRisks.map((risk) => {
                    const riskLevels = domain.getRiskLevelsForRisk(risk.id || '').sort(sortByIndex);
                    return (
                      <Fragment>
                        <Grid
                          className={classes.answerTitleContainer}
                          justifyContent={'space-between'}
                          container
                          flexDirection="row"
                          alignItems={'end'}
                        >
                          <Grid item>
                            <FormTextLabel variant={'body1'} text={`Риск: ${risk?.name}`} />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              // className={classes.actionButton}
                              onClick={() => {
                                domain.addRiskLevel(risk.id || '');
                              }}
                              // disabled={disabled}
                            >
                              Добавить уровень риска
                            </Button>
                          </Grid>
                        </Grid>
                        {riskLevels.map((riskLevel, index) => {
                          return (
                            <Grid key={riskLevel.pseudoId || index} container flexDirection={'row'}>
                              <Grid item>
                                <RiskLevelNameField domain={domain} index={index} riskLevel={riskLevel} />
                              </Grid>
                              <Grid item>
                                <div style={{ width: 200 }}>
                                  <FormAutocomplete
                                    label={'Цвет'}
                                    onChangeSelect={(value) => {
                                      riskLevel.color = value?.value || null;
                                    }}
                                    selected={riskLevel.color}
                                    values={RiskColorsMap.map((item) => ({
                                      value: item.color,
                                      name: item.name,
                                      icons: [
                                        {
                                          position: FormAutocompleteOptionIconPosition.left,
                                          fragment: <RiskColorIcon color={item.color} />,
                                        },
                                      ],
                                    }))}
                                  />
                                </div>
                                {/* <FormBox>
                                  <ColorPicker defaultValue={'red'} />
                                </FormBox> */}
                              </Grid>
                              <Grid item>
                                <RiskLevelMinField domain={domain} index={index} riskLevel={riskLevel} />
                              </Grid>
                              <Grid item>
                                <RiskLevelMaxField domain={domain} index={index} riskLevel={riskLevel} />
                              </Grid>

                              <Grid item>
                                <RiskLevelDescriptionField domain={domain} index={index} riskLevel={riskLevel} />
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    domain.removeRiskLevel(riskLevel.pseudoId || '');
                                  }}
                                  style={{ color: Colors.negativePassive, margin: '15px' }}
                                >
                                  Удалить уровень риска
                                </Button>
                              </Grid>
                              <Divider light={true} variant={'fullWidth'} />
                            </Grid>
                          );
                        })}

                        <Divider light={true} variant={'fullWidth'} />
                      </Fragment>
                    );
                  })}
                <Toolbar />
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid style={{ width: '100%' }} item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Настройки уровней критичности для требований</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3}>
                <FormTextLabel variant={'h5'} text={'Уровни критичности для требований: '} />
                <Divider light={true} variant={'fullWidth'} />
                {domain.ui.model.entity.risksIds?.length === 0 && (
                  <Fragment>
                    <FormTextLabel variant={'body1'} text={'Нужно выбрать риски'} />
                  </Fragment>
                )}
                {selectedRisks.length > 0 &&
                  selectedRisks.map((risk) => {
                    const riskLevels = domain.getRiskRequirementThreatsLevelsForRisk(risk.id || '').sort(sortByIndex);
                    return (
                      <Fragment>
                        <Grid
                          className={classes.answerTitleContainer}
                          justifyContent={'space-between'}
                          container
                          flexDirection="row"
                          alignItems={'end'}
                        >
                          <Grid item>
                            <FormTextLabel variant={'body1'} text={`Риск: ${risk?.name}`} />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="outlined"
                              color="secondary"
                              // className={classes.actionButton}
                              onClick={() => {
                                domain.addRiskRequirementThreatLevel(risk.id || '');
                              }}
                              // disabled={disabled}
                            >
                              Добавить уровень критичности
                            </Button>
                          </Grid>
                        </Grid>
                        {riskLevels.map((riskRequirementThreatLevel, index) => {
                          return (
                            <Grid key={riskRequirementThreatLevel.pseudoId || index} container flexDirection={'row'}>
                              <Grid item>
                                <RiskLevelNameField
                                  domain={domain}
                                  index={index}
                                  riskLevel={riskRequirementThreatLevel}
                                />
                              </Grid>
                              <Grid item>
                                <div style={{ width: 200 }}>
                                  <FormAutocomplete
                                    label={'Цвет'}
                                    onChangeSelect={(value) => {
                                      riskRequirementThreatLevel.color = value?.value || null;
                                    }}
                                    selected={riskRequirementThreatLevel.color}
                                    values={RiskColorsMap.map((item) => ({
                                      value: item.color,
                                      name: item.name,
                                      icons: [
                                        {
                                          position: FormAutocompleteOptionIconPosition.left,
                                          fragment: <RiskColorIcon color={item.color} />,
                                        },
                                      ],
                                    }))}
                                  />
                                </div>
                                {/* <FormBox>
                                  <ColorPicker defaultValue={'red'} />
                                </FormBox> */}
                              </Grid>
                              <Grid item>
                                <RiskLevelMinField
                                  domain={domain}
                                  index={index}
                                  riskLevel={riskRequirementThreatLevel}
                                />
                              </Grid>
                              <Grid item>
                                <RiskLevelMaxField
                                  domain={domain}
                                  index={index}
                                  riskLevel={riskRequirementThreatLevel}
                                />
                              </Grid>

                              <Grid item>
                                <RiskLevelDescriptionField
                                  domain={domain}
                                  index={index}
                                  riskLevel={riskRequirementThreatLevel}
                                />
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    domain.removeRequirementThreatLevel(riskRequirementThreatLevel.pseudoId || '');
                                  }}
                                  style={{ color: Colors.negativePassive, margin: '15px' }}
                                >
                                  Удалить уровень критичности
                                </Button>
                              </Grid>
                              <Divider light={true} variant={'fullWidth'} />
                            </Grid>
                          );
                        })}

                        <Divider light={true} variant={'fullWidth'} />
                      </Fragment>
                    );
                  })}
                <Toolbar />
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid style={{ width: '100%' }} item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Настройки значений</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={3} style={{ width: '100%' }}>
                <FormTextLabel variant={'h5'} text={'Настройка значений: '} />
                <Divider light={true} variant={'fullWidth'} />

                <Fragment>
                  {(domain.ui.model.entity.risksIds?.length === 0 ||
                    domain.ui.model.entity.questionsIds?.length === 0) && (
                    <FormTextLabel variant={'body1'} text={'Нужно выбрать риски и вопросы'} />
                  )}
                </Fragment>

                {selectedQuestions.map((question) => {
                  if (question) {
                    return (
                      <Grid flexDirection="row" container style={{ margin: '2%', width: '100%' }}>
                        <Grid item flex="0.2" style={{ paddingTop: '24px' }}>
                          <MultiLineText words={''} value={`Вопрос: ${question?.text}`} />
                        </Grid>

                        <Grid flex="0.8" item>
                          <Grid container flexDirection="row" alignItems="flex-end">
                            {selectedRisks.map((risk) => {
                              if (risk) {
                                return (
                                  <Grid style={{ width: '18%' }} item>
                                    <Grid container justifyContent="flex-end" flexDirection={'column'}>
                                      <Grid item>
                                        <FormTextLabel variant={'body1'} text={`Риск: ${risk.name}`} />
                                      </Grid>
                                      {question.answersMap.answers.sort(sortByIndex).map((answer) => {
                                        domain.getOrCreateAnswerDataByRisk(question.id || '', risk.id || '', answer);
                                        return (
                                          <AnswerDataItemField
                                            answer={answer}
                                            domain={domain}
                                            questionId={question.id || ''}
                                            riskId={risk.id}
                                          />
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                );
                              } else {
                                return <div />;
                              }
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return <div />;
                  }
                })}
                <Toolbar />
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Toolbar />
      </Grid>

      <FormActions onSave={() => domain.onSaveUpdates()} onCancel={() => domain.onCancelUpdates()} />
    </FormModalContainer>
  );
});

const AnswerDataItemField = observer(({ answer, domain, questionId, riskId }: any) => {
  const dataIndex = domain.ui.model.entity.answersValueToRiskData.valueData.findIndex(
    (item) => item.questionId === questionId && item.riskId === riskId && item.answerId === answer.pseudoId,
  );
  const data = domain.ui.model.entity.answersValueToRiskData.valueData[dataIndex];
  return (
    <Grid item>
      <FormTextLabel variant={'body1'} text={`Ответ: ${answer.text}`} />
      <FormTextField
        inputProps={{ style: { color: data.isDefault ? Colors.negativeActiveOpacity('0.5') : 'white' } }}
        style={{ borderColor: 'red' }}
        required={true}
        multiline={false}
        label={'Значение'}
        value={data.value}
        onChange={(newValue) => {
          const dataIndex = domain.ui.model.entity.answersValueToRiskData.valueData.findIndex(
            (item) => item.questionId === questionId && item.riskId === riskId && item.answerId === answer.pseudoId,
          );
          if (dataIndex !== -1) {
            domain.ui.model.entity.answersValueToRiskData.valueData[dataIndex].value = newValue;
            domain.ui.model.entity.answersValueToRiskData.valueData[dataIndex].isDefault = false;
          }
        }}
      />
    </Grid>
  );
});

const NameField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={false}
      label={'Название'}
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const DescriptionField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  return (
    <FormTextField
      required={true}
      multiline={true}
      rows={4}
      label={'Описание'}
      value={domain.ui.model.entity.description}
      onChange={domain.getUpdateFieldHandler('description')}
      errorMessage={domain.getValidationErrorFor('description')?.message}
    />
  );
});

const RisksField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  const risksItems = [
    { value: 'all', name: 'Выбрать все', types: [AutocompleteSelectItemType.noSelection] },
    ...domain.dataTableDomain.ui.rows.risks.list.sort(sortByIndex).map((risk) => ({
      value: risk.id,
      name: risk.name,
    })),
  ];

  return (
    <FormMultiAutocomplete
      values={risksItems}
      onChangeSelect={(newValue: any) => {
        if (newValue.find((item) => item.value === 'all')) {
          domain.ui.model.entity.risksIds =
            domain.dataTableDomain.ui.rows.risks.list.map((item) => item.id || '') || [];
        } else {
          domain.ui.model.entity.risksIds = newValue.map((value: any) => value.value);
        }
        domain.resyncAnswerDataValues();
      }}
      key="riskIds"
      selected={domain.ui.model.entity.risksIds}
      label="Риски"
      disabled={false}
    />
  );
});

const QuestionsField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  const items = [
    { value: 'all', name: 'Выбрать все', types: [AutocompleteSelectItemType.noSelection] },
    ...domain.dataTableDomain.ui.rows.questions.list.sort(sortByIndex).map((item) => ({
      value: item.id,
      name: item.text, //.length <= 50 ? item.text : `${item.text.substr(0, 50)}...`,
    })),
  ];

  return (
    <FormMultiAutocomplete
      values={items}
      onChangeSelect={(newValue: any) => {
        if (newValue.find((item) => item.value === 'all')) {
          domain.ui.model.entity.questionsIds =
            domain.dataTableDomain.ui.rows.questions.list.map((item) => item.id || '') || [];
        } else {
          domain.ui.model.entity.questionsIds = newValue.map((value: any) => value.value);
        }
        domain.resyncAnswerDataValues();
      }}
      key="questionsIds"
      selected={domain.ui.model.entity.questionsIds}
      label="Вопросы"
      disabled={false}
    />
  );
});

const StaticQuestionsField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  const items = [
    { value: 'all', name: 'Выбрать все', types: [AutocompleteSelectItemType.noSelection] },
    ...domain.dataTableDomain.ui.rows.staticQuestions.list.sort(sortByIndex).map((item) => ({
      value: item.id,
      name: item.name.length > 30 ? item.name : `${item.name.substr(0, 30)}`,
    })),
  ];

  return (
    <FormMultiAutocomplete
      values={items}
      onChangeSelect={(newValue: any) => {
        if (newValue.find((item) => item.value === 'all')) {
          domain.ui.model.entity.staticQuestionsIds =
            domain.dataTableDomain.ui.rows.staticQuestions.list.map((item) => item.id || '') || [];
        } else {
          domain.ui.model.entity.staticQuestionsIds = newValue.map((value: any) => value.value);
        }
      }}
      key="staticQuestionsIds"
      selected={domain.ui.model.entity.staticQuestionsIds}
      label="Статичные Вопросы"
      disabled={false}
    />
  );
});

const QuestionsFieldsField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  const items = [
    { value: 'all', name: 'Выбрать все', types: [AutocompleteSelectItemType.noSelection] },
    ...domain.dataTableDomain.ui.rows.questionFields.list.sort(sortByIndex).map((item) => ({
      value: item.id,
      name: item.name.length > 30 ? item.name : `${item.name.substr(0, 30)}`,
    })),
  ];

  return (
    <FormMultiAutocomplete
      values={items}
      onChangeSelect={(newValue: any) => {
        if (newValue.find((item) => item.value === 'all')) {
          domain.ui.model.entity.questionFieldsIds =
            domain.dataTableDomain.ui.rows.questionFields.list.map((item) => item.id || '') || [];
        } else {
          domain.ui.model.entity.questionFieldsIds = newValue.map((value: any) => value.value);
        }
      }}
      key="questionFieldIds"
      selected={domain.ui.model.entity.questionFieldsIds}
      label="Дополнительные Поля"
      disabled={false}
    />
  );
});

const RiskLevelNameField = observer(
  ({
    domain,
    riskLevel,
    index,
  }: {
    domain: RiskManagerTemplateFormDomain;
    riskLevel: IRiskManagerRiskLevelSettingsItem;
    index: number;
  }) => {
    return (
      <FormTextField
        required={true}
        label={'Название'}
        value={riskLevel.name}
        onChange={(newValue: any) => {
          riskLevel.name = newValue;
        }}
        // errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const RiskLevelDescriptionField = observer(
  ({
    domain,
    riskLevel,
    index,
  }: {
    domain: RiskManagerTemplateFormDomain;
    riskLevel: IRiskManagerRiskLevelSettingsItem;
    index: number;
  }) => {
    return (
      <FormTextField
        required={false}
        multiline={true}
        rows={2}
        label={'Описание'}
        value={riskLevel.description}
        onChange={(newValue: any) => {
          riskLevel.description = newValue;
        }}
        // errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const RiskLevelMinField = observer(
  ({
    domain,
    riskLevel,
    index,
  }: {
    domain: RiskManagerTemplateFormDomain;
    riskLevel: IRiskManagerRiskLevelSettingsItem;
    index: number;
  }) => {
    return (
      <FormTextField
        required={true}
        type={'number'}
        label={'Минимальное значение'}
        value={riskLevel.minValue?.toString()}
        onChange={domain.getUpdateIntegerFieldHandlerForRiskLevel(riskLevel, 'minValue')}
        // errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const RiskLevelMaxField = observer(
  ({
    domain,
    riskLevel,
    index,
  }: {
    domain: RiskManagerTemplateFormDomain;
    riskLevel: IRiskManagerRiskLevelSettingsItem;
    index: number;
  }) => {
    return (
      <FormTextField
        required={true}
        type={'number'}
        label={'Максимальное значение'}
        value={riskLevel.maxValue?.toString()}
        onChange={domain.getUpdateIntegerFieldHandlerForRiskLevel(riskLevel, 'maxValue')}
        // errorMessage={domain.ui.getAnswerError(index, 'text')}
      />
    );
  },
);

const IsDefaultField = observer(({ domain }: { domain: RiskManagerTemplateFormDomain }) => {
  const [isChecked, setChecked] = useState(domain.ui.model.entity.isDefault);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isDefault = event.target.checked;
  };
  useEffect(() => {
    setChecked(domain.ui.model.entity.isDefault);
  }, [domain.ui.model.entity.isDefault]);
  return (
    <FormControlLabel
      style={{ width: '100%', padding: '16px', margin: 0 }}
      control={<Switch color={'success'} checked={isChecked} onChange={handleChange} />}
      label="Поумолчанию"
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  answerTitleContainer: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  answerContainer: {
    // padding: theme.spacing(2),
    height: '500px',
    width: '100%',
    // overflow: 'scroll',
    overflow: 'scroll',
    overflowY: 'scroll',
  },
}));
