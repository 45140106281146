import { RestCRUDService } from '../../../../service/common/rest/RestCRUDService';
import { IRiskManagerRequirementThreatLinkModel } from './IRiskManagerRequirementThreatLinkModel';
import { IRiskManagerRequirementThreatLinkSearch } from './IRiskManagerRequirementThreatLinkSearch';
import { IRiskManagerRequirementThreatLinkService } from './IRiskManagerRequirementThreatLinkService';

export class RestRiskManagerRequirementThreatLinkService
  extends RestCRUDService<IRiskManagerRequirementThreatLinkModel, IRiskManagerRequirementThreatLinkSearch>
  implements IRiskManagerRequirementThreatLinkService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/requirement/threat/link`;
  }
}
