import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { DataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/DataGridDetailUI';
import { IDataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/IDataGridDetailUI';
import { RequirementCategoryAdminDetailComponent } from './RequirementCategoryAdminDetailComponent';

export class RequirementCategoryAdminDetailUI
  extends DataGridDetailUI<IRequirementCategoryModel>
  implements IDataGridDetailUI<IRequirementCategoryModel>
{
  constructor() {
    super();
    this.getDetailPanelContent.setValue(RequirementCategoryAdminDetailComponent);
  }
}
