import { IContainer } from './container/IContainer';
import { setValueInjection } from './container/setValueInjection';
import { BaseEntityListStore } from './store/base/BaseEntityListStore';
import { BaseEntityStore } from './store/base/BaseEntityStore';
import { BasePrimitiveStore } from './store/base/BasePrimitiveStore';
import { EntityListStoreToken } from './store/interface/IEntityListStore';
import { EntityStoreToken } from './store/interface/IEntityStore';
import { PrimitiveStoreToken } from './store/interface/IPrimitiveStore';

export function commonContainer(container: IContainer): IContainer {
  setValueInjection(container, PrimitiveStoreToken, (defaultValue: any) => {
    return new BasePrimitiveStore(defaultValue);
  });
  setValueInjection(container, EntityStoreToken, (defaultValue: any) => {
    return new BaseEntityStore(defaultValue);
  });
  setValueInjection(container, EntityListStoreToken, (defaultValue: any) => {
    return new BaseEntityListStore(defaultValue);
  });

  return container;
}
