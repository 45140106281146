import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Theme, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { SyncAlt } from '@mui/icons-material';
import { useParams } from 'react-router';

import { ContainerContext } from '../../../../../../common/container/ContainerContext';
import { ICommonProperties } from '../../../../../../common/properties/ICommonProperties';
import { FormAutocomplete } from '../../../../../design/form/FormAutocomplete';
import { FormMultiAutocomplete } from '../../../../../design/form/FormMultiAutocomplete';
import { FormTextLabel } from '../../../../../design/form/FormTextLabel';
import { FormTitleText } from '../../../../../design/form/FormTitleText';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { FormTextArea } from '../../../../../design/form/FormTextArea';
import { ChipListInput } from '../../../../../design/input/chipListInput/ChipListInput';
import { Colors } from '../../../../../design/color/Colors';
import { IJiraIssueFieldType } from '../../../../../../service/application/jira/IJiraIssueFieldsData';
import { JiraApplicationFieldDataValueType } from '../../../../../../service/taskManager/IJiraTaskManager';
import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { IAdminPageProperties } from '../../../IAdminPageProperties';
import { FormIcon } from '../../../../../design/form/FormIcon';
import { TaskManagerServerListAdminDomain } from './store/TaskManagerTemplateDomain';
import { ProjectFormBottomExtensions } from '../../../../../user/page/project/form/parts/ProjectFormBottomExtensions';
import { IPageWithLayoutProperties } from '../../../../../layout/page/IPageWithLayoutProperties';


export interface ITaskManagerServerListDomain extends IAdminPageProperties {
  domain: TaskManagerServerListAdminDomain;
}

export interface ITaskManagerServerDomain extends ICommonProperties {
  domain: TaskManagerServerListAdminDomain;
}

export interface RiskManagerDataFormProperties extends IPageWithLayoutProperties {
  projectFormDomain: TaskManagerServerListAdminDomain;
}

export const TemplateJiraRiskContainer = (props: RiskManagerDataFormProperties) => {
  return (<></>);
}

export const ConnectionTempalte = observer((props: ITaskManagerServerListDomain) => {
  const [domain] = useState(new TaskManagerServerListAdminDomain(props.layoutDomain));
  const container = useContext(ContainerContext);
  const { entityId = null } = useParams<any>();
  useEffect(() => {
    domain.boot(entityId);
  }, [domain, entityId])

  useEffect(() => {
    setJiraProjectId(domain?.taskManager.entity?.connectionTemplate.settings?.value?.projectId)
  }, [domain?.taskManager.entity?.connectionTemplate.settings?.value?.projectId])

  const RiskExtend = useContext(ContainerContext).get(TemplateJiraRiskContainer);
  const JiraPrioritiesAutocompleteComponent = useContext(ContainerContext).get(JiraTemplatePrioritiesAutocomplete);
  const [jiraProjectId, setJiraProjectId] = useState(domain?.taskManager.entity?.connectionTemplate.settings?.value?.projectId)
  const jiraIssueTypeId = domain?.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;
  const jiraAllowedStatuses = domain?.taskManager.entity?.connectionTemplate.settings?.value?.allowedInternalStatusesIds;
  let jiraNamePrefix = ConfigManager.getConfig().defaultValues?.jiraIssuePrefix || '';
  if (jiraNamePrefix.length > 0) {
    jiraNamePrefix = `${jiraNamePrefix} `;
  }

  return (
    <div style={{ maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid item xs={12}>
        <Grid container direction={'column'}>
          <Grid item>
            <Typography style={{ paddingLeft: '15px' }} variant='h4'>Шаблон заполнения данных в Jira</Typography>
          </Grid>
          <Grid item>
          </Grid>
          {domain.taskManager.entity?.id && (
            <Grid item>
              <JiraProjectAutocomplete domain={domain} />
            </Grid>
          )}
          {jiraProjectId && (
            <Grid item>
              <JiraIssueTypeAutocomplete domain={domain} />
            </Grid>
          )}
          {jiraProjectId && (
            <Grid item>
              <RiskExtend projectFormDomain={domain} layoutDomain={props.layoutDomain} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && (
            <Grid item>
              <FormTextLabel text={`Формат названия задачи: '${jiraNamePrefix}Короткое Название'`} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && (
            <Grid item>
              <JiraPrioritiesAutocompleteComponent domain={domain} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && (
            <Grid item>
              <JiraInternalStatusesToJiraSyncAutocomplete domain={domain} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && Number(jiraAllowedStatuses?.length) > 0 && (
            <Grid item>
              <JiraStatusesAutocomplete domain={domain} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && (
            <Grid item>
              <JiraIssuesFieldsAutocomplete domain={domain} />
            </Grid>
          )}
          {jiraProjectId && jiraIssueTypeId && (
            <Grid item>
              <JiraDeletedTaskStatusesAutocomplete domain={domain} />
            </Grid>
          )}
          <Grid>
            <div style={{ display: 'flex', width: '275px', justifyContent: 'space-between', paddingLeft: '15px', alignItems: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                style={{ height: '35px' }}
                onClick={() => {
                  domain.cancel();
                }}
              >
                Отменить
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => {
                  domain.saveTemplate();
                }}
              >
                Сохранить
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>)
});

export const JiraIssuesFieldsAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const issueTypeId = domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;
  const classes = useStyles();
  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraIssuesFields();
        domain.jiraIssuesFields.value.fields
          .filter(
            (item) =>
              item.type !== IJiraIssueFieldType.notAllowed ||
              (item.type === IJiraIssueFieldType.notAllowed && item.required),
          )
          .forEach((field) => {
            const fieldData = domain.taskManager.entity?.connectionTemplate.settings?.value?.fields?.find(
              (item) => item.fieldKey === field.key,
            );
            if (!fieldData) {
              domain.taskManager.entity?.connectionTemplate.settings?.value?.fields?.push({
                fieldKey: field?.key,
                fieldType: field?.type || IJiraIssueFieldType.notAllowed,
                isRequired: field?.required || false,
                fieldValueSettings: { type: JiraApplicationFieldDataValueType.noAction, settings: {} },
              });
            } else {
              if (!fieldData.fieldType) {
                fieldData.fieldType = field?.type || IJiraIssueFieldType.notAllowed;
              }
            }
          });
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  return (
    <Grid container direction={'column'}>
      {domain.jiraIssuesFields.value.fields?.filter((item) => item.type !== IJiraIssueFieldType.notAllowed).length >
        0 && (
          <Grid item>
            <FormTitleText text={'Jira поля'} />
          </Grid>
        )}
      {domain.jiraIssuesFields.value.fields.map((field) => {
        const taskManagerFieldData = domain.taskManager.entity?.connectionTemplate.settings?.value?.fields?.find((item) => {
          return item.fieldKey === field.key;
        });
        if (taskManagerFieldData) {
          taskManagerFieldData.isRequired = field.required;
        }

        const error = domain.jiraValidationErrors?.list?.find((item) => {
          return item.target === `field-${field.key}`;
        });
        return (
          <Grid item style={{ width: '98%' }}>
            <Grid xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
              {field.type !== IJiraIssueFieldType.notAllowed && (
                <Grid xs={2} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={error && Colors.negativePassive}
                    text={field.required ? `${field.name}*` : field.name}
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.notAllowed && field.required && (
                <Grid xs={2} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={Colors.negativePassive}
                    text={field.required ? `${field.name}*` : field.name}
                  />
                </Grid>
              )}
              {field.type !== IJiraIssueFieldType.notAllowed && (
                <Grid xs={1} item className={classes.formControl}>
                  <FormIcon>
                    <ArrowRightIcon
                      color={
                        taskManagerFieldData?.fieldValueSettings?.type !== JiraApplicationFieldDataValueType.noAction
                          ? 'secondary'
                          : 'disabled'
                      }
                    />
                  </FormIcon>
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.notAllowed && field.required && (
                <Grid xs={4} className={classes.formControl} item>
                  <FormTextLabel
                    textColor={Colors.negativePassive}
                    text={' Поле обязательно для заполнения и не поддерживается интеграцией'}
                  />
                </Grid>
              )}

              {field.type === IJiraIssueFieldType.singleRowString && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                      { value: JiraApplicationFieldDataValueType.requirementShortName, name: 'Название требования' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.multiRowString && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                      { value: JiraApplicationFieldDataValueType.requirementShortName, name: 'Название требования' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {field.type === IJiraIssueFieldType.stringArray && (
                <Grid xs={2} item style={{ paddingTop: '15px' }}>
                  <FormAutocomplete
                    values={[
                      { value: JiraApplicationFieldDataValueType.noAction, name: 'Не использовать' },
                      { value: JiraApplicationFieldDataValueType.defaultFieldBehavior, name: 'По умолчанию' },
                    ]}
                    onChangeSelect={(newValue: any) => {
                      if (taskManagerFieldData) {
                        taskManagerFieldData.fieldValueSettings.type = newValue.value;
                        if (newValue.value === JiraApplicationFieldDataValueType.noAction) {
                          taskManagerFieldData.fieldValueSettings.settings = {};
                        }
                      }
                    }}
                    key={`change-field-value-settings-${field.key}`}
                    selected={taskManagerFieldData?.fieldValueSettings?.type}
                    label="Тип интеграции поля"
                  />
                </Grid>
              )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior && (
                  <Grid item xs={1} className={classes.formControl}>
                    <FormIcon>
                      <ArrowRightIcon
                        color={
                          (taskManagerFieldData?.fieldValueSettings?.type as unknown) !==
                            JiraApplicationFieldDataValueType.noAction
                            ? 'secondary'
                            : 'disabled'
                        }
                      />
                    </FormIcon>
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.singleRowString && (
                  <Grid item xs={6}>
                    <FormTextField
                      onChange={(value) => {
                        if (taskManagerFieldData) {
                          taskManagerFieldData.fieldValueSettings.settings.value = value;
                        }
                      }}
                      value={taskManagerFieldData?.fieldValueSettings?.settings?.value}
                      label={'Значение текстового поля ' + field?.name}
                      errorMessage={error?.message}
                    />
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.multiRowString && (
                  <Grid item xs={6}>
                    <FormTextArea
                      onChange={(value) => {
                        if (taskManagerFieldData) {
                          taskManagerFieldData.fieldValueSettings.settings.value = value;
                        }
                      }}
                      value={taskManagerFieldData?.fieldValueSettings?.settings?.value}
                      label={'Значение текстового поля ' + field?.name}
                      errorMessage={error?.message}
                    />
                  </Grid>
                )}
              {taskManagerFieldData?.fieldValueSettings?.type ===
                JiraApplicationFieldDataValueType.defaultFieldBehavior &&
                field.type === IJiraIssueFieldType.stringArray && (
                  <Grid item xs={6}>
                    <Grid item>
                      <ChipListInput
                        id={`jira-labels-${field?.name}-application-form`}
                        options={[]}
                        defaultValue={taskManagerFieldData?.fieldValueSettings?.settings?.value || []}
                        label={`Поле ${field?.name}`}
                        placeholder={'Введите метку и нажмите Enter'}
                        onChange={(newValues) => {
                          if (taskManagerFieldData?.fieldValueSettings?.settings) {
                            taskManagerFieldData.fieldValueSettings.settings.value = newValues;
                          }
                        }}
                        errorMessage={error?.message}
                        helperText={`Метка (${field?.name}) не должна содержать пробелов и не должа быть больше 255 символов`}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

const JiraStatusesAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const [statuses] = useState<any>(domain.editStatuses.list);

  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const issueTypeId = domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;
  const allowedInternalStatuses =
    domain.taskManager.entity?.connectionTemplate.settings?.value?.allowedInternalStatusesIds || [];
  const isLoading = domain.isLoadingJiraStatuses.value;
  const allowedStatuses = statuses.filter((status) => allowedInternalStatuses.includes(status.id || ''));

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraStatuses();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  return (
    <Grid container direction={'column'}>
      {allowedStatuses.map((allowedStatus) => {
        const statusesValues = domain.statusesValues.list.filter((item) => item.statusId === allowedStatus.id);

        return (
          <Grid item>
            <FormTitleText text={allowedStatus.name} />
            {statusesValues.map((statusValue) => {
              const jiraStatusesData = domain.jiraProjectStatuses.value;
              const jiraStatusesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraStatusesData].map((item) => ({
                name: item.name || '',
                value: item.id || '',
              }));
              if (statusValue.isActive) {
                const statusesMap = domain.taskManager.entity?.connectionTemplate.settings?.value?.statusesMap || [];
                const selectedJiraStatusId = statusesMap.find(
                  (item) => item.internalStatusValueId === statusValue.id && item.internalStatusId === allowedStatus.id,
                )?.externalStatusId;
                return (
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid xs={2} item>
                      <FormTextLabel text={statusValue.name} />
                    </Grid>
                    <Grid item>
                      <FormIcon>
                        <SyncAlt color={selectedJiraStatusId ? 'secondary' : 'disabled'} />
                      </FormIcon>
                    </Grid>
                    <Grid item xs={4}>
                      <FormAutocomplete
                        values={isLoading ? [] : jiraStatusesAutocompleteValues}
                        onChangeSelect={(newValue) => {
                          if (domain.taskManager.entity?.connectionTemplate.settings?.value) {
                            if (!domain.taskManager.entity?.connectionTemplate.settings?.value?.statusesMap) {
                              domain.taskManager.entity.connectionTemplate.settings.value.statusesMap = [];
                            }
                            const selectedJiraStatusIndex =
                              domain.taskManager.entity?.connectionTemplate.settings?.value?.statusesMap.findIndex(
                                (item) =>
                                  item.internalStatusValueId === statusValue.id &&
                                  item.internalStatusId === allowedStatus.id,
                              );
                            if (!newValue?.value) {
                              domain.taskManager.entity?.connectionTemplate.settings.value.statusesMap.splice(
                                selectedJiraStatusIndex,
                                1,
                              );
                            } else {
                              if (selectedJiraStatusIndex !== -1) {
                                domain.taskManager.entity.connectionTemplate.settings.value.statusesMap[
                                  selectedJiraStatusIndex
                                ].externalStatusId = newValue.value;
                                domain.taskManager.entity.connectionTemplate.settings.value.statusesMap[
                                  selectedJiraStatusIndex
                                ].name = newValue.name;
                              } else {
                                domain.taskManager.entity?.connectionTemplate.settings.value.statusesMap.push({
                                  name: newValue.name,
                                  externalStatusId: newValue.value,
                                  internalStatusId: allowedStatus.id || '',
                                  internalStatusValueId: statusValue.id || '',
                                });
                              }
                            }
                          }
                        }}
                        key={`jira-status-map-${statusValue.id}`}
                        selected={selectedJiraStatusId}
                        label="Статус в Jira"
                        onOpen={() => {
                          domain.removeJiraValidationErrors();
                        }}
                        isLoading={isLoading}
                        loadingText={'Загрузка статусов'}
                      />
                    </Grid>
                  </Grid>
                );
              }
            })}
          </Grid>
        );
      })}
    </Grid>
  );
});

const JiraProjectAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const projectsData = domain.jiraProjects.value;
  const autocompleteValues: any = projectsData.map((projectData) => ({
    name: projectData.name || '',
    key: projectData.key || '',
    value: projectData.id || '',
  }));
  const isLoading = domain.isLoadingJiraProjects.value;
  const [projectId, setProjectId] = useState(domain.taskManager?.entity?.connectionTemplate.settings?.value?.projectId);
  useEffect(() => {
    domain.loadJiraProjects();
  }, [domain.taskManager?.entity?.id]);
  return (
    <FormAutocomplete
      errorMessage={domain.getJiraValidationErrorFor('projectId')?.message}
      values={autocompleteValues}
      disabled={isLoading}
      helperText={'Jira проект для создания шаблона на его основе'}
      onChangeSelect={(newValue) => {
        if (domain.taskManager?.entity?.connectionTemplate.settings?.value) {
          domain.setNewProject();
          domain.taskManager.entity.connectionTemplate.settings.value.projectId = newValue?.value || null;
          setProjectId(newValue?.value);
          //@ts-ignore
          domain.taskManager.entity.connectionTemplate.settings.value.projectKey = newValue?.key || null;
        }
        domain.checkJiraConnectionByStates();
        domain.clearJiraLoadedDataValues(false, true, true, true, true);
      }}
      key="jiraProjectId"
      selected={projectId}
      label="Проект"
      onOpen={() => {
        domain.removeJiraValidationErrors();
      }}
      isLoading={isLoading}
      loadingText={'Загрузка проектов'}
    />
  );
});

const JiraInternalStatusesToJiraSyncAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const [statusesAutocompleteValues, setStatusesAutocompleteValues] = useState<any>([]);
  useEffect(() => {
    domain.editPerformerTypes.list.forEach((performer) => {
      const statuses = domain.editStatuses.list.filter((item) => performer.statusesIds?.includes(item.id || ''));
      const statusesAutocompleteValues: any = statuses.map((data) => ({
        name: data.name || '',
        value: data.id || '',
      }));
  
      setStatusesAutocompleteValues((state) => {
        const uniqueElements = statusesAutocompleteValues.filter(status => !state.some(item => status.id === item.id));
        return [...state, ...uniqueElements];
      })
    })
  }, []);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <FormMultiAutocomplete
          values={statusesAutocompleteValues}
          onChangeSelect={(newValue: any) => {
            if (domain.taskManager.entity?.connectionTemplate.settings?.value) {
              domain.taskManager.entity.connectionTemplate.settings.value.allowedInternalStatusesIds = newValue.map(
                (value: any) => value.value,
              );
            }
          }}
          key="allowedInternalStatusesIds"
          selected={domain.taskManager.entity?.connectionTemplate.settings?.value?.allowedInternalStatusesIds || []}
          label="Статусы для синхронизации"
        />
      </Grid>
    </Grid>
  );
});

const JiraIssueTypeAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const issueTypes = domain.jiraIssueTypes.value;
  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const autocompleteValues: any = issueTypes.map((data) => ({
    name: data.name || '',
    value: data.id || '',
  }));
  const isLoading = domain.isLoadingJiraIssueTypes.value;

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraIssueTypes();
      };
      loadData();
    }
  }, [projectId, domain]);

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <FormAutocomplete
          errorMessage={domain.getJiraValidationErrorFor('issueType')?.message}
          values={isLoading ? [] : autocompleteValues}
          onChangeSelect={(newValue) => {
            if (domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type) {
              domain.taskManager.entity.connectionTemplate.settings.value.issue.type = {
                id: newValue?.value || null,
                name: newValue?.name || null,
              };
            }
            domain.clearJiraLoadedDataValues(false, false, true, true);
            domain.checkJiraConnectionByStates();
          }}
          key="issueType"
          selected={domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id}
          label="Тип задачи"
          onOpen={() => {
            domain.removeJiraValidationErrors();
          }}
          isLoading={isLoading}
          loadingText={'Загрузка типов задач'}
        />
      </Grid>
    </Grid>
  );
});

export const JiraTemplatePrioritiesAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const jiraProjectPriorities = domain.jiraProjectPriorities.value;
  const jiraPrioritiesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraProjectPriorities].map((item) => ({
    name: item.name || '',
    value: item.id || '',
  }));

  const internalTagsForPriorities = domain.jiraProjectTagsForPriority.value;

  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const issueTypeId = domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraPriorities();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  return (
    <Grid container direction={'column'}>
      <FormTitleText text={'Приоритет по тэгу Критичность требования'} />
      {internalTagsForPriorities.map((tagForPriority) => {
        const isLoading = domain.isLoadingJiraPriorities.value;
        const selectedPriorityId =
          domain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap?.find(
            (link) => link.tagId === tagForPriority.tag.id,
          )?.externalPriorityId || null;
        return (
          <Grid item>
            <Grid xs={8} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
              <Grid xs={1} item>
                <FormTextLabel text={tagForPriority.tag.name} />
              </Grid>
              <Grid item>
                <FormIcon>
                  <SyncAlt color={!!selectedPriorityId ? 'secondary' : 'disabled'} />
                </FormIcon>
              </Grid>
              <Grid item xs={4}>
                <FormAutocomplete
                  values={isLoading ? [] : jiraPrioritiesAutocompleteValues}
                  onChangeSelect={(newValue) => {
                    if (domain.taskManager.entity?.connectionTemplate.settings?.value) {
                      if (!domain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap) {
                        domain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap = [];
                      }
                      const selectedJiraPriorityIndex =
                        domain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap.findIndex(
                          (item) => item.tagId === tagForPriority.tag.id,
                        );
                      if (!newValue?.value) {
                        domain.taskManager.entity?.connectionTemplate.settings.value.prioritiesMap.splice(
                          selectedJiraPriorityIndex,
                          1,
                        );
                      } else {
                        if (selectedJiraPriorityIndex !== -1) {
                          domain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap[
                            selectedJiraPriorityIndex
                          ].externalPriorityId = newValue.value;
                        } else {
                          domain.taskManager.entity?.connectionTemplate.settings.value.prioritiesMap.push({
                            isDefault: tagForPriority.isDefault,
                            orderIndex: tagForPriority.orderIndex,
                            externalPriorityId: newValue.value,
                            tagId: tagForPriority.tag.id || '',
                          });
                        }
                      }
                    }
                  }}
                  key={`jira-priority-map-${tagForPriority.tag.id}`}
                  selected={selectedPriorityId}
                  label="Приоритет в Jira"
                  onOpen={() => {
                    domain.removeJiraValidationErrors();
                  }}
                  isLoading={isLoading}
                  loadingText={'Загрузка приоритетов'}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

const JiraDeletedTaskStatusesAutocomplete = observer(({ domain }: ITaskManagerServerDomain) => {
  const projectId = domain.taskManager.entity?.connectionTemplate.settings?.value?.projectId;
  const issueTypeId = domain.taskManager.entity?.connectionTemplate.settings?.value?.issue?.type?.id;
  const isLoading = domain.isLoadingJiraStatuses.value;

  const jiraStatusesData = domain.jiraProjectStatuses.value;
  const jiraStatusesAutocompleteValues: any = [{ id: null, name: '' }, ...jiraStatusesData].map((item) => ({
    name: item.name || '',
    value: item.id || '',
  }));

  useEffect(() => {
    if (projectId) {
      const loadData = async () => {
        await domain.loadJiraStatuses();
      };
      loadData();
    }
  }, [projectId, issueTypeId, domain]);

  return (
    <Grid item>
      <FormTitleText text={'Статус для неактуальных требований'} />
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
        <Grid xs={2} item>
          <FormTextLabel text={'Требование не актуально'} />
        </Grid>
        <Grid item>
          <FormIcon>
            <SyncAlt color={domain.taskManager.entity?.connectionTemplate.settings?.value?.deletedStatus?.externalPriorityId ? 'secondary' : 'disabled'} />
          </FormIcon>
        </Grid>
        <Grid item xs={4}>
          <FormAutocomplete
            values={isLoading ? [] : jiraStatusesAutocompleteValues}
            onChangeSelect={(newValue) => {
              if (domain.taskManager.entity?.connectionTemplate.settings?.value) {
                domain.taskManager.entity.connectionTemplate.settings.value.deletedStatus.externalPriorityId = newValue?.value || '';
                domain.taskManager.entity.connectionTemplate.settings.value.deletedStatus.name = newValue?.name || '';
              }
            }}
            key={`jira-status-map-${''}`}
            selected={domain.taskManager.entity?.connectionTemplate.settings?.value?.deletedStatus?.externalPriorityId}
            label="Статус в Jira"
            onOpen={() => {
              domain.removeJiraValidationErrors();
            }}
            // onClose={() => changeIsloading(false)}
            isLoading={isLoading}
            loadingText={'Загрузка статусов'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(6),
  },
  formControl: {
    padding: theme.spacing(2),
  },
}));