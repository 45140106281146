export const ApplicationRoutes = {
    Login: '/login',
    LoginTimeout :'/login-timeout',
    Logout :'/logout',
    Root :'/',
    ProjectAll :'/project/all',
    ProjectInfo :'/project/:projectId/info',
    ProjectCreate:'/project/new',
    ProjectEditApplication :'/project/edit/:projectId/application/:applicationId',
    ProjectEdit :'/project/:projectId/edit',
    SystemCreate :'/project/:projectId/application/new',
    ApplicationAll: '/application/all',
    ApplicationInfo :'/application/:applicationId/info',
    ApplicationRequirement :'/application/:applicationId/requirement/new',
    Settings :'/settings',
    Notification :'/notification',
    UnitPreview :'/learning/preview/:unitId',
  };
  