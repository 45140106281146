export interface IProjectFieldsMap {
  fields: IProjectFieldsMapData[];
}

export interface IProjectFieldsMapData {
  name?: string;
  title?: string;
  description?: string;
  values: IProjectFieldsMapValue[];
}

export interface IProjectFieldsMapValue {
  type: IProjectFieldsMapValueType;
  name: string;
  title: string;
  description: string;
  extraData?: any;
}

export enum IProjectFieldsMapValueType {
  info = 'info',
  text = 'text',
  multiText = 'multiText',
  list = 'list',
  checkbox = 'checkbox',
  name = 'name',
  codeName = 'codeName',
  jiraLink = 'jiraLink',
  comment = 'comment',
  // managersIds = 'managersIds',
  // developersIds = 'developersIds',
  // securityTestersIds = 'securityTestersIds',
  rolesMap = 'rolesMap',
}
