import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { TaskManagerAdminModal } from './TaskManagerAdminModal';
import { IJiraWebhookDataType, ITaskManagerServer } from '../../../../../../../service/taskManager/server/ITaskManagerServer';
import { ITaskManagerType, TaskManagerConnectionState } from '../../../../../../../service/taskManager/ITaskManager';
import { JiraServerCredentialsType } from '../../../../../../../service/taskManager/server/IJiraServerCredentials';

export class TaskManagerAdminModalUI
  extends DataTableFormUI<ITaskManagerServer>
  implements IDataTableFormUI<ITaskManagerServer>
{
  constructor() {
    super();
    this.formComponent.setValue(TaskManagerAdminModal);
  }
  getDefaultModel() {
    return {
      isActive: true,
      connectionTemplate: { settings: null },
      credentials: {
        type: JiraServerCredentialsType.basic,
        value: {
          host: '',
          username: '',
          apiToken: ''
        }
      },
      connectionState: TaskManagerConnectionState.connected,
      type: ITaskManagerType.jira,
      name: '',
      webhookData: {
        type: IJiraWebhookDataType.basic,
        value: {
          id: '',
          events: [],
          callbackUrl: '',
          serverId: '',
          creator: '',
          filter: '',
          isHavePermissions: false
        }
      },
      createDate: undefined,
      lastUpdateDate: undefined,
    };
  }
}
