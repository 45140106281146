import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { IVendorRequirementService } from '../../../../../../../service/requirement/vendor/IVendorRequirementService';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { DataTableFormDomain } from './../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from './../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { VendorRequirementModalUI } from './VendorRequirementModalUI';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';

export class VendorRequirementModalDomain
  extends DataTableFormDomain<IVendorRequirementModel, IVendorRequirementService>
  implements IDataTableFormDomain<IVendorRequirementModel>
{
  public ui: VendorRequirementModalUI;
  constructor(
    entityService: IVendorRequirementService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IVendorRequirementModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new VendorRequirementModalUI());
    this.ui = new VendorRequirementModalUI();
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  sortAndFilterRequirements(reqList: IRequirementModel[]) {
    const filteredReqList = reqList
      .filter((a) => a.type === RequirementType.system)
      .sort((a, b) => {
        let aPrefix = "";
        let bPrefix = "";
        let aSuffix = "";
        let bSuffix = "";

        const aPrefixMatch = a.shortName?.match(/[a-zA-Z]+/);
        if (aPrefixMatch) aPrefix = aPrefixMatch[0];

        const bPrefixMatch = b.shortName?.match(/[a-zA-Z]+/);
        if (bPrefixMatch) bPrefix = bPrefixMatch[0];

        const aSuffixMatch = a.shortName?.match(/\d+/);
        if (aSuffixMatch) aSuffix = aSuffixMatch[0];

        const bSuffixMatch = b.shortName?.match(/\d+/);
        if (bSuffixMatch) bSuffix = bSuffixMatch[0];

        if (aPrefix < bPrefix) return -1;
        if (aPrefix > bPrefix) return 1;

        return parseInt(aSuffix) - parseInt(bSuffix);
      })

    return filteredReqList
  }

  async loadDependency() {
    const [vendorEntitySearchResult, requirementEntitySearchResult] = await Promise.all([
      this.rootPrivateServices.vendor.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.requirement.entity.search({ limit: 100000 }),
    ]);
    this.ui.vendorEntity.setList(vendorEntitySearchResult.data);
    this.ui.requirementEntity.setList(this.sortAndFilterRequirements(requirementEntitySearchResult.data));
  }
}
