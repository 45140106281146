import { IFileOption } from '../../../../service/unit/interface/IUnitPractice';
import { IPassingFile } from '../parts/passingFileTree/IPassingFileTree';
import { CodeMode } from '../store/ICodeMode';
import { IPassingCodeEditorDomain } from './IPassingCodeEditorDomain';
import { IPassingCodeEdtitorUI } from './IPassingCodeEditorUI';
import { PassingCodeEditorUI } from './PassingCodeEditorUI';

export class PassingCodeEditorDomain implements IPassingCodeEditorDomain {
  public ui: IPassingCodeEdtitorUI;
  constructor() {
    this.ui = new PassingCodeEditorUI();
  }
  boot = () => {
    const optionReset = (file: IPassingFile) => {
      file.children?.forEach((file) => {
        if (file.isQuestion) {
          file.options?.forEach((option) => (option.isCorrect = false));
        } else {
          file.hasOwnProperty('children') && optionReset(file);
        }
      });
    };
    this.ui.fileTree.list.map((file) => optionReset(file));
  };
  pickingFile = (file: IPassingFile) => {
    if (file.children?.length === undefined) {
      if (this.ui.mode.value === CodeMode.review) {
        this.ui.currentFile.setValue(file);
        this.ui.currentOption.setValue(null as any);
      }
      if (this.ui.mode.value === CodeMode.writing) {
        this.ui.currentFile.setValue(file);
      }
    }
  };
  pickingOption = (option: IFileOption) => {
    this.ui.currentOption.setValue(option);
  };
}
