import { Button } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ProjectButtonLinkProperties {
  url: string;
  children: any;
  icon?: any;
  className?: string;
  dataCy?: string
}

export const ProjectButtonLink = ({ icon, url, children, className, dataCy }: ProjectButtonLinkProperties) => {
  return (
    <Button
      className={className}
      style={{ textTransform: 'none' }}
      variant="outlined"
      startIcon={icon}
      color="secondary"
      component={RouterLink}
      to={url}
      data-cy={dataCy}
    >
      {children}
    </Button>
  );
};
