import { IExtension } from '../interface/IExtension';
import { IContainer } from '../../common/container/IContainer';
import { ExportDataRouter, ImportDataRouter } from './router/DataImportRouter';
import { ApplicationRouterExtensions } from '../../application/ApplicationRouterExtensions';
import { addDataManagerTranslations } from './locale/addDataManagerTranslations';

export default class DataManagerExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container = this.setRouters(container);
    container = await this.addLocale(container);
  }

  setRouters(container: IContainer) {
    let routerExtension = container.get(ApplicationRouterExtensions);
    routerExtension.push(ImportDataRouter);
    routerExtension.push(ExportDataRouter);
    container.set(ApplicationRouterExtensions, routerExtension);
    return container;
  }

  async addLocale(container: IContainer) {
    container = await addDataManagerTranslations(container);
    return container;
  }
}
