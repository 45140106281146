import { DomainStore } from '../../../../../common/store/base/DomainStore';
import { ILayoutModalWindowDomain } from './ILayoutModalWindowDomain';
import { ILayoutModalWindowUI } from './ILayoutModalWindowUI';
import { LayoutModalWindowUI } from './LayoutModalWindowUI';

export class LayoutModalWindowDomain extends DomainStore implements ILayoutModalWindowDomain {
  constructor(private uiStore: ILayoutModalWindowUI = new LayoutModalWindowUI()) {
    super();
  }

  showModalWindow(component: any, options?: { isFullWidth?: boolean; fullWidthMaxSize?: string }): void {
    this.uiStore.showModalWindow(component, options);
    this.uiStore.openActiveModalWindow();
  }

  closeModalWindow(): void {
    this.uiStore.closeActiveModalWindow();
  }

  get ui() {
    return this.uiStore;
  }
}
