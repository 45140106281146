import { IRequirementCategoryModel } from './../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementCategoryService } from '../../../../../../../service/requirement/category/IRequirementCategoryService';
import { DataTableFormDomain } from './../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from './../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { RequirementCategoryAdminModalUI } from './RequirementCategoryAdminModalUI';
export class RequirementCategoryAdminModalDomain
  extends DataTableFormDomain<IRequirementCategoryModel, IRequirementCategoryService>
  implements IDataTableFormDomain<IRequirementCategoryModel>
{
  constructor(
    entityService: IRequirementCategoryService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IRequirementCategoryModel, any>,
  ) {
    super(entityService, layoutDomain, dataTableDomain, new RequirementCategoryAdminModalUI());
  }
}
