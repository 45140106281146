import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IRequirementCategoryModel } from './IRequirementCategoryModel';
import { IRequirementCategorySearch } from './IRequirementCategorySearch';
import { IRequirementCategoryService } from './IRequirementCategoryService';

export class RestRequirementCategoryService
  extends RestCRUDService<IRequirementCategoryModel, IRequirementCategorySearch>
  implements IRequirementCategoryService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/requirement/category`;
  }
}
