import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { DataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/DataGridDetailUI';
import { IDataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/IDataGridDetailUI';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import specificationCategoryAdminDetailComponent from './SpecificationCategoryAdminDetailComponent';

export class SpecificationCategoryAdminDetailUI
  extends DataGridDetailUI<ISpecificationCategoryModel>
  implements IDataGridDetailUI<ISpecificationCategoryModel>
{
  constructor(public dataTableDomain: IDataTableDomain<ISpecificationCategoryModel, any>) {
    super();
    this.getDetailPanelContent.setValue(specificationCategoryAdminDetailComponent);
  }
}
