import { FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export const FormBox = observer(({ children }: any) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      {children}
    </FormControl>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(2),
  },
}));
