import { IApplicationAffectModel } from '../../../../../../service/application/affect/IApplicationAffectModel';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationAffectAdminUI } from './ApplicationAffectAdminUI';
import { ApplicationAffectModalDomain } from './modal/ApplicationAffectModalDomain';

export class ApplicationAffectAdminDomain extends DataTableDomain<IApplicationAffectModel, any> {
  public ui: ApplicationAffectAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.application.affect as any);
    this.ui = new ApplicationAffectAdminUI(this, null);
    this.modalDomain = new ApplicationAffectModalDomain(rootPrivateServices.application.affect, layoutDomain, this);
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadList();
  }

  async loadDependencies() {
    const requirementsCategories = await this.rootPrivateServices.requirement.category.search({ limit: 10000000 });
    const requirements = await this.rootPrivateServices.requirement.entity.search({ limit: 10000000 });
    this.ui.requirements.setList(requirements.data);
    this.ui.requirementsCategories.setList(requirementsCategories.data);
  }

  async setPermissions() {
    await super.setPermissions();

    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-affect-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-affect-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-affect-update']]),
    );
  }
}
