import { IStatusValueModel } from '../../../../../../../service/status/value/IStatusValueModel';
import { IProjectApplicationView } from './IProjectApplicationView';
import { IProjectTeamMemberView } from './IProjectTeamView';

export interface IProjectView {
  isUnitedWithApplication: boolean;
  unitedApplicationId: string | null;
  id: string;
  name: string;
  description?: string;
  applications: IProjectApplicationView[];
  applicationStatusesValues: { statusId: string; values: IStatusValueModel[] }[];
  team: IProjectTeamMemberView[];
  activeUserCanAddApplications: boolean;
  activeUserCanEditProject: boolean;
}

export const defaultProjectView: IProjectView = {
  id: '',
  name: '',
  description: '',
  team: [],
  applications: [],
  activeUserCanAddApplications: false,
  activeUserCanEditProject: false,
  applicationStatusesValues: [],
  isUnitedWithApplication: false,
  unitedApplicationId: null,
};
