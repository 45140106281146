import { makeStyles } from '@mui/styles';
import React, { MouseEvent } from 'react';

import { OverflownContent } from '../../../../shared/OverflownContent';

type ListItem = { title: string; description: string, value: string }

interface IPreviewSidebarListProps {
  items?: Array<ListItem>;
  onItemClick?: (value: ListItem) => void;
}

export const PreviewSidebarList = ({ items, ...props }: IPreviewSidebarListProps) => {
  const onItemClick = (event: MouseEvent<HTMLElement>, item: ListItem) => {
    if (props?.onItemClick) {
      event.stopPropagation();
      props?.onItemClick(item);
    }
  };

  const classes = useStyles();

  return (
    <div>
      {items?.map((item) => (
        <div className={classes.listItem} onClick={(event) => onItemClick(event, item)}>
          <OverflownContent contentClasses={classes.listItemItemTitle}>{item?.title}</OverflownContent>
          <OverflownContent placement='bottom' className={classes.description}>{item.description}</OverflownContent>
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  listItem: {
    borderTop: '0.5px solid #777777',
    cursor: 'pointer',
    '&:hover': {
      color: '#777777',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '100ms',
    },
    '&:active': {
      color: '#696969',
    },
  },
  description: {
    margin: '0 16px 8px 16px',
    color: 'rgba(255, 255, 255, 0.70)',
    fontSize: '12px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
    '&:hover > *': {
      color: '#777777',
    },
  },
  listItemItemTitle: {
    margin: '8px 16px 8px 16px',
    cursor: 'pointer',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
  },
}));
