import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ComplexMultiselectDomain } from '../../../form/store/ComplexMultiselectDomain';

export class SpecificationFormSelectionDomain extends ComplexMultiselectDomain {
  specificationCategoriesValues = (specifications: ISpecificationEntityModel[], categoryId?: string) => {
    return specifications
      .filter((entity) => entity.categoryId === categoryId)
      .map((entity) => ({
        value: entity.id || '',
        name: entity.isActive ? entity.name : `${entity.name} (неактивен)`,
      }));
  };
}
