import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

import { inject } from '../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IApplicationTemplateModel } from '../../../../../../service/application/template/IApplicationTemplateModel';
import { IApplicationTemplateSearch } from '../../../../../../service/application/template/IApplicationTemplateSearch';
import { IPerformerTypeModel } from '../../../../../../service/performer/type/IPerformerTypeModel';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { makeMultiAutoCompleteViewForTable } from '../../../../../design/table/edit/TableMultiAutocompleteView';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../table/store/IAdminTableUI';

export class ApplicationTemplateAdminDomain extends AdminTableDomain<
  IApplicationTemplateModel,
  IApplicationTemplateSearch
> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected specifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    protected specificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    protected performers: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
  ) {
    super(layoutDomain, rootPrivateServices.application.template);
    this.ui.isInlineEdit.setValue(false);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-template-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-template-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-template-update']]),
    );
  }

  protected async loadDependencies() {
    try {
      const defaultSearchQuery = { limit: 1000000 };
      this.ui.isLoading.setValue(true);

      const specificationsSearchResult = await this.rootPrivateServices.specification.entity.search(defaultSearchQuery);
      const performersSearchResult = await this.rootPrivateServices.performer.type.search(defaultSearchQuery);
      this.ui.isLoading.setValue(false);

      this.performers.setList(performersSearchResult.data);
      this.specifications.setList(specificationsSearchResult.data);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async loadData() {
    this.setActions();
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private setActions() {
    const actions: any[] = [];
    if (
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-application-template-update']])
    ) {
      actions.push({
        icon: () => <EditIcon />,
        tooltip: 'Изменить шаблон',
        onClick: (event, rowData: any) => {
          this.router.goTo(`/settings/application/template/${rowData?.id}/edit`);
        },
      });
    }
    this.ui.actions.setList(actions);
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    const isActiveFilter = (item: any) => item.isActive === true;
    const specifications = this.specifications.list.filter(isActiveFilter).map((specification) => ({
      value: specification.id,
      name: specification.isActive ? specification.name : `${specification.name} (неактивен)`,
    }));
    const performers = this.performers.list.filter(isActiveFilter).map((performer) => ({
      value: performer.id,
      name: performer.isActive ? performer.name : `${performer.name} (неактивен)`,
    }));

    return [
      {
        title: 'Название',
        field: 'name',
        isRequired: true,
      },
      {
        title: 'Характеристики',
        field: 'specificationIds',
        render: makeMultiAutoCompleteViewForTable((item) => item.specificationsIds, specifications),
      },
      {
        title: 'Исполнитель',
        field: 'performerTypeId',
        render: makeMultiAutoCompleteViewForTable((item) => item.performerTypeId, performers),
      },
      {
        title: 'По умолчанию',
        field: 'isDefault',
        type: 'boolean',
        initialEditValue: true,
      },
      {
        title: 'Активен',
        field: 'isActive',
        type: 'boolean',
        initialEditValue: true,
      },
    ];
  }
}
