import { IRequirementSearch } from '../../../../../../../service/requirement/entity/IRequirementSearch';
import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { DataTableRowsUI } from './../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from './../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { VendorRequirementAdminUI } from '../VendorRequirementAdminUI';
export class VendorRequirementRowsUI
  extends DataTableRowsUI<IVendorRequirementModel, IRequirementSearch>
  implements IDataTableRowsUI<IVendorRequirementModel, IRequirementSearch>
{
  constructor(public rootUI: VendorRequirementAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'vendorId') {
      return {
        dependenciesModels: this.rootUI.vendorEntity.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }
    if (fieldName === 'requirementsIds') {
      return {
        dependenciesModels: this.rootUI.requirementEntity.list,
        valueField: 'shortName',
        activeField: 'isActive',
        isArray: true,
      };
    }

    return null;
  }
}
