import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { ProjectLink } from '../../../../view/design/link/ProjectLink';
import { IRiskManagerDataModel } from '../../service/manager/data/IRiskManagerDataModel';
import { RiskColorIcon } from '../color/RiskColorIcon';
import { IRiskManagerProjectInfoDomain } from './domain/IRiskManagerProjectInfoDomain';

export interface IRiskManagerProjectRiskInfoProperties {
  domain: IRiskManagerProjectInfoDomain;
  projectId: string;
}

export const RiskManagerProjectRiskInfo = observer(({ domain, projectId }: IRiskManagerProjectRiskInfoProperties) => {
  const [riskManagerData, setRiskManagerData] = useState<IRiskManagerDataModel | null>(null);

  useEffect(() => {
    const riskManagerDataFromDomain = domain.riskManagersData.list.find(
      (riskManagerData) => riskManagerData.targetId === projectId,
    );
    setRiskManagerData(riskManagerDataFromDomain || null);
  }, [domain.riskManagersData.list.length]);
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      {riskManagerData ? (
        <Fragment>
          <Typography variant="h5">
            {domain.isHaveAccessToRiskManager && (
              <ProjectLink url={'/settings/risk/manager'}> Уровни рисков</ProjectLink>
            )}
            {!domain.isHaveAccessToRiskManager && <div> Уровни рисков</div>}
          </Typography>
          {riskManagerData.riskLevelsReportData.data.length > 0 && riskManagerData.isComplete ? (
            <>
              <Divider className={classes.textDivider} />
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Риск</TableCell>
                      <TableCell>Уровень</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {riskManagerData.riskLevelsReportData.data.map((levelData) => {
                      return (
                        <TableRow>
                          <TableCell>{levelData?.riskName}</TableCell>
                          <TableCell>
                            <Grid container spacing={2} direction="row">
                              <Grid item> {levelData?.riskLevelName} </Grid>
                              <Grid item>
                                <RiskColorIcon title={levelData.riskLevelName} color={levelData.riskColor} />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <div>Расчет рисков произойдет после заполнения всех полей анкеты рисков</div>
          )}
        </Fragment>
      ) : null}
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  textDivider: {
    width: 100,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
