import { injectEntityList } from '../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../common/store/base/injectPrimitive';
import { IDataTableOptions } from './IDataTableOptions';

export class DataTableOptions implements IDataTableOptions {
  public pagination = injectPrimitive<boolean>(true);
  public getRowHeight = ({ id }: any) => {
    const row = document.querySelector(`[data-id='${id}']`);
    const fields = row?.querySelectorAll('[data-field]');
    let maxHeight = 0;
    fields?.forEach((field) => {
      if (field.firstElementChild && field.firstElementChild.clientHeight > maxHeight) {
        maxHeight = field.firstElementChild?.clientHeight || 0;
      }
    });
    return maxHeight && 50 + maxHeight;
  };
  public disableColumnPinning = injectPrimitive<boolean>(true);
  public checkboxSelection = injectPrimitive<boolean>(false);
  public autoHeight = injectPrimitive<boolean>(false);
  public style = { border: 'none' };
  public rowsPerPageOptions = injectEntityList<number>([20, 50, 100]);
  public disableColumnResize = injectPrimitive<boolean>(true);
}
