import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ITheoryBlock, ITheoryBlocks } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITheoryComponent } from '../ITheoryComponent';
import { TheoryComponentList } from '../TheoryComponentList';

export interface ITabsSubBlocks {
  name: string;
  subBlocks: ITheoryBlock<ITheoryBlocks>[];
}
export interface ITabsBlock {
  tabsCount: number;
  tabsData: {
    title: string;
    contentBlocks: ITabsSubBlocks[];
    sortIndex: number;
    pseudoId: string;
  };
}
export const TabsBlock = observer(({ data }: ITheoryComponent<ITabsBlock>) => {
  const [value, setValue] = React.useState<string>(data.tabsData.contentBlocks[0]?.name || '');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%', marginBottom: '32px' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            {data.tabsData.contentBlocks.map((tab) => {
              return <Tab label={tab.name} value={tab.name} />;
            })}
          </TabList>
        </Box>
        {data.tabsData.contentBlocks.map((tab) => {
          return (
            <TabPanel
              value={tab.name}
              sx={{
                '& > div': { background: 'transparent', boxShadow: 'none' },
                '& .MuiTableContainer-root': { background: 'transparent', boxShadow: 'none' },
              }}
            >
              {tab.subBlocks.map((block) => {
                const Element: ITheoryComponent<any> = TheoryComponentList[block.block];
                // @ts-ignore
                return <Element data={block.data} />;
              })}
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
});
