import { Dialog, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonLayoutDomain } from '../store/ICommonLayoutDomain';

export interface LayoutModalWindowProperties {
  layoutDomain: ICommonLayoutDomain;
}

export const LayoutDrawer = observer(({ layoutDomain }: LayoutModalWindowProperties) => {
  const onClose = () => {
    layoutDomain.drawer.closeDrawer();
  };
  const activeDrawer = layoutDomain.drawer.ui.activeDrawer;
  return (
    <div>
      {activeDrawer.component && (
        <Dialog
          open={activeDrawer.isOpen}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {activeDrawer.component()}
        </Dialog>
      )}
    </div>
  );
});
