import { IVendorEntityModel } from '../../../../../../service/vendor/entity/IVendorEntityModel';
import { IVendorEntityService } from '../../../../../../service/vendor/entity/IVendorEntityService';
import { IRowContextMenuUI } from '../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { VendorEntityContextMenuUI } from './VendorEntityContextMenuUI';

export class VendorEntityContextMenuDomain extends RowContextMenuDomain<IVendorEntityModel> {
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: IVendorEntityService;
    dataTableDomain: IDataTableDomain<IVendorEntityModel, any>;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<IVendorEntityModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new VendorEntityContextMenuUI(null);
  }
}
