import { Grid, Theme, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Colors } from '../color/Colors';

export const FormTextLabel = ({
  text,
  variant = 'inherit',
  isRequired = false,
  textColor = undefined,
}: {
  text: string;
  textColor?: string;
  variant?: any;
  isRequired?: boolean;
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction={'row'}>
        <Grid>
          <Typography color={textColor} variant={variant} className={classes.label}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  labelRequirement: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
}));
