import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IUserSystemRoleModel } from './IUserSystemRoleModel';
import { IUserSystemRoleSearch } from './IUserSystemRoleSearch';
import { IUserSystemRoleService } from './IUserSystemRoleService';

export class RestUserSystemRoleService
  extends RestCRUDService<IUserSystemRoleModel, IUserSystemRoleSearch>
  implements IUserSystemRoleService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/user/system/role`;
  }
}
