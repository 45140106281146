import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerTemplateModel } from '../../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerTemplateService } from '../../../../service/manager/template/IRiskManagerTemplateService';
import { GenerateUUID } from '../../../../service/pseudoId/GenerateUUID';
import { IRiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerTemplateDomain } from '../RiskManagerTemplateDomain';
import { RiskManagerTemplateFormUI } from './RiskManagerTemplateFormUI';

export class RiskManagerTemplateFormDomain
  extends DataTableFormDomain<IRiskManagerTemplateModel, IRiskManagerTemplateService>
  implements IDataTableFormDomain<IRiskManagerTemplateModel>
{
  public ui: RiskManagerTemplateFormUI;
  public dataTableDomain: RiskManagerTemplateDomain;

  constructor(
    public rootService: IRiskManagerRootService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: RiskManagerTemplateDomain,
    ui = new RiskManagerTemplateFormUI(),
  ) {
    super(rootService.manager.template, layoutDomain, dataTableDomain, ui);
    this.ui = ui;
    this.dataTableDomain = dataTableDomain;
    this.modalOptions.setValue({
      isFullWidth: true,
      fullWidthMaxSize: 'xl',
    });
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateTemplate();
    }
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    this.ui.model.entity.requirementThreatsLevelSettings = this.ui.model.entity.requirementThreatsLevelSettings || {
      levels: [],
    };
    await this.loadDependency();
  }

  async loadDependency() { }

  addRiskLevel(riskId: string) {
    this.ui.model.entity.riskLevelsSettings.levels.push({
      description: '',
      name: '',
      minValue: 0,
      maxValue: 0,
      pseudoId: GenerateUUID(),
      riskId: riskId,
      color: null,
    });
  }

  addRiskRequirementThreatLevel(riskId: string) {
    this.ui.model.entity.requirementThreatsLevelSettings.levels.push({
      description: '',
      name: '',
      minValue: 0,
      maxValue: 0,
      pseudoId: GenerateUUID(),
      riskId: riskId,
      color: null,
    });
  }

  removeRiskLevel(pseudoId: string) {
    this.ui.model.entity.riskLevelsSettings.levels = this.ui.model.entity.riskLevelsSettings.levels.filter(
      (level) => level.pseudoId !== pseudoId,
    );
  }

  removeRequirementThreatLevel(pseudoId: string) {
    this.ui.model.entity.requirementThreatsLevelSettings.levels =
      this.ui.model.entity.requirementThreatsLevelSettings.levels.filter((level) => level.pseudoId !== pseudoId);
  }

  getRiskLevelsForRisk(riskId: string) {
    return this.ui.model.entity.riskLevelsSettings.levels.filter((level) => level.riskId === riskId);
  }

  getRiskRequirementThreatsLevelsForRisk(riskId: string) {
    return (
      this.ui.model.entity.requirementThreatsLevelSettings?.levels.filter((level) => level.riskId === riskId) || []
    );
  }

  getOrCreateAnswerDataByRisk(questionId: string, riskId: string, answer: any) {
    const data = this.ui.model.entity.answersValueToRiskData.valueData.find(
      (item) => item.questionId === questionId && item.riskId === riskId && item.answerId === answer.pseudoId,
    );
    if (data) {
      return data;
    } else {
      this.ui.model.entity.answersValueToRiskData.valueData.push({
        isDefault: true,
        questionId,
        riskId,
        answerId: answer.pseudoId,
        value: answer.defaultValue,
      });
      return { isDefault: true, questionId, riskId, answerId: answer.pseudoId, value: answer.defaultValue };
    }
  }

  resyncDependenciesValues() {
    const allQuestionIds = this.dataTableDomain.ui.rows.questions.list.map((item) => item.id);
    const allRisksIds = this.dataTableDomain.ui.rows.risks.list.map((item) => item.id);
    const allStaticQuestionIds = this.dataTableDomain.ui.rows.staticQuestions.list.map((item) => item.id);
    const allQuestionFieldIds = this.dataTableDomain.ui.rows.questionFields.list.map((item) => item.id);

    this.ui.model.entity.questionsIds = this.ui.model.entity.questionsIds?.filter((id) => allQuestionIds.includes(id));
    this.ui.model.entity.risksIds = this.ui.model.entity.risksIds?.filter((id) => allRisksIds.includes(id));
    this.ui.model.entity.staticQuestionsIds = this.ui.model.entity.staticQuestionsIds?.filter((id) =>
      allStaticQuestionIds.includes(id),
    );
    this.ui.model.entity.questionFieldsIds = this.ui.model.entity.questionFieldsIds.filter((id) =>
      allQuestionFieldIds.includes(id),
    );
  }

  resyncAnswerDataValues() {
    const answerPseudoIds: any[] = [];
    const questionIds = this.ui.model.entity.questionsIds;
    this.dataTableDomain.ui.rows.questions.list
      .filter((question) => questionIds.includes(question.id || ''))
      .forEach((question) => question.answersMap.answers.forEach((answer) => answerPseudoIds.push(answer.pseudoId)));
    const risksIds = this.ui.model.entity.risksIds;

    this.ui.model.entity.answersValueToRiskData.valueData =
      this.ui.model.entity.answersValueToRiskData.valueData.filter(
        (item) =>
          questionIds.includes(item.questionId) &&
          risksIds?.includes(item.riskId) &&
          answerPseudoIds.includes(item.answerId),
      );
  }

  setAnswerDataValue(newValue: any, questionId: string, riskId: string, answer: any) {
    const dataIndex = this.ui.model.entity.answersValueToRiskData.valueData.findIndex(
      (item) => item.questionId === questionId && item.riskId === riskId && item.answerId === answer.pseudoId,
    );
    if (dataIndex !== -1) {
      this.ui.model.entity.answersValueToRiskData.valueData[dataIndex].value = newValue;
      this.ui.model.entity.answersValueToRiskData.valueData[dataIndex].isDefault = false;
    } else {
      this.ui.model.entity.answersValueToRiskData.valueData.push({
        isDefault: true,
        questionId,
        riskId,
        answerId: answer.pseudoId,
        value: newValue,
      });
    }
  }

  getUpdateIntegerFieldHandlerForRiskLevel(riskLevel, value) {
    return (newValue: any) => {
      const INTEGER_REGEX = /^-?\d*$/;

      if (INTEGER_REGEX.test(newValue) || newValue === '') {
        riskLevel[value] = newValue;
        this.removeValidationErrors()
      } else {
        this.addValidationErrors([{
          target: value,
          message: 'Введите целое число',
        }])
      }
    };
  }
}
