import { IExportDataMainPageDomain } from './interface/IExportDataMainPageDomain';
import { IRootAdminService } from '../../../../../service/RootAdminService';
import { injectRootService, ServiceType } from '../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { ExportDataSupportedEntities } from './interface/ExportDataSupportedEntities';
import { IExportDataMainPageDomainData } from './interface/IExportDataMainPageDomainData';
import { inject } from '../../../../../common/container/inject';
import { ExportDataMainPageDomainData } from './ExportDataMainPageDomainData';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';

export class ExportDataMainPageDomain implements IExportDataMainPageDomain {
  private mainService: IRootAdminService = injectRootService(ServiceType.admin);
  public data: IExportDataMainPageDomainData = inject(ExportDataMainPageDomainData);
  public i18n: ILocalization = inject<ILocalization>(ILocalizationToken);

  constructor(public layoutDomain: IMainLayoutDomainStore) {}

  async bootstrap() {
    this.setupSupportedEntitiesList();
    await this.loadData();
  }

  private setupSupportedEntitiesList() {
    this.data.supportEntitiesList.setValue([
      ExportDataSupportedEntities.specification,
      ExportDataSupportedEntities.specificationCategory,
    ]);
  }

  private async loadData() {
    this.data.isDataLoading.setValue(true);

    const supportedEntitiesToLoaderMap = {
      [ExportDataSupportedEntities.specification]: this.loadSpecification,
      [ExportDataSupportedEntities.specificationCategory]: this.loadSpecificationCategory,
    };

    const supportedEntities = this.data.supportEntitiesList.value;
    let loadDataPromises = supportedEntities.map((supportedEntity) => supportedEntitiesToLoaderMap[supportedEntity]());
    await Promise.all(loadDataPromises);

    this.data.isDataLoading.setValue(false);
  }

  async loadSpecification() {
    const specifications = await this.mainService.specification.entity.search({ limit: 1000000 });
    this.data.entitiesData[ExportDataSupportedEntities.specification] = this.data.entitiesData[
      ExportDataSupportedEntities.specification
    ] || { data: injectPrimitive([]) };
    this.data.entitiesData[ExportDataSupportedEntities.specification].data.setList(specifications.data);
  }

  async loadSpecificationCategory() {
    const specificationsCategories = await this.mainService.specification.category.search({ limit: 1000000 });
    this.data.entitiesData[ExportDataSupportedEntities.specificationCategory] = this.data.entitiesData[
      ExportDataSupportedEntities.specificationCategory
    ] || { data: injectPrimitive([]) };
    this.data.entitiesData[ExportDataSupportedEntities.specificationCategory].data.setList(
      specificationsCategories.data,
    );
  }
}
