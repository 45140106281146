import { ConfigManager } from '../../../application/config/ConfigManager';
import { IConfig } from '../../../application/config/IConfig';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import wretch from 'wretch';

export class AnalyticsDataService {
  protected config: IConfig = ConfigManager.getConfig()

  constructor(
    private type: CRUDServiceType
  ) {
  }

  async getApplicationRequirementsData(applicationId) {
    const url = `${this.getEndpoint()}/applicationRequirementsData/${applicationId}`;
    return await this.request()
      .url(url)
      .get()
      .json()
      .catch(this.handleError);
  }

  async getApplicationVendorData(applicationId) {
    const url = `${this.getEndpoint()}/applicationVendorData/${applicationId}`;
    return await this.request()
      .url(url)
      .get()
      .json()
      .catch(this.handleError);
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/analytics`;
  }

  handleError = (errorResponse: any) => {
    return errorResponse;
  };

  protected request() {
    const jwt = localStorage.getItem('jwtToken');
    return wretch()
      .options({
        headers: {
          ...(jwt && ({ Authorization: `Bearer ${jwt}` } as any)),
        },
      })
      .errorType('json');
  }
}
