import { Socket, io } from 'socket.io-client';
import wretch from 'wretch';

import { ConfigManager } from '../../../application/config/ConfigManager';
import { IConfig } from '../../../application/config/IConfig';
import { ICoreSearchAudit } from '../../coreCommon/common/audit/ICoreSearchAudit';
import { IServiceModel } from '../model/IServiceModel';
import { ISearchRequestData } from '../search/ISearchRequestData';
import { ISearchResultData } from '../search/ISearchResultData';
import { IRestCRUDService } from './IRestCRUDService';
import { RestErrorHandler } from './RestErrorHandler';

wretch().errorType('json');

export abstract class RestCRUDService<ModelType extends IServiceModel, SearchRequestType extends ISearchRequestData>
  implements IRestCRUDService<ModelType, SearchRequestType>
{
  private static socket: Socket;

  constructor(protected config: IConfig = ConfigManager.getConfig(), protected errorHandler = new RestErrorHandler()) {}

  getSocket() {
    if (!RestCRUDService.socket) {
      RestCRUDService.socket = io(ConfigManager.getSiteDomain(''), {
        auth: { token: localStorage.getItem('jwtToken') },
        transports: ['websocket'],
      });
    }
    return RestCRUDService.socket;
  }

  abstract getEndpoint(): string;

  async createByModel(entityForCreate: ModelType): Promise<string> {
    const result = await this.request()
      .url(this.getEndpoint())
      .post(entityForCreate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
    return result.id;
  }

  async updateByModel(entityForUpdate: ModelType): Promise<boolean> {
    const result = await this.request()
      .url(this.getEndpoint())
      .put(entityForUpdate)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
    return !!result.id;
  }

  async deleteById(id: string): Promise<boolean> {
    await this.request()
      .url(`${this.getEndpoint()}/${id}`)
      .delete()
      .fetchError(this.handleError)
      .json()
      .catch(this.handleError);

    return true;
  }

  async getById(id: string, audit?: ICoreSearchAudit): Promise<ModelType> {
    let searchResult: any = null;
    if (audit?.auditDateInMS) {
      searchResult = await this.request()
        .url(`${this.getEndpoint()}/${id}/audit/${audit?.auditDateInMS}/replace/id/${audit?.isReplaceId || true}`)
        .get()
        .res()
        .catch(this.handleError);
    } else {
      searchResult = await this.request().url(`${this.getEndpoint()}/${id}`).get().res().catch(this.handleError);
    }

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async getAuditListById(id: string, offset?: number, limit?: number): Promise<ModelType[]> {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}/audit/list/${id}`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async search(searchRequest: SearchRequestType): Promise<ISearchResultData<ModelType, SearchRequestType>> {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}/search`)
      .post(searchRequest)
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  handleError = (errorResponse: any) => {
    return this.errorHandler.handle(errorResponse);
  };

  protected request() {
    const jwt = localStorage.getItem('jwtToken');
    return wretch()
      .options({
        headers: {
          ...(jwt && ({ Authorization: `Bearer ${jwt}` } as any)),
        },
      })
      .errorType('json');
  }

  protected dateTimeReviver = (key: string, value: any) => {
    function createDateAsUTC(date) {
      return new Date(date);
    }

    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === 'string' && dateFormat.test(value)) {
      const timeWithWrongTimeZone = new Date(value);
      return createDateAsUTC(timeWithWrongTimeZone);
    }

    return value;
  };
}
