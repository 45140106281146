import React, { Fragment } from 'react';

import { UserSystemRoleModelPermissionMap } from '../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { SettingsItemView } from '../../../view/admin/page/settings/SettingsPage';
import { IPageWithLayoutProperties } from '../../../view/layout/page/IPageWithLayoutProperties';
import { RiskManagerSystemRolePermissions } from '../systemRole/RiskManagerSystemRoleExtensions';

export const RiskManagerSettingsMenu = ({ layoutDomain }: IPageWithLayoutProperties) => {
  return (
    <Fragment>
      {layoutDomain.userHaveAnyAccess([
        RiskManagerSystemRolePermissions[
          'extension-risk-manager-settings-admin-read'
        ] as unknown as UserSystemRoleModelPermissionMap,
      ]) && (
        <SettingsItemView
          key="risks"
          title="Анкета Рисков"
          buttons={[
            {
              title: 'Настройки',
              url: `/settings/risk/manager`,
            },
          ]}
          infoText={'Содержит настройки Анкеты Рисков'}
        />
      )}
    </Fragment>
  );
};
