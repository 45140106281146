import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { DataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/DataGridDetailUI';
import { IDataGridDetailUI } from '../../../../../../design/dataTable/parts/detail/IDataGridDetailUI';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import TagCategoryAdminDetailComponent from './TagCategoryAdminDetailComponent';

export class TagCategoryAdminDetailUI
  extends DataGridDetailUI<ITagCategoryModel>
  implements IDataGridDetailUI<ITagCategoryModel>
{
  constructor(public dataTableDomain: IDataTableDomain<ITagCategoryModel, any>) {
    super();
    this.getDetailPanelContent.setValue(TagCategoryAdminDetailComponent);
  }
}
