import { Grid, TableCell, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { UserProjectItemView } from '../../../../../view/user/page/project/list/store/model/UserProjectItemView';
import { ProjectListDomain } from '../../../../../view/user/page/project/list/store/ProjectListDomain';
import { RiskColorIcon } from '../../color/RiskColorIcon';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { IRiskManagerDataModel } from '../../../service/manager/data/IRiskManagerDataModel';
import { UserProjectItemWithRiskManagerView } from './domain/UserProjectItemWithRiskManagerView';
import { IRiskManagerProjectListDomain } from './domain/IRiskManagerProjectListDomain';

export interface RiskManagerDataInfoInProjectListColumnProperties {
  item: UserProjectItemWithRiskManagerView;
  domain: IRiskManagerProjectListDomain;
}

const sortByIndex = (current: any, next: any) => (current.sortIndex < next.sortIndex ? -1 : 1);

export const RiskManagerDataInfoInProjectListColumn = observer(
  ({ domain: projectListDomain, item: projectInfo }: RiskManagerDataInfoInProjectListColumnProperties) => {
    const classes = useStyles();
    const [riskManagerData, setRiskManagerData] = useState<IRiskManagerDataModel | null>(null);

    useEffect(() => {
      const riskManager = projectListDomain.riskManagersData.list.find((item) => item.targetId === projectInfo.id);
      setRiskManagerData(riskManager);
    }, [projectListDomain.riskManagersData.list, projectInfo]);

    return (
      <TableCell className={classes.cell}>
        {riskManagerData && !riskManagerData?.isComplete && (
          <div>Расчет рисков произойдет после заполнения всех полей анкеты рисков</div>
        )}
        {riskManagerData?.riskLevelsReportData?.data?.length === 0 && <div></div>}
        {(riskManagerData?.riskLevelsReportData?.data?.length || 0) > 0 && riskManagerData?.isComplete && (
          <Grid container flexDirection={'row'}>
            {riskManagerData?.riskLevelsReportData?.data?.sort(sortByIndex).map((item) => {
              return (
                <Grid item style={{ padding: '3px' }}>
                  <RiskColorIcon
                    title={`${item?.riskName}: ${item?.riskLevelName}`}
                    color={item?.riskColor || '#ffffff'}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </TableCell>
    );
  },
);

export const useStyles = makeStyles((theme: Theme) => {
  const cellStyle = {
    borderBottom: 'none',
    verticalAlign: 'top',
  };

  return {
    cell: {
      maxWidth: '200px',
      minWidth: '200px',
      paddingTop: '30px',
      ...cellStyle,
    },
  };
});
