
import { AdditionalColumnEntityModalUI } from './AdditionalColumnEntityModalUI';
import { IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IAdditionalColumnService } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnService';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';

export class AdditionalColumnEntityModalDomain
  extends DataTableFormDomain<IAdditionalColumnModel, any>
  implements IDataTableFormDomain<IAdditionalColumnModel>
{
  public ui: AdditionalColumnEntityModalUI;
  constructor(
    entityService: IAdditionalColumnService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IAdditionalColumnModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new AdditionalColumnEntityModalUI());
    this.ui = new AdditionalColumnEntityModalUI();
  }
  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependecies();
  }

  async loadDependecies() {
    const systemTypesResult = await this.rootPrivateServices.vendor.entity.getTypes();
    this.ui.systemTypes.setList(systemTypesResult);
  }
}
