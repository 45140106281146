import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionService } from '../../../../service/question/IRiskManagerQuestionService';
import { IRiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerQuestionDomain } from '../RiskManagerQuestionDomain';
import { RiskManagerQuestionFormUI } from './RiskManagerQuestionFormUI';

export class RiskManagerQuestionFormDomain
  extends DataTableFormDomain<IRiskManagerQuestionModel, IRiskManagerQuestionService>
  implements IDataTableFormDomain<IRiskManagerQuestionModel>
{
  public ui: RiskManagerQuestionFormUI;
  public dataTableDomain: RiskManagerQuestionDomain;

  constructor(
    public rootService: IRiskManagerRootService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: RiskManagerQuestionDomain,
    ui = new RiskManagerQuestionFormUI(),
  ) {
    super(rootService.question.entity, layoutDomain, dataTableDomain, ui);
    this.ui = ui;
    this.dataTableDomain = dataTableDomain;
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  async loadDependency() {
    const answerPresetSearchResult = await this.rootService.question.answerPreset.search({ limit: 100000 });
    this.ui.answerPresets.setList(answerPresetSearchResult.data);
    const defaultAnswerPreset = answerPresetSearchResult.data.find((item) => item.isDefault === true);
    if (defaultAnswerPreset) {
      this.ui.defaultAnswerPreset.setEntity(defaultAnswerPreset);
      if (!this.ui.model.entity.id) {
        this.setPreset(this.ui.defaultAnswerPreset.entity);
      }
    }
  }

  setPreset(preset: IRiskManagerQuestionAnswerPresetModel | null) {
    if (preset) {
      this.ui.model.entity.presetId = preset?.id || null;
      this.ui.model.entity.answersMap.answers =
        preset?.answers.map((answer) => ({
          ...answer,
          questionId: this.ui.model.entity.id || null,
        })) || [];
    } else {
      this.ui.model.entity.presetId = null;
      this.ui.model.entity.answersMap.answers = [];
    }
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateQuestions();
    }
  }
}
