import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ITagCategoryModel } from '../../../../../../service/tag/category/ITagCategoryModel';
import { ITagCategorySearch } from '../../../../../../service/tag/category/ITagCategorySearch';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataGridDetailDomain } from '../../../../../design/dataTable/parts/detail/DataGridDetailDomain';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TagCategoryAdminContextMenuDomain } from './context/TagCategoryAdminContextMenuDomain';
import { TagCategoryAdminDetailUI } from './detail/TagCategoryAdminDetailUI';
import { TagCategoryAdminModalDomain } from './modal/TagCategoryAdminModalDomain';
import { TagCategoryAdminUI } from './TagCategoryAdminUI';
export class CustomAppBar {
  Toolbar = TableHeader;
}

export class TagCategoryAdminDomain extends DataTableDomain<ITagCategoryModel, ITagCategorySearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.tag.category);
    this.ui = new TagCategoryAdminUI(this, null);
    this.modalDomain = new TagCategoryAdminModalDomain(rootPrivateServices.tag.category, layoutDomain, this);
    this.contextMenuDomain = new TagCategoryAdminContextMenuDomain({
      entityService: rootPrivateServices.tag.category,
      dataTableDomain: this,
      layoutDomain,
    });
    this.detailDomain = new DataGridDetailDomain(new TagCategoryAdminDetailUI(this));
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Тэги для требований');
    this.ui.tableSubtitle?.setValue('Категории');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-create']]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-delete']]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-update']]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

  async loadDetail() {
    this.detailDomain.ui.isDetail.setValue(true);
    const entitiesDetailSearchResult = await this.rootPrivateServices.tag.entity.search({ limit: 100000 });
    this.detailDomain.ui.detailEntities.setList(entitiesDetailSearchResult.data);
    this.detailDomain.ui.detailKey.setValue('categoryId');
  }
}
