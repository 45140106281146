import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { NotificationType } from '../../../../../../service/notification/INotificationModel';
import { INotificationsSettings, IUserEntityModel } from '../../../../../../service/user/IUserEntityModel';

export class SettingsUi {
  constructor(
    public notificationSettings: IEntityStore<INotificationsSettings> = injectEntity({
      isSendWithMail: false,
      notificationToEmail: [],
      email: '',
    }),
    public currentUser: IEntityStore<IUserEntityModel> = injectEntity({
      notificationsSettings: {
        isSendWithMail: false,
        notificationToEmail: [],
        email: '',
      },
    }),
    public userNotificationsList: IEntityListStore<{ value: NotificationType; name: string }> = injectEntityList([]),
  ) {}
}
