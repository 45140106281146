import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionService } from '../../../../service/question/IRiskManagerQuestionService';
import { RiskManagerQuestionDomain } from '../RiskManagerQuestionDomain';
import { RiskManagerQuestionContextMenuUI } from './RiskManagerQuestionContextMenuUI';

export class RiskManagerQuestionContextMenuDomain extends RowContextMenuDomain<IRiskManagerQuestionModel> {
  public dataTableDomain: RiskManagerQuestionDomain;

  constructor(
    entityService: IRiskManagerQuestionService,
    dataTableDomain: RiskManagerQuestionDomain,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerQuestionModel> | null,
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerQuestionContextMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateQuestions();
  }
}
