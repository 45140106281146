import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagCategoryService } from '../../../../../../../service/tag/category/ITagCategoryService';
import { IRowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TagCategoryAdminContextMenuUI } from './TagCategoryAdminContextMenuUI';

export class TagCategoryAdminContextMenuDomain extends RowContextMenuDomain<ITagCategoryModel> {
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: ITagCategoryService;
    dataTableDomain: IDataTableDomain<ITagCategoryModel, any>;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<ITagCategoryModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new TagCategoryAdminContextMenuUI(this);
  }
}
