import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';

export interface ILearningUnitPreviewPageUI {
  unit: IEntityStore<IUnitModel>;
  isLoading: IPrimitiveStore<boolean>;
  currentStep: IPrimitiveStore<string>;
  currentSubstep: IPrimitiveStore<string | number>;
  isEbaleToCheck: IPrimitiveStore<boolean>;
  isEbaleToRePass: IPrimitiveStore<boolean>;
  steps: IEntityListStore<string>;
  activeSteps: IEntityListStore<string>;
}

export class LearningUnitPreviewPageUI implements ILearningUnitPreviewPageUI {
  constructor(
    public unit = injectEntity<IUnitModel>({} as IUnitModel),
    public isLoading = injectPrimitive<boolean>(false),
    public currentStep = injectPrimitive<string>(''),
    public currentSubstep = injectPrimitive<string>(''),
    //(v_babaev): added isEbaleToCheck & isEbaleToRePass to make quizQuestionComponents active and enabled
    public isEbaleToCheck = injectPrimitive<boolean>(true),
    public isEbaleToRePass = injectPrimitive<boolean>(true),
    public steps = injectEntityList<string>(['theory', 'quiz', 'practice']),
    public activeSteps = injectEntityList<string>([])
  ) {}
}
