import { IMainLayoutDomainStore } from '../../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IUnitFinalResultModel } from '../../../../../../../service/unit/interface/IUnitFinalResultModel';
import { IUnitDomain } from '../../../store/IUnitDomain';
import { IUnitFinalResultsDomain } from './IUnitFinalResultsDomain';
import { IUnitFinalResultsUI } from './IUnitFinalResultsUI';
import { UnitFinalResultsUI } from './UnitFinalResultsUI';

export class UnitFinalResultsDomain implements IUnitFinalResultsDomain {
  public ui: IUnitFinalResultsUI;
  public unitDomain: IUnitDomain;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    unitDomain: IUnitDomain,
  ) {
    this.ui = new UnitFinalResultsUI();

    this.unitDomain = unitDomain;
  }
  boot = () => {
    this.ui.finalResult.setEntity(this.unitDomain.ui.unit.entity.finalResult?.results as IUnitFinalResultModel);
    this.ui.isEnabled.setValue(this.unitDomain.ui.unit.entity.finalResult?.isEnabled);
  };

  updateResultVerbose = (value) => {
    this.unitDomain.ui.unit.entity.finalResult.results = {
      ...this.unitDomain.ui.unit.entity.finalResult.results,
      ...value,
    };
  };
}
