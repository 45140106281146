import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { ApplicationAffectAdminModel } from '../ApplicationAffectAdminModel';
import { ApplicationAffectModal } from './ApplicationAffectModal';

export class ApplicationAffectModalUI
  extends DataTableFormUI<ApplicationAffectAdminModel>
  implements IDataTableFormUI<ApplicationAffectAdminModel>
{
  constructor(
    public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
    public requirementsCategories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>(
      [],
    ),
  ) {
    super();
    this.formComponent.setValue(ApplicationAffectModal);
  }
}
