import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { SpecificationCategoryModal } from './SpecificationCategoryModal';

export class SpecificationCategoryModalUI
  extends DataTableFormUI<ISpecificationCategoryModel>
  implements IDataTableFormUI<ISpecificationCategoryModel>
{
  constructor(
    public entities: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>([]),
    public categories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
  ) {
    super();
    this.formComponent.setValue(SpecificationCategoryModal);
  }
}
