import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { ITagEntityModel } from './ITagEntityModel';
import { ITagEntitySearch } from './ITagEntitySearch';
import { ITagEntityService } from './ITagEntityService';

export class RestTagEntityService
  extends RestCRUDService<ITagEntityModel, ITagEntitySearch>
  implements ITagEntityService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/tag`;
  }
}
