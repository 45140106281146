import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { UIStore } from '../../../../../common/store/base/UIStore';
import { ISignInPageUIStore } from './ISignInPageUIStore';

export class SignInPageUIStore extends UIStore implements ISignInPageUIStore {
  constructor(
    public isError = injectPrimitive<boolean>(false),
    public isLockForm = injectPrimitive<boolean>(false),
    public loginField = injectPrimitive<string>(''),
    public loginErrorMessage = injectPrimitive<string>(''),
    public passwordField = injectPrimitive<string>(''),
    public passwordErrorMessage = injectPrimitive<string>(''),
    public loginMethods = injectEntityList<any>([{ name: 'Локальный вход', value: null }]),
    public loginMethodValue = injectPrimitive<any>(null),
  ) {
    super();
  }
}
