import { IPassingService } from './passing/IPassingService';
import { RestPassingService } from './passing/RestPassingService';
import { IQuizService } from './quiz/IQuizService';
import { RestQuizService } from './quiz/RestQuizService';
import { ITaskService } from './tasks/ITaskService';
import { RestTaskService } from './tasks/RestTaskService';
import { IUnitService } from './unit/IUnitService';
import { RestUnitService } from './unit/RestUnitService';
import { IUnitResultService } from './unitResult/IUnitResultService';
import { RestUnitResultService } from './unitResult/RestUnitResultService';
import { RestLearningReportService } from './report/RestLearningReportService';
import { ILearningReportService } from './report/ILearningReportService';
import { Service } from '../../../common/store/base/Service';
import { IService } from '../../../common/store/interface/IService';
import { IContextService } from './context/IContextService';
import { RestContextService } from './context/RestContextService';
import { IUserDataService } from './userData/IUserDataService';
import { RestUserDataService } from './userData/RestUserDataService';

export interface ILearningRootService extends IService {
  unitResult: IUnitResultService;
  quiz: IQuizService;
  passing: IPassingService;
  unit: IUnitService;
  tasks: ITaskService;
  report: ILearningReportService;
  context: IContextService;
  userData: IUserDataService;

}


export class LearningRootService extends Service implements ILearningRootService {
  constructor(
    public unitResult = new RestUnitResultService(),
    public quiz = new RestQuizService(),
    public passing = new RestPassingService(),
    public unit = new RestUnitService(),
    public tasks = new RestTaskService(),
    public report = new RestLearningReportService(),
    public context = new RestContextService(),
    public userData = new RestUserDataService()
  ) {
    super();
  }
}

export const LearningRootServiceToken = Symbol.for('LearningRootServiceToken');
