import { Chip, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerTemplateModel } from '../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerTemplateSearch } from '../../../service/manager/template/IRiskManagerTemplateSearch';
import { RiskManagerTemplateRowsUI } from './rows/RiskManagerTemplateRowsUI';

export class RiskManagerTemplateUI
  extends DataTableUI<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>
  implements IDataTableUI<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>
{
  public rows: RiskManagerTemplateRowsUI;

  constructor(
    public domain: IDataTableDomain<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>,
    rows: RiskManagerTemplateRowsUI | null,
  ) {
    super(domain, rows);
    this.rows = rows || new RiskManagerTemplateRowsUI(this);
    this.tableRootPaperElevation.setValue(0);
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'isDefault',
        headerName: 'По умолчанию',
        flex: 1,
        type: 'boolean',
      },
    ];
  }
}
