import { IConfig } from '../../../application/config/IConfig';

export interface IRestService {
  post<Request = any, Response = boolean>(url: string, data: Request): Promise<Response>;
  put<Request = any, Response = boolean>(url: string, data: Request): Promise<Response>;
  get<QueryData = any, QueryResult = any>(url: string, queryData?: QueryData): Promise<QueryResult>;
  delete<Response = boolean>(url: string): Promise<Response>;
  getConfig(): IConfig;
}

export const RestServiceToken = Symbol.for('RestServiceToken');
