import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from '../../store/ApplicationDetailsMode';
import { ApplicationInfoDetailsForm } from './ApplicationInfoDetailsForm';
import { ApplicationInfoDetailsView } from './ApplicationInfoDetailsView';

export interface IApplicationInfoDetailsViewProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const ApplicationInfoDetails = observer(({ domain }: IApplicationInfoDetailsViewProperties) => {
  return (
    <Fragment>
      {domain.ui.mode.value === ApplicationDetailsMode.view && <ApplicationInfoDetailsView domain={domain} />}
      {domain.ui.mode.value === ApplicationDetailsMode.edit && <ApplicationInfoDetailsForm domain={domain} />}
    </Fragment>
  );
});
