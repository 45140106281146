import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { SpecificationEntityModal } from './SpecificationEntityModal';

export class SpecificationEntityModalUI
  extends DataTableFormUI<ISpecificationEntityModel>
  implements IDataTableFormUI<ISpecificationEntityModel>
{
  constructor(
    public categories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
  ) {
    super();
    this.formComponent.setValue(SpecificationEntityModal);
  }
}
