import React from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { AnalyticsDomain } from "../../store/AnalyticsDomain";
import { ApplicationInfoChartsGroup } from "./chartsGroup/ApplicationInfoChartsGroup";

export const AnalyticsRequirements = observer(({ domain }: { domain: AnalyticsDomain }) => {

    return (
        <Grid container width={'100%'}>
            <ApplicationInfoChartsGroup domain={domain} />
        </Grid>
    )
})
