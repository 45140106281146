import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { UIStore } from '../../../../../../common/store/base/UIStore';
import { IVendorEntityModel } from '../../../../../../service/vendor/entity/IVendorEntityModel';
import { VendorSystemType } from '../../../../../../service/vendor/entity/VendorSystemType';
import { UserProjectItemView } from './model/UserProjectItemView';

export class ProjectListPageUi extends UIStore {
  constructor(
    public isLoading = injectPrimitive<boolean>(false),
    public isCanEdit = injectPrimitive<boolean>(false),
    public isCanCreate = injectPrimitive<boolean>(false),
    public isCanDelete = injectPrimitive<boolean>(false),
    public isEmpty = injectPrimitive<boolean>(false),
    public userProjects = injectEntityList<UserProjectItemView>([]),
    public systemVendors = injectEntityList<IVendorEntityModel>([]),
    public owaspId = injectPrimitive<string | null>(null),
    public cbrId = injectPrimitive<string | null>(null),
    public pciId = injectPrimitive<string | null>(null),
    public searchProjectTerm = injectPrimitive<string>(''),
    public projectListOffset = injectPrimitive<number>(0),
    public projectsTotalCount = injectPrimitive<number>(0),
    public applicationsTotalCount = injectPrimitive<number>(0),
    public isAllProjectsRendered = injectPrimitive<boolean>(false),
    public isLoadingProjects = injectPrimitive<boolean>(true),
    public tableSettings = injectPrimitive<{
      showName: boolean;
      showSystemsCount: boolean;
      showRequirementsCount: boolean;
      showLastActive: boolean;
      showActions: boolean;
    }>({
      showName: true,
      showSystemsCount: true,
      showRequirementsCount: true,
      showLastActive: true,
      showActions: true,
    }),
  ) {
    super();
  }

  startLoading(): void {
    this.isEmpty.setValue(false);
    this.isLoading.setValue(true);
  }

  endLoading(): void {
    this.isLoading.setValue(false);
  }

  setUserProjects(userProjects: UserProjectItemView[]): void {
    this.userProjects.setList(userProjects);
    this.isEmpty.setValue(userProjects.length === 0);
  }

  setSystemVendors(systemVendors: IVendorEntityModel[]): void {
    this.systemVendors.setList(systemVendors);
    const owaspId = VendorSystemType.owasp;
    const cbrId = VendorSystemType.cbr;
    const pciId = VendorSystemType.pci;

    this.owaspId.setValue(owaspId);
    this.cbrId.setValue(cbrId);
    this.pciId.setValue(pciId);
  }

  getVendorIdBySystemName(systemName: VendorSystemType) {
    return this.systemVendors.list.find((vendor: IVendorEntityModel) => vendor.systemType === systemName)?.id || null;
  }
}
