import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Colors } from '../../../../../../design/color/Colors';
import { ProjectButtonLink } from '../../../../../../design/link/ProjectButtonLink';
import { IAdminPageProperties } from '../../../../IAdminPageProperties';
import { TaskManagerServerWebhookInfoDomain } from './store/TaskManagerServerWebhookInfoDomain';

export interface ITaskManagerServerWebhookInfoPageProperties extends IAdminPageProperties {}

export const TaskManagerServerWebhookInfoPage = observer(
  ({ layoutDomain }: ITaskManagerServerWebhookInfoPageProperties) => {
    const [domain] = useState<TaskManagerServerWebhookInfoDomain>(new TaskManagerServerWebhookInfoDomain(layoutDomain));
    const { entityId = null } = useParams<any>();
    useEffect(() => {
      domain.boot(entityId);
    }, [domain, entityId]);

    return (
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={8}>
          <Typography variant="h5" component="p">
            Соединение с сервером "{domain.ui.taskManager.entity?.name}"
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" component="p">
            Хост: {domain.ui.taskManager.entity?.credentials?.value?.host}
          </Typography>
          <Typography variant="body2" component="p">
            Пользователь: "{domain.ui.taskManager.entity?.credentials?.value?.username}"
          </Typography>
          <br />
          {domain.ui.taskManager.entity?.webhookData?.value?.isHavePermissions === true && (
            <Typography variant="body2" style={{ color: Colors.positiveActive }} component="p">
              Webhook: Создан автоматически
            </Typography>
          )}
          {!domain.ui.taskManager.entity?.webhookData?.value?.isHavePermissions && (
            <div>
              <Typography variant="body1" style={{ color: Colors.warningActive }} component="p">
                Webhook: У пользователя нет прав создавать или отслеживать webhook. Параметры для ручного создания:
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                URL:
              </Typography>
              <Typography variant="subtitle2" component="p">
                {domain.ui.taskManager.entity?.webhookData?.value?.callbackUrl}
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                JQL фильтр:
              </Typography>
              <Typography variant="subtitle2" component="p">
                creator = "{domain.ui.taskManager.entity?.webhookData?.value?.creator}"
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                События, относящиеся к задаче:
              </Typography>
              <Typography variant="subtitle2" component="p">
                Задача: обновлено, удалено
              </Typography>
              <Typography variant="subtitle2" component="p">
                Комментарий: создано, обновлено, удалено
              </Typography>
            </div>
          )}
        </Grid>

        <Grid item xs={8}>
          <br />
          <ProjectButtonLink url={'/settings/taskManager/server'}>Вернуться</ProjectButtonLink>
        </Grid>
      </Grid>
    );
  },
);

// export const x = observer(({ domain }: { domain: TaskManagerServerFormDomain }) => {
//   return (
//     <Grid item xs={6}>
//       <Accordion TransitionProps={{ unmountOnExit: true }}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="application-jira-settingsForm"
//           id="application-jira-settingsForm"
//         >
//           <Grid container spacing={2} direction={'row'}>
//             <Grid item>
//               <Typography>Jira</Typography>
//             </Grid>
//             <Grid item>
//               {isConnectedJira && <CloudDoneIcon style={{ color: Colors.positivePassive }} />}
//               {!isConnectedJira && <CloudOffIcon style={{ color: Colors.negativePassive }} />}
//             </Grid>
//           </Grid>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Grid container direction={'column'}>
//             {domain.ui.editJiraTaskManager.entity.serverId && (
//               <Grid item>
//                 <JiraProjectAutocomplete domain={domain} />
//               </Grid>
//             )}

//             {jiraProjectId && (
//               <Grid item>
//                 <JiraIssueTypeAutocomplete domain={domain} />
//               </Grid>
//             )}
//             {jiraProjectId && jiraIssueTypeId && (
//               <Grid item>
//                 <FormTextLabel text={`Формат названия задачи: '${jiraNamePrefix}Короткое Название'`} />
//               </Grid>
//             )}
//             {jiraProjectId && jiraIssueTypeId && Number(jiraTagsForPriority?.length) > 0 && (
//               <Grid item>
//                 <JiraPrioritiesAutocompleteComponent domain={domain} />
//               </Grid>
//             )}
//             {jiraProjectId && jiraIssueTypeId && (
//               <Grid item>
//                 <JiraInternalStatusesToJiraSyncAutocomplete domain={domain} />
//               </Grid>
//             )}
//             {jiraProjectId && jiraIssueTypeId && Number(jiraAllowedStatuses?.length) > 0 && (
//               <Grid item>
//                 <JiraStatusesAutocomplete domain={domain} />
//               </Grid>
//             )}

//             {jiraProjectId && jiraIssueTypeId && (
//               <Grid item>
//                 <ChipListInput
//                   id={'jira-labels-application-settingsForm'}
//                   options={[]}
//                   defaultValue={domain.ui.editJiraTaskManager.entity?.settings?.value?.customLabels || []}
//                   label={'Метка (Label) Системы в Jira'}
//                   placeholder={'Введите метку и нажмите Enter'}
//                   onChange={(newValues) => {
//                     if (domain.ui.editJiraTaskManager.entity.settings?.value) {
//                       domain.ui.editJiraTaskManager.entity.settings.value.customLabels = newValues;
//                     }
//                   }}
//                   helperText={'Метка (Label) не должна содержать пробелов и не должа быть больше 255 символов'}
//                 />
//               </Grid>
//             )}
//           </Grid>
//         </AccordionDetails>
//       </Accordion>
//     </Grid>
//   );
// });
