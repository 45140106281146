import { IContainer } from './IContainer';

export class Container implements IContainer {
  public static instances: Map<any, any> = new Map();

  set<SetType>(setType: SetType, returnType?: SetType): void {
    Container.instances.set(setType, returnType || setType);
  }

  setByToken<SetType, TokenType = any>(token: TokenType, value: SetType): void {
    Container.instances.set(token, value);
  }

  get<ReturnType>(returnType: ReturnType): ReturnType {
    return Container.instances.get(returnType) || returnType;
  }

  getByToken<ReturnType, TokenType>(token: TokenType, defaultValue?: ReturnType): ReturnType {
    const containerValue = Container.instances.get(token);
    if (typeof containerValue == 'boolean') {
      //@ts-ignore
      return containerValue;
    } else {
      return Container.instances.get(token) || defaultValue || null;
    }
  }

  debug() {
    console.log(Array.from(Container.instances.keys()));
  }
}
