import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { DataGridDetailUI } from './DataGridDetailUI';
import { IDataGridDetailDomain } from './IDataGridDetailDomain';
import { IDataGridDetailUI } from './IDataGridDetailUI';

export class DataGridDetailDomain<RowType extends IServiceModel> implements IDataGridDetailDomain<RowType> {
  public ui: IDataGridDetailUI<RowType>;
  constructor(ui?: IDataGridDetailUI<RowType>) {
    this.ui = ui || new DataGridDetailUI();
  }
}
