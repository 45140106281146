import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { ApplicationAffectAdminDomain } from './store/ApplicationAffectAdminDomain';

export interface IApplicationAffectAdminTableProperties extends IAdminPageProperties {}

export const ApplicationAffectAdminTable = observer(({ layoutDomain }: IApplicationAffectAdminTableProperties) => {
  const [domain] = useState<ApplicationAffectAdminDomain>(new ApplicationAffectAdminDomain(layoutDomain));

  return <DataTable dataTableDomain={domain} />;
});
