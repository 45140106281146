import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { PageEmptyContent } from '../../../view/design/page/PageEmptyContent';
import { PageLoader } from '../../../view/design/page/PageLoader';
import { IPageWithLayoutProperties } from '../../../view/layout/page/IPageWithLayoutProperties';
import { RiskManagerDataTable } from './data/adminTable/RiskManagerDataTable';
import { RiskManagerSettingsPageDomain } from './domain/RiskManagerSettingsPageDomain';
import { RiskManagerQuestionFieldTable } from './field/RiskManagerQuestionFieldTable';
import { RiskManagerQuestionTable } from './question/RiskManagerQuestionTable';
import { RiskManagerQuestionAnswerPresetTable } from './questionAnswerPreset/RiskManagerQuestionAnswerPresetTable';
import { RiskManagerRiskTable } from './risk/RiskManagerRIskTable';
import { RiskManagerStaticQuestionTable } from './staticQuestion/RiskManagerStaticQuestionTable';
import { RiskManagerTemplateTable } from './template/RiskManagerTemplateTable';

export const RiskManagerSettingsPage = observer(({ layoutDomain }: IPageWithLayoutProperties) => {
  const [domain] = useState<RiskManagerSettingsPageDomain>(new RiskManagerSettingsPageDomain(layoutDomain));
  const isLoading = false;
  const isEmptyContent = false;
  const classes = useStyles();

  useEffect(() => {
    domain.setPermissions();
  }, [domain, domain.riskDomain.layoutDomain.ui.activeUser.entity]);

  return (
    <React.Fragment>
      {domain.isCanRead.value && (
        <Grid className={classes.mainContainer} container spacing={2} direction="column">
          <Paper className={classes.mainContainerPaper} elevation={0}>
            <Typography variant={'h4'}>Анкета Рисков</Typography>
          </Paper>
          <Paper className={classes.mainContainerPaper} elevation={0}>
            {isLoading && <PageLoader />}
            {!isLoading && !isEmptyContent && (
              <Grid spacing={2} container direction="column">
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Риски</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerRiskTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Шаблон ответов</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerQuestionAnswerPresetTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Вопросы</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerQuestionTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Статичные вопросы</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerStaticQuestionTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Дополнительные поля</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerQuestionFieldTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Шаблон</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerTemplateTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Анкеты рисков</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.tableAccordion}>
                      <RiskManagerDataTable rootDomain={domain} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Toolbar />
              </Grid>
            )}
            {!isLoading && isEmptyContent && <PageEmptyContent text={''} isFullPage={true} />}
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
  mainContainerPaper: {
    padding: theme.spacing(2),
  },
  tableAccordion: {
    height: 820,
  },
  root: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));
