import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react';
import React from 'react';

import { useButtonStyles } from './ButtonStyle';

export interface AddButtonProperties {
  className?: string;
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  label?: string;
}

export const ResetFilters = observer(({ className = '', label = 'Сбросить фильтры', onClick }: AddButtonProperties) => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  className = className || `${buttonClasses.button} ${classes.reset}`;
  return (
    <Button className={className} variant="outlined" startIcon={<RestartAltIcon />} onClick={onClick} color="secondary">
      {label}
    </Button>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  reset: {
    height: '32px',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '0px',
  },
}));
