import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ITaskManagerServerSearch } from '../../../../../../service/taskManager/server/ITaskManagerServerSearch';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TaskManagerAdminUI } from './TaskManagerAdminUI';
import { ITaskManagerServer } from '../../../../../../service/taskManager/server/ITaskManagerServer';
import { TaskManagerAdminContextMenuDomain } from './context/TaskManagerAdminContextMenuDomain';
import { TaskManagerAdminModalDomain } from './modal/TaskManagerAdminModalDomain';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';

export class CustomAppBar {
  Toolbar = TableHeader
}

export class TaskManagerAdminDomain extends DataTableDomain<ITaskManagerServer, ITaskManagerServerSearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.taskManager.server);
    this.ui = new TaskManagerAdminUI(this, null);
    this.modalDomain = new TaskManagerAdminModalDomain(rootPrivateServices.taskManager.server, layoutDomain, this);
    this.contextMenuDomain = new TaskManagerAdminContextMenuDomain({
      entityService: rootPrivateServices.taskManager.server,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Интеграции');
    this.ui.tableSubtitle?.setValue('Соединение с Jira');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-create']]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-delete']]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-update']]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

}
