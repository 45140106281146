import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IPerformerTypeModel } from '../../../../../../../service/performer/type/IPerformerTypeModel';
import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { FormUI } from '../../../../form/store/FormUI';
import { RequirementFormDomain } from './RequirementFormDomain';
import { RequirementFormModel } from './RequirementFormModel';

export class RequirementFormUI extends FormUI<RequirementFormModel> {
  constructor(
    public domain: RequirementFormDomain,
    public isDelete = injectPrimitive<boolean>(false),
    public type = injectPrimitive<RequirementType>(RequirementType.system),
    public tags = injectEntityList<ITagEntityModel>([]),
    public tagsCategories = injectEntityList<ITagCategoryModel>([]),
    public specifications = injectEntityList<ISpecificationEntityModel>([]),
    public specificationsCategories = injectEntityList<ISpecificationCategoryModel>([]),
    public performersType = injectEntityList<IPerformerTypeModel>([]),
    public categories = injectEntityList<IRequirementCategoryModel>([]),
    public applicationId = injectPrimitive<string>(''),
    public isCanGenerateShortName = injectPrimitive<boolean>(false),
    public isLoading = injectPrimitive<boolean>(false),
  ) {
    super();
  }

  specificationsAutocompleteValues(categoryId?: string | null): any {
    return this.specifications.list
      .filter((entity) => entity.categoryId === categoryId)
      .map((entity) => ({
        value: entity.id,
        name: entity.isActive ? entity.name : `${entity.name} (неактивен)`,
      }));
  }

  tagsAutocompleteValues() {
    return this.tags.list.map((tag) => ({
      value: tag.id,
      name: tag.isActive ? tag.name : `${tag.name} (неактивен)`,
    }));
  }

  performersTypeAutocompleteValues() {
    return this.performersType.list.map((performerType) => ({
      value: performerType.id,
      name: performerType.isActive ? performerType.name : `${performerType.name} (неактивен)`,
    }));
  }

  getCategoriesValues() {
    return this.categories.list.map((category) => ({
      value: category.id,
      name: category.isActive ? category.name : `${category.name} (неактивен)`,
    }));
  }
}
