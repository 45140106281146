import React from "react";
import { observer } from "mobx-react";
import { format, getYear } from "date-fns";
import { CircularProgress, Grid, Tab, Tabs, Theme } from '@mui/material';
import {
  Line,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ComposedChart,
  ResponsiveContainer
} from 'recharts';
import { makeStyles } from '@mui/styles';
import { LegendChartLabel } from '../../LegendChartLable'
import { AnalyticsDomain } from "../../../store/AnalyticsDomain";
import { StatusValueColor } from "../../../../status/value/color/StatusValueColor";
import { Colors } from "../../../../../../design/color/Colors";

export const ApplicationDynamicsChart = observer(({ domain }: { domain: AnalyticsDomain }) => {
  const classes = useStyles();
  const currentChartData = domain.ui.requirementsUi.currentApplicationRequirementsData.value || []
  const currentChartStatuses = domain.ui.requirementsUi.currentApplicationRequirementsStatuses.value || []

  const dataForChart = currentChartData.map(item => {
    let currentDateInPeriod
    if (domain.ui.requirementsUi.analyticsApplicationRequirementDataTimeTab.value === 'applicationRequirementsDayData') {
      currentDateInPeriod = format(new Date(item.screenDate), 'dd/MM')
    } if (domain.ui.requirementsUi.analyticsApplicationRequirementDataTimeTab.value === 'applicationRequirementsMonthData') {
      currentDateInPeriod = format(new Date(item.screenDate), 'MM/yy')
    } if (domain.ui.requirementsUi.analyticsApplicationRequirementDataTimeTab.value === 'applicationRequirementsYearData') {
      currentDateInPeriod = getYear(new Date(item.screenDate))
    }
    const transformedObject = {
      totalRequirements: item.totalRequirements,
      date: currentDateInPeriod
    };

    for (const status of item.statuses) {
      transformedObject[status.statusValueId] = status.counter;
    }

    return transformedObject
  })

  const isNeedBarLabel =
    domain.ui.requirementsUi.analyticsApplicationRequirementDataTimeTab.value !==
    'applicationRequirementsDayData' ||
    dataForChart.length <= 12;

  const isChartLoading = domain.ui.requirementsUi.isLoadingApplicationRequirementDataChart.value || false

  return (
    <Grid className={classes.dynamicsChartBlock}>
      <Grid item className={classes.dynamicsChartHeader}>
        <Grid>Динамика по работе над АС</Grid>
        <Grid className={classes.dynamicsChartDateChanger}>
          {/* for dateRangePicker */}
          <Tabs
            value={domain.ui.requirementsUi.analyticsApplicationRequirementDataTimeTab.value}
            onChange={(e, value) => {
              domain.ui.requirementsUi.changeAnalyticsApplicationRequirementDataTimeTabs(value)
            }}
            indicatorColor="secondary"
            textColor="secondary"
            className={classes.timePickerBlock}
          >
            <Tab
              disabled={isChartLoading}
              className={classes.timePickerTab} label={'ГОД'} value={'applicationRequirementsYearData'} />
            <Tab
              disabled={isChartLoading}
              className={classes.timePickerTab} label={'МЕС'} value={'applicationRequirementsMonthData'} />
            <Tab
              disabled={isChartLoading}
              className={classes.timePickerTab} label={'ДЕНЬ'} value={'applicationRequirementsDayData'} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid height={'90%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        {isChartLoading ?
          <CircularProgress /> :
          dataForChart.length ?
            <>
              <ResponsiveContainer width={'100%'} height={350} >
                <ComposedChart data={dataForChart} margin={{ top: 24 }}>
                  <XAxis dataKey="date" fontSize={10} domain={[3, 12]} allowDataOverflow />
                  <YAxis fontSize={12} width={34} />
                  <Tooltip content={<CustomizedTooltip isNeedBarLabel={isNeedBarLabel} />} />
                  <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" vertical={false} opacity={0.3} />
                  <Bar
                    label={isNeedBarLabel && { position: 'top' }}
                    name={"Всего"} dataKey="totalRequirements" fill='#66BB6A4D'
                    maxBarSize={80}
                    radius={[5, 5, 0, 0]}
                    style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, marginTop: 10 }}
                  />
                  {currentChartStatuses.length && currentChartStatuses.map((statusValueData) => {
                    const statusColor = StatusValueColor.find(item => item.id === statusValueData.color)?.rgbaTemplate
                    const rgbColor = Colors.rgbaOpacityTemplate(statusColor, '1')
                    const statusValueName = statusValueData.isDeleted ? statusValueData.statusValueName + ' (Удален)' : statusValueData.statusValueName
                    return <Line name={statusValueName} type="linear" dot={{ fill: rgbColor }} dataKey={statusValueData.statusValueId} stroke={rgbColor} />
                  })}
                </ComposedChart>
              </ResponsiveContainer>
              <Grid className={classes.dynamicsChartLegendBlock}>
                <Grid>
                  <LegendChartLabel labelName={"Требования"} color="#66BB6A4D" />
                </Grid>
                <Grid className={classes.dynamicsChartLegendStatusesBlock}>
                  {currentChartStatuses.length > 0 && currentChartStatuses.map((statusValueData) => {
                    const statusColor = StatusValueColor.find(item => item.id === statusValueData.color)?.rgbaTemplate
                    const rgbColor = statusColor ? Colors.rgbaOpacityTemplate(statusColor, '1') : "#FFFFFF"
                    const statusValueName = statusValueData.isDeleted
                      ? statusValueData.statusValueName + ' (Удален)' : statusValueData.isActive
                        ? statusValueData.statusValueName : statusValueData.statusValueName + ' (Не активен)'

                    return <LegendChartLabel labelName={statusValueName} color={rgbColor as string} />
                  })}
                </Grid>
              </Grid>
            </> : <></>
        }
      </Grid>
    </Grid>)
})

// todo - make reuseble
const CustomizedTooltip = observer((props) => {
  const classes = useStyles();
  const { active, payload, isNeedBarLabel } = props
  const lineData = payload.map((item, index) => {
    if (index) {
      return <div>{item.name}: {item.value}</div>
    } else {
      return null;
    }
  }).filter(item => item !== undefined);

  return (
    <>
      {active && payload &&
        <div className={classes.chartTooltip}>
          {!isNeedBarLabel && <div>Всего: {payload[0].payload.totalRequirements}</div>}
          {lineData}
          {!isNeedBarLabel && <div>Дата: {payload[0].payload.date}</div>}
        </div >}
    </>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  dynamicsChartBlock: {
    minWidth: 570,
    minHeight: 500, // изменить на 350
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 16px 24px',
    backgroundColor: '#29363C',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center'
  },
  dynamicsChartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    height: 30,
    width: '100%'
  },
  dynamicsChartMainBlock: {
    height: '90%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dynamicsChartLegendBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  dynamicsChartDateChanger: {
    display: 'flex'
  },
  dynamicsChartLegendStatusesBlock: {
    display: 'flex',
    overflowX: 'auto',
    height: 36,
    flexWrap: 'nowrap'
  },
  timePickerBlock: {
    padding: 0,
    height: 30,
    minHeight: 30
  },
  timePickerTab: {
    width: 42,
    minWidth: 42,
    height: 25,
    minHeight: 25
  },
  chartTooltip: {
    backgroundColor: '#616161',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    borderRadius: 4,
    alignItems: 'flex-start',
    padding: 4
  },
}));
