import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IAdditionalColumnModel } from './IAdditionalColumnModel';
import { IAdditionalColumnSearch } from './IAdditionalColumnSearch';
import { IAdditionalColumnService } from './IAdditionalColumnService';

export class RestAdditionalColumnService
  extends RestCRUDService<IAdditionalColumnModel, IAdditionalColumnSearch>
  implements IAdditionalColumnService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/additional/column`;
  }
}
