import React from "react";
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

export const FakeChart = ({
  showedText = 'Загрузка...',
  img = <CircularProgress />
}: {
  showedText: string,
  img: JSX.Element | null
}) => {
  const classes = useStyles();

  const data = [
    { a: 5, b: 7 },
    { a: 4, b: 8 },
    { a: 6, b: 6 },
    { a: 2, b: 10 },
    { a: 10, b: 2 },
    { a: 5, b: 7 },
    { a: 7, b: 5 },
    { a: 5, b: 7 },
    { a: 4, b: 8 },
  ]
  return (
    <div className={classes.fakeChartBlock}>
      <div className={classes.fakeChartBlockBlur}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: 320,
        }}>
          {img}
          {showedText}
        </div>
      </div>
      <ResponsiveContainer width={'100%'} height={350}>
        <ComposedChart data={data}>
          <XAxis dataKey="date" fontSize={10} domain={[3, 12]} allowDataOverflow />
          <YAxis fontSize={12} width={22} />
          <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" vertical={false} opacity={0.3} />
          <Bar label={{ position: 'top' }} name={"Всего"} dataKey="a" fill='#FFCA28'
            radius={[5, 5, 0, 0]} style={{ borderTopLeftRadius: 1, borderTopRightRadius: 1 }} barSize={14} />
          <Bar label={{ position: 'top' }} name={"Всего"} dataKey="b" fill='#F4511E'
            radius={[5, 5, 0, 0]} style={{ borderTopLeftRadius: 1, borderTopRightRadius: 1 }} barSize={14} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  fakeChartBlock: {
    height: 500, // изменить на 350 
    minWidth: 570,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 16px 24px',
    backgroundColor: '#29363cee',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  fakeChartBlockBlur: {
    position: 'absolute',
    width: '100%',
    height: 340,
    zIndex: 100,
    backgroundColor: '#29363cf7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));