import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IUnitListAdminPageDomain } from '../../../../../../../../extension/learning/view/admin/page/unitListPage/store/IUnitListAdminPageDomain';
import { UnitListAdminPageDomain } from '../../../../../../../../extension/learning/view/admin/page/unitListPage/store/UnitListAdminPageDomain';
import { IAdminPageProperties } from '../../../../../IAdminPageProperties';
import { RecommendedUnitListCard } from './RecommendedUnitListCard';

export interface Props extends IAdminPageProperties {
  currentRoleId?: string | null;
  checkedUnits?: string[];
  setCheckedUnits?: (value: any) => any;
}

export const RecommendedUnits = observer(({ layoutDomain, currentRoleId, checkedUnits, setCheckedUnits }: Props) => {
  const [unitListAdminPageDomain] = useState<IUnitListAdminPageDomain>(new UnitListAdminPageDomain(layoutDomain));

  useEffect(() => {
    const checkedUnits: string[] = [];
    unitListAdminPageDomain.ui.filtredGroupedUnits.list.map((group) => {
      group.unitsList.forEach((unit) => {
        if (currentRoleId && unit.settings.teamRolesIds.includes(currentRoleId)) {
          checkedUnits.push(unit.id);
        }
        setCheckedUnits?.(checkedUnits);
      });
    });
  }, [currentRoleId, unitListAdminPageDomain.ui.filtredGroupedUnits.list]);

  useEffect(() => {
    unitListAdminPageDomain.loadData();
  }, [unitListAdminPageDomain]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        padding: '0 16px',
      }}
    >
      <Typography variant="h4" sx={{ opacity: 0.7 }}>
        Рекомендованные юниты для роли
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3,1fr)',
          columnGap: '24px',
          alignItems: 'baseline',
          rowGap: '32px',
          paddingBottom: '64px',
        }}
      >
        {unitListAdminPageDomain.ui.filtredGroupedUnits.list.map((group) => {
          return (
            <RecommendedUnitListCard
              title={group.groupName}
              units={group.unitsList}
              domain={unitListAdminPageDomain}
              currentRoleId={currentRoleId}
              checkedUnits={checkedUnits}
              setCheckedUnits={setCheckedUnits}
            />
          );
        })}
      </Box>
    </Box>
  );
});
