import { RestService } from '../../common/rest/RestService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IAdditionalColumnTypeModel } from './IAdditionalColumnTypeModel';
import { IAdditionalColumnTypeService } from './IAdditionalColumnTypeService';

export class RestAdditionalColumnTypeService extends RestService implements IAdditionalColumnTypeService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  async getTypes(): Promise<IAdditionalColumnTypeModel> {
    return await this.request()
      .url(this.getEndpoint())
      .get()
      .json<IAdditionalColumnTypeModel>()
      .catch(this.errorHandler.handle);
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/additional/column/type`;
  }
}
