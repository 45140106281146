import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IAdditionalColumnContentModel } from './IAdditionalColumnContentModel';
import { IAdditionalColumnContentSearch } from './IAdditionalColumnContentSearch';
import { IAdditionalColumnContentService } from './IAdditionalColumnContentService';

export class RestAdditionalColumnContentService
  extends RestCRUDService<IAdditionalColumnContentModel, IAdditionalColumnContentSearch>
  implements IAdditionalColumnContentService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/additional/column/content`;
  }
}
