import { IPassingStepperDomain } from '../../../../../../../design/passingStepper/store/IPassingStepperDomain';
import { PassingStepperDomain } from '../../../../../../../design/passingStepper/store/PassingStepperDomain';
import { IPassingFinalResultsDomain } from '../IPassingFinalResultsDomain';
import { PassingFinalResultsDomain } from '../PassingFinalResultsDomain';
export class PassingFinalResultsStepperDomain extends PassingStepperDomain implements IPassingStepperDomain {
  public rootDomain: IPassingFinalResultsDomain;
  constructor(rootDomain: IPassingFinalResultsDomain) {
    super();
    this.rootDomain = rootDomain || new PassingFinalResultsDomain();
  }
}
