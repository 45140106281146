import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { GenerateUUID } from '../../../../service/pseudoId/GenerateUUID';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { RiskManagerQuestionFieldInputType } from '../../../../service/question/field/RiskManagerQuestionFieldInputType';
import { RiskManagerQuestionFieldRelationType } from '../../../../service/question/field/RiskManagerQuestionFieldRelationType';
import { RiskManagerQuestionFieldForm } from './RiskManagerQuestionFieldForm';

const defaultModelValue: IRiskManagerQuestionFieldModel = {
  description: '',
  name: '',
  sortIndex: 1,
  inputType: RiskManagerQuestionFieldInputType.multiRow,
  relationType: RiskManagerQuestionFieldRelationType.answers,
  relationValues: { values: [] },
};

export class RiskManagerQuestionFieldFormUI
  extends DataTableFormUI<IRiskManagerQuestionFieldModel>
  implements IDataTableFormUI<IRiskManagerQuestionFieldModel>
{
  constructor(
    public answerPresets: IEntityListStore<IRiskManagerQuestionAnswerPresetModel> = injectEntityList([]),
    public defaultAnswerPreset: IEntityStore<IRiskManagerQuestionAnswerPresetModel | null> = injectEntity(null),
  ) {
    super(defaultModelValue);
    this.formComponent.setValue(RiskManagerQuestionFieldForm);
  }
}
