import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { IAdditionalColumnContentModel } from '../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { IAdditionalColumnContentSearch } from '../../../../../../service/additionalColumn/content/IAdditionalColumnContentSearch';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdditionalColumnContentDomain } from './AdditionalColumnContentDomain';
import { AdditionalColumnRequirementRelationType } from '../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';

export class AdditionalColumnContentUI
  extends DataTableUI<IAdditionalColumnContentModel, IAdditionalColumnContentSearch>
  implements IDataTableUI<IAdditionalColumnContentModel, IAdditionalColumnContentSearch>
{
  constructor(
    public domain: AdditionalColumnContentDomain,
    public layoutDomain: IMainLayoutDomainStore,

  ) {
    super(domain, null);
    this.rows.requestSearch = (searchTerm) => {
      this.requestSearch(searchTerm || '')
    }
  }
  setDomainId(): string {
    return 'settings/additional/column/content';
  }

  requestSearch = (searchTerm: string) => {
    this.rows.searchTerm.setValue(searchTerm);
    const searchRegex = new RegExp(this.rows.escapeRegExp(searchTerm.trim()), 'i');
    const filteredRows = [...this.rows.entities.list]
      .filter((row) => {
        return Object.keys(row).some((field) => {
          let value = ''
          if (field === 'additionalColumnId') {
            value = this.domain.additionalColumns.list
              .find((additionalColumnValue) => row[field] === additionalColumnValue.id)?.name.toLocaleLowerCase() || ''
            return searchRegex.test(value);
          } if (field === 'requirementsIds') {
            if (row.requirementsRelationType === AdditionalColumnRequirementRelationType.all) {
              value = 'Все требования';
              return searchRegex.test(value);
            }
            value = this.domain.requirements.list
              .filter((requirement) => row[field].includes(requirement.id || ''))
              .map((requirement) => requirement?.shortName).join().toLocaleLowerCase();
            return searchRegex.test(value);
          } else {
            value = this.rows.getFieldValueAsString(field, row[field]);
            return searchRegex.test(value);
          }
        });
      });
    this.rows.setFilteredEntities(filteredRows);
  }

  async getColumns(): Promise<GridColumns> {
    const additionalColumns = this.domain.additionalColumns.list.map((column) => ({
      value: column.id,
      name: column.isActive ? column.name : `${column.name} (неактивен)`,
    }));
    const requirements = this.domain.requirements.list.map((column) => ({
      value: column.id,
      name: column.shortName,
    }));

    this.setActionColumn()
    return [
      {
        field: 'content',
        headerName: 'Контент',
        flex: 1,
        type: 'string',
        renderCell: (params) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params?.row?.content || ''} />;
        },
      },
      {
        headerName: 'Дополнительная колонка',
        field: 'additionalColumnId',
        flex: 1,
        type: 'string',
        renderCell: (params) => {
          const additionalColumnName = additionalColumns.find((category) => category.value === params?.row?.additionalColumnId)?.name || ''
          return <MultiLineText words={this?.rows?.searchTerm?.value} value={additionalColumnName || ''} />;
        },
        valueGetter: ({ row: entityData }) => {
          const realValueName = this.domain.additionalColumns.list.find(item => item.id === entityData.additionalColumnId)?.name.toUpperCase() || ''
          return realValueName
        },
      },
      {
        field: 'requirementsIds',
        headerName: 'Требования',
        flex: 1,
        type: 'string',
        valueGetter: ({ row: entityData }) => {
          let names = requirements
            .filter((requirement) => entityData.requirementsIds.includes(requirement?.value || ''))
            .map((requirement) => requirement?.name);
          if (entityData.requirementsRelationType === AdditionalColumnRequirementRelationType.all) {
            names = ['Все требования'];
          }
          const result = names.join() || ''
          return result
        },
        renderCell: (params) => {
          let names = requirements
            .filter((requirement) => params?.row?.requirementsIds.includes(requirement?.value || ''))
            .map((requirement) => requirement?.name);

          if (params.row.requirementsRelationType === AdditionalColumnRequirementRelationType.all) {
            names = ['Все требования'];
          }
          return <MultiLineText words={this?.rows?.searchTerm?.value} value={names || ''} />;
        },
      },
    ];
  }



  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
