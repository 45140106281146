import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../common/store/interface/IEntityStore';
import { RequirementEntityDrawerDomain } from '../../../../../view/admin/page/requirement/newEntity/store/drawer/RequirementEntityDrawerDomain';
import { RequirementEntityModalDomain } from '../../../../../view/admin/page/requirement/newEntity/store/modal/RequirementEntityModalDomain';
import { IRiskManagerRequirementThreatLinkModel } from '../../../service/requirement/IRiskManagerRequirementThreatLinkModel';
import { IRiskManagerRiskModel } from '../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';

export class RiskManagerRequirementThreatFormFieldsDomain {
  constructor(
    public baseDomain: RequirementEntityModalDomain,
    private rootPrivateServices: RiskManagerRootService = new RiskManagerRootService('admin'),
    public requirementThreatLink: IEntityStore<IRiskManagerRequirementThreatLinkModel> = injectEntity({
      risksMap: { risksIds: [] },
      requirementId: '',
    }),
    public risks: IEntityListStore<IRiskManagerRiskModel> = injectEntityList([]),
  ) {}

  async loadRequirementLink() {
    this.requirementThreatLink.setEntity({
      requirementId: '',
      risksMap: { risksIds: this.requirementThreatLink.entity?.risksMap?.risksIds || [] },
    });
    if (this.baseDomain.ui.model.entity.id) {
      const requirementThreatLink = (
        await this.rootPrivateServices.requirement.link
          .search({
            limit: 1,
            filter: { requirementId: { equal: this.baseDomain.ui.model.entity.id } },
          })
          .catch((error) => ({
            data: [],
          }))
      ).data[0];

      if (requirementThreatLink) {
        this.requirementThreatLink.setEntity(requirementThreatLink);
      }
    }

    const risks = await this.rootPrivateServices.risk.search({ limit: 10000 }).catch((error) => ({ data: [] }));
    this.risks.setList(risks.data);
  }

  async save() {
    const entity = this.requirementThreatLink.entity;
    entity.requirementId = this.baseDomain.ui.model.entity.id || '';

    if (entity.id && entity.id) {
      await this.baseDomain.callService(this.rootPrivateServices.requirement.link.updateByModel(entity));
    } else {
      this.requirementThreatLink.entity.id = (await this.baseDomain.callService(
        this.rootPrivateServices.requirement.link.createByModel(entity),
      )) as string;
    }
  }
}

export class RiskManagerRequirementThreatDrawerFieldsDomain {
  constructor(
    public baseDomain: RequirementEntityDrawerDomain,
    private rootPrivateServices: RiskManagerRootService = new RiskManagerRootService('admin'),
    public requirementThreatLink: IEntityStore<IRiskManagerRequirementThreatLinkModel> = injectEntity({
      risksMap: { risksIds: [] },
      requirementId: '',
    }),
    public risks: IEntityListStore<IRiskManagerRiskModel> = injectEntityList([]),
  ) {}

  async loadRequirementLink() {
    this.requirementThreatLink.setEntity({
      requirementId: this.baseDomain.ui.model.entity?.id || '',
      risksMap: { risksIds: this.requirementThreatLink.entity?.risksMap?.risksIds || [] },
    });
    if (this.baseDomain.ui.model.entity?.id) {
      const requirementThreatLink = (
        await this.rootPrivateServices.requirement.link
          .search({
            limit: 1,
            filter: { requirementId: { equal: this.baseDomain.ui.model.entity.id } },
          })
          .catch((error) => ({
            data: [],
          }))
      ).data[0];

      if (requirementThreatLink) {
        this.requirementThreatLink.setEntity(requirementThreatLink);
      }
    }

    const risks = await this.rootPrivateServices.risk.search({ limit: 10000 }).catch((error) => ({ data: [] }));
    this.risks.setList(risks.data);
  }

  async save() {
    const entity = this.requirementThreatLink.entity;
    entity.requirementId = this.baseDomain.ui.model.entity.id || '';

    if (entity.id && entity.id) {
      await this.baseDomain.callService(this.rootPrivateServices.requirement.link.updateByModel(entity));
    } else {
      this.requirementThreatLink.entity.id = (await this.baseDomain.callService(
        this.rootPrivateServices.requirement.link.createByModel(entity),
      )) as string;
    }
  }
}
