import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { CoreRestCRUDService } from '../coreCommon/common/rest/CoreRestCRUDService';
import { INotificationModel } from './INotificationModel';
import { INotificationService } from './INotificationService';

export class RestNotificationService extends CoreRestCRUDService<INotificationModel> implements INotificationService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/notification`;
  }

  async getUnreadCount(): Promise<{ unreadCount: number }> {
    const searchResult = await this.request().url(`${this.getEndpoint()}/count`).get().res().catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async readNotifications(unreadNotifications: INotificationModel[]): Promise<any> {
    return await this.request()
      .url(`${this.getEndpoint()}/readNotifications`)
      .put(unreadNotifications)
      .json<{ state: string; id: string }>()
      .catch(this.handleError);
  }

  async subscribeSocket(handler: (data: any) => void): Promise<void> {
    // this.getSocket().on('notification', handler);
  }
}
