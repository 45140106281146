import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UnitDomain } from '../../../store/UnitDomain';

export const useFormValidation = (unitDomain: UnitDomain) => {
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({ title: '' });
  const isFormValid = useMemo(() => !Object.values(validationErrors).filter((item) => item).length, [validationErrors]);
  const isInitialValidationDone = useRef(false)

  useEffect(() => {
    unitDomain.ui.validSteps.setEntity({ ...unitDomain.ui.validSteps.entity, settings: isFormValid });
  }, [isFormValid, unitDomain.ui.validSteps]);

  const onTitleChange = useCallback(
    (value) => {
      setValidationErrors?.({ title: !value.trim() ? 'Заполните поле' : '' });
      unitDomain.ui.unit.entity.settings.titleHeading = value;
    },
    [setValidationErrors, unitDomain.ui.unit.entity.settings],
  );

  useEffect(() => {
    if(!isInitialValidationDone.current && unitDomain.ui.unit.entity.settings) {
      onTitleChange(unitDomain.ui.unit.entity.settings.titleHeading)
      isInitialValidationDone.current = true
    }
  }, [onTitleChange, unitDomain.ui.unit.entity.settings])

  return { validationErrors, onTitleChange };
};
