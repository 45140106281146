import Container from '@mui/material/Container';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export interface IEmptyLayoutProperties extends ICommonProperties {
  children: any;
}

export default function EmptyLayout({ children }: IEmptyLayoutProperties) {
  return (
    <Container component="main" maxWidth="xs">
      {children}
    </Container>
  );
}
