import { createContext } from 'react';
import { IPassingFinalResultsDomain } from './parts/finalResults/store/IPassingFinalResultsDomain';
import { IPassingPracticeDomain } from './parts/practice/store/IPassingPracticeDomain';
import { IPassingQuizDomain } from './parts/quiz/store/IPassingQuizDomain';
import { IPassingTheoryDomain } from './parts/theory/store/IPassingTheoryDomain';
import { IPassingDomain } from './store/IPassingDomain';

export type PassingContextType = {
redirectToStep: (event: React.MouseEvent<Element, MouseEvent>, step: string) => void;
resetSteps: () => void;
passingDomain: IPassingDomain;
theoryDomain: IPassingTheoryDomain;
quizDomain: IPassingQuizDomain;
practiceDomain: IPassingPracticeDomain;
finalResultsDomain: IPassingFinalResultsDomain;
};

export const PassingContext = createContext<PassingContextType>({} as PassingContextType);
