import { FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';

export interface IFormTextFieldProperties extends ICommonProperties {
  label: string;
  onChange: (newValue: string) => void;
  onBlur?: (newValue: string) => void;
  value?: string;
  type?: string;
  errorMessage?: string | null;
  multiline?: boolean;
  rows?: string | number | undefined;
  helperText?: string | undefined;
  required?: boolean | undefined;
  disabled?: boolean;
  style?: any;
  inputProps?: any;
  placeholder?: string;
  variant?: 'standard' | 'outlined' | 'filled';
}

export const FormTextFieldStandard = observer(
  ({
    label,
    onChange,
    onBlur,
    value = '',
    multiline = false,
    type = 'text',
    errorMessage = null,
    rows = undefined,
    helperText = undefined,
    required = false,
    disabled = false,
    style = {},
    inputProps = {},
    placeholder = '',
    variant = 'standard',
  }: IFormTextFieldProperties) => {
    const classes = useStyles();
    const onTextFieldChange = (event: any) => {
      onChange(event.target.value);
    };
    const onTextFiledBlur = (event: any) => {
      onBlur && onBlur(event.target.value);
    };
    return (
      <FormControl fullWidth className={classes.formControl} variant={variant}>
        <TextField
          onBlur={onTextFiledBlur}
          style={style}
          disabled={disabled}
          required={required}
          error={!!errorMessage}
          helperText={errorMessage || null}
          value={value}
          type={type}
          onChange={onTextFieldChange}
          label={label}
          variant={variant}
          multiline={multiline || false}
          rows={rows}
          inputProps={inputProps}
          placeholder={placeholder}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {},
}));
