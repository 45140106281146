import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { inject } from '../../../../common/container/inject';
import { IRootPublicService, RootPublicServiceToken } from '../../../../service/public/RootPublicService';
import { IRouterService, RouterServiceToken } from '../../../../service/route/IRouterService';
import { SignInPage } from './SignInPage';

export const SignInPageWithLogout = observer(() => {
  const [services] = useState(inject<IRootPublicService>(RootPublicServiceToken));
  const [router] = useState(inject<IRouterService>(RouterServiceToken));

  useEffect(() => {
    const logout = async () => {
      await services.activeUser.logoutActiveUser();
      setTimeout(() => {
        router.goTo('/login');
      }, 100);
    };
    logout();
  }, [services, router]);
  return <div></div>;
});
