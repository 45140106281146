import { BlockType } from '../../../../../service/passing/interface/IPassingTheory';
import { CodeExample } from './media/CodeExample';
import { Media } from './media/Media';
import { HiddenBlock } from './nesting/HiddenBlock';
import { SpecialBlock } from './nesting/SpecialBlock';
import { TabsBlock } from './nesting/TabsBlock';
import { TagsBlock } from './nesting/TagsBlock';
import { Spacing48 } from './spacing/Spacing48';
import { Spacing64 } from './spacing/Spacing64';
import { Heading1 } from './text/Heading1';
import { Heading2 } from './text/Heading2';
import { Heading3 } from './text/Heading3';
import { List } from './text/List';
import { Paraghraph } from './text/Paraghraph';
import { TableComponent } from './text/Table';

type TheoryComponentMap = {
  [key in BlockType]: React.ComponentType<any>;
};

export const TheoryComponentList: TheoryComponentMap = {
  [BlockType.HEADING_1]: Heading1,
  [BlockType.HEADING_2]: Heading2,
  [BlockType.HEADING_3]: Heading3,
  [BlockType.PARAGRAPH]: Paraghraph,
  [BlockType.TABLE]: TableComponent,
  [BlockType.LIST]: List,
  [BlockType.IMAGE]: Media,
  [BlockType.VIDEO]: Media,
  [BlockType.CODE_EXAMPLE]: CodeExample,
  [BlockType.SPECIAL_BLOCK]: SpecialBlock,
  [BlockType.SPACING_48]: Spacing48,
  [BlockType.SPACING_64]: Spacing64,
  [BlockType.HIDDEN_BLOCK]: HiddenBlock,
  [BlockType.TABS]: TabsBlock,
  [BlockType.TAGS]: TagsBlock,
};
