import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { ITagCategoryModel } from '../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../service/tag/entity/ITagEntityModel';
import { FilterTitle } from './FilterTitle';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';

interface TagsFilterProperties {
  tags: IEntityListStore<ITagEntityModel>;
  tagsCategories: IEntityListStore<ITagCategoryModel>;
  searchTagsIds: IPrimitiveStore<string[]>;
  setNewValue: (id: string, checked: boolean) => void;
  rootDomain?: any
}
export const TagsFilter = observer(({ tags, tagsCategories, searchTagsIds, setNewValue, rootDomain }: TagsFilterProperties) => {
  const currentTagsArray =
    rootDomain?.filterDomain.filteredTags
      && rootDomain?.filterDomain.filteredTags.value !== null
      ? rootDomain?.filterDomain.filteredTags.value
      : tags.list

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} style={{ boxShadow: "none", position: 'inherit' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
        <FilterTitle selectedCount={searchTagsIds.value.length} title={'Тэги'} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={!(rootDomain instanceof RequirementEntityAdminDomain)}>
          {currentTagsArray.length ? tagsCategories.list.map((tagCategory) => {
            const categorySpecifications = currentTagsArray.filter((tag) => tag.categoryId === tagCategory.id);
            if (categorySpecifications.length !== 0) {
              return (
                <TagCategory
                  key={tagCategory.id}
                  category={tagCategory}
                  categoryTags={categorySpecifications}
                  searchTagsIds={searchTagsIds.value}
                  setNewValue={setNewValue}
                />
              );
            }
          })
            : <div>Теги не найдены</div>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});

interface TagCategoryProperties {
  category: ITagCategoryModel;
  categoryTags: ITagEntityModel[];
  searchTagsIds: string[];
  setNewValue: (id: string, checked: boolean) => void;
}

const TagCategory = observer(({ category, categoryTags, searchTagsIds, setNewValue }: TagCategoryProperties) => {
  return (
    <div>
      <FormControl component="fieldset" margin='none'>
        <FormLabel
          color="secondary"
          component="legend"
          style={{ marginBottom: 10 }}
        >
          {category.name}
        </FormLabel>
        <FormGroup
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {categoryTags.map((tag) => {
            const isChecked = searchTagsIds.includes(tag.id || '');
            return <Tag key={tag.id} tag={tag} setNewValue={setNewValue} isChecked={isChecked} />;
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
});

interface TagProperties {
  setNewValue: (id: string, checked: boolean) => void;
  tag: ITagEntityModel;
  isChecked: boolean;
}

const Tag = observer(({ tag, isChecked, setNewValue }: TagProperties) => {
  return (
    <FormControlLabel
      style={{ marginBottom: 15 }}
      control={
        <Checkbox
          checked={isChecked}
          name={tag.name}
          sx={{
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
      }
      onChange={(event: any) => {
        setNewValue(tag.id || '', event.target.checked);
      }}
      label={tag.name}
    />
  );
});
