import { inject } from '../../../../../../../common/container/inject';
import { IRootPublicService } from '../../../../../../../service/public/RootPublicService';
import { IRootAdminService } from '../../../../../../../service/RootAdminService';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IPassingCodeEditorDomain } from '../../../../design/codeEditor/passingStore/IPassingCodeEditorDomain';
import { PassingCodeEditorDomain } from '../../../../design/codeEditor/passingStore/PassingCodeEditorDomain';
import { ILearningUnitPreviewPageUI, LearningUnitPreviewPageUI } from './LearningUnitPreviewPageUI';

export interface ILearningUnitPreviewPageDomain {
  layoutDomain: IMainLayoutDomainStore;
  learningRootService: ILearningRootService;
  rootService: IRootPublicService | IRootAdminService;
  ui: ILearningUnitPreviewPageUI;
  passingCodeEditorDomain: IPassingCodeEditorDomain;
  loadData: (id: IUnitModel['id']) => void;
}

export class LearningUnitPreviewPageDomain implements ILearningUnitPreviewPageDomain {
  ui: LearningUnitPreviewPageUI;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public passingCodeEditorDomain = new PassingCodeEditorDomain(),
    public rootService = injectRootService(layoutDomain.serviceType.value),
    public learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {
    this.ui = new LearningUnitPreviewPageUI();
  }

  loadData = async (id: IUnitModel['id']) => {
    this.ui.isLoading.setValue(true);
    const unit = await this.learningRootService.unit.getById(id);
    this.ui.unit.setEntity(unit);
    this.ui.isLoading.setValue(false);
    this.ui.activeSteps.setList(this.getActiveStatuses(unit));
    this.ui.currentStep.setValue(this.ui.activeSteps.list[0]);
  };

  getActiveStatuses(unit: IUnitModel) {
    const steps: string[] = [];

    if (unit.theory?.isEnabled) {
      steps.push('theory');
    }

    if (unit.quiz?.isEnabled) {
      steps.push('quiz');
    }

    if (unit.practice?.isEnabled) {
      steps.push('practice');
    }

    steps.push('finalResults');

    return steps;
  }
}
