import { IApplicationAffectService } from '../../../../../../../service/application/affect/IApplicationAffectService';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationAffectAdminModel } from '../ApplicationAffectAdminModel';
import { ApplicationAffectModalUI } from './ApplicationAffectModalUI';

export class ApplicationAffectModalDomain
  extends DataTableFormDomain<ApplicationAffectAdminModel, IApplicationAffectService>
  implements IDataTableFormDomain<ApplicationAffectAdminModel>
{
  public ui: ApplicationAffectModalUI;
  constructor(
    entityService: IApplicationAffectService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ApplicationAffectAdminModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new ApplicationAffectModalUI());
    this.ui = new ApplicationAffectModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  async loadDependency() {
    const requirementsCategories = await this.rootPrivateServices.requirement.category.search({ limit: 10000000 });
    const requirements = await this.rootPrivateServices.requirement.entity.search({ limit: 10000000 });
    this.ui.requirements.setList(requirements.data);
    this.ui.requirementsCategories.setList(requirementsCategories.data);
  }
}
