import { IValidationErrorData } from '../../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';

export class FormUI<ModelType extends any> {
  constructor(
    //@ts-ignore
    public model = injectEntity<ModelType>({}),
    public validationErrors = injectEntityList<IValidationErrorData>([]),
    public isError = injectPrimitive<boolean>(false),
    public isLoading = injectPrimitive<boolean>(false),
  ) {}
}
