import { ApplicationRouterExtensions } from '../../application/ApplicationRouterExtensions';
import { IContainer } from '../../common/container/IContainer';
import { SettingsPageExtension } from '../../view/admin/page/settings/SettingsPageExtension';
import { IExtension } from '../interface/IExtension';
import { AdminLogsRouter } from './admin/logs/router/AdminLogsRouter';
import { AdminLogsSettingsMenu } from './admin/logs/settings/AdminLogsSettingsMenu';

export default class MvideoExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container = this.setRouters(container);
    container = this.setSettingsPage(container);
  }

  setSettingsPage(container: IContainer): IContainer {
    const settingsPageExtensions = container.get(SettingsPageExtension);
    settingsPageExtensions.push(AdminLogsSettingsMenu);
    return container;
  }

  setRouters(container: IContainer) {
    let routerExtension = container.get(ApplicationRouterExtensions);
    routerExtension.push(AdminLogsRouter);

    container.set(ApplicationRouterExtensions, routerExtension);
    return container;
  }
}
