import { Done, StarBorder } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../service/unitResult/IUnitResultModel';

export interface IUnitListUserCardProperties {
  card: IUnitModel;
  action: any;
  score: number;
  passingUnitResult: IUnitResultModel;
}
export const UnitListUserCard = observer(({ card, action, score, passingUnitResult }: IUnitListUserCardProperties) => {
  return (
    <>
      {passingUnitResult && (
        <Box
          onClick={() => {
            action(card.id);
          }}
          sx={{
            cursor: 'pointer',
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            background:
              passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult !== 0
                ? 'rgba(46, 125, 50, 0.12)'
                : !passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult !== 0
                ? 'rgba(255, 255, 255, 0.12)'
                : 'rgba(144, 202, 249, 0.12)',
            '&:hover': {
              background:
                passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult !== 0
                  ? 'rgba(46, 125, 50, 0.18)'
                  : !passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult !== 0
                  ? 'rgba(255, 255, 255, 0.18)'
                  : 'rgba(144, 202, 249, 0.18)',
            },
          }}
        >
          <Typography sx={{ maxWidth: '250px' }}>{card.settings.titleHeading}</Typography>
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {passingUnitResult?.isPassed && (
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                {Math.round(passingUnitResult?.totalUnitResult)}
                <span style={{ color: 'rgba(255,255,255,0.5)' }}> / {Math.round(score)}</span>
              </Typography>
            )}
            {!passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult > 0 && (
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                {Math.round(passingUnitResult.totalUnitResult)}
                <span style={{ color: 'rgba(255,255,255,0.5)' }}> / {Math.round(score)}</span>
              </Typography>
            )}
            {passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult !== 0 && (
              <Done sx={{ height: '20px', width: '20px', background: 'rgba(102, 187, 106, 1)', borderRadius: '50%' }} />
            )}
            {!passingUnitResult?.isPassed && passingUnitResult?.totalUnitResult === 0 && (
              <>
                <Typography>+ {Math.round(score)} </Typography>
                <StarBorder
                  sx={{ height: '20px', width: '20px', background: 'rgba(25, 118, 210, 1)', borderRadius: '50%' }}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
});
