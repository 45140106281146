import { domain } from 'process';

import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper } from '@mui/material';
import React from 'react';

export interface FormModalContainerProperties {
  children: any;
  onClose?: () => void;
  width?: number;
  dataCy?: string
}

export const FormModalContainer = ({ children, onClose = () => {}, width = 500, dataCy }: FormModalContainerProperties) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={{ width: '100%', minWidth: width }}
      data-cy={dataCy}
    >
      <Paper elevation={3} style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ margin: '5px', alignSelf: 'flex-end' }}>
            <IconButton
              data-cy={`close-modal`}
              color="secondary"
              aria-label="Закрыть окно"
              component="span"
              onClick={() => {
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </div>
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};
