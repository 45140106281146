import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { RequirementCategoryAdminModal } from './RequirementCategoryAdminModal';

export class RequirementCategoryAdminModalUI
  extends DataTableFormUI<IRequirementCategoryModel>
  implements IDataTableFormUI<IRequirementCategoryModel>
{
  constructor() {
    super();
    this.formComponent.setValue(RequirementCategoryAdminModal);
  }
}
