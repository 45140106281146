import { RowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../view/design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';

export class RiskManagerQuestionContextMenuUI<RowType> extends RowContextMenuUI<IRiskManagerQuestionModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
