import { observer } from 'mobx-react';
import React from 'react';
import { DiffMethod, StringDiff } from 'react-string-diff';

import { Colors } from '../../../../../../design/color/Colors';

export const ApplicationInfoHistoryDiffText = observer(
  ({ oldValue, newValue }: { oldValue: string; newValue: string }) => {
    return (
      <StringDiff
        styles={{
          added: {
            backgroundColor: Colors.positivePassiveOpacity('0.3'),
          },
          removed: {
            backgroundColor: Colors.negativePassiveOpacity('0.3'),
          },
          default: {},
        }}
        oldValue={oldValue}
        newValue={newValue}
        method={DiffMethod.WordsWithSpace}
      />
    );
  },
);
