import { RowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../view/design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';
import { IRiskManagerStaticQuestionModel } from '../../../../service/question/static/IRiskManagerStaticQuestionModel';

export class RiskManagerStaticQuestionContextMenuUI<RowType> extends RowContextMenuUI<IRiskManagerStaticQuestionModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
