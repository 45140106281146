import { action, computed, observable } from 'mobx';

import { IdGenerator } from '../../../../../common/generator/IdGenerator';
import { UIStore } from '../../../../../common/store/base/UIStore';
import { ILayoutNotification, ILayoutNotificationState } from './ILayoutNotification';
import { ILayoutNotificationStore } from './ILayoutNotificationStore';

export class LayoutNotificationStore extends UIStore implements ILayoutNotificationStore {
  private defaultDurationInMs: number = 5000;

  @observable private notificationsState: ILayoutNotification[] = [];

  @computed get allNotifications() {
    return this.notificationsState;
  }

  @computed get newNotifications() {
    return this.notificationsState.filter((notification) => notification.state === ILayoutNotificationState.new);
  }

  @action.bound
  showNotification(newNotification: ILayoutNotification): void {
    newNotification.id = newNotification.id || IdGenerator.generate();
    newNotification.state = ILayoutNotificationState.new;
    this.notificationsState.push(newNotification);
  }

  @action.bound
  markAsCompleted(id: string): void {
    const notificationIndex = this.notificationsState.findIndex((notification) => notification.id === id);
    this.notificationsState[notificationIndex].state = ILayoutNotificationState.complete;
  }

  @action.bound
  cleanNotifications(): void {
    this.notificationsState = [];
  }
}
