import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { IPassingListPageDomain } from '../../store/IPassingListPageDomain';
import { GroupCardComponentHeader } from './GroupCardComponentHeader';
import { GroupCardComponentList } from './GroupCardComponentList';

interface GroupCardComponentProperties {
  groupName: string;
  passingPercent: number;
  importantUnitList: IUnitModel[];
  unimportantUnitList: IUnitModel[];
  domain: IPassingListPageDomain;
}

export const GroupCardComponent = observer(
  ({ groupName, passingPercent, importantUnitList, unimportantUnitList, domain }: GroupCardComponentProperties) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '16px',
          borderRadius: '16px',
          background: '#2B363C',
        }}
      >
        <GroupCardComponentHeader groupName={groupName} passingPercent={passingPercent} />
        <GroupCardComponentList title={'Обязательные'} unitList={importantUnitList} domain={domain} />
        <GroupCardComponentList title={'Дополнительные'} unitList={unimportantUnitList} domain={domain} />
      </Box>
    );
  },
);
