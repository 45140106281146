import { IContainer } from './IContainer';

/**
 * @deprecated The method should not be used
 */
export const setClassInjection = (container: IContainer, token: any, instance: any) => {
  container.setByToken(token, new instance());

  return container;
};
