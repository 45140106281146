import { ICoreServiceModel } from '../coreCommon/common/model/ICoreServiceModel';

export interface IMailEntityModel extends ICoreServiceModel {
  authType: authEnum;
  name: string;
  host: string;
  port: number;
  user: string;
  password: string;
  workstation?: string;
  domain?: string;
  ignoreTLS: boolean;
}

export enum authEnum {
  Plain = 'Plain',
  NTLM = 'NTLM',
}
