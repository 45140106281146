import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { IActiveDirectoryServerEntityModel } from './IActiveDirectoryServerEntityModel';
import { IActiveDirectoryServerEntitySearch } from './IActiveDirectoryServerEntitySearch';
import { IActiveDirectoryServerEntityService } from './IActiveDirectoryServerEntityService';

export class RestActiveDirectoryServerEntityService
  extends RestCRUDService<IActiveDirectoryServerEntityModel, IActiveDirectoryServerEntitySearch>
  implements IActiveDirectoryServerEntityService
{
  private jwtStorageName = 'jwtToken';

  constructor(private type: CRUDServiceType) {
    super();
  }

  async loginUser(login: string, password: string, serverId: string): Promise<any> {
    const url = `${this.getEndpoint()}/login`;
    const result = await this.request().url(url).post({ login, password, serverId }).json().catch(this.handleError);

    localStorage.setItem(this.jwtStorageName, result.jwt);
    return true;
  }

  async syncById(id: string): Promise<boolean> {
    const url = `${this.getEndpoint()}/sync/${id}`;
    await this.request().url(url).post().json().catch(this.handleError);

    return true;
  }

  async tryToDeleteADConnection(id: string) {
    const url = `${this.getEndpoint()}/tryToDeleteADConnection/${id}`;
    const result = await this.request().url(url).post().json().catch(this.handleError);

    return result;
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/activedirectory/server`;
  }
}
