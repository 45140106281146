import { ICoreServiceModel } from "../../../../service/coreCommon/common/model/ICoreServiceModel";

export interface IUserDataModel extends ICoreServiceModel {
  userId: string;
  level: UserDataLevel;
  totalScore: number;
  maxScore: number;
  toNextLevelScore: number;
}

export enum UserDataLevel {
  SENIOR = 'senior',
  MIDDLE = 'middle',
  JUNIOR = 'junior',
}
