export class ComplexMultiselectDomain {
  protected complexMultiSelectData: any = {};

  getComplexMultiSelectFieldHandler({
    selectedIds = [],
    selectedCategoryId = null,
    selectedCategoryValues = [],
    onChange,
  }: {
    selectedIds: string[];
    selectedCategoryId: string | null | undefined;
    selectedCategoryValues: { value: string }[];
    onChange: (specificationIds: string[]) => void;
  }) {
    if (!selectedCategoryId) {
      return (newValueList: { value?: string }[]) => {
        const newValues = newValueList.map((value) => value.value || '');
        onChange(newValues);
      };
    } else {
      this.complexMultiSelectData[selectedCategoryId] = selectedCategoryValues
        .filter((valueData: { value: string }) => selectedIds.includes(valueData.value))
        .map((valueData: { value: string }) => valueData.value);
      return (newValueList: { value?: string }[]) => {
        this.complexMultiSelectData[selectedCategoryId] = newValueList.map((value) => value.value);
        let resultIds: any[] = [];
        for (let categoryId in this.complexMultiSelectData) {
          resultIds = [...resultIds, ...this.complexMultiSelectData[categoryId]];
        }
        onChange(resultIds);
      };
    }
  }
}
