export enum RiskManagerQuestionFieldInputType {
  multiRow = 'multiRow',
  oneRow = 'oneRow',
  number = 'number',
  // date = 'date',
  // link = 'link',
}

export const RiskManagerQuestionFieldInputTypeArray = Object.keys(RiskManagerQuestionFieldInputType) as Array<
  keyof typeof RiskManagerQuestionFieldInputType
>;
