import { injectEntity } from '../../../../../../../../../common/store/base/injectEntity';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IUnitFinalResultModel } from '../../../../../../../service/unit/interface/IUnitFinalResultModel';
import { IUnitFinalResultsUI } from './IUnitFinalResultsUI';

export class UnitFinalResultsUI implements IUnitFinalResultsUI {
  constructor(
    public isEnabled = injectPrimitive<boolean>(true),
    public finalResult = injectEntity<IUnitFinalResultModel>({} as IUnitFinalResultModel),
  ) {}
}
