import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetSearch } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetSearch';

export class RiskManagerQuestionAnswerPresetUI
  extends DataTableUI<IRiskManagerQuestionAnswerPresetModel, IRiskManagerQuestionAnswerPresetSearch>
  implements IDataTableUI<IRiskManagerQuestionAnswerPresetModel, IRiskManagerQuestionAnswerPresetSearch>
{
  constructor(
    domain: IDataTableDomain<IRiskManagerQuestionAnswerPresetModel, IRiskManagerQuestionAnswerPresetSearch>,
    rows: IDataTableRowsUI<IRiskManagerQuestionAnswerPresetModel, IRiskManagerQuestionAnswerPresetSearch> | null,
  ) {
    super(domain, rows);
    this.tableRootPaperElevation.setValue(0);
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },

      {
        field: 'answers',
        headerName: 'Ответы',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: any[] }) => {
          return (
            <DataTableRowDependency
              searchTerm={this.rows.searchTerm.value}
              values={params.value || []}
              getTextValue={(item: any) => item.text}
            />
          );
        },
      },
      {
        field: 'isDefault',
        headerName: 'По умолчанию',
        flex: 0.3,
        type: 'boolean',
      },
    ];
  }
}
