import { RestCRUDService } from '../../../../../service/common/rest/RestCRUDService';
import { IRiskManagerTemplateModel } from './IRiskManagerTemplateModel';
import { IRiskManagerTemplateSearch } from './IRiskManagerTemplateSearch';
import { IRiskManagerTemplateService } from './IRiskManagerTemplateService';

export class RestRiskManagerTemplateService
  extends RestCRUDService<IRiskManagerTemplateModel, IRiskManagerTemplateSearch>
  implements IRiskManagerTemplateService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/template`;
  }
}
