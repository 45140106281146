import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { PassingStepper } from '../../../../../design/passingStepper/PassingStepper';
import { IPassingPracticeDomain } from './store/IPassingPracticeDomain';
import { PassingPracticeStepperDomain } from './store/steppper/PassingPracticeStepperDomain';

export interface IPassingPractice {
  practiceDomain: IPassingPracticeDomain;
}
export const PassingPractice = observer(({ practiceDomain }: IPassingPractice) => {
  const [stepperDomain] = useState<PassingPracticeStepperDomain>(new PassingPracticeStepperDomain(practiceDomain));

  return <PassingStepper stepperDomain={stepperDomain} />;
});
