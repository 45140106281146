import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { IVendorEntityModel } from '../../../../../service/vendor/entity/IVendorEntityModel';
import { FilterTitle } from './FilterTitle';
import { useRequirementFilterStyle } from './style';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';

interface VendorsFilterProperties {
  vendors: IEntityListStore<IVendorEntityModel>;
  searchIds: IPrimitiveStore<string[]>;
  setNewValue: (id: string, checked: boolean) => void;
  rootDomain?: any
}
export const VendorsFilter = observer(({ vendors, searchIds, setNewValue, rootDomain }: VendorsFilterProperties) => {
  const classes = useRequirementFilterStyle();
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} style={{ boxShadow: "none", position: 'inherit' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
        <FilterTitle selectedCount={searchIds.value.length} title={'Регуляторы'} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={!(rootDomain instanceof RequirementEntityAdminDomain)}>
          <Grid item>
            <FormControl component="fieldset" margin='none'>
              <FormGroup
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {vendors.list.map((vendor) => {
                  const isChecked = searchIds.value.includes(vendor.id || '');
                  return <Vendor key={vendor.id} vendor={vendor} setNewValue={setNewValue} isChecked={isChecked} />;
                })}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});

interface VendorProperties {
  setNewValue: (id: string, checked: boolean) => void;
  vendor: IVendorEntityModel;
  isChecked: boolean;
}

const Vendor = observer(({ vendor, isChecked, setNewValue }: VendorProperties) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          name={vendor.name}
          sx={{
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
      }
      onChange={(event: any) => {
        setNewValue(vendor.id || '', event.target.checked);
      }}
      label={vendor.name}
    />
  );
});
