import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Grid, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { ConstructorType, injectValue } from '../../../../../common/container/inject';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { CBRIcon } from '../../../../design/icon/CBRIcon';
import { OWASPIcon } from '../../../../design/icon/OWASPIcon';
import { PCIIcon } from '../../../../design/icon/PCIIcon';
import { ProjectButtonLink } from '../../../../design/link/ProjectButtonLink';
import { ProjectLink } from '../../../../design/link/ProjectLink';
import { PageEmptyContent } from '../../../../design/page/PageEmptyContent';
import { PageLoader } from '../../../../design/page/PageLoader';
import { IUserPageProperties } from '../../IUserPageProperties';
import { AllRequirementVendorLink } from './inject/AllRequirementVendorLink';
import { IsShowAllRequirementVendorList } from './inject/IsShowAllRequirementVendorList';
import { LeftProjectButtons } from './inject/LeftProjectButtons';
import { ProjectSearchBar } from './parts/ProjectSearchBar';
import { UserProjectTable } from './parts/UserProjectTable';
import { ProjectListDomain } from './store/ProjectListDomain';

export interface IProjectListProperties extends IUserPageProperties { }

export const ProjectListPage = observer(({ layoutDomain }: IProjectListProperties) => {
  const [pageDomain] = useState<ProjectListDomain>(
    new (injectValue<ConstructorType<ProjectListDomain>>(ProjectListDomain))(layoutDomain),
  );
  const AllRequirementVendorLinkComponent = useContext(ContainerContext).get(AllRequirementVendorLink);
  const classes = useStyles();
  const leftProjectButtons = useContext(ContainerContext).get(LeftProjectButtons);

  const loadMore = async () => {
    pageDomain.ui.projectListOffset.setValue(pageDomain.ui.projectListOffset.value + 20);
    await pageDomain.triggerSearch(pageDomain.ui.projectListOffset.value);
  }

  useEffect(() => {
    pageDomain.loadData();
  }, [pageDomain, layoutDomain]);

  useEffect(() => {
    pageDomain.ui.isLoadingProjects.setValue(true);
    setTimeout(() => {
      const projectListLazyLoad = [
        {
          handler: async () => {
            await loadMore();
          },
          idName: `load-more-button`,
          isOnce: true,
        },
      ];
      setTimeout(() => {
        layoutDomain.ui.addDetections(projectListLazyLoad);
        pageDomain.ui.isLoadingProjects.setValue(false);
      }, 1000);
      return () => {
        layoutDomain.ui.removeDetections(projectListLazyLoad);
      };
    }, 1000);
  }, [pageDomain.ui.userProjects.list.length]);

  const emptyTableContent = pageDomain.ui.projectsTotalCount.value
    ? 'По вашим критериям поиска ничего не найдено'
    : 'Вы не являетесь участником проектов';

  const isCanLoadMoreProjects = !pageDomain.ui.isAllProjectsRendered.value;
  const isEmpty = pageDomain.ui.isEmpty.value;
  const isLoading = !isEmpty && pageDomain.ui.isLoading.value;
  const isContentRender = !isEmpty && !isLoading;
  const isCanSeeVendors =
    useContext(ContainerContext).getByToken(IsShowAllRequirementVendorList, true) === true &&
    pageDomain.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-is-allow'],
      UserSystemRoleModelPermissionMap['settings-requirement-vendors-read'],
    ]);
  const projectsCount = pageDomain.ui.projectsTotalCount.value;
  const applicationsCount = pageDomain.ui.applicationsTotalCount.value;

  return (
    <Fragment>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {!isLoading && (
          <Grid item={true} xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'h4'}>Мои проекты</Typography>
              <Divider className={classes.titleDivider} />
              <div className={classes.tableDivider} />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                {/* learning report */}
                {/* {leftProjectButtons.map((Component, index) => {
                return <Component.Component key={index} domain={pageDomain} />;
              })} */}
                {pageDomain.ui.isCanCreate.value === true && (
                  <ProjectButtonLink icon={<AddIcon />} url={`/project/new`} dataCy='add-project-button'>
                    Добавить Проект
                  </ProjectButtonLink>
                )}
              </Box>
            </Grid>
            <Grid item>
              <div className={classes.projectCounter}>
                <div style={{ color: '#b6b6b6' }}>
                  Всего:&nbsp;
                </div>
                <div>
                  {`проектов ${projectsCount}, систем ${applicationsCount}`}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {!isLoading && (<ProjectSearchBar domain={pageDomain} />)}
        {isContentRender && (
          <Grid
            style={{ marginLeft: '-25px', marginTop: '25px' }}
            item={true}
            xs={12}
            sx={{ '& table': { width: '100%' } }}
          >
            <UserProjectTable
              domain={pageDomain}
              userProjectsData={{
                userProjects: pageDomain.ui.userProjects.list,
              }}
            />
            <LoadingButton
              id={`load-more-button`}
              onClick={() => loadMore()}
              variant="outlined"
              color="primary"
              style={{ marginTop: '10px', margin: '0 0 0 31px' }}
              disabled={!isCanLoadMoreProjects}
              loading={pageDomain.ui.isLoadingProjects.value}
            >
              {isCanLoadMoreProjects ? 'Загрузить еще' : 'Все проекты загружены'}
            </LoadingButton>
          </Grid>
        )}
        {isEmpty && (
          <Grid item={true} xs={12}>
            <PageEmptyContent isFullPage={false} text={emptyTableContent} />
          </Grid>
        )}
        {isLoading && <PageLoader />}
        <Toolbar />
      </Grid>
      {isCanSeeVendors && <Typography variant={'h6'}>Коллекции требований</Typography>}
      {isCanSeeVendors && <Divider className={classes.titleDivider} />}
      {isCanSeeVendors && (
        <Grid container direction="row">
          {pageDomain.ui.cbrId.value && (
            <Grid item={true} xs={3} className={classes.imageContainer}>
              <ProjectLink url={`/settings/vendor/requirement/system-type/${pageDomain.ui.cbrId.value}`}>
                <CBRIcon />
              </ProjectLink>
            </Grid>
          )}
          {pageDomain.ui.owaspId.value && (
            <Grid item={true} xs={3} className={classes.imageContainer}>
              <ProjectLink url={`/settings/vendor/requirement/system-type/${pageDomain.ui.owaspId.value}`}>
                <OWASPIcon />
              </ProjectLink>
            </Grid>
          )}
          {pageDomain.ui.pciId.value && (
            <Grid item={true} xs={3} className={classes.imageContainer}>
              <ProjectLink url={`/settings/vendor/requirement/system-type/${pageDomain.ui.pciId.value}`}>
                <PCIIcon />
              </ProjectLink>
            </Grid>
          )}
          <AllRequirementVendorLinkComponent domain={pageDomain} />
        </Grid>
      )}
    </Fragment>
  );
});

export const useStyles = makeStyles((theme: Theme) => ({
  titleDivider: {
    width: theme.spacing(12),
    margin: '10px 0',
  },
  tableDivider: {
    margin: '30px 0',
  },
  imageContainer: {
    minWidth: 450,
  },
  projectCounter: {
    width: 250,
    height: 41,
    fontWeight: 200,
    fontSize: 14,
    textAlign: 'end',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-end',
  },
}));
