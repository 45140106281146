import { IRequirementModel } from './../../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementSearch } from './../../../../../../../service/requirement/entity/IRequirementSearch';
import { IDataTableRowsUI } from './../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableRowsUI } from '../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { RequirementEntityAdminUI } from '../RequirementEntityAdminUI';

export class RequirementEntityRowsUI
  extends DataTableRowsUI<IRequirementModel, IRequirementSearch>
  implements IDataTableRowsUI<IRequirementModel, IRequirementSearch>
{
  constructor(public rootUI: RequirementEntityAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'categoryId') {
      return {
        dependenciesModels: this.rootUI.categories.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }
    if (fieldName === 'authorId') {
      return {
        dependenciesModels: this.rootUI.users.list,
        valueField: 'displayName',
        activeField: 'isActive',
        isArray: false,
      };
    }
    if (fieldName === 'specificationsIds') {
      return {
        dependenciesModels: this.rootUI.specifications.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: true,
      };
    }
    if (fieldName === 'tagsIds') {
      return {
        dependenciesModels: this.rootUI.tags.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: true,
      };
    }
    if (fieldName === 'performersTypesIds') {
      return {
        dependenciesModels: this.rootUI.performers.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: true,
      };
    }

    return null;
  }
}
