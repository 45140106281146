import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { AdditionalColumnContentAdminForm } from '../../additionalColumn/content/form/AdditionalColumnContentAdminForm';
import { AdditionalColumnContentDataTable } from '../../additionalColumn/newContent/AdditionalColumnContentDataTable'
import { AdditionalColumnEntityAdminTable } from '../../additionalColumn/newEntity/AdditionalColumnEntityAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const AdditionalColumnSettingsItem: ISettingsItem = {
  id: 'additionalColumn',
  settingsItem: {
    key: 'additionalColumn',
    title: 'Дополнительные колонки',
    buttons: [
      {
        title: 'Колонки',
        url: `/settings/additional/column/entity`,
        permissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-read']],
      },
      {
        title: 'Контент',
        url: `/settings/additional/column/content`,
        permissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-read']],
    infoText:
      'Отображаются в качестве скрытой панели в перечне требований для АС. Содержат дополнительную информацию к требованиям',
    orderIndex: 700,
  },
  routers: [
    {
      key: 'additional-column-entity-settings',
      path: '/settings/additional/column/entity',
      pageTitle: 'Дополнительная колонка',
      component: AdditionalColumnEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-read']],
    },
    {
      key: 'additional-column-content-list-settings',
      path: '/settings/additional/column/content',
      pageTitle: 'Контент доп. колонок',
      component: AdditionalColumnContentDataTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-read']],
    },
    {
      key: 'additional-column-content-new-settings',
      path: '/settings/additional/column/content/new',
      pageTitle: 'Добавить контент доп. колонок',
      component: AdditionalColumnContentAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-create']],
    },
    {
      key: 'additional-column-content-edit-settings',
      path: '/settings/additional/column/content/:entityId/edit',
      pageTitle: 'Редактировать контент доп. колонок',
      component: AdditionalColumnContentAdminForm,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-additional-columns-update']],
    },
  ],
};
