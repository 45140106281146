import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IFileOption, ITaskLanguage } from '../../../../service/unit/interface/IUnitPractice';
import { IPassingFile } from '../parts/passingFileTree/IPassingFileTree';
import { CodeMode } from '../store/ICodeMode';
import { IPassingCodeEdtitorUI } from './IPassingCodeEditorUI';

export class PassingCodeEditorUI implements IPassingCodeEdtitorUI {
  constructor(
    public mode = injectPrimitive<CodeMode>(CodeMode.review),
    public lang = injectPrimitive<ITaskLanguage>(null as any),
    public fileTree = injectEntityList<IPassingFile>(null as any),
    public currentOption = injectPrimitive<IFileOption>(null as any),
    public currentFile = injectPrimitive<IPassingFile>(null as any),
  ) {}
}
