import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IAdditionalColumnModel } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IPerformerTypeModel } from '../../../../../../service/performer/type/IPerformerTypeModel';
import { IPerformerTypeSearch } from '../../../../../../service/performer/type/IPerformerTypeSearch';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IStatusEntityModel } from '../../../../../../service/status/entity/IStatusEntityModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { makeMultiAutoCompleteForTable } from '../../../../../design/table/edit/TableMultiAutocomplete';
import { makeMultiAutoCompleteViewForTable } from '../../../../../design/table/edit/TableMultiAutocompleteView';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdminTableDomain } from '../../../table/store/AdminTableDomain';
import { IAdminTableColumn } from '../../../table/store/IAdminTableUI';

export class PerformerTypeAdminDomain extends AdminTableDomain<IPerformerTypeModel, IPerformerTypeSearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    protected statuses: IEntityListStore<IStatusEntityModel> = injectEntityList<IStatusEntityModel>([]),
    protected additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>(
      [],
    ),
  ) {
    super(layoutDomain, rootPrivateServices.performer.type);
  }

  setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-performers-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-performers-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-performers-update']]),
    );
  }

  protected async loadDependencies() {
    try {
      const defaultSearchQuery = { limit: 1000000 };
      this.ui.isLoading.setValue(true);

      const statusesListSearchResult = await this.rootPrivateServices.status.entity.search(defaultSearchQuery);
      const additionalColumnsListSearchResult = await this.rootPrivateServices.additionalColumn.entity.search(
        defaultSearchQuery,
      );
      this.ui.isLoading.setValue(false);

      this.statuses.setList(statusesListSearchResult.data);
      this.additionalColumns.setList(additionalColumnsListSearchResult.data);
    } catch (error) {
      return this.errorsHandler(error);
    }
  }

  async loadData() {
    await this.loadDependencies();
    const columns = await this.getColumns();
    this.ui.columns.setList(columns);
    await super.loadData();
  }

  private async getColumns(): Promise<IAdminTableColumn[]> {
    const isActiveFilter = (item: any) => item.isActive === true;
    const statusesItems = this.statuses.list.filter(isActiveFilter).map((status) => ({
      value: status.id,
      name: status.isActive ? status.name : `${status.name} (неактивен)`,
    }));
    const additionalColumnsItems = this.additionalColumns.list.filter(isActiveFilter).map((column) => ({
      value: column.id,
      name: column.isActive ? column.name : `${column.name} (неактивен)`,
    }));

    return [
      {
        title: 'Название',
        field: 'name',
        isRequired: true,
      },
      {
        title: 'Описание',
        field: 'description',
        custom: {
          multiline: true,
        },
        isRequired: true,
      },
      {
        title: 'Включает все статусы и доп. колонки',
        field: 'isIncludeEverything',
        type: 'boolean',
        initialEditValue: false,
        isRequired: true,
      },
      {
        title: 'Статусы',
        field: 'statusesIds',
        editComponent: makeMultiAutoCompleteForTable('Статусы', statusesItems),
        render: makeMultiAutoCompleteViewForTable((item) => item.statusesIds, statusesItems),
      },
      {
        title: 'Дополнительные колонки',
        field: 'additionalColumnsIds',
        editComponent: makeMultiAutoCompleteForTable('Доп колонки', additionalColumnsItems),
        render: makeMultiAutoCompleteViewForTable((item) => item.additionalColumnsIds, additionalColumnsItems),
      },
      {
        title: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        initialEditValue: 1,
        validate: (rowData) =>
          Number.isInteger(rowData.orderIndex)
            && !Number.isNaN(rowData.orderIndex)
              ? true
              : { isValid: false, helperText: 'Введите целое число' }
      },
      {
        title: 'Активен',
        field: 'isActive',
        type: 'boolean',
        initialEditValue: true,
      },
    ];
  }
}
