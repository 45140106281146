import { CheckCircle, FlagOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { PassingContext } from '../../../../../PassingContext';

export interface IPassingNavigationItemProperties {
  title: string;
  passed: boolean;
  isActive: boolean;
  navigationRedirect: (title: string) => void;
}
export interface IPassingNavigationItemProps {
  title: string;
  passed: boolean;
  isActive: boolean;
  isActiveStep: boolean;
  pickedLang: string;
  navigationRedirect: (title: string) => void;
}
export const PassingPracticeNavigationItem = observer(
  ({ title, passed, navigationRedirect, isActiveStep, pickedLang }: IPassingNavigationItemProps) => {
    const { passingDomain } = useContext(PassingContext);
    if (!pickedLang) {
      return null;
    }

    const handleClick = () => {
      navigationRedirect(title);
      passingDomain.theoryDomain.onExpandIconClick('')
      passingDomain.theoryDomain.ui.activeStep.setValue(0);
    };

    return (
      <Box
        onClick={handleClick}
        sx={{
          padding: '4px 10px',
          display: 'flex',
          gap: '10px',
          color: 'white',
          cursor: 'pointer',
          '&:last-child': {
            marginBottom: '10px',
          },
        }}
      >
        {!passed ? (
          <FlagOutlined sx={{ color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }} />
        ) : (
          <CheckCircle sx={{ color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }} />
        )}
        <Typography sx={{ color: isActiveStep ? 'rgba(144, 202, 249, 1)' : '' }}>{title}</Typography>
      </Box>
    );
  },
);
