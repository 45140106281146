import { Checkbox, FormControl, FormControlLabel, FormLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export interface IFormCheckboxFieldProperties extends ICommonProperties {
  label: string;
  description?: string;
  onChange: (newValue: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  errorMessage?: string | null;
  disabled?: boolean | undefined;
  dataCy?: string;
}

export const FormCheckboxField = observer(
  ({ label, onChange, value, defaultValue = false, disabled = false, description, dataCy }: IFormCheckboxFieldProperties) => {
    value = value === undefined || value === null ? defaultValue : value;
    const classes = useStyles();
    const onTextFieldChange = (event: any) => {
      onChange(event.target.checked);
    };
    return (
      <FormControl fullWidth variant="outlined" disabled={disabled} className={classes.formControl}>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              disabled={disabled}
              style={{ width: 25 }}
              checked={value}
              onChange={onTextFieldChange}
              color="secondary"
              data-cy={dataCy}
            />
          }
          label={label}
        />
        {description && description.length && description.length > 0 && (
          <FormLabel disabled={disabled} color="secondary">
            <Typography variant={'subtitle2'}>{description}</Typography>
          </FormLabel>
        )}
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));
