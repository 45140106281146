import { ApplicationDetailUI } from '../../../view/user/page/application/info/store/ApplicationDetailUI';

export const extendApplicationDetailUIConstructor = (
  Ref: new (...args: any[]) => ApplicationDetailUI,
): new (...args: any[]) => ApplicationDetailUI => {
  class ExtendedDomainRef extends Ref {
    constructor() {
      super();
      this.isSupportJira.setValue(false);
      this.tabsData.value.tabs = this.tabsData.value.tabs.filter((tab) => {
        return tab.key !== 'requirement' && tab.key !== 'history';
      });
      this.tabsData.value.defaultViewKey = 'learning';
    }
  }

  return ExtendedDomainRef;
};
