import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { MouseEvent } from 'react';

import {
  BlockType,
  ITheoryBlock,
  ITheoryBlocks,
  IUnitTheory,
} from '../../../../../../../../service/unit/interface/IUnitTheory';
import { IHeading } from '../../../../../passing/theoryComponents/text/Heading1';
import { OverflownContent } from '../../../../shared/OverflownContent';
import { PreviewAccordion } from '../../../../shared/PreviewAccordion';

interface IPreviewSidebarTheoryProps {
  theory?: IUnitTheory;
  onItemClick?: (value: string) => void;
}

export const PreviewSidebarTheory = ({ theory, ...props }: IPreviewSidebarTheoryProps) => {
  const getClickableHeadlines = (items: ITheoryBlock<ITheoryBlocks>[]) => {
    return items.filter((item) => item.block === BlockType.HEADING_1) as ITheoryBlock<IHeading>[];
  };

  const onItemClick = (event: MouseEvent<HTMLElement>, title: string) => {
    if (props?.onItemClick) {
      event.stopPropagation();
      props?.onItemClick(title);
    }
  };

  const classes = useStyles();

  return (
    <>
      {theory?.chapters.map((chapter) => (
        <div className={classes.chapter}>
          <PreviewAccordion
            isExpandable={!!getClickableHeadlines(chapter?.theoryBlocks)?.length}
            title={
              <OverflownContent
                onClick={(event) => onItemClick(event, chapter?.title)}
                contentClasses={classes.chapterItemTitle}
              >
                {chapter?.title}
              </OverflownContent>
            }
          >
            {getClickableHeadlines(chapter?.theoryBlocks).map((item) => (
              <div className={classes.chapterItem} onClick={(event) => onItemClick(event, item.data.text)}>
                <OverflownContent contentClasses={classes.chapterItemTitle}>{item.data.text}</OverflownContent>
              </div>
            ))}
          </PreviewAccordion>
        </div>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  chapter: {
    borderTop: '0.5px solid #777777',
  },
  chapterItem: {
    padding: '0 32px 8px 32px',
    cursor: 'pointer',
  },
  chapterItemTitle: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
    '&:hover': {
      color: '#777777',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '100ms',
    },
    '&:active': {
      color: '#696969',
    },
  },
}));
