import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SettingsForm } from '../settingsForm/SettingsForm';
import { INotificationDomain } from '../store/INotificationDomain';

interface INotificationNavProperty {
  layoutDomain: IMainLayoutDomainStore;
  notificationDomain: INotificationDomain;
}

export const NotificationNav = observer(({ layoutDomain, notificationDomain }: INotificationNavProperty) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }}>
      {/*<Box sx={{ flex: '1' }}>*/}
      {/*  <FormMultiAutocomplete*/}
      {/*    values={LoggerEventListAsArray.map((item) => ({ value: item.key, name: item.value })).sort((a, b) => {*/}
      {/*      return a.name.localeCompare(b.name);*/}
      {/*    })}*/}
      {/*    onChangeSelect={(newValue: any) => {}}*/}
      {/*    key="events"*/}
      {/*    selected={['']}*/}
      {/*    label="События"*/}
      {/*  />*/}
      {/*</Box>*/}
      {/*<FormControlLabel control={<Switch />} label="Только новое" />*/}
      {/*<FormControlLabel control={<Switch />} label="Важное" sx={{ marginRight: 'auto', height: '100%', flex: '1' }} />*/}
      {/*<Box sx={{ display: 'flex', alignItems: 'center' }}>*/}
      {/*  {' '}*/}
      {/*  Сбросить фильтр{' '}*/}
      {/*  <IconButton*/}
      {/*    style={{*/}
      {/*      marginLeft: '15px',*/}
      {/*      backgroundColor: '#782A2A',*/}
      {/*    }}*/}
      {/*    color="error"*/}
      {/*  >*/}
      {/*    <FilterAltOffIcon color="secondary" />*/}
      {/*  </IconButton>*/}
      {/*</Box>*/}
      {notificationDomain.ui.isSetConfig.value && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {' '}
          Настройки{' '}
          <IconButton
            style={{
              marginLeft: '15px',
              backgroundColor: '#7A8286',
            }}
            onClick={() => {
              const settingForm = () => <SettingsForm layoutDomain={layoutDomain} />;
              layoutDomain.modalWindow.showModalWindow(settingForm);
            }}
            color="primary"
          >
            <SettingsIcon color="secondary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
});
