import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { AdditionalColumnEntityModal } from './AdditionalColumnEntityModal';

export class AdditionalColumnEntityModalUI
  extends DataTableFormUI<IAdditionalColumnModel>
  implements IDataTableFormUI<IAdditionalColumnModel>
{
  constructor(public systemTypes = injectEntityList<any>([])) {
    super();

    this.formComponent.setValue(AdditionalColumnEntityModal);
  }
}
