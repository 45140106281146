import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { TagEntityAdminModal } from './TagEntityAdminModal';

export class TagEntityAdminModalUI
  extends DataTableFormUI<ITagEntityModel>
  implements IDataTableFormUI<ITagEntityModel>
{
  constructor(public categories: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([])) {
    super();
    this.formComponent.setValue(TagEntityAdminModal);
  }
}
