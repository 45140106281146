import { IUIStore } from './IUIStore';

export interface IEntityListStore<EntityType> extends IUIStore {
  list: EntityType[];
  setList(newList: EntityType[]): void;
  removeByEntity(entity: EntityType): void;
}

export interface IEntityListStoreFactory<Type> {
  (defaultEntity?: Type[]): IEntityListStore<Type>;
}

export const EntityListStoreToken = Symbol.for('EntityListStoreToken');
