import { IUIStore } from './IUIStore';

export interface IEntityStore<EntityType> extends IUIStore {
  entity: EntityType;
  setEntity(value: EntityType): void;
}

export interface IEntityStoreFactory<Type> {
  (defaultEntity: Type): IEntityStore<Type>;
}

export const EntityStoreToken = Symbol.for('EntityStoreToken');
