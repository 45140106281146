import AddIcon from '@mui/icons-material/Add';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useState } from 'react';

import { ProjectButtonLink } from '../../../../design/link/ProjectButtonLink';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdminTable } from '../../table/AdminTable';
import { ApplicationTemplateAdminDomain } from './store/ApplicationTemplateAdminDomain';

export interface IApplicationTemplateAdminTableProperties extends IAdminPageProperties {}

export const ApplicationTemplateAdminTable = observer(({ layoutDomain }: IApplicationTemplateAdminTableProperties) => {
  const [domain] = useState(new ApplicationTemplateAdminDomain(layoutDomain));
  const classes = useStyles();

  return (
    <Fragment>
      {domain.ui.isCanCreate.value && (
        <Paper className={classes.root} elevation={3}>
          <ProjectButtonLink icon={<AddIcon />} url={`/settings/application/template/new`}>
            Добавить шаблон
          </ProjectButtonLink>
        </Paper>
      )}

      <AdminTable tableDomain={domain} />
    </Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));
