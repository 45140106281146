import { StepContent } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';

export const VerticalStepper = ({ steps }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStep = (step: number) => () => {
    if (step === activeStep) {
      setActiveStep(-1);
    } else {
      setActiveStep(step);
    }
  };

  return (
    <Stepper nonLinear activeStep={activeStep} orientation={'vertical'}>
      {Object.keys(steps).map((key, index) => (
        <Step key={steps[key].label}>
          <StepButton color="inherit" onClick={handleStep(index)}>
            {steps[key].label}
          </StepButton>
          <StepContent>{steps[key].content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
};
