import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { ISpecificationEntitySearch } from './ISpecificationeEntitySearch';
import { ISpecificationEntityModel } from './ISpecificationEntityModel';
import { ISpecificationEntityService } from './ISpecificationEntityService';

export class RestSpecificationEntityService
  extends RestCRUDService<ISpecificationEntityModel, ISpecificationEntitySearch>
  implements ISpecificationEntityService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/specification`;
  }
}
