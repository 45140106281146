import { ISettingsItem } from './ISettingsItem';
import { AdditionalColumnSettingsItem } from './items/AdditionalColumnSettingsItem';
import { ApplicationTemplateSettingsItem } from './items/ApplicationTemplateSettingsItem';
import { IntegrationSettingsItem } from './items/IntegrationSettingsItem';
import { PerformerSettingsItem } from './items/PerformerSettingsItem';
import { RequirementSettingsItem } from './items/RequirementSettingsItem';
import { SpecificationSettingsItem } from './items/SpecificationSettingsItem';
import { StatusSettingsItem } from './items/StatusSettingsItem';
import { TagSettingsItem } from './items/TagSettingsItem';
import { UserSettingsItem } from './items/UserSettingsItem';
import { VendorSettingsItem } from './items/VendorSettingsItem';

export const SettingsPageTree: { [name: string]: ISettingsItem } = {
  specification: SpecificationSettingsItem,
  requirement: RequirementSettingsItem,
  vendor: VendorSettingsItem,
  tag: TagSettingsItem,
  status: StatusSettingsItem,
  performer: PerformerSettingsItem,
  additionalColumn: AdditionalColumnSettingsItem,
  integration: IntegrationSettingsItem,
  user: UserSettingsItem,
  applicationTemplate: ApplicationTemplateSettingsItem,
};
