import { IPerformerTypeSearch } from '../../../../../../../../service/performer/type/IPerformerTypeSearch';
import { DataTableRowsUI } from '../../../../../../../design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { UserAdminModel } from '../UserAdminModel';
import { UserAdminUI } from '../UserAdminUI';

export class UserAdminRowsUI
  extends DataTableRowsUI<UserAdminModel, IPerformerTypeSearch>
  implements IDataTableRowsUI<UserAdminModel, IPerformerTypeSearch>
{
  constructor(public rootUI: UserAdminUI) {
    super();
  }
  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    if (fieldName === 'systemRoleId') {
      return {
        dependenciesModels: this.rootUI.systemRoleId.list,
        valueField: 'name',
        activeField: 'isActive',
        isArray: false,
      };
    }

    return null;
  }
}
