import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { observer } from "mobx-react";

interface SaveAnswerDialogProps {
	domain: any;
}

const SaveAnswerDialog = observer(({ domain }: SaveAnswerDialogProps) => {
	const handleClose = () => {
		domain.isModalOpen.setValue(false)
		domain.isFinalySave.setValue(true)
	}

	const handleSave = async () => {
		domain.isModalOpen.setValue(false)
		if (domain?.ui?.model?.entity?.isUnitedWithApplication && domain?.applicationDomain !== null) {
			await domain.applicationDomain?.entity.saveApplication()
		} else {
			domain.save()
		}
	}

	return <Dialog
		open={domain.isModalOpen.value}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogTitle id="alert-dialog-title">
			{"Сохранение анкеты"}
		</DialogTitle>
		<DialogContent>
			<DialogContentText id="alert-dialog-description">
				Для полного подсчета рисков необходимо заполнить все поля.
				Вы действительно хотите продолжить сохранение анкеты?
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose}>
				Вернуться к анкете
			</Button>
			<Button onClick={handleSave} autoFocus>
				Сохранить без подсчета рисков
			</Button>
		</DialogActions>
	</Dialog>;
});

export default SaveAnswerDialog;