import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { DataTable } from '../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../domain/RiskManagerSettingsPageDomain';

export interface IRiskManagerRiskAdminTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerRiskTable = observer(({ rootDomain }: IRiskManagerRiskAdminTableProperties) => {
  return <DataTable tableHeight={800} dataTableDomain={rootDomain.riskDomain} />;
});
