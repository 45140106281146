import { IConfig } from './IConfig';
import { IPublicFrontendConfigFromServer } from './IPublicFrontendConfigFromServer';

let configCache: IConfig | null = null;
export class ConfigManager {
  static serverConfig: IPublicFrontendConfigFromServer;

  static getConfig(): IConfig {
    configCache = configCache || {
      extensions: {
        include: ConfigManager.serverConfig.extensions || [],
        configs: ConfigManager.serverConfig.extensionsConfigs || [],
        getExtensionsConfigByName: (name: string, defaultValue: any) =>
          ConfigManager.getConfig().extensions.configs.find((config) => config.name === name)?.value ?? defaultValue,
      },
      url: {
        api: {
          admin: ConfigManager.getSiteDomain('/v2/admin'),
          public: ConfigManager.getSiteDomain('/v2/api'),
          file: ConfigManager.getSiteDomain('/file'),
        },
      },
      defaultValues: {
        jiraIssuePrefix: ConfigManager.serverConfig.jiraPrefix || '',
      },
      license: {
        isSupport: ConfigManager.serverConfig.isShowLicense || false,
        licenseString: ConfigManager.serverConfig.licenseDataAsString || '',
      },
      text: {
        projectTitle: ConfigManager.serverConfig.title || 'START X',
      },
      ui: {
        project: {
          form: {
            settings: ConfigManager.serverConfig.projectSettings || [],
            isUnitedWithApplicationByDefault: false,
          },
        },
        application: {
          acceptWithVersion: false,
        },
      },
    };

    return configCache;
  }

  static setConfigFromServer(externalConfig: IPublicFrontendConfigFromServer): void {
    ConfigManager.serverConfig = externalConfig;
  }

  static getSiteDomain(uri: string): string {
    return window.location.protocol + '//' + window.location.host + uri;
  }
}
