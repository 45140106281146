import { ILearningConfig } from './ILearningConfig';

//@ts-ignore
const globalConfig = window['globalConfig'] || {};
let configCache: ILearningConfig | null = null;
export class LearningEnvConfigManager {
  static getConfig(): ILearningConfig {
    configCache = configCache || {
      url: {
        api: {
          learning: LearningEnvConfigManager.getSiteDomain('/api/learning'),
        },
      },
    };

    return configCache;
  }

  //@ts-ignore
  private static getEnvVar(name: string, defaultValue: any = ''): ReturnType {
    const reactPrefix = 'REACT_APP_';

    return globalConfig[`${reactPrefix}${name}`] || defaultValue;
  }
  private static getSiteDomain(uri: string): string {
    return window.location.protocol + '//' + window.location.host + uri;
  }
}
