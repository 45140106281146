import { observer } from 'mobx-react';
import React from 'react';

import { IPrimitiveStore } from '../../../../common/store/interface/IPrimitiveStore';
import { AutocompleteSelectItem } from '../../form/FormMultiAutocomplete';
import { HighLightText } from '../../text/highlight/HighLightText';

export const makeMultiAutoCompleteViewForTable = (
  selectData: (item: any) => string[],
  values: AutocompleteSelectItem[],
  term?: IPrimitiveStore<string>,
  exceptionBehavior?: { exception: (rowData: any) => boolean; text: string },
) => {
  return (rowData: any) => {
    let itemsNames: string[] = [];
    if (rowData) {
      const selected = selectData(rowData) || [];
      const selectedNames = values.filter((value) => selected.indexOf(value.value) !== -1);

      selectedNames.forEach((selectedItem: AutocompleteSelectItem) => {
        itemsNames.push(selectedItem?.name || 'unknown');
      });
    }

    const isException = exceptionBehavior && exceptionBehavior.exception(rowData);

    return isException ? (
      <RenderText text={exceptionBehavior?.text} term={term} />
    ) : (
      <RenderText text={itemsNames.join(', ')} term={term} />
    );
  };
};

const RenderText = observer(({ text, term }: any) => <HighLightText text={text} terms={[term?.value || '']} />);
