import { injectRootService } from '../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AnalyticsUi } from './AnalyticsUi';

export class AnalyticsDomain {
  public ui = new AnalyticsUi(this);

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    layoutDomain = this.layoutDomain
  }

  async loadReqData() {
    this.ui.requirementsUi.loadData()
  }
}
