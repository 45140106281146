import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { IAdditionalColumnContentModel } from '../../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { IAdditionalColumnContentService } from '../../../../../../../service/additionalColumn/content/IAdditionalColumnContentService';
import { AdditionalColumnContentModalUI } from './AdditionalColumnContentModalUI'
import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { AdditionalColumnType, IAdditionalColumnModel } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { LayoutNotificationType } from '../../../../../../layout/common/notification/store/ILayoutNotification';
import { inject } from '../../../../../../../common/container/inject';
import { RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { AdditionalColumnRequirementRelationType } from '../../../../../../../service/additionalColumn/content/AdditionalColumnRequirementRelationType';
import { IError } from '../../../../../../../common/error/IError';

export class AdditionalColumnContentModalDomain
  extends DataTableFormDomain<IAdditionalColumnContentModel, any>
  implements IDataTableFormDomain<IAdditionalColumnContentModel>
{
  public ui: AdditionalColumnContentModalUI;
  public requirementsRelationTypes: { name: string; value: AdditionalColumnRequirementRelationType }[] = [];

  public additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>(
    [],
  )
  public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([])
  public additionalColumnsValues: IEntityListStore<{ name: string; value: string }> = injectEntityList<{
    name: string;
    value: string;
  }>([])
  public requirementsValues: IEntityListStore<{ name: string; value: string }> = injectEntityList<{
    name: string;
    value: string;
  }>([])
  public activeAdditionalColumn: IEntityStore<IAdditionalColumnModel | null> = injectEntity(null)
  public router: IRouterService = inject<IRouterService>(RouterServiceToken)
  constructor(
    entityService: IAdditionalColumnContentService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<IAdditionalColumnContentModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new AdditionalColumnContentModalUI());
    this.ui = new AdditionalColumnContentModalUI();
    this.setRequirementsRelationType();
  }

  async loadData(entityId: string | null) {
    await this.loadDependencies();
    await super.loadData(entityId);
    if (this.ui.model.entity.uploadedFileUrl) {

    }
    await this.setAdditionalColumn(this.ui.model.entity.additionalColumnId);
    if (entityId === null) {
      this.ui.model.entity.requirementsRelationType = AdditionalColumnRequirementRelationType.all;
    }
  }

  //   getUpdateFieldHandler(fieldName: string) {
  //   return (newValue: any) => {
  //     // @ts-ignore
  //     this.ui.model.entity[fieldName] = newValue;
  //   };
  // }

  protected async loadDependencies() {
    try {
      const defaultSearchQuery = { limit: 1000000 };
      this.ui.isLoading.setValue(true);

      const additionalColumnsListSearchResult = await this.rootPrivateServices.additionalColumn.entity.search(
        defaultSearchQuery,
      );

      const requirementsListSearchResult = await this.rootPrivateServices.requirement.entity.search({
        limit: 1000000,
        sort: {
          rules: [
            {
              isAsc: false,
              isDesc: true,
              name: 'isActive',
            },
            {
              isAsc: true,
              isDesc: false,
              name: 'shortName',
            },
          ],
        },
      });

      this.ui.isLoading.setValue(false);

      this.additionalColumns.setList(additionalColumnsListSearchResult.data);
      this.requirements.setList(requirementsListSearchResult.data);
      this.additionalColumnsValues.setList(
        additionalColumnsListSearchResult.data.map((additionalColumn) => ({
          name: additionalColumn.name,
          value: additionalColumn.id || '',
        })),
      );
      this.requirementsValues.setList(
        this.sortAndFilterRequirements(requirementsListSearchResult.data)
      );
    } catch (error) {
      return this.errorsHandler(error as IError);
    }
  }

  setAdditionalColumn(additionalColumnId: string) {
    const additionalColumn = this.additionalColumns.list.find((column) => column.id === additionalColumnId);
    this.activeAdditionalColumn.setEntity(additionalColumn || null);
  }

  save = async () => {
    if (this.activeAdditionalColumn.entity?.type === AdditionalColumnType.UPLOAD_FILE) {
      this.ui.model.entity['content'] = AdditionalColumnType.UPLOAD_FILE;
      this.ui.model.entity['uploadedFileUrl'] = '';
    }
    await this.removeValidationErrors();

    if (
      this.activeAdditionalColumn.entity?.type === AdditionalColumnType.DOWNLOAD_FILE &&
      this.ui.model.entity['uploadedFileUrl'] === ''
    ) {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Необходимо загрузить файл',
      });
      throw new Error('Ошибка валидации');
    }

    await super.save();
    if (this.ui.validationErrors.list.length === 0) {
      this.router.goTo('/settings/additional/column/content');
    }
  };

  cancelEdit = async () => {
    this.router.goTo('/settings/additional/column/content');
  };

  async cancelFile(additionColumnContent: any) {
    await this.rootPrivateServices.additionalColumn.content.updateByModel({
      ...additionColumnContent,
      uploadedFileUrl: '',
    });
  }

  private setRequirementsRelationType() {
    this.requirementsRelationTypes = [
      {
        value: AdditionalColumnRequirementRelationType.all,
        name: 'Для всех требований',
      },
      {
        value: AdditionalColumnRequirementRelationType.selected,
        name: 'Для выборочных требований',
      },
    ];
  }

  sortAndFilterRequirements(reqList: IRequirementModel[]) {
    const filteredReqList = reqList
      .filter((a) => a.type === RequirementType.system)
      .sort((a, b) => {
        let aPrefix = "";
        let bPrefix = "";
        let aSuffix = "";
        let bSuffix = "";

        const aPrefixMatch = a.shortName?.match(/[a-zA-Z]+/);
        if (aPrefixMatch) aPrefix = aPrefixMatch[0];

        const bPrefixMatch = b.shortName?.match(/[a-zA-Z]+/);
        if (bPrefixMatch) bPrefix = bPrefixMatch[0];

        const aSuffixMatch = a.shortName?.match(/\d+/);
        if (aSuffixMatch) aSuffix = aSuffixMatch[0];

        const bSuffixMatch = b.shortName?.match(/\d+/);
        if (bSuffixMatch) bSuffix = bSuffixMatch[0];

        if (aPrefix < bPrefix) return -1;
        if (aPrefix > bPrefix) return 1;

        return parseInt(aSuffix) - parseInt(bSuffix);
      })

    return filteredReqList.map((requirement) => ({
      name: requirement.shortName || '',
      value: requirement.id || '',
      type: requirement.type || '',
    }))
  }
}
