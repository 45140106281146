import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerTemplateModel } from '../../../service/manager/template/IRiskManagerTemplateModel';
import { IRiskManagerTemplateSearch } from '../../../service/manager/template/IRiskManagerTemplateSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerTemplateContextMenuDomain } from './context/RiskManagerTemplateContextMenuDomain';
import { RiskManagerTemplateFormDomain } from './form/RiskManagerTemplateFormDomain';
import { RiskManagerTemplateUI } from './RiskManagerTemplateUI';

export class RiskManagerTemplateDomain extends DataTableDomain<IRiskManagerTemplateModel, IRiskManagerTemplateSearch> {
  public ui: RiskManagerTemplateUI;
  public modalDomain: any;

  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.manager.template);
    this.ui = new RiskManagerTemplateUI(this, null);
    this.modalDomain = new RiskManagerTemplateFormDomain(rootPrivateServices, layoutDomain, this);
    this.contextMenuDomain = new RiskManagerTemplateContextMenuDomain(
      rootPrivateServices.manager.template,
      this,
      layoutDomain,
    );
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
  }

  async loadDependencies() {
    try {
      const [risks, questions, staticQuestion, fields] = await Promise.all([
        this.rootPrivateServices.risk.search({ limit: 1000000 }),
        this.rootPrivateServices.question.entity.search({ limit: 1000000 }),
        this.rootPrivateServices.question.static.search({ limit: 1000000 }),
        this.rootPrivateServices.question.field.search({ limit: 1000000 }),
      ]);

      this.ui.rows.risks.setList(risks.data);
      this.ui.rows.questions.setList(questions.data);
      this.ui.rows.staticQuestions.setList(staticQuestion.data);
      this.ui.rows.questionFields.setList(fields.data);
    } catch (error) {
      this.errorsHandler(error);
    }
  }
}
