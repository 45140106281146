import { RestCRUDService } from '../../common/rest/RestCRUDService';
import { CRUDServiceType } from '../../common/service/CRUDServiceType';
import { ISpecificationCategoryModel } from './ISpecificationCategoryModel';
import { ISpecificationCategorySearch } from './ISpecificationCategorySearch';
import { ISpecificationCategoryService } from './ISpecificationCategoryService';

export class RestSpecificationCategoryService
  extends RestCRUDService<ISpecificationCategoryModel, ISpecificationCategorySearch>
  implements ISpecificationCategoryService
{
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/specification/category`;
  }
}
