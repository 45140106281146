import { RestCRUDService } from '../../../../service/common/rest/RestCRUDService';
import { IRiskManagerRiskModel } from './IRiskManagerRiskModel';
import { IRiskManagerRiskSearch } from './IRiskManagerRIskSearch';
import { IRiskManagerRiskService } from './IRiskManagerRiskService';

export class RestRiskManagerRiskService
  extends RestCRUDService<IRiskManagerRiskModel, IRiskManagerRiskSearch>
  implements IRiskManagerRiskService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/risk`;
  }
}
