import { DataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormUI';
import { GenerateUUID } from '../../../../service/pseudoId/GenerateUUID';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerQuestionAnswerPresetForm } from './RiskManagerQuestionAnswerPresetForm';

const defaultModelValue = { name: '', isDefault: false, answers: [] };

export class RiskManagerQuestionAnswerPresetFormUI
  extends DataTableFormUI<IRiskManagerQuestionAnswerPresetModel>
  implements IDataTableFormUI<IRiskManagerQuestionAnswerPresetModel>
{
  constructor() {
    super(defaultModelValue);
    this.formComponent.setValue(RiskManagerQuestionAnswerPresetForm);
  }

  addAnswer() {
    this.model.entity.answers.push({ defaultValue: 0, pseudoId: GenerateUUID(), sortIndex: 1, text: '' });
  }

  removeAnswer(pseudoId: string) {
    this.model.entity.answers = [...this.model.entity.answers.filter((item) => item.pseudoId !== pseudoId)];
  }

  getAnswerError(index: number, property: string) {
    const result = Object.values(
      this.validationErrors?.list
        .find((validationError) => validationError.property === 'answers')
        ?.children?.find((answer) => Number(answer.property) === index)
        ?.children?.find((item) => item.property?.toLocaleLowerCase() === property.toLocaleLowerCase())
        ?.constraints || { nonError: null },
    )[0];

    return result;
  }

  getPresetError(property: string) {
    const result = Object.values(
      this.validationErrors?.list?.find((item) => item.property?.toLocaleLowerCase() === property.toLocaleLowerCase())
        ?.constraints || { nonError: null },
    )[0];

    return result;
  }
}
