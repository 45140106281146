import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { DataTableDomain } from '../../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerDataModel } from '../../../../service/manager/data/IRiskManagerDataModel';
import { IRiskManagerDataSearch } from '../../../../service/manager/data/IRiskManagerDataSearch';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { RiskManagerRootService } from '../../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerDataTableContextMenuDomain } from './context/RiskManagerDataTableContextMenuDomain';
import { RiskManagerDataTableUI } from './RiskManagerDataTableUI';

export class RiskManagerDataTableDomain extends DataTableDomain<IRiskManagerDataModel, IRiskManagerDataSearch> {
  public ui: RiskManagerDataTableUI;
  public modalDomain: any;
  public contextMenuDomain: RiskManagerDataTableContextMenuDomain;

  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private rootPrivateServices = new RiskManagerRootService('admin'),
    private risks: IEntityListStore<IRiskManagerRiskModel | null> = injectEntityList([]),
  ) {
    super(layoutDomain, rootPrivateServices.manager.data);
    this.ui = new RiskManagerDataTableUI(this, null);

    this.contextMenuDomain = new RiskManagerDataTableContextMenuDomain(
      rootPrivateServices.manager.data,
      this,
      layoutDomain,
    );
    this.ui.features.setValue({
      isCanBulkActivate: false,
      isCanBulkDeactivate: false,
      isCanCreate: false,
      isCanDelete: false,
      isCanEdit: false,
      isCanRedirectDetail: false,
    });
    this.ui.actionColumnSettings.setValue({
      isShow: true,
      iconDisableHandler: (row: any) => {
        return !this.contextMenuDomain.reportDomain.isHaveAccessAndReport.value && !row.isNeedUpdate;
      },
    });
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
    await this.rootDomain.setPermissions();
    this.ui.isCanCreate.setValue(false);
    this.ui.isCanDelete.setValue(false);
    this.ui.isCanEdit.setValue(false);
  }

  async loadDependencies() {
    try {
      const [risks, projects] = await Promise.all([
        this.rootPrivateServices.risk.search({ limit: 1000000 }),
        this.rootDomain.coreRootService.project.entity.search({ limit: 1000000 }),
      ]);
      this.ui.rows.risks.setList(risks.data);
      this.ui.rows.projects.setList(projects.data);
    } catch (error) {
      this.errorsHandler(error);
    }
  }
}
