import { inject } from '../../../../../common/container/inject';
import { injectEntity } from '../../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';
import { ISortRule } from '../../../../../service/common/search/IServiceModelSort';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { DefaultSearchRequest } from '../../store/DataTableUI';
import { IDataTableRowsUI } from './IDataTableRowsUI';

export class DataTableRowsUI<RowType, SearchRequestType extends ISearchRequestData>
  implements IDataTableRowsUI<RowType, SearchRequestType>
{
  constructor(
    public searchRequest = injectEntity<SearchRequestType>(new DefaultSearchRequest() as any),
    public entities = injectEntityList<RowType>([]),
    public filteredEntities = injectEntityList<RowType>([]),
    public searchTerm = injectPrimitive<string>(''),
    public page = injectPrimitive<number>(0),
    public pageSize = injectPrimitive<number>(20),
    public selectionEntities = injectEntityList<RowType>([]),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public rowsCounter = injectPrimitive<any>({
      counter: 0,
      filteredRow: [],
    }),
    public totalEntitiesCount = injectPrimitive<number>(0),
    public searchRequestForAnyLoadData = injectPrimitive<SearchRequestType>({ filter: {} } as any),
  ) { }

  addNewEntity(entity: RowType) {
    this.filteredEntities.setList([entity, ...this.filteredEntities.list]);
    this.entities.setList([entity, ...this.entities.list]);
  }

  updateEntity(entity: RowType) {
    const entityIndex = this.entities.list.findIndex((tableEntity: any) => tableEntity.id === (entity['id'] || ''));

    if (entityIndex !== -1) {
      this.entities.list[entityIndex] = entity;
    }

    const filteredEntityIndex = this.filteredEntities.list.findIndex(
      (tableEntity: any) => tableEntity.id === (entity['id'] || ''),
    );

    if (filteredEntityIndex !== -1) {
      this.filteredEntities.list[filteredEntityIndex] = entity;
    }
  }

  setSearchTermByUrl(query: string) {
    this.searchTerm.setValue(query);
  }

  setFilteredEntities(rows: RowType[]) {
    const rowsIds = rows.map((item: any) => item?.id);

    rows = [
      ...rows,
      ...this.selectionEntities.list.filter((selectionEntity: any) => !rowsIds.includes(selectionEntity?.id)),
    ];

    this.filteredEntities.setList(rows);
  }

  setSelectionEntities(rows: RowType[]) {
    this.selectionEntities.setList(rows);
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    return null;
  }

  getFieldValueAsString(fieldName: string, value: any) {
    const dependenciesSettings = this.getFieldDependenciesSettings(fieldName);

    if (dependenciesSettings) {
      let valueAsDependencies = this.getFieldValueAsDependencies(fieldName, value);

      if (dependenciesSettings.customMapper) {
        valueAsDependencies = valueAsDependencies.map(dependenciesSettings.customMapper);
      }

      const valueAsDependenciesString = valueAsDependencies
        .map((valueAsDependency) => {
          let dependencyAsString = valueAsDependency[dependenciesSettings.valueField];
          return dependencyAsString;
        })
        .join(', \n');
      return valueAsDependenciesString;
    }

    return value;
  }

  getFieldValueAsDependencies(fieldName: string, value: any) {
    const dependenciesSettings = this.getFieldDependenciesSettings(fieldName);
    if (dependenciesSettings) {
      const filter = dependenciesSettings.customFilter
        ? dependenciesSettings.customFilter
        : (dependencyModels, value) =>
          dependencyModels.filter((dependencyModel) => {
            return dependenciesSettings.isArray ? value.includes(dependencyModel.id) : dependencyModel.id === value;
          });

      let valueAsDependencies: any[] = filter(dependenciesSettings.dependenciesModels, value);

      if (dependenciesSettings.activeField !== null) {
        valueAsDependencies = valueAsDependencies.map((item) => {
          const itemCopy = { ...item };
          itemCopy.name =
            itemCopy[dependenciesSettings.activeField as string] === false
              ? `${itemCopy.name} (неактивен)`
              : itemCopy.name;
          return itemCopy;
        });
      }
      return valueAsDependencies;
    }

    return [];
  }

  requestSearch = (searchTerm: string) => {
    this.searchTerm.setValue(searchTerm);
    const searchRegex = new RegExp(this.escapeRegExp(searchTerm), 'i');
    const filteredRows = [...this.entities.list].filter((row) => {
      return Object.keys(row).some((field) => {
        const value = this.getFieldValueAsString(field, row[field]);
        return searchRegex.test(value);
      });
    });
    this.setFilteredEntities(filteredRows);
  };

  escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  sortRulesToGridSortModel(rules: ISortRule[] | undefined) {
    const gridRules = rules?.filter((rule) => {
      return rule.isAsc === true || rule.isDesc === true;
    });
    gridRules?.forEach((rule) => {
      if (rule.isDesc) {
        rule.field = rule.name;
        rule.sort = 'desc';
        delete rule.name;
        delete rule.isDesc;
      } else if (rule.isAsc) {
        rule.field = rule.name;
        rule.sort = 'asc';
        delete rule.name;
        delete rule.isAsc;
      }
    });
    return gridRules;
  }
}
