import { ITagEntityModel } from './../../../../../../service/tag/entity/ITagEntityModel';
import { ITagEntitySearch } from './../../../../../../service/tag/entity/ITagEntitySearch';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { TagEntityAdminContextMenuDomain } from './context/TagEntityAdminContextMenuDomain';
import { TagEntityAdminModalDomain } from './modal/TagEntityAdminModalDomain';
import { TagEntityAdminUI } from './TagEntityAdminUI';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';

export class CustomAppBar {
  Toolbar = TableHeader;
}

export class TagEntityAdminDomain extends DataTableDomain<ITagEntityModel, ITagEntitySearch> {
  public ui: TagEntityAdminUI;

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.tag.entity);
    this.ui = new TagEntityAdminUI(this, null);
    this.modalDomain = new TagEntityAdminModalDomain(rootPrivateServices.tag.entity, layoutDomain, this);
    this.contextMenuDomain = new TagEntityAdminContextMenuDomain({
      entityService: rootPrivateServices.tag.entity,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Тэги для требований');
    this.ui.tableSubtitle.setValue('Значения');
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-create']]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-delete']]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-tags-update']]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
  }

  async loadData() {
    await this.loadDepenicies();
    await super.loadData();
  }

  async loadDepenicies() {
    const categoriesSearchResult = await this.rootPrivateServices.tag.category.search({ limit: 1000000 });
    this.ui.categories.setList(categoriesSearchResult.data);
  }
}
