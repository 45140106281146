import { Grid, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';

import { FormMultiAutocomplete } from '../../../../view/design/form/FormMultiAutocomplete';
import { FormTextLabel } from '../../../../view/design/form/FormTextLabel';
import { IRiskManagerRequirementEntityModalDomain } from './store/extendRequirementEntityModalDomain';

export const RiskManagerRequirementThreatFormFields = observer(
  ({
    domain,
    isDrawer,
    isInfoMode,
  }: {
    domain: IRiskManagerRequirementEntityModalDomain;
    isDrawer?: boolean;
    isInfoMode?: boolean;
  }) => {
    useEffect(() => {
      domain.riskManagerRequirementThreatFormFieldsDomain.loadRequirementLink();
    }, [domain, domain.riskManagerRequirementThreatFormFieldsDomain, domain.ui.model.entity?.id]);

    if (isInfoMode) {
      return (
        <>
          <Grid item xs={3}>
            <Typography variant={'body2'}>Риски</Typography>
          </Grid>
          <Grid item xs={9}>
            {domain.riskManagerRequirementThreatFormFieldsDomain.risks.list
              .filter((risk) => {
                if (risk.id) {
                  return domain.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.entity?.risksMap?.risksIds?.includes(
                    risk.id,
                  );
                }
                return false;
              })
              .map((risk) => (
                <Typography variant={'body2'}>{risk.name}</Typography>
              ))}
          </Grid>
        </>
      );
    }

    return (
      <Paper elevation={isDrawer ? 0 : 3} style={isDrawer ? {} : { margin: 10 }}>
        {!isDrawer && <FormTextLabel text={'Риски'} />}
        <Fragment>
          <FormMultiAutocomplete
            key={'extension_risk_manager_requirement_threat_link'}
            disabled={!domain.ui.model.entity?.categoryId}
            values={domain.riskManagerRequirementThreatFormFieldsDomain.risks.list.map((risk) => ({
              name: risk.name,
              value: risk.id,
            }))}
            onChangeSelect={(values) => {
              if (
                domain.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.entity?.risksMap?.risksIds
              ) {
                domain.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.entity.risksMap.risksIds =
                  values.map((item) => item.value);
              }
            }}
            selected={
              domain.riskManagerRequirementThreatFormFieldsDomain.requirementThreatLink.entity?.risksMap?.risksIds
            }
            label={'Связанные риски'}
            helperText={''}
          />
        </Fragment>
      </Paper>
    );
  },
);
