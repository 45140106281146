import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
export interface INestingButton {
  title: string;
  action: Function;
}

export const NestingButton = observer(({ title, action }: INestingButton) => {
  return (
    <Button
      onClick={() => action()}
      size="small"
      startIcon={<Add />}
      sx={{
        color: 'black',
        background: '#E0E0E0',
        boxShadow:
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
      }}
    >
      <Typography sx={{ fontSize: '12px' }}>{title}</Typography>
    </Button>
  );
});
