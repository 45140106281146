import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IPassingListPageDomain } from '../../passingListPage/store/IPassingListPageDomain';
import { PassingListPageDomain } from '../../passingListPage/store/PassingListPageDomain';
import { LearningUserRatingPageUI } from './LearningUserRatingPageUI';

export class LearningUserRatingPageDomain extends PassingListPageDomain implements IPassingListPageDomain {
  public ui: LearningUserRatingPageUI;

  constructor(public layoutDomain: IMainLayoutDomainStore) {
    super(layoutDomain);
    this.ui = new LearningUserRatingPageUI();
    this.ui.isAdminMode.setValue(true);
    
  }

  getUserDataBio = async (userId: string): Promise<void> => {
    const systemRoleSearchResult = await this.rootService.systemRole.entity.search({ limit: 100000 });
    const userSearchResult = await this.rootService.user.entity.getById(userId);
    const userScore = await this.learningRootService.userData.search({filter:[{fieldName:'userId',type:'equal',value:userId}]})
    
    this.ui.userData.setValue(userScore.data[0])
    this.ui.userDisplayName.setValue(userSearchResult?.displayName as string);
    const userSystemRole = systemRoleSearchResult.data.filter((role) => role.id === userSearchResult.systemRoleId)[0]
      .name;
    this.ui.userSystemRole.setValue(userSystemRole);

    
  };
  goBackToRaitngTable = () => {
    this.router.goBack();
  };
}
