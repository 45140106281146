import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { ApplicationDetailDomain } from '../../../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';

export class RiskManagerPriorityByRequirementThreatDomain {
  constructor(
    public baseDomain: ApplicationDetailDomain,
    private rootPrivateServices: RiskManagerRootService = new RiskManagerRootService('admin'),
    public requirementThreatData: IPrimitiveStore<{ name: string; levelPseudoId: string }[]> = injectPrimitive([]),
  ) {}

  async loadRequirementThreatData() {
    const sortByName = function compare(
      a: { name: string; levelPseudoId: string },
      b: { name: string; levelPseudoId: string },
    ) {
      if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1;
      }
      if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1;
      }
      return 0;
    };

    const riskManagerData = await this.rootPrivateServices.manager.data
      .getByProjectId(this.baseDomain.ui.application.entity.projectId || '')
      .catch((error) => undefined);

    riskManagerData?.templateDataCopy?.requirementThreatsLevelSettings.levels.forEach((level) => {
      const risk = riskManagerData.risksCopy.find((risk) => risk.id === level.riskId);
      if (risk) {
        this.requirementThreatData.value.push({
          name: `${risk?.name} - ${level.name}`,
          levelPseudoId: level.pseudoId,
        });
        this.requirementThreatData.value.sort(sortByName);
      }
    });
  }
}
