import { observer } from 'mobx-react';
import React from 'react';

import { DataTable } from '../../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';

export interface RiskManagerDataTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerDataTable = observer(({ rootDomain }: RiskManagerDataTableProperties) => {
  return <DataTable tableHeight={800} dataTableDomain={rootDomain.dataTableDomain} />;
});
