export interface IJiraIssueFields {
  fields: IJiraIssueFieldsData[];
}

export interface IJiraIssueFieldsData {
  key: string;
  name: string;
  type: IJiraIssueFieldType;
  required: boolean;
  valueData: {
    isCanCustom: boolean;
    autocompleteUrl: string;
    autocompleteData: any;
  };
}

export enum IJiraIssueFieldType {
  notAllowed = 'notAllowed',
  singleRowString = 'singleRowString',
  multiRowString = 'multiRowString',
  stringArray = 'stringArray',
}
