import React from 'react';
import { Drawer, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CommonFilter } from '../../../../common/requirement/filter/RequirementFilter';
import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';
import { observer } from 'mobx-react';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';
import { Search } from '@mui/icons-material';

export interface FilterDrawerProperties extends ICommonProperties {
  tableDomain: RequirementEntityAdminDomain;
}

const FilterDrawer = observer(({ tableDomain }: FilterDrawerProperties) => {
  const windowInnerWidth = window.innerWidth;
  const buttonIndentRight = window.innerWidth > 1200 ? windowInnerWidth * 0.25 - 25 : 300 - 25;
  const appBarHeight = tableDomain.ui.toolBarHeight?.value || 128;
  const classes = useStyles();

  return (
    <>
      <IconButton
        style={{
          top: appBarHeight ? ` calc(${appBarHeight / 3}px + 50%)` : '50%',
          right: tableDomain.filterDomain.isOpenFilter.value ? buttonIndentRight : 10,
        }}
        className={classes.hideButton}
        onClick={() => {
          tableDomain.filterDomain.isOpenFilter.setValue(!tableDomain.filterDomain.isOpenFilter.value);
        }}
      >
        {tableDomain.filterDomain.isOpenFilter.value ? (
          <ArrowForwardIosIcon className="arrowIcon" />
        ) : (
          <FilterListIcon className="filterIcon" />
        )}
      </IconButton>
      <Drawer
        open={tableDomain.filterDomain.isOpenFilter?.value}
        anchor="right"
        variant="persistent"
        classes={{
          paper: classes.dialogPaper,
        }}
        PaperProps={{
          sx: {
            top: appBarHeight + 1,
            height: window.innerHeight - appBarHeight,
          },
        }}
      >
        <div className={classes.searchBlock}>
          <TextField
            style={{ width: '100%' }}
            size="small"
            defaultValue="Small"
            variant="outlined"
            maxRows={1}
            autoComplete={'off'}
            value={tableDomain.filterDomain.filterSearchTerm.value}
            onChange={(event) => {
              tableDomain.filterDomain.searchFiltersRequest(event?.target?.value || '');
            }}
            placeholder="Поиск по фильтрам"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              style: { fontSize: 13, paddingLeft: 10 },
            }}
            InputLabelProps={{ style: { fontSize: 13 } }}
          />
        </div>
        <div style={{ width: '100%', height: '95%', overflowY: 'auto' }}>
          <CommonFilter domain={tableDomain.filterDomain} />
        </div>
      </Drawer>
    </>
  );
});

export default FilterDrawer;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    padding: 10,
    minWidth: 300,
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    overflow: 'auto',
  },
  searchBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 10,
    fontSize: '13px',
  },
  searchTittle: {
    fontSize: 12,
    fontWeight: 100,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  hideButton: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1201,
    background: '#263238',
    transition: 'all 0.225s cubic-bezier(0, 0, 0.2, 1)',
  },
  arrowIcon: {
    transition: 'all 0.225s cubic-bezier(0, 0, 0.2, 1)',
  },
  filterIcon: {
    transition: 'all 0.225s cubic-bezier(0, 0, 0.2, 1)',
  },
}));
