import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerRootService, RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { IRiskManagerDataModel } from '../../../service/manager/data/IRiskManagerDataModel';
import { ProjectInfoDomain } from '../../../../../view/user/page/project/info/store/ProjectInfoDomain';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { IRiskManagerProjectInfoDomain } from './IRiskManagerProjectInfoDomain';
import { IContainer } from '../../../../../common/container/IContainer';
import { injectEntityList } from '../../../../../common/store/base/injectEntityList';

export const extendProjectInfoDomain = (
  Ref: new (...args: any[]) => ProjectInfoDomain,
): new (...args: any[]) => IRiskManagerProjectInfoDomain => {
  class ExtendedProjectFormDomainRef extends Ref {
    public riskManagersData: IEntityListStore<IRiskManagerDataModel> = injectEntityList([]);
    public isLoadRisks: IPrimitiveStore<boolean> = injectPrimitive(false);
    public isHaveAccessToRiskManager: IPrimitiveStore<boolean> = injectPrimitive(false);
    private riskManagerRootService: IRiskManagerRootService = new RiskManagerRootService('admin');
    constructor(layoutDomain: IMainLayoutDomainStore, container: IContainer) {
      super(layoutDomain, container);
    }

    async loadData(projectId?: string): Promise<void> {
      const isHaveAccessToRiskManager = this.layoutDomain.userHaveAnyAccess([
        'extension-risk-manager-settings-admin-read',
      ]);

      this.isHaveAccessToRiskManager.setValue(isHaveAccessToRiskManager);

      await super.loadData(projectId);
      const projectIds = this.ui.projects.list.map((projecct) => projecct.id);
      this.isLoadRisks.setValue(true);
      const riskManagerData = (
        await this.riskManagerRootService.manager.data
          .search({
            limit: 100000,
            fields: ['id', 'targetId', 'riskLevelsReportData', 'isComplete'],
            filter: {
              targetId: {
                any: projectIds,
              },
            },
          })
          .catch((error) => {
            console.log(error);
            return { data: [] as IRiskManagerDataModel[] };
          })
      ).data;
      this.riskManagersData.setList(riskManagerData);
      this.isLoadRisks.setValue(false);
    }
  }

  return ExtendedProjectFormDomainRef;
};
