import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { Chapter } from '../../../../passing/parts/theory/Chapter';
import { LearningUnitPreviewPageContext } from '../../../store/LearningUnitPreviewPageContext';
import { scrollToElement } from '../../../utils/scrollToElement';

export const PreviewTheory = observer(() => {
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const unit = unitPreviewDomain?.ui?.unit?.entity;
  const currentSubstep = unitPreviewDomain?.ui?.currentSubstep?.value;
  const classes = useStyles();

  useEffect(() => {
    scrollToElement(`a[href="#${currentSubstep as string}"] > div > h3`);
  }, [currentSubstep]);

  return (
    <div className={classes.theorySectionContent}>
      {unit?.theory?.chapters?.map((item) => (
        <a href={`#${item.title}`} className={classes.chapterWrapper}>
          <Chapter chapter={item} />
        </a>
      ))}
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  theorySectionContent: {
    width: '100%',
    padding: '24px',
  },
  chapterWrapper: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));
