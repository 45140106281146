import { observer } from 'mobx-react';
import React from 'react';

import { IPrimitiveStore } from '../../../../common/store/interface/IPrimitiveStore';
import { HighLightText } from './../../text/highlight/HighLightText';
import { AutocompleteSelectItem } from '../../form/FormMultiAutocomplete';

export const makeSingleAutoCompleteViewForTable = (
  selectData: (item: any) => string[],
  values: AutocompleteSelectItem[],
  term?: IPrimitiveStore<string>,
) => {
  return (rowData: any) => {
    const selected = selectData(rowData) || null;
    const selectedItem = values.find((value) => selected === value.value) || null;
    return <RenderText selectedItem={selectedItem} term={term} />;
  };
};

export const SingleAutoCompleteViewForTable = observer(({ selectData, values, term, rowData }: any) => {
  const selected = selectData(rowData) || null;
  const selectedItem = values.find((value: any) => selected === value.value) || null;
  return <RenderText selectedItem={selectedItem} term={term} />;
});

const RenderText = observer(({ selectedItem, term }: any) => {
  return <HighLightText text={selectedItem?.name || ''} terms={[term?.value || '']} />;
});
