import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { ITask, ITaskLanguage } from '../../../../../../../service/unit/interface/IUnitPractice';
import { IUnitPracticeUI } from './IUnitPracticeUI';
export class UnitPracticeUI implements IUnitPracticeUI {
  constructor(
    public tasks = injectEntityList<ITask>([]),
    public pickedTask = injectPrimitive<ITask>(null as any),
    public pickedLang = injectPrimitive<ITaskLanguage>(null as any),
    public contentPage = injectPrimitive<string>('task'),
    public isEnabled = injectPrimitive<boolean>(true),
  ) {}
}
