import { PerformerTypeAdminDomain } from './../../../view/admin/page/performer/type/store/PerformerTypeAdminDomain';

export const extendAdminPerformerTypeTableDomain = (
  Ref: new (...args: any[]) => PerformerTypeAdminDomain,
): new (...args: any[]) => PerformerTypeAdminDomain => {
  class ExtendedDomainRef extends Ref {
    setPermissions() {
      super.setPermissions();
      this.ui.isCanCreate.setValue(false);
    }
  }

  return ExtendedDomainRef;
};
