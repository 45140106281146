import { RestCRUDService } from '../../../../../service/common/rest/RestCRUDService';
import { IRiskManagerStaticQuestionModel } from './IRiskManagerStaticQuestionModel';
import { IRiskManagerStaticQuestionSearch } from './IRiskManagerStaticQuestionSearch';
import { IRiskManagerStaticQuestionService } from './IRiskManagerStaticQuestionService';

export class RestRiskManagerStaticQuestionService
  extends RestCRUDService<IRiskManagerStaticQuestionModel, IRiskManagerStaticQuestionSearch>
  implements IRiskManagerStaticQuestionService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/question/static`;
  }
}
