import { PerformerTypeAdminDomain } from '../../../view/admin/page/performer/type/store/PerformerTypeAdminDomain';
import { ProjectListPageUi } from '../../../view/user/page/project/list/store/ProjectListPageUi';

export const extendProjectListPageUiConstructor = (
  Ref: new (...args: any[]) => ProjectListPageUi,
): new (...args: any[]) => ProjectListPageUi => {
  class ExtendedDomainRef extends Ref {
    constructor() {
      super();
      this.tableSettings.value.showLastActive = false;
      this.tableSettings.value.showRequirementsCount = false;
    }
  }

  return ExtendedDomainRef;
};
