import React from 'react';

import { CoreContainer } from './CoreContainer';
import { ExtensionContainer } from './ExtensionContainer';
import { IContainer } from './IContainer';

export const coreContainer = new CoreContainer();
export const extensionContainer = new ExtensionContainer();

export const ContainerContext = React.createContext<IContainer>(coreContainer);
