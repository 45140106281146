import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../../../common/store/interface/IPrimitiveStore';
import { IPerformerTypeModel } from '../../../../../../../service/performer/type/IPerformerTypeModel';
import { IRequirementCategoryModel } from '../../../../../../../service/requirement/category/IRequirementCategoryModel';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { ISpecificationCategoryModel } from '../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { RequirementEntityModal } from './RequirementEntityModal';

export class RequirementEntityModalUI
  extends DataTableFormUI<IRequirementModel>
  implements IDataTableFormUI<IRequirementModel>
{
  constructor(
    public categories: IEntityListStore<IRequirementCategoryModel> = injectEntityList<IRequirementCategoryModel>([]),
    public specifications: IEntityListStore<ISpecificationEntityModel> = injectEntityList<ISpecificationEntityModel>(
      [],
    ),
    public specificationsCategories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
    public tagCategory: IEntityListStore<ITagCategoryModel> = injectEntityList<ITagCategoryModel>([]),
    public tags: IEntityListStore<ITagEntityModel> = injectEntityList<ITagEntityModel>([]),
    public performers: IEntityListStore<IPerformerTypeModel> = injectEntityList<IPerformerTypeModel>([]),
    public isCanGenerateShortName: IPrimitiveStore<boolean> = injectPrimitive<boolean>(true),
    public users: IEntityListStore<IUserEntityModel> = injectEntityList<IUserEntityModel>([]),
  ) {
    super();
    this.formComponent.setValue(RequirementEntityModal);
  }
}
