import { ITagCategoryModel } from '../../../../../../../service/tag/category/ITagCategoryModel';
import { DataTableFormUI } from '../../../../../../design/dataTable/parts/form/DataTableFormUI';
import { IDataTableFormUI } from '../../../../../../design/dataTable/parts/form/IDataTableFormUI';
import { TagCategoryAdminModal } from './TagCategoryAdminModal';

export class TagCategoryAdminModalUI
  extends DataTableFormUI<ITagCategoryModel>
  implements IDataTableFormUI<ITagCategoryModel>
{
  constructor() {
    super();
    this.formComponent.setValue(TagCategoryAdminModal);
  }
}
