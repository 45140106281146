import { ProjectInfoUI } from '../../../view/user/page/project/info/store/ProjectInfoUI';

export const extendProjectInfoUIConstructor = (
  Ref: new (...args: any[]) => ProjectInfoUI,
): new (...args: any[]) => ProjectInfoUI => {
  class ExtendedDomainRef extends Ref {
    constructor() {
      super();
      this.tableSettings.value.showRequirement = false;
      this.tableSettings.value.showStatuses = false;
    }
  }

  return ExtendedDomainRef;
};
