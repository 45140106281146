import { computed, observable } from 'mobx';

import { UIStore } from '../../../../../common/store/base/UIStore';
import { ILayoutDrawerUI } from './ILayoutDrawerUI';

export class LayoutDrawerUI extends UIStore implements ILayoutDrawerUI {
  @observable private componentState: any = null;
  @observable private isOpenState: boolean = false;
  @observable private modalOptions: { isFullWidth?: boolean; fullWidthMaxSize?: string } = {};

  @computed get activeDrawer() {
    return {
      component: this.componentState,
      isOpen: this.isOpenState,
    };
  }

  @computed get activeDrawerOptions() {
    return this.modalOptions;
  }

  showDrawer(modalContentComponent: Function, options?: { isFullWidth?: boolean; fullWidthMaxSize?: string }): void {
    this.componentState = modalContentComponent;
    this.modalOptions = options || {};
    this.isOpenState = true;
  }

  closeActiveDrawer() {
    this.isOpenState = false;
  }

  openActiveDrawer() {
    this.isOpenState = true;
  }
}
