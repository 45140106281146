import { IJiraIssueFieldType } from '../application/jira/IJiraIssueFieldsData';

export type ITaskManagerSettings = IJiraApplicationSettings | null;

export interface IJiraApplicationSettings {
  value?: IJiraApplicationAllSettingsTypes;
  type?: IJiraApplicationSettingsType;
}

type IJiraApplicationAllSettingsTypes = IJiraApplicationCommonSettings;

export interface IJiraApplicationCommonSettings {
  allowedInternalStatusesIds: string[];
  statusesMap: JiraApplicationStatusLink[];
  prioritiesMap: JiraApplicationPriorityLink[];
  riskTemplateId?: string | null;
  deletedStatus: JiraDeletedStatus,
  issue?: {
    namePrefix?: string | null;
    type?: {
      name?: string | null;
      id?: string | null;
    };
  };
  projectId?: string | null;
  projectKey?: string | null;
  customLabels: string[];
  fields?: JiraApplicationFieldData[];
}

export interface JiraApplicationStatusLink {
  internalStatusId: string;
  internalStatusValueId: string;
  externalStatusId: string;
  name?: string;
}

export interface JiraApplicationPriorityLink {
  tagId: string;
  orderIndex: number;
  isDefault: boolean;
  externalPriorityId: string;
  name?: string;
}

export enum IJiraApplicationSettingsType {
  applicationCommon = 'applicationCommon',
}

export interface JiraApplicationFieldData {
  fieldKey: string;
  fieldType: IJiraIssueFieldType;
  isRequired?: boolean;
  fieldValueSettings: JiraApplicationFieldValueData;
}

export interface JiraDeletedStatus {
  externalPriorityId: string;
  name?: string;
}

export interface JiraApplicationFieldValueData {
  type: JiraApplicationFieldDataValueType;
  settings: any;
}

export enum JiraApplicationFieldDataValueType {
  noAction = 'noAction',
  defaultFieldBehavior = 'defaultFieldBehavior',
  requirementShortName = 'requirementShortName',
}
