import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionAnswerPresetService } from '../../../../service/question/answerPreset/IRiskManagerQuestionService';
import { RiskManagerQuestionAnswerPresetDomain } from '../RiskManagerQuestionAnswerPresetDomain';
import { RiskManagerQuestionAnswerPresetContextMenuUI } from './RiskManagerQuestionAnswerPresetContextMenuUI';

export class RiskManagerQuestionAnswerPresetContextMenuDomain extends RowContextMenuDomain<IRiskManagerQuestionAnswerPresetModel> {
  public dataTableDomain: RiskManagerQuestionAnswerPresetDomain;
  constructor(
    entityService: IRiskManagerQuestionAnswerPresetService,
    dataTableDomain: RiskManagerQuestionAnswerPresetDomain,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerQuestionAnswerPresetModel> | null,
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerQuestionAnswerPresetContextMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateAnswerPresets();
  }
}
