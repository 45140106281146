import { IContainer } from '../../common/container/IContainer';
import { setDefaultRootContainer } from './setDefaultRootContainer';

export enum RootContainerType {
  default = 'default',
}

export async function rootContainerFactory(type: RootContainerType, container: IContainer) {
  if (RootContainerType[type] === RootContainerType.default) {
    container = await setDefaultRootContainer(container);
  }
  if (!RootContainerType[type]) {
    // @todo error handler
  }

  return container;
}
