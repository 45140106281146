import { IRiskManagerDataService } from './manager/data/IRiskManagerDataService';
import { RestRiskManagerDataService } from './manager/data/RestRiskManagerDataService';
import { IRiskManagerTemplateService } from './manager/template/IRiskManagerTemplateService';
import { RestRiskManagerTemplateService } from './manager/template/RestRiskManagerTemplateService';
import { IRiskManagerQuestionAnswerPresetService } from './question/answerPreset/IRiskManagerQuestionService';
import { RestRiskManagerQuestionAnswerPresetService } from './question/answerPreset/RestRiskManagerQuestionService';
import { IRiskManagerQuestionFieldService } from './question/field/IRiskManagerQuestionFieldService';
import { RestRiskManagerQuestionFieldService } from './question/field/RestRiskManagerQuestionService';
import { IRiskManagerQuestionService } from './question/IRiskManagerQuestionService';
import { RestRiskManagerQuestionService } from './question/RestRiskManagerQuestionService';
import { IRiskManagerStaticQuestionService } from './question/static/IRiskManagerStaticQuestionService';
import { RestRiskManagerStaticQuestionService } from './question/static/RestRiskManagerStaticQuestionService';
import { IRiskManagerRequirementThreatLinkService } from './requirement/IRiskManagerRequirementThreatLinkService';
import { RestRiskManagerRequirementThreatLinkService } from './requirement/RestRiskManagerRequirementThreatLinkService';
import { IRiskManagerRiskService } from './risk/IRiskManagerRiskService';
import { RestRiskManagerRiskService } from './risk/RestRiskManagerRiskService';

export interface IRiskManagerRootService {
  risk: IRiskManagerRiskService;
  question: {
    entity: IRiskManagerQuestionService;
    answerPreset: IRiskManagerQuestionAnswerPresetService;
    static: IRiskManagerStaticQuestionService;
    field: IRiskManagerQuestionFieldService;
  };
  manager: {
    template: IRiskManagerTemplateService;
    data: IRiskManagerDataService;
  };
  requirement: {
    link: IRiskManagerRequirementThreatLinkService;
  };
}

export class RiskManagerRootService implements IRiskManagerRootService {
  constructor(
    type: 'admin' | 'public',
    public risk = new RestRiskManagerRiskService(type),
    public manager = {
      data: new RestRiskManagerDataService(type),
      template: new RestRiskManagerTemplateService(type),
    },
    public question = {
      entity: new RestRiskManagerQuestionService(type),
      answerPreset: new RestRiskManagerQuestionAnswerPresetService(type),
      static: new RestRiskManagerStaticQuestionService(type),
      field: new RestRiskManagerQuestionFieldService(type),
    },
    public requirement = {
      link: new RestRiskManagerRequirementThreatLinkService(type),
    },
  ) {}
}
