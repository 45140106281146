import { Toolbar } from '@mui/material';
import React, { Fragment } from 'react';

export interface PageEmptyContentProperties {
  text: string;
  isFullPage?: boolean;
}

export const PageEmptyContent = ({ text, isFullPage = true }: PageEmptyContentProperties) => {
  return (
    <Fragment>
      {isFullPage && (
        <div>
          <Toolbar />

          <h1>{text}</h1>
        </div>
      )}
      {!isFullPage && (
        <div>
          <Toolbar />
          <h3>{text}</h3>
        </div>
      )}
    </Fragment>
  );
};
