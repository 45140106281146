import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { VendorEntityAdminTable } from '../../newVendor/VendorEntityAdminTable';
import { VendorRequirementAdminTable } from '../../requirement/newVendor/VendorRequirementAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const VendorSettingsItem: ISettingsItem = {
  id: 'vendor',
  settingsItem: {
    key: 'vendors',
    title: 'Требования внешних регуляторов',
    buttons: [
      {
        title: 'Категории',
        url: `/settings/vendor/category`,
        permissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
      },
      {
        title: 'Значения',
        url: `/settings/vendor/requirement`,
        permissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
    infoText:
      'Содержит перечень альтернативных описаний требований по информационной безопасности. Используется для связи формулировок требований из раздела "Шаблоны требований" с оригинальными формулировками требований законодательства, регуляторов и т.д.',
    orderIndex: 300,
  },
  routers: [
    {
      key: 'vendor-category-settings',
      path: '/settings/vendor/category',
      pageTitle: 'Категории каталога требований',
      component: VendorEntityAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
    },
    {
      key: 'vendor-requirements-entity-settings',
      path: '/settings/vendor/requirement',
      pageTitle: 'Каталог требований',
      component: VendorRequirementAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
    },
    {
      key: 'vendor-requirements-entity-bytype-settings',
      path: '/settings/vendor/requirement/system-type/:vendorType',
      pageTitle: 'Каталог требований',
      component: VendorRequirementAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
    },
    {
      key: 'vendor-requirements-entity-byid-settings',
      path: '/settings/vendor/requirement/:vendorId',
      pageTitle: 'Каталог требований',
      component: VendorRequirementAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-requirement-vendors-read']],
    },
  ],
};
