import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IEntityStore } from '../../../../../../common/store/interface/IEntityStore';
import { IPrimitiveStore } from '../../../../../../common/store/interface/IPrimitiveStore';
import { IMailEntityModel, authEnum } from '../../../../../../service/mail/IMailEntityModel';

export class MailAdminUI {
  constructor(
    public mailSettings: IEntityStore<IMailEntityModel> = injectEntity({
      authType: authEnum.Plain,
      host: '',
      name: '',
      password: '',
      port: 0,
      user: '',
      workstation: undefined,
      domain: undefined,
    }),
    public isCanCreate: IPrimitiveStore<boolean> = injectPrimitive(false),
    public isCanDelete: IPrimitiveStore<boolean> = injectPrimitive(false),
    public isCanEdit: IPrimitiveStore<boolean> = injectPrimitive(false),
    public isEmptySettings: IPrimitiveStore<boolean> = injectPrimitive(true),
  ) {}
}
