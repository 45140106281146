import { injectEntity } from '../../../../../../../common/store/base/injectEntity';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityStore } from '../../../../../../../common/store/interface/IEntityStore';
import { IPassingModel } from '../../../../../service/passing/interface/IPassingModel';
import { IPassingUI, IScoreCoefficient } from './IPassingUI';

export class PassingUi implements IPassingUI {
  constructor(
    public unitId = injectPrimitive<string>(''),
    public isLoading = injectPrimitive<boolean>(false),
    public isEmpty = injectPrimitive<boolean>(false),
    public title = injectPrimitive<string>(''),
    public passingPage = injectPrimitive<string>('theory'),
    public scoreCoefficient = injectPrimitive<IScoreCoefficient>(null as any),
    public passing: IEntityStore<IPassingModel> = injectEntity({
      id: undefined,
      createDate: undefined,
      isDeleted: false,
      quiz: {},
      practice: {},
      theory: [],
      settings: {
        contextId: '',
        characteristicsId: '',
        characteristicsIds: [],
        teamRolesIds: [],
        titleHeading: '',
        titleLead: '',
      },
      lastUpdateDate: undefined,
    }),
  ) {}

  startLoading(): void {
    this.isEmpty.setValue(false);
    this.isLoading.setValue(true);
  }
  endLoading(): void {
    this.isLoading.setValue(false);
  }
}
