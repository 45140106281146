import React from "react";
import { observer } from "mobx-react";
import { Autocomplete, CircularProgress, Grid, TextField, Theme } from '@mui/material';
import {
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  LabelList,
  Cell
} from 'recharts';
import { makeStyles } from '@mui/styles';
import { LegendChartLabel } from '../../LegendChartLable'
import { AnalyticsDomain } from "../../../store/AnalyticsDomain";
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

export const ApplicationVendorChart = observer(({ domain }: { domain: AnalyticsDomain }) => {
  const classes = useStyles();

  const isChartLoading = domain.ui.requirementsUi.isLoadingVendorDataChart.value || false
  const isChartDisabled = isChartLoading || !domain.ui.requirementsUi.currentVendorType?.value?.vendorName
  const isAllVendorsType = domain.ui.requirementsUi.currentVendorType?.value?.vendorId === 'allVendorRequirements'
  const data = domain.ui.requirementsUi.currentVendorTypeData.value
  const defaultValue = domain.ui.requirementsUi.currentVendorType?.value?.vendorName ?
    domain.ui.requirementsUi.currentVendorType.value :
    { vendorName: 'Нет регуляторов' }

  const totalVendorReqs = data[data.length - 1]?.totalVendorRequirementsCount + data[data.length - 1]?.vendorRequirementsIdsForStatusCount || 0
  const percentDone = Math.round(100 * data[data.length - 1]?.vendorRequirementsIdsForStatusCount / totalVendorReqs) || 0

  return (
    <Grid className={classes.vendorChartBlock}>
      <Grid item className={classes.vendorChartHeader}>
        <Grid >Процент реализации в АС требований регуляторов</Grid>
        <Grid className={classes.vendorChartTypeChanger}>
          <Autocomplete
            size="small"
            disableClearable
            loading={isChartLoading}
            loadingText={'Поиск Систем...'}
            disabled={isChartDisabled}
            options={domain.ui.requirementsUi.vendorTypeList.value}
            value={defaultValue}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {
                  option.vendorName
                }
              </li>
            )}
            getOptionLabel={(option) =>
              domain.ui.requirementsUi.currentVendorType?.value?.vendorName || 'Нет регуляторов'}
            renderInput={(params) =>
              <TextField
                {...params}
                multiline={false}
                variant="outlined"
                placeholder='Система'
                className={classes.applicationVendorAutocomplite}
              />}
            onChange={(e, value) => {
              domain.ui.requirementsUi.setVendorType(value)
            }}
          />
        </Grid>
      </Grid>
      <Grid height={'90%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        {isChartLoading ?
          <CircularProgress /> :
          data.length ?
            <>
              <Grid className={classes.vendorChartLegendBlock}>
                <Grid className={classes.aaa}>
                  Выполненные требования по месяцам
                </Grid>
                <Grid className={classes.vendorChartLegendStatusesBlock}>
                  <LegendChartLabel labelName={"Выполнено"} color="#66BB6A" />
                  <LegendChartLabel labelName={"План"} color="#434E54" />
                </Grid>
              </Grid>
              <ResponsiveContainer width={'100%'} height={220} >
                <ComposedChart
                  data={data}
                  margin={{
                    top: 24,
                  }}
                >
                  <Tooltip content={
                    isAllVendorsType
                      ? <TooltipForAllVendors />
                      : <CustomizedTooltip />
                  } />
                  <Bar
                    dataKey="vendorRequirementsIdsForStatusCount"
                    fill="#66BB6A"
                    name={"Выполненно"}
                    stackId="vendorBar"
                    maxBarSize={80}
                    radius={[5, 5, 0, 0]}
                    style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, marginTop: 10 }}
                  >
                    {data.map((entry, index) =>
                      entry.totalVendorRequirementsCount ? (
                        <Cell key={`cell-${index}`} fill={'#66BB6A'} radius={0} />
                      ) : (
                        <Cell key={`cell-${index}`} fill={'#66BB6A'} />
                      )
                    )}
                    <LabelList
                      fill='#FFFFFF'
                      position='insideTopLeft'
                      valueAccessor={(props) => {
                        let result
                        const totalVendorReqs = props.totalVendorRequirementsCount + props.vendorRequirementsIdsForStatusCount || 0
                        const percentDone = Math.round(100 * props.vendorRequirementsIdsForStatusCount / totalVendorReqs) || 0
                        if (props.vendorRequirementsIdsForStatusCount === 0 || percentDone < 15) {
                          result = null
                        } else {
                          result = props.vendorRequirementsIdsForStatusCount
                        }
                        return (
                          result
                        )
                      }}
                    />
                  </Bar>
                  <XAxis dataKey="date" fontSize={10} domain={[3, 12]} allowDataOverflow />
                  <YAxis fontSize={12} width={34} />
                  <Bar
                    dataKey="totalVendorRequirementsCount"
                    fill="#434E54"
                    name={"План"}
                    stackId="vendorBar"
                    maxBarSize={80}
                    radius={[5, 5, 0, 0]}
                    style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, marginTop: 10 }}
                  >
                    <LabelList
                      fill="white"
                      position='top'
                      textAnchor="left"
                      content={(props) => (<text
                        offset="5"
                        width="80"
                        height="114.33333333333331"
                        //@ts-ignore
                        x={props.x} y={props.y - 5}
                        text-anchor="start"
                        fill="#808080"
                      >{props.value || null}</text>)
                      }
                      valueAccessor={(props) => (
                        props.totalVendorRequirementsCount !== 0
                          ? props.totalVendorRequirementsCount + props.vendorRequirementsIdsForStatusCount
                          : null
                      )}
                    />
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
              <Grid className={classes.vendorChartInfoBlock}>
                <Grid className={classes.vendorChartInfo}>
                  <Grid className={classes.vendorChartInfoHeader}>
                    Всего требований системы
                  </Grid>
                  <Grid className={classes.vendorChartInfoText}>
                    {domain.ui.requirementsUi.currentApplicationData.entity.requirementCount}
                  </Grid>
                </Grid>
                <Grid className={classes.vendorChartInfo}>
                  <Grid className={classes.vendorChartInfoHeader}>
                    Всего требований регуляторов
                  </Grid>
                  <Grid className={classes.vendorChartInfoText}>
                    {totalVendorReqs}
                  </Grid>
                </Grid>
                <Grid className={classes.vendorChartInfo}>
                  <Grid className={classes.vendorChartInfoHeader}>
                    Процент выполнения
                  </Grid>
                  <Grid className={classes.vendorChartInfoText}>
                    {percentDone + '%'}
                  </Grid>
                </Grid>
              </Grid>
            </> : <div style={{ display: 'flex' }}>
              <RunningWithErrorsIcon sx={{ marginRight: '10px' }} />
              Требования регуляторов не найдены
            </div>
        }
      </Grid>
    </Grid >)
})

// todo - make reuseble
const CustomizedTooltip = observer((props) => {
  const classes = useStyles();

  const { active, payload } = props

  const lineData = payload.map((item, index) => {
    return <div>{item.name}: {item.value}</div>
  }).filter(item => item !== undefined)

  return (
    <>
      {active && payload && lineData.length > 0 &&
        <div className={classes.chartTooltip}>
          {lineData}
        </div >}
    </>
  )
})

const TooltipForAllVendors = observer((props) => {
  const classes = useStyles();
  const { active, payload } = props
  const lineData = payload[0]?.payload?.dataByVendor.map((item, index) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ marginRight: 10 }}>
          {item.vendorName}:
        </div>
        <div>
          {item.vendorRequirementsIdsForStatusCount}/{item.totalVendorRequirementsCount}
        </div>
      </div>
    )
  }).filter(item => item !== undefined)

  return (
    <>
      {active && payload && lineData.length > 0 &&
        <div className={classes.chartTooltip}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ marginRight: 10 }}>
              Регулятор:
            </div>
            <div>
              Выполнено/План
            </div>
          </div>
          {lineData}
        </div >}
    </>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  applicationVendorAutocomplite: {
    width: 240,
  },
  vendorChartBlock: {
    minWidth: 570,
    minHeight: 500, // изменить на 350
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 16px 24px',
    backgroundColor: '#29363C',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center'
  },
  vendorChartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    height: 40,
    width: '100%'
  },
  vendorChartLegendBlock: {
    margin: '16px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#FFFFFFB2'
  },
  vendorChartTypeChanger: {
    display: 'flex'
  },
  vendorChartLegendStatusesBlock: {
    display: 'flex',
    overflowX: 'auto',
    height: 36,
    flexWrap: 'nowrap'
  },
  timePickerBlock: {
    padding: 0,
    height: 30,
    minHeight: 30
  },
  timePickerTab: {
    width: 42,
    minWidth: 42,
    height: 25,
    minHeight: 25
  },
  chartTooltip: {
    backgroundColor: '#616161',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    borderRadius: 4,
    alignItems: 'flex-start',
    padding: 4
  },
  vendorChartInfoBlock: {
    width: '100%',
    minHeight: 100,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 16,
    marginTop: 24,
  },
  vendorChartInfo: {
    height: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    border: '1px solid #FFFFFF1A',
    borderRadius: '8px',
    color: '#FFFFFFB2',
    justifyContent: 'space-between'
  },
  vendorChartInfoHeader: {
    fontSize: '14px',
    color: '#FFFFFFB2',
  },
  vendorChartInfoText: {
    fontSize: '34px',
    color: '#FFFFFF',
  },
  aaa: {
    display: 'flex',
    alignItems: 'center',
  }
}));
