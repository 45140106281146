import { DataTableDomain } from '../../../../../view/design/dataTable/store/DataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerQuestionFieldModel } from '../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldSearch } from '../../../service/question/field/IRiskManagerQuestionFieldSearch';
import { IRiskManagerQuestionModel } from '../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../service/question/IRiskManagerQuestionSearch';
import { RiskManagerRootService } from '../../../service/RiskManagerRootService';
import { RiskManagerSettingsPageDomain } from '../../domain/RiskManagerSettingsPageDomain';
import { RiskManagerQuestionFieldContextMenuDomain } from './context/RiskManagerQuestionFieldContextMenuDomain';
import { RiskManagerQuestionFieldFormDomain } from './form/RiskManagerQuestionFormDomain';
import { RiskManagerQuestionFieldUI } from './RiskManagerQuestionFieldUI';

export class RiskManagerQuestionFieldDomain extends DataTableDomain<
  IRiskManagerQuestionFieldModel,
  IRiskManagerQuestionFieldSearch
> {
  public ui: RiskManagerQuestionFieldUI;

  constructor(
    public rootDomain: RiskManagerSettingsPageDomain,
    public layoutDomain: IMainLayoutDomainStore,
    private rootPrivateServices = new RiskManagerRootService('admin'),
  ) {
    super(layoutDomain, rootPrivateServices.question.field);
    this.ui = new RiskManagerQuestionFieldUI(this, null);
    this.modalDomain = new RiskManagerQuestionFieldFormDomain(rootPrivateServices, layoutDomain, this);
    this.contextMenuDomain = new RiskManagerQuestionFieldContextMenuDomain({
      entityService: rootPrivateServices.question.field,
      dataTableDomain: this,
      layoutDomain,
    });
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    });
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
  }

  async loadDependencies() {
    const answerPreset = await this.rootPrivateServices.question.answerPreset.search({ limit: 100000 });
    this.ui.answerPresets.setList(answerPreset.data);
  }
}
