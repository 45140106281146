import wretch from 'wretch';

import { IPassingModel } from './interface/IPassingModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';

wretch().errorType('json');

export class RestPassingService extends LearningCoreRestCRUDService<IPassingModel> {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/passing`;
  }
}
