import { DataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../view/design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { IRiskManagerRiskService } from '../../../../service/risk/IRiskManagerRiskService';
import { RiskManagerRiskAdminDomain } from '../RiskManagerRiskAdminDomain';
import { RiskManagerRiskAdminModalUI } from './RiskManagerRiskAdminModalUI';

export class RiskManagerRiskAdminModalDomain
  extends DataTableFormDomain<IRiskManagerRiskModel, IRiskManagerRiskService>
  implements IDataTableFormDomain<IRiskManagerRiskModel>
{
  public dataTableDomain: RiskManagerRiskAdminDomain;

  constructor(
    entityService: IRiskManagerRiskService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: RiskManagerRiskAdminDomain,
  ) {
    super(entityService, layoutDomain, dataTableDomain, new RiskManagerRiskAdminModalUI());
    this.dataTableDomain = dataTableDomain;
  }

  async onSaveUpdates() {
    await super.onSaveUpdates();
    if (this.ui.validationErrors.list.length === 0) {
      await this.dataTableDomain.rootDomain.onUpdateRisks();
    }
  }
}
