import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { injectEntityList } from '../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { MultiLineText } from '../../../../../view/design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../view/design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../view/design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../view/design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../view/design/dataTable/store/IDataTableUI';
import { IRiskManagerQuestionAnswerPresetModel } from '../../../service/question/answerPreset/IRiskManagerQuestionAnswerPresetModel';
import { IRiskManagerQuestionModel } from '../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../service/question/IRiskManagerQuestionSearch';
import { RiskManagerQuestionRowsUI } from './rows/DataTableRowsUI';

export class RiskManagerQuestionUI
  extends DataTableUI<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>
  implements IDataTableUI<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>
{
  constructor(
    public domain: IDataTableDomain<IRiskManagerQuestionModel, IRiskManagerQuestionSearch>,
    rows: IDataTableRowsUI<IRiskManagerQuestionModel, IRiskManagerQuestionSearch> | null,
    public answerPresets: IEntityListStore<IRiskManagerQuestionAnswerPresetModel> = injectEntityList<IRiskManagerQuestionAnswerPresetModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new RiskManagerQuestionRowsUI(this);
    this.tableRootPaperElevation.setValue(0);
  }

  async getColumns(): Promise<GridColumns> {
    return [
      {
        field: 'text',
        headerName: 'Текст вопроса',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'presetId',
        headerName: 'Шаблон ответа',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('presetId', params.value || '');
          return (
            <DataTableRowDependency
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        field: 'answersMap',
        headerName: 'Ответы',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: any }) => {
          return (
            <DataTableRowDependency
              searchTerm={this.rows.searchTerm.value}
              values={params.value.answers}
              getTextValue={(item: any) => item.text}
            />
          );
        },
      },

      {
        field: 'sortIndex',
        headerName: 'Порядок отображения',
        flex: 1,
        type: 'number',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
      },
    ];
  }
}
