import { IContainer } from './IContainer';

/**
 * @deprecated The method should not be used
 */
export const setValueInjection = (container: IContainer, token: any, value: any) => {
  container.setByToken(token, value);

  return container;
};
