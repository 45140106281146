import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import { ReactNode } from 'react';

import { ICommonProperties } from '../../../../common/properties/ICommonProperties';
import { Colors } from '../../view/design/colors/Colors';

export interface IApplicationThemeProperties extends ICommonProperties {
  children: ReactNode;
  isFlip?: boolean;
}
export const ApplicationTheme = ({ isFlip = false, children }: IApplicationThemeProperties) => {
  const theme = createProjectTheme(isFlip);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const createProjectTheme = (isFlip: boolean) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      secondary: {
        main: '#fff',
      },
      background: { paper: Colors.mainPassive, default: Colors.mainPassive },
      error: {
        main: Colors.negativePassive,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
    },
    typography: {
      fontFamily: ['Late', 'Roboto', 'Arial', 'sans-serif'].join(','),
    },
  });
  return theme;
};
