import { Theme } from '@emotion/react';
import { ArrowDropDown } from '@mui/icons-material';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { Button, Chip, InputAdornment, List, Paper, TextField, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useEffect } from 'react';

import { AddButton } from '../../../button/AddButton';
import { ResetFilters } from '../../../button/ResetFilters';
import { Colors } from '../../../color/Colors';
import { IDataTableDomain } from '../../store/IDataTableDomain';

export interface QuickSearchToolbarProperties<RowType, SearchRequestType> {
  tableDomain: IDataTableDomain<any, any>;
}

export const QuickSearchToolbar = <RowType, SearchRequestType>({
  tableDomain,
}: QuickSearchToolbarProperties<RowType, SearchRequestType>) => {
  const classes = useStyles();
  const [isOpenList, setIsOpenList] = useState(false);
  const handleDeleteItem = (id: any) => {
    const result = tableDomain.ui.rows.rowsCounter.value.filteredRow.filter((row: { id: any }) => {
      return row.id !== id;
    });
    tableDomain.ui.rows.rowsCounter.setValue({ counter: result.length, filteredRow: result });
    tableDomain.ui.rows.setSelectionEntities(result);
  };

  useEffect(() => {
    if (tableDomain.ui.rows.rowsCounter.value.counter === 0) {
      setIsOpenList(false);
      tableDomain.ui.isShown.setValue(false);
    }
  }, [tableDomain, tableDomain.ui.rows.rowsCounter.value.counter]);

  return (
    <div>
      <Toolbar className={classes.actions}>
        <div className={classes.bottomRow}>
          <TextField
            style={{ width: '200px', marginRight: 'auto' }}
            variant="standard"
            color="secondary"
            autoComplete={'off'}
            value={tableDomain.ui.rows.searchTerm.value}
            onChange={(event) => {
              tableDomain.requestSearch(event?.target?.value || '');
            }}
            placeholder="Поиск"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    tableDomain.requestSearch('');
                  }}
                >
                  <Clear
                    sx={{
                      height: '18px',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {tableDomain.ui.isShowResetFilters.value && !tableDomain.ui.isDefaultSortModel.value && (
            <ResetFilters onClick={tableDomain.resetVisibilityAndFilterModel} />
          )}
        </div>
        <div className={classes.buttons}>
          {tableDomain.ui.features.value.isCanCreate && tableDomain.ui.isCanCreate.value && (
            <AddButton dataCy="add-button" onClick={tableDomain.onHandleAdd} />
          )}

          {tableDomain.ui.rows.rowsCounter.value.filteredRow.length !== 0 && (
            <Button
              endIcon={<ArrowDropDown />}
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setIsOpenList(!isOpenList);
              }}
            >
              Выбрано элементов: {tableDomain.ui.rows.rowsCounter.value.counter}
            </Button>
          )}
        </div>
      </Toolbar>
      {isOpenList && (
        <div style={{ position: 'absolute', top: '56.5px', left: '218px', width: '235px', zIndex: 10 }}>
          <Paper elevation={3}>
            <List
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                overflowY: 'scroll',
                maxHeight: '320px',
              }}
            >
              {tableDomain.ui.rows.rowsCounter.value.filteredRow.map((row: any) => (
                <Chip
                  color="secondary"
                  label={row.name || row.shortName}
                  key={row.id}
                  variant="outlined"
                  style={{ marginBottom: '5px', padding: '2.5px 10px', justifyContent: 'space-between' }}
                  onDelete={() => {
                    handleDeleteItem(row.id);
                  }}
                />
              ))}
            </List>
            {tableDomain.ui.rows.rowsCounter.value.filteredRow.length !== 0 && (
              <Button
                variant="text"
                color="secondary"
                style={{ alignSelf: 'center', width: '100%', background: '#ffffff14' }}
                onClick={() => {
                  tableDomain.handleClearCheckBox();
                  setIsOpenList(false);
                }}
              >
                Очистить список выбранных
              </Button>
            )}
          </Paper>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    alignSelf: 'flex-end',
  },
  actions: {
    padding: '20px 10px 20px 15px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column-reverse',
  },
  button: {
    width: '220px',
    height: 'fit-content',
    marginRight: '10px',
    color: '#f4f4f4',
  },
  add: {
    marginBottom: '20px',
    marginRight: '10px',
  },
  delete: {
    backgroundColor: `${Colors.redButtonOpacity('0.6')} `,
    marginBottom: '20px',
  },
  buttons: {
    display: 'flex',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));
