import { Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export interface IFormTitleTextProperties extends ICommonProperties {
  text: string;
  description?: string;
}

export const FormTitleText = observer(({ text, description }: IFormTitleTextProperties) => {
  const classes = useStyles();
  return (
    <div className={classes.formText}>
      <Typography>{text}</Typography>
      {description && description.length && description.length > 0 && (
        <Typography variant={'subtitle2'}>{description}</Typography>
      )}
      <Divider variant="fullWidth" />
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  formText: {
    padding: theme.spacing(2),
  },
}));
