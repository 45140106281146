import { inject } from '../../../../../../../../common/container/inject';
import { IError } from '../../../../../../../../common/error/IError';
import { injectEntity } from '../../../../../../../../common/store/base/injectEntity';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../../service/RootAdminService';
import { IRouterService, RouterServiceToken } from '../../../../../../../../service/route/IRouterService';
import { ITaskManagerServer } from '../../../../../../../../service/taskManager/server/ITaskManagerServer';
import { LayoutNotificationType } from '../../../../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../../layout/main/store/domain/IMainLayoutDomainStore';

export class TaskManagerServerWebhookInfoDomain {
  constructor(
    private layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),

    public ui = {
      taskManager: injectEntity<ITaskManagerServer | null>(null),
    },
  ) {}

  async boot(taskManagerId: string | null) {
    await this.loadTaskManagerServer(taskManagerId);
  }

  async loadTaskManagerServer(taskManagerId: string | null) {
    if (taskManagerId) {
      const taskManagerServer = await this.rootPrivateServices.taskManager.server
        .getById(taskManagerId)
        .catch(this.errorsHandler);

      this.ui.taskManager.setEntity(taskManagerServer);
    }
  }

  errorsHandler = async (error: IError): Promise<any> => {
    if (error.webCode === '400') {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Ошибка валидации',
      });
    } else {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: 'Неизвестная ошибка',
      });
    }

    return null;
  };
}
