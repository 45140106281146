import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';

import { PassingQuestion } from '../../../../passing/parts/quiz/PassingQuestion';
import { LearningUnitPreviewPageContext } from '../../../store/LearningUnitPreviewPageContext';
import { scrollToElement } from '../../../utils/scrollToElement';

export const PreviewQuiz = observer(() => {
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const unit = unitPreviewDomain?.ui?.unit?.entity;
  const currentSubstep = unitPreviewDomain?.ui?.currentSubstep?.value;
  const classes = useStyles();

  useEffect(() => {
    scrollToElement(`#question_${currentSubstep}`);
  }, [currentSubstep]);

  return (
    <div className={classes.quizContainer}>
      {unit?.quiz?.questions?.map((item, index) => (
        <div id={`question_${index}`} className={classes.quizItem}>
          {/* @ts-ignore */}
          <PassingQuestion index={index} question={item} rootDomain={unitPreviewDomain} />
        </div>
      ))}
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  quizContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    padding: '24px',
  },
  quizTitle: {
    padding: '12px',
    color: 'rgba(255, 255, 255, 0.50)',
  },
  quizItem: {
    '& .MuiBox-root': {
      padding: '0 12px',
    },
  },
}));
