import { IRowContextMenuUI } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { RowContextMenuDomain } from '../../../../../../view/design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { IDataTableDomain } from '../../../../../../view/design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerRiskModel } from '../../../../service/risk/IRiskManagerRiskModel';
import { IRiskManagerRiskService } from '../../../../service/risk/IRiskManagerRiskService';
import { RiskManagerRiskAdminDomain } from '../RiskManagerRiskAdminDomain';
import { RiskManagerRiskMenuUI } from './RiskManagerRiskMenuUI';

export class RiskManagerRiskContextMenuDomain extends RowContextMenuDomain<IRiskManagerRiskModel> {
  public dataTableDomain: RiskManagerRiskAdminDomain;

  constructor(
    entityService: IRiskManagerRiskService,
    dataTableDomain: RiskManagerRiskAdminDomain,
    layoutDomain: IMainLayoutDomainStore,
    ui?: IRowContextMenuUI<IRiskManagerRiskModel> | null,
  ) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new RiskManagerRiskMenuUI(this);
    this.dataTableDomain = dataTableDomain;
  }

  async deleteById(rowId: string) {
    await super.deleteById(rowId);
    this.dataTableDomain.rootDomain.onUpdateRisks();
  }
}
