import { inject } from '../../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IRootAdminService, RootAdminServiceToken } from '../../../../../../../service/RootAdminService';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { IUserEntityService } from '../../../../../../../service/user/IUserEntityService';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormDomain } from '../../../../form/store/FormDomain';
import { UserAdminModel } from './UserAdminModel';

export class UserAdminFormDomain extends FormDomain<UserAdminModel, IUserEntityService> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootPrivateServices = inject<IRootAdminService>(RootAdminServiceToken),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public roles: IEntityListStore<{ name: string; value: string }> = injectEntityList([]),
  ) {
    super(layoutDomain, rootPrivateServices.user.entity);
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependencies();
    if (!this.ui.model.entity.systemRole) {
      this.ui.model.entity.systemRole = '';
    }
  }

  async loadDependencies() {
    const systemRoles = await this.rootPrivateServices.systemRole.entity.search({ limit: 1000000 });
    this.roles.setList(systemRoles.data.map((item) => ({ name: item.name || '', value: item.id || '' })));
  }

  save = async () => {
    if (this.ui.model.entity.password === '') {
      this.ui.model.entity.password = undefined;
    }
    await this.removeValidationErrors();
    await super.save();
    if (this.ui.validationErrors.list.length === 0) {
      this.router.goTo('/settings/user/entity');
    }
  };

  cancelEdit = async () => {
    this.router.goTo('/settings/user/entity');
  };
}
