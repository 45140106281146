import { AccessTime, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

export const PassingSidebarAccordion = observer(
  ({ onChange, isExpanded, onClick, title, timeLimitLabel, children }) => {
    const classes = useStyles();

    return (
      <Accordion onChange={onChange} expanded={isExpanded} className={classes.passingAccordion}>
        <AccordionSummary onClick={onClick} expandIcon={<ExpandMore />}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography>{title}</Typography>
            <Box sx={{ display: 'flex', gap: '6px' }}>
              <AccessTime />
              <Typography>{timeLimitLabel}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        {children}
      </Accordion>
    );
  },
);

const useStyles = makeStyles(() => ({
  passingBodySidebar: {
    width: '100%',
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
  },
  passingAccordion: {
    width: '100%',
    overflow: 'hidden',
    '&:hover': { background: 'rgba(25, 118, 210, 0.08)' },
    '&.Mui-expanded:first-of-type': { background: 'rgba(25, 118, 210, 0.08)' },
  },
}));
