import { IApplicationModel } from "../../../../service/application/entity/IApplicationModel";
import { IApplicationSearch } from "../../../../service/application/entity/IApplicationSearch";
import { RestCRUDService } from "../../../../service/common/rest/RestCRUDService";
import { CRUDServiceType } from "../../../../service/common/service/CRUDServiceType";


export class RestRatingService
  extends RestCRUDService<IApplicationModel, IApplicationSearch>
   {
  constructor(private type: CRUDServiceType) {
    super();
  }


  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/rating_app`;
  }

  async setSecondRating(applicationDataId: string): Promise<boolean> {
    await this.request()
      .url(`${this.getEndpoint()}/secondValue`)
      .post({applicationDataId})
      .fetchError(this.handleError)
      .json()
      .catch(this.handleError);

    return true;
  }

}
