import { ITagEntityModel } from '../../../../../../../service/tag/entity/ITagEntityModel';
import { RowContextMenuUI } from '../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuUI';
import { DefaultRowContextMenu } from '../../../../../../design/dataTable/parts/rowContextMenu/view/DefaultRowContextMenu';

export class TagEntityAdminContexMenuUI<RowType> extends RowContextMenuUI<ITagEntityModel> {
  constructor(contextDomain) {
    super(contextDomain);
    this.defaultContextMenu.setValue({
      Component: DefaultRowContextMenu,
      id: 'default',
      properties: {
        event: null,
        isOpened: false,
        row: null,
      },
    });
  }
}
