import { injectEntityList } from '../../../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../../../common/store/base/injectPrimitive';
import { IQuestion } from '../../../../../../../service/unit/interface/IUnitQuiz';

export class QuizUI {
  constructor(
    public questions = injectEntityList<IQuestion>([]),
    public activeQuestion = injectPrimitive<IQuestion>(null as any),
    public content = injectPrimitive<string>('question'),
    public isEnabled = injectPrimitive<boolean>(true),
  ) {}
}
