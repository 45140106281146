import wretch from 'wretch';

import { IQuizModel } from './IQuizModel';
import { LearningCoreRestCRUDService } from '../common/LearningCoreRestCRUDService';

wretch().errorType('json');

export class RestQuizService extends LearningCoreRestCRUDService<IQuizModel> {
  getEndpoint(): string {
    const url = this.learningConfig.url.api.learning;
    return `${url}/quiz`;
  }
}
