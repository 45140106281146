import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { IAdminPageProperties } from '../../../../view/admin/page/IAdminPageProperties';
import { DataTable } from '../../../../view/design/dataTable/DataTable';
import { RiskManagerSettingsPageDomain } from '../domain/RiskManagerSettingsPageDomain';
import { RiskManagerQuestionAnswerPresetDomain } from './domain/RiskManagerQuestionAnswerPresetDomain';

export interface IRiskManagerQuestionAnswerPresetTableProperties {
  rootDomain: RiskManagerSettingsPageDomain;
}

export const RiskManagerQuestionAnswerPresetTable = observer(
  ({ rootDomain }: IRiskManagerQuestionAnswerPresetTableProperties) => {
    return <DataTable tableHeight={800} dataTableDomain={rootDomain.answerPresetDomain} />;
  },
);
