import { Button, ButtonTypeMap } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { PropsWithChildren, memo } from 'react';

export type UiButtonStyle = 'outlined' | 'ghost' | 'primary' | 'secondary' | 'success' | 'danger' | 'light';
const LOADER_SIZES = {
  small: '1rem',
  medium: '1.25rem',
  large: '1.5rem',
};

type Props = {
  onClick?: () => void;
  pending?: boolean;
  contentClasses?: string;
  style?: UiButtonStyle;
  disabled?: boolean;
} & ButtonTypeMap['props'];

export const UiButton = memo(
  ({
    size = 'medium',
    style = 'primary',
    onClick,
    pending,
    children,
    contentClasses,
    disabled,
  }: PropsWithChildren<Props>) => {
    const classes = useStyles();
    const wrapperClasses = classNames(classes[style], classes.wrapperClasses);

    return (
      <Button disabled={disabled} size={size} className={wrapperClasses} onClick={onClick}>
        <div className={contentClasses}>{children}</div>
        {pending && <CircularProgress size={LOADER_SIZES[size]} />}
      </Button>
    );
  },
);

const useStyles = makeStyles(() => ({
  primary: {
    background: '#90CAF9',
    color: '#000',
    '&:hover': {
      background: '#74CEF9',
      color: '#000',
    },
  },
  secondary: {
    background: '#FFFFFF29',
    color: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF40',
    },
  },
  wrapperClasses: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}));
