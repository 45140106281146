import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { AdditionalColumnContentDomain } from './store/AdditionalColumnContentDomain';

export interface IAdditionalColumnContentEntityAdminTableProperties extends IAdminPageProperties { }

export const AdditionalColumnContentDataTable = observer(({ layoutDomain }: IAdditionalColumnContentEntityAdminTableProperties) => {

  useEffect(() => {
    layoutDomain.ui.pagePadding.setValue(0)
    return () => layoutDomain.ui.pagePadding.setValue(24)
  }, [])
  const [domain] = useState(new AdditionalColumnContentDomain(layoutDomain));
  return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
});
