import { DataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../../../../../../view/design/dataTable/parts/rows/IDataTableRowsUI';
import { IRiskManagerQuestionFieldModel } from '../../../../service/question/field/IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldSearch } from '../../../../service/question/field/IRiskManagerQuestionFieldSearch';
import { IRiskManagerQuestionModel } from '../../../../service/question/IRiskManagerQuestionModel';
import { IRiskManagerQuestionSearch } from '../../../../service/question/IRiskManagerQuestionSearch';
import { RiskManagerQuestionFieldDomain } from '../RiskManagerQuestionFieldDomain';
import { RiskManagerQuestionFieldUI } from '../RiskManagerQuestionFieldUI';

export class RiskManagerQuestionRowsFieldUI
  extends DataTableRowsUI<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>
  implements IDataTableRowsUI<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>
{
  constructor(public rootUI: RiskManagerQuestionFieldUI) {
    super();
  }

  getFieldDependenciesSettings(fieldName: string): {
    dependenciesModels: any[];
    valueField: string;
    activeField: string | null;
    isArray: boolean;
    customFilter?: (dependencyModels: any[]) => any;
    customMapper?: (dependencyModels: any[]) => any;
  } | null {
    // if (fieldName === 'presetId') {
    //   return {
    //     dependenciesModels: this.rootUI.answerPresets.list,
    //     valueField: 'name',
    //     activeField: null,
    //     isArray: false,
    //   };
    // }

    return null;
  }
}
