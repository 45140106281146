import { inject } from '../common/container/inject';
import { IRootPublicService, RootPublicServiceToken } from './public/RootPublicService';
import { IRootAdminService, RootAdminServiceToken } from './RootAdminService';

export enum ServiceType {
  admin = 'admin',
  user = 'user',
}

export function injectRootService(type: ServiceType): IRootPublicService | IRootAdminService {
  const injectToken = type === ServiceType.admin ? RootAdminServiceToken : RootPublicServiceToken;
  return inject<IRootPublicService | IRootAdminService>(injectToken);
}
