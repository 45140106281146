import { RestCRUDService } from '../../../../../service/common/rest/RestCRUDService';
import { IRiskManagerQuestionFieldModel } from './IRiskManagerQuestionFieldModel';
import { IRiskManagerQuestionFieldSearch } from './IRiskManagerQuestionFieldSearch';
import { IRiskManagerQuestionFieldService } from './IRiskManagerQuestionFieldService';

export class RestRiskManagerQuestionFieldService
  extends RestCRUDService<IRiskManagerQuestionFieldModel, IRiskManagerQuestionFieldSearch>
  implements IRiskManagerQuestionFieldService
{
  constructor(private type: 'admin' | 'public') {
    super();
  }

  getEndpoint(): string {
    const url = this.type === 'admin' ? this.config.url.api.admin : this.config.url.api.public;
    return `${url}/extension/risk/manager/question/field`;
  }
}
