export interface UserProjectItemView {
  id: string;
  title: string;
  subtitle: string;
  projectApplicationsCount: number;
  projectRequirementCount: number;
  lastActivity?: Date | null;
  status: UserProjectItemStatus;
  isUnitedWithApplication: boolean;
  unitedApplicationId: string | null;
  rolesMap: { data?: { roleId: string; userId: string }[] };
}

export enum UserProjectItemStatus {
  grow = 'grow',
  down = 'down',
  stable = 'stable',
}
