export enum UserProjectRoleAction {
  updateTeamProject = 'updateTeamProject',
  updateFieldsProject = 'updateFieldsProject',
  creteApplication = 'creteApplication',
  editApplication = 'editApplication',
  reportsAccess = 'reportsAccess',
  customRequirementsAccess = 'customRequirementsAccess',
  acceptCancelRequirementAccess = 'acceptCancelRequirementAccess',
  newMessagesNotification = 'newMessagesNotification',
  showUnverifiedRequirements = 'showUnverifiedRequirements',
  showRegulators = 'showRegulators',
  jiraIntegration = 'jiraIntegration',
}
