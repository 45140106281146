import { Grid } from '@mui/material';
import React from 'react';

import { AFTIcon } from '../../../../../design/icon/AFTIcon';
import { ProjectLink } from '../../../../../design/link/ProjectLink';
import { ProjectListDomain } from '../store/ProjectListDomain';

export const AllRequirementVendorLink = ({ domain }: { domain: ProjectListDomain }) => {
  return (
    <Grid item={true} xs={3}>
      <ProjectLink url="/settings/requirement/entity">
        <AFTIcon />
      </ProjectLink>
    </Grid>
  );
};
export const AllRequirementVendorLinkToken = Symbol.for('AllRequirementVendorLinkToken');
