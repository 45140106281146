import {
  Toolbar,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { FormAutocomplete } from '../../../../../view/design/form/FormAutocomplete';
import { IPageWithLayoutProperties } from '../../../../../view/layout/page/IPageWithLayoutProperties';
import { RiskManagerDataFormDomain } from './domain/RiskManagerDataFormDomain';
import { TaskManagerServerListAdminDomain } from '../../../../../view/admin/page/taskManager/server/connectinoTemplate/store/TaskManagerTemplateDomain';

export interface RiskManagerDataFormProperties extends IPageWithLayoutProperties {
  projectFormDomain: TaskManagerServerListAdminDomain;
  projectId: string;
}

export const RiskManagerDataJiraForm = observer((props: RiskManagerDataFormProperties) => {

  const [riskManagerDomain] = useState(new RiskManagerDataFormDomain(props.layoutDomain));
  useEffect(() => {
    riskManagerDomain.boot(props.projectFormDomain.riskTemplateId.value);
  }, [riskManagerDomain]);

  useEffect(() => {
    riskManagerDomain.setTemplate(props.projectFormDomain.riskTemplateId.value || null);
  }, [props.projectFormDomain.riskTemplateId.value]);

  return (
    <Fragment>
        <Fragment>
          <Fragment>
            <TemplateField domain={riskManagerDomain} jiraDomain={props.projectFormDomain} isCanEdit={true} />
          </Fragment>
        </Fragment>
      <Toolbar />
    </Fragment>
  );
});

const TemplateField = observer(({ domain, isCanEdit, jiraDomain }: { domain: RiskManagerDataFormDomain, isCanEdit: boolean, jiraDomain: TaskManagerServerListAdminDomain }) => {
  const values = [
    {
      name: 'Без анкеты рисков',
      value: null
    },
    ...domain.ui.templates.list.map((template) => ({
      value: template.id,
      name: template.name,
    }))
  ];

  useEffect(() => {
    domain.setTemplate(jiraDomain.riskTemplateId.value);
  }, [jiraDomain.riskTemplateId.value])

  useEffect(() => {
    jiraDomain.riskTemplateId.setValue(domain.ui.model.entity.templateId);
  }, [domain.ui.model.entity.templateId])

  return (
    <Fragment>
      <FormAutocomplete
        values={values}
        onChangeSelect={(newValue: any) => {
          if (jiraDomain.taskManager.entity?.connectionTemplate.settings?.value?.prioritiesMap) {
            jiraDomain.taskManager.entity.connectionTemplate.settings.value.prioritiesMap = []
          }
          domain.setTemplate(newValue.value);
        }}
        key="templateInputId"
        errorMessage={undefined}
        selected={domain.ui.model.entity.templateId}
        selectedDefault={null}
        label="Шаблон анкеты рисков"
        disabled={!isCanEdit}
      />
    </Fragment>
  );
});

