import { IError } from '../../../../../../common/error/IError';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IAdditionalColumnContentModel } from '../../../../../../service/additionalColumn/content/IAdditionalColumnContentModel';
import { IAdditionalColumnContentSearch } from '../../../../../../service/additionalColumn/content/IAdditionalColumnContentSearch';
import { IAdditionalColumnModel } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ITagCategoryModel } from '../../../../../../service/tag/category/ITagCategoryModel';
import { ITagCategorySearch } from '../../../../../../service/tag/category/ITagCategorySearch';
import TableHeader from '../../../../../design/dataTable/parts/appBar/TableHeader';
import { DataGridDetailDomain } from '../../../../../design/dataTable/parts/detail/DataGridDetailDomain';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AdditionalColumnContentUI } from './AdditionalColumnContentUI';
import { AdditionalColumnContentModalDomain } from './modal/AdditionalColumnContentModalDomain';

export class CustomAppBar {
  Toolbar = TableHeader;
}

export class AdditionalColumnContentDomain extends DataTableDomain<IAdditionalColumnContentModel, IAdditionalColumnContentSearch> {
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    public additionalColumns: IEntityListStore<IAdditionalColumnModel> = injectEntityList<IAdditionalColumnModel>(
      [],
    ),
    public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
  ) {
    super(layoutDomain, rootPrivateServices.additionalColumn.content);
    this.ui = new AdditionalColumnContentUI(this, layoutDomain);
    this.modalDomain = new AdditionalColumnContentModalDomain(rootPrivateServices.additionalColumn.content, layoutDomain, this);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle?.setValue('Дополнительные колонки');
    this.ui.tableSubtitle?.setValue('Контент');
  }

  async setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-additional-columns-update']]),
    );
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
  }

  protected async loadDependencies() {
    try {
      const defaultSearchQuery = { limit: 1000000 };
      this.ui.isLoading.setValue(true);

      const additionalColumnsListSearchResult = await this.rootService.additionalColumn.entity.search(
        defaultSearchQuery,
      );
      const requirementsListSearchResult = await this.rootService.requirement.entity.search({
        limit: 1000000,
        sort: {
          rules: [
            {
              isAsc: false,
              isDesc: true,
              name: 'isActive',
            },
            {
              isAsc: true,
              isDesc: false,
              name: 'shortName',
            },
          ],
        },
      });

      this.ui.isLoading.setValue(false);

      this.additionalColumns.setList(additionalColumnsListSearchResult.data);
      this.requirements.setList(requirementsListSearchResult.data);
    } catch (error) {
      return this.errorsHandler(error as IError<any>);
    }
  }

}
