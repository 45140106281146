import { IConfig } from '../../../../../application/config/IConfig';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { ServiceType } from '../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { INotificationDomain } from '../../../../user/page/notification/store/INotificationDomain';
import { ICommonLayoutDomain } from '../../../common/store/ICommonLayoutDomain';
import { IMainLayoutUIStore } from '../ui/IMainLayoutUIStore';
import { IEditProjectPermission } from './IEditProjectPermission';

export interface IMainLayoutDomainStore extends ICommonLayoutDomain {
  ui: IMainLayoutUIStore;
  loadLayoutData(): Promise<void>;
  checkPermissions(allowedPermissions: UserSystemRoleModelPermissionMap[]): Promise<void>;
  config: IConfig;
  loadLayoutData(allowedPermissions: UserSystemRoleModelPermissionMap[]): Promise<void>;
  logout(): Promise<void>;
  setPageTitle(title: string): void;
  setAllowedPermissions(allowedPermissions?: UserSystemRoleModelPermissionMap[]): void;
  serviceType: IPrimitiveStore<ServiceType>;
  getActiveUrl(): string;
  userHaveAnyAccess(permissions?: string[], projectPermissions?: { id: string; permissions: string[] }): boolean;
  notificationDomain: INotificationDomain;
  clearActiveUser: () => void;
  userHaveAnyAccessToEditProject: (projectId?) => IEditProjectPermission
  isSelected: IPrimitiveStore<string>;
}

export const MainLayoutDomainStoreToken = Symbol.for('MainLayoutDomainStoreToken');
