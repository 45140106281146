import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { PassingStepper } from '../../../../../design/passingStepper/PassingStepper';
import { IPassingTheoryDomain } from './store/IPassingTheoryDomain';
import { PassingTheoryStepperDomain } from './store/stepper/PassingTheoryStepperDomain';

export interface IPassingTheory {
  theoryDomain: IPassingTheoryDomain;
}
export const PassingTheory = observer(({ theoryDomain }: IPassingTheory) => {
  const [stepperDomain] = useState<PassingTheoryStepperDomain>(new PassingTheoryStepperDomain(theoryDomain));

  return <PassingStepper stepperDomain={stepperDomain} />;
});
