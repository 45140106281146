import { UserSystemRoleModelPermissionMap } from '../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';

export const ApplicationRouterExtensions: {
  path: string;
  pageTitle: string;
  component: any;
  allowedPermissions: UserSystemRoleModelPermissionMap[];
  showNavBar?: boolean;
  showPaddings?: boolean;
  fullHeight?: boolean;
  overflowY?: boolean;
}[] = [];
