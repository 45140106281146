import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { CommonFilter } from '../../../../../../common/requirement/filter/RequirementFilter';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationRequirementFilterDomain } from '../../store/filter/ApplicationRequirementFilterDomain';

export interface IFilterProperties extends ICommonProperties {
  domain: ApplicationRequirementFilterDomain;
  layoutDomain: IMainLayoutDomainStore;
}

export const ActiveRequirementTableFilter = observer(({ domain, layoutDomain }: IFilterProperties) => {
  return <CommonFilter domain={domain} layoutDomain={layoutDomain} />;
});
